import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import wbApi from "../../API/wbApi";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import SlidingPane from "react-sliding-pane";
import AddFooterSlider from "../../pages/sections/Edit_CustomFooter_Sec/AddFooterSlider";
import { useDispatch } from "react-redux";
import { getSelectedFooterData } from "../../redux/actions/FooterActions";
import EditFacilitiesSlider from "../../pages/sections/Edit_Facility_Sec/EditFacilitiesSlider";

interface IAutoWidthCardProps {
  serviceName: string;
  handleClick: () => void;
  editData: any
}
const AutoWidthCard: React.FC<IAutoWidthCardProps> = ({
  serviceName,
  handleClick,
  editData
}) => {

  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);

  // useEffect(() => { editData() }, [])
  editData()




  return (
    <>
      <div className="auto__width__card">
        <div className="left_section">
          <i className="bi bi-grip-vertical"></i>
          <div className="sec-name">{serviceName}</div>
        </div>

        <div className="right_section">
          <div className="button" onClick={() => { setOpen(true) }}>
            <i className="bi bi-pencil-square"></i>
          </div>

          <div className="button" onClick={() => { handleClick() }}>
            <i className="bi bi-trash"></i>
          </div>
        </div>
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <EditFacilitiesSlider
          onClose={() => {
            setOpen(false);
          }}
        />
      </SlidingPane>
    </>
  );
};

export default AutoWidthCard;
