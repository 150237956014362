import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";
import wbApi from "../../../API/wbApi";
import InputTextField from "../../../views/inputfield/InputTextField";
import { updateStateAfterNewAddedContact } from "../../../redux/actions/ContactAction";
import Button from "../../../views/buttons/Button";
import { validateEmail, validateLandline, validateMobile } from "../../../UtilityFunctions";

interface IContactSliderProps {
  onClose: () => void;
  allIcon: any[];
  contactsApi: () => void;
  // fetchSection: () => void;
}

const AddContactSlider: React.FC<IContactSliderProps> = ({
  onClose,
  allIcon,
  contactsApi
}) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const data = useSelector((state: RootState) => state.contactData);

  const dispatch = useDispatch()

  // States
  const [selectIcon, setSelectIcon] = useState<any>("");
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [addressName, setAddressName] = useState("");
  const [description, setDescription] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
  const [landLineNumber, setLandLineNumber] = useState("");
  const [landLineNumberHelperText, setLandLineNumberHelperText] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [whatsAppNumberHelperText, setWhatsAppNumberHelperText] = useState("");
  const [email, setEmail] = useState<any[]>([""]);
  const [emailHelperText, setEmailHelperText] = useState<any>("");
  const [sectId, setSectId] = useState("");
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  useEffect(() => {
    setFilteredResults(allIcon);
  }, []);


  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = allIcon.filter((item: any) => {
        return item.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allIcon);
    }
    setSearchInput(keyword);
  };

  const selectIconHandler = (icon: any) => {
    let icons = [];
    let selectedIcons = icons.push(icon);
    setSelectIcon(selectedIcons);
  };

  const addEmailHandler = () => {
    let addEmail = [...email, ""]
    setEmail(addEmail)
  };

  const handleChangeHandler = (e: any, idx: any) => {
    const Email = e.target.value;
    let emailsArr = [...email];
    emailsArr[idx] = Email;
    setEmail(emailsArr);
    if (!validateEmail(Email)) {
      setEmailHelperText("Invalid email")
    } else {
      setEmailHelperText("");

    }


  };



  const addContactHandler = async () => {
    if (emailHelperText.length === 0
      &&
      whatsAppNumberHelperText.length === 0
      &&
      landLineNumberHelperText.length === 0
      &&
      mobileNumberHelperText.length === 0) {
      setLoader(true);
      try {
        let addContactReq = await wbApi.post(
          "/wb/insert-update-wb-contact-details",
          {
            id: sectId ? sectId : "undefined",
            company_id,
            template_id,
            icon: selectIcon,
            address_name: addressName,
            description: description,
            mobile_no: mobileNumber,
            landline_no: landLineNumber,
            whatsapp_no: whatsAppNumber,
            email: email,
          }
        );
        // console.log("addContactReq", addContactReq);
        if (addContactReq.data.status === 1) {
          toast.success(addContactReq.data.message);
          dispatch(updateStateAfterNewAddedContact(nanoid()))
          onClose()
          contactsApi();
          setLoader(false);
        } else {
          toast.error(addContactReq.data.message);
          onClose()
          setLoader(false);
        }
      } catch (error) {
        toast.error("Something Went Wrong");
        setLoader(false);
        // console.log(error);
      }
    } else {
      toast.error("Please provide Valid values")
    }

  };


  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Contact</div>
      </div>
      <div className="height-64px"></div>
      <div className="search__booking__wrapper mb-0">
        <i className="bi bi-search"></i>
        <input
          placeholder="Search Icon"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }}
          onFocus={() => setShowIcon(true)}
          onBlur={() => setShowIcon(false)}

        />
      </div>
      {
        showIcon &&
        <div className="icon_wrapper">
          {filteredResults.map((item) => {
            return (
              <div
                key={item.id}
                onClick={() => {
                  selectIconHandler(item);
                }}
              >
                <i className={`${item.icon} me-2`}></i>
                {item.name}
              </div>
            );
          })}
        </div>

      }

      <div className="mt-2">
        <InputTextField
          label="Address Name"
          value={addressName}
          className="description description__slider"
          handleChange={(e) => {
            setAddressName(e.target.value);
          }}
        />
      </div>

      <div>
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>
      <div className="mt-2">
        <InputTextField
          label="Mobile Number"
          value={mobileNumber}
          helperText={mobileNumberHelperText}
          className="description description__slider"
          handleChange={(e) => {
            const { value } = e.target;
            setMobileNumber(value);
            if (validateMobile(value)) {
              setMobileNumberHelperText("");
            } else {
              setMobileNumberHelperText("Invalid Mobile Number");
            }

          }}
        />
      </div>

      <div className="mt-2">
        <InputTextField
          label="Landline Number"
          value={landLineNumber}
          helperText={landLineNumberHelperText}
          className="description description__slider"
          handleChange={(e) => {
            const { value } = e.target;
            setLandLineNumber(value);
            if (validateLandline(value)) {
              setLandLineNumberHelperText("");
            } else {
              setLandLineNumberHelperText("Invalid Land Line Number");
            }

          }}
        />
      </div>

      <div className="mt-2">
        <InputTextField
          label="WhatsApp Number"
          value={whatsAppNumber}
          helperText={whatsAppNumberHelperText}
          className="description description__slider"
          handleChange={(e) => {
            const { value } = e.target;
            setWhatsAppNumber(value);
            if (validateMobile(value)) {
              setWhatsAppNumberHelperText("")
            } else {
              setWhatsAppNumberHelperText("Invalid Mobile Number");
            }
          }}
        />
      </div>




      <div className="email__section__wrapper">
        <div className="email_button_wrapper">
          {email.map((email: any, index: any) => {
            return (

              <InputTextField
                label="Email"
                key={index}
                value={email}
                className="description description__slider"
                handleChange={
                  (e: any) => {
                    handleChangeHandler(e, index)
                  }
                }
                helperText={emailHelperText}
              />


            );
          })}
        </div>
        <div
          className="email__addbtn"
          onClick={(e: any) => {
            addEmailHandler();
          }}
        >
          <i className="bi bi-plus-lg"></i>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            handleClick={() => {
              addContactHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddContactSlider;
