import { UPDATE_CONTACT } from "../actionTypes";

const initialState = ''

const ContactReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_CONTACT:
            return action.payload



        default: return state
    }
}

export default ContactReducer;