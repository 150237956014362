import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React, { useCallback, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { Capitalize, CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import { HotelRoomsTypeDetailsAction } from "../../../redux/actions/SectionAction";
import { Draggable } from "react-drag-reorder";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";

const EditHotelRooms = () => {
  // Data From Redux
  const { company_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId, hotelRoomIdOnClick } = useSelector(
    (state: RootState) => state.sections
  );
  // States
  const [rooms, setRooms] = useState<any[]>([]);
  const [updateOrderrooms, setUpdateOrderRooms] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    fetchSection();
  }, []);

  const fetchSection = async () => {
    try {
      let SectionRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-room-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (SectionRes.data.status === 1) {
        setDefaultValue(SectionRes?.data?.default_hotel);
        let roomArray = SectionRes?.data?.room_details?.data?.filter((room: any) => room[0]?.hotel_id === hotelRoomIdOnClick.hotel_id);
        setRooms(roomArray[0]);
      } else {
        setDefaultValue({});
        setRooms([]);
      }
    } catch (error: any) {
      // toast.error(CATCH_MESSAGE);
      console.log(error);
    }
  };


  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderRoom = [...rooms];
    let temp = orderRoom[currentPos].id;
    orderRoom[currentPos].id = orderRoom[newPos].id
    orderRoom[newPos].id = temp
    setUpdateOrderRooms(orderRoom);
  };

  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (rooms?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {rooms?.map((item) => (
            <React.Fragment key={item.id}>
              <div className="reorder__cursor">
                <i className="bi bi-arrows-move"></i>
              </div>
              <div
                className="room_type_card_reorder_wrapper"
                onClick={() => {
                  navigate(
                    "/section-default/edit-room-details/edit-hotel-room-type"
                  );
                  dispatch(HotelRoomsTypeDetailsAction(item));
                }}
              >
                <div className="image__container">
                  <img
                    src={`https://d3ki85qs1zca4t.cloudfront.net/bookingEngine/${item?.room_image?.[0]}`}
                    alt={item.room_type}
                    className="room_type_image"
                  />
                </div>
                <div className="name-update-wrapper">
                  <div className="hotel__room__name">
                    {Capitalize(item.room_type)}
                  </div>
                  <div className="last-updated">
                    Last Updated:
                    {item?.updated_at
                      ?.split(" ")[0]
                      ?.split("-")
                      ?.reverse()
                      ?.join("-")}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </Draggable>
      );
    }
    return null;
  }, [rooms]);


  // Make Hotel Default Property

  const makeDefaultHotel = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let response = await wbApi.post(`/wb/make-hotel-default-for-section`, {
        id: defaultValue?.id ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        default_hotel_id: event.target.checked === true ? hotelRoomIdOnClick.hotel_id : "",
        section_id: defaultSectionId
      })
      if (response.data.status === 1) {
        toast.success(response.data.message);
        fetchSection();
      } else {
        toast.error(response.data.message);
      }

    } catch (error) {
      toast.error(CATCH_MESSAGE);

    }
  }

  // Api To Reorder Rooms
  const updateOrderHandler = async () => {
    setLoader(true);
    try {
      let response = await wbApi.post(`/ordering/order-wb-room`,
        {
          company_id: company_id,
          template_id: template_id,
          hotel_id: hotelRoomIdOnClick.hotel_id,
          room: updateOrderrooms,
        })
      if (response.data.status === 1) {
        toast.success(response.data.message);
        setLoader(false);
        fetchSection();
      } else {
        toast.error(response.data.message);
        setLoader(false);
      }

    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE)
    }

  }

  return (
    <>
      {/* ---------------------BreadCrumbs section----------------------- */}

      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Rooms",
              href: "/section-default/edit-room-details",
            },
            {
              label: `${hotelRoomIdOnClick.hotel_name}`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*----------------------- Main page setion----------------------- */}

      <div className="form-check mt-4">
        <label className="form-check-label" htmlFor="setDefaultHotel">
          Set as Default
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="setDefaultHotel"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeDefaultHotel(e)}
          checked={defaultValue?.default_hotel_id == hotelRoomIdOnClick}
        ></input>
      </div>


      {rooms?.length > 0 ? (
        <>
          <div className="default_section_image_card_container mt-3 mb-2">
            <DraggableRender />
          </div>
          <div className="room_reoder_update_button">
            <Button handleClick={() => updateOrderHandler()} className="updatee-btn" loader={loader}>Update Order</Button>
          </div>

        </>
      ) : (
        <NoDataComponent message="No Rooms Found" />
      )
      }

      {/* <div className="sixtypx-height"></div> */}
    </>
  );
};

export default EditHotelRooms;
