import { GET_SELECTED_FOOTER_DATA, EMPTY_FOOTER_DATA } from "../actionTypes";

const initialState = {
    id: null,
    title: '',
    description: '',
    icon: []
}

const FooterDataReducer = (state = initialState, action: any) => {
    // console.log(action)
    switch (action.type) {
        case GET_SELECTED_FOOTER_DATA:
            return { ...state, ...action.payload }

        case EMPTY_FOOTER_DATA:
            return {
                id: null,
                title: '',
                description: '',
                icon: []
            }


        default: return state
    }
}

export default FooterDataReducer;