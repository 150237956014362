import { GET_SELECTED_BRAND_DATA, EMPTY_BRAND_DATA, UPDATE_BRAND_STATE } from "../actionTypes"

const initialState = {
    brand_title: "",
    brand_description: "",
    brand_image_alt: "",
    brand_hotels: "",
    display_description: "",
    allHotels: [],
}

const BrandsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SELECTED_BRAND_DATA:
            return {
                ...action.payload,
            }
        case EMPTY_BRAND_DATA:
            return {
                // brand_title: "",
                // brand_description: "",
                // brand_image_alt: "",
                // brand_hotels: "",
                // display_description: "",
                allHotels: action.payload
            }
        // case UPDATE_BRAND_STATE:
        //     return action.payload

        default: return state
    }
}

export default BrandsReducer;