import React, { useEffect, useState } from "react";
import Button from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InputTextField from "../../../views/inputfield/InputTextField";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import InputSelectField from "../../../views/inputfield/InputSelectField";
import { updateStateAfterNewAdded } from "../../../redux/actions/BrandsAction";
import { nanoid } from "@reduxjs/toolkit";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

interface IAddBrandSliderProps {
  onClose: () => void;
  getBrandSectionData: () => void;
}

const AddBrandSlider: React.FC<IAddBrandSliderProps> = ({ onClose, getBrandSectionData }) => {
  const [title, setTitle] = useState('')
  const [altText, setAltText] = useState('')
  const [hotelNmae, setHotelNmae] = useState<any>('')
  const [description, setDescription] = useState('')
  const [selectedHotelsArr, setSelectedHotelsArr] = useState<any>([])
  const [selectedHotelName, setSelectedHotelName] = useState('')
  const [dataId, setDataId] = useState<any>("undefined")
  const [brandSectionBannerImg, setBrandSectionBannerImg] = useState<any>()
  const [brandLinkFile, setBrandLinkFile] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useDispatch()
  const navigate = useNavigate();


  const data = useSelector(
    (state: RootState) => state.allSects
  );

  const setFetchedData = () => {
    if (!data.brand_title) return
    else {
      setAltText(data.brand_image_alt)
      setTitle(data.brand_title)
      setDescription(data.brand_description)
      setSelectedHotelName(data.brand_hotels[0])
      if (data.id) {
        setDataId(data?.id)
      } else {
        setDataId("undefined")
      }
      if (data.brand_image) {
        setBrandSectionBannerImg([
          getImage(data.brand_image)
        ]);
      } else {
        setBrandSectionBannerImg([]);
      }

      const allHotels = data.allHotels
      const brandHotels = data.brand_hotels

      const arr: any = []
      const arr2: any = []
      brandHotels.map((id: number) => {
        const obj = allHotels.forEach((hotel: any) => {
          const { hotel_id, hotel_name } = hotel
          if (id === hotel_id) {
            arr.push(hotel_name)
            return
          }
        })
      })
      setHotelNmae([...arr])


      brandHotels.map((id: number) => {
        const obj = allHotels.forEach((hotel: any) => {
          const { hotel_id, hotel_name } = hotel
          if (id === hotel_id) {
            arr2.push(hotel_id)
            return
          }
        })
      })
      setSelectedHotelsArr([...arr2])
    }
  }

  useEffect(() => {
    if (!selectedHotelName) return
    setSelectedHotelsArr((prev: any) => [...prev, selectedHotelName])
  }, [selectedHotelName])

  useEffect(() => {
    setFetchedData()
  }, [])

  const selectHotelsFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedHotelName(e.target.value)
  }

  // Image capture and saving sectional details
  const accpBrandFiles = (value: any) => {
    setBrandLinkFile(value);
  };

  // This is for deleting fetched images
  const deleteBrandLinkFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deleteBrandLinkFilesApi();
            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const deleteBrandLinkFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-brand-image`, {
        company_id: company_id,
        image_name: data.brand_image,
        template_id: template_id,
      });
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        setBrandSectionBannerImg([]);
        getBrandSectionData();
        onClose();
      } else {
        toast.error(deleteRes.data.message);
        onClose();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Save Handler
  const saveHandler = async () => {
    if (brandLinkFile.length) {
      uploadHandler();
    } else {
      addNewBrand("undefined");
    }
  };

  // const Upload handler
  const uploadHandler = async () => {
    let payload = new FormData();
    payload.append("uploadFile[0]", brandLinkFile[0]);
    try {
      let uploadRes = await wbApi.post(
        `/wb/insert-dz-images/${company_id}/${template_id}/brand`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (uploadRes.data.status === 1) {
        await addNewBrand(uploadRes.data.image_ids);
      }
      else {
        toast.error(uploadRes.data.message);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  }


  // Add/edit brand
  const addNewBrand = async (imgId: any) => {
    setLoader(true);
    try {
      const resp = await wbApi.post(`wb/insert-update-wb-brand`, {
        brand_description: description,
        brand_image: imgId ?? "undefined",
        brand_image_alt: altText,
        brand_title: title,
        company_id: company_id,
        id: dataId ?? "undefined",
        template_id: template_id,
        hotels: selectedHotelsArr,
      })

      if (resp.data.status === 1) {
        toast.success(resp.data.message)
        dispatch(updateStateAfterNewAdded(nanoid()))
        setSelectedHotelsArr([])
        setRefreshList(!refreshList);
        getBrandSectionData();
        setLoader(false);
        onClose()
      }
      else {
        toast.error(resp.data.message);
        setLoader(false);
        onClose()
      }
    }
    catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE)
    }
  }


  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">{dataId !== "undefined" ? `Edit ${title}` : `Add Brand`}</div>
      </div>

      <div className="height-64px"></div>
      <InputTextField
        label="Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => setTitle(e.target.value)}
      />
      <InputTextField
        label="Alternate Text"
        value={altText}
        className="description description__slider"
        handleChange={(e) => setAltText(e.target.value)}
      />
      <InputSelectField
        label="Hotel Name"
        value={selectedHotelName}
        values={data.allHotels ? data.allHotels.map((obj: any) => obj.hotel_id) : []}
        handleChange={(e) => selectHotelsFunc(e)}
        options={data.allHotels ? data.allHotels.map((obj: any) => obj.hotel_name) : []}
      />

      <div className="mb-2 mt-2">Select Offer Image</div>

      <div className="slider__dropzone__height">
        <DropzoneArea
          centerMesage={"Drag your photos or click here (1 Image Only)"}
          incomingfiles={brandSectionBannerImg}
          dispatchFiles={(val) => accpBrandFiles(val)}
          isDelete={(val) => deleteBrandLinkFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
          refresh={refreshList}
        />
      </div>

      <div className="__default--input--field mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn" handleClick={() => onClose()}>Cancel</Button>
          <Button handleClick={() => saveHandler()} loader={loader}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default AddBrandSlider;
