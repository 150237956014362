import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextField from "../../../views/inputfield/InputTextField";
import Button from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import AutoWidthCard from "../../../views/datacard/AutoWidthCard";
import ColorPicker from "../../../views/colorpicker/ColorPicker";
import AddFooterSlider from "./AddFooterSlider";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { nanoid } from "@reduxjs/toolkit";
import {
  emptyFooterData,
  getSelectedFooterData,
} from "../../../redux/actions/FooterActions";
import AddFooterDataSlider from "../othersectionsliders/AddFooterDataSlider";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import EditFooterSlider from "./EditFooterSlider";
import { Draggable } from "react-drag-reorder";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";

const CustomFooter = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [slider, setSlider] = useState(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [color, setColor] = useState("#223189");
  const [altText, setAltText] = useState("");
  const [customFooterBackGroundImg, setCustomeFooterBackGroundImg] = useState<any>("");
  const [customFooterImg, setCustomeFooterImg] = useState<any[]>([]);
  const [incomingCustomFooterImg, setIncomingCustomeFooterImg] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [updateBenifit, setUpdateBenifit] = useState<any>({});
  const [updateOrderCustomFooter, setUpdateOrderCustomFooter] = useState<any[]>([]);
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const footerUpdate = useSelector((state: RootState) => state.footer);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const dispatch = useDispatch();

  const [footerData, setFooterData] = useState<any[]>([]);
  const [allIcons, setAllIcons] = useState([]);
  const [footerID, setFooterID] = useState("");
  const [update, setUpdate] = useState("");
  const [loader, setLoader] = useState<boolean>(false);

  const getFooterData = async () => {
    try {
      const resp = await wbApi(
        `wb-dashboard/fetch-wb-custom-footer-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setFooterData(resp?.data?.wb_custom_footer);
        setAllIcons(resp?.data?.all_icons);
        setColor(resp?.data?.custom_footer_details?.background_color);
        setAltText(resp?.data?.custom_footer_details?.background_image_alt);
        setFooterID(resp?.data?.custom_footer_details?.id);
        setCustomeFooterBackGroundImg(resp?.data?.custom_footer_details?.background_image)
        if (resp?.data?.custom_footer_details?.background_image) {
          setIncomingCustomeFooterImg([getImage(resp?.data?.custom_footer_details?.background_image)])
        } else {
          setIncomingCustomeFooterImg([])
        }
      } else {
        setFooterData([]);
        setAllIcons([]);
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error);
    }
  };

  useEffect(() => {
    getFooterData();
  }, [update, footerUpdate]);


  const accpAboutFiles = (value: any) => {
    setCustomeFooterImg(value);
  };

  const delteAboutFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteCustomFooterFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteCustomFooterFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: customFooterBackGroundImg,
          template_id: template_id,
          section_id: defaultSectionId,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        getFooterData();
      } else {
        toast.error(deleteRes.data.message);
        getFooterData();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  function saveHandler() {
    if (customFooterImg.length) {
      uploadHandler();
    } else {
      saveFooterData("undefined");
    }
  }

  const uploadHandler = async () => {
    if (customFooterImg.length === 1) {

      let payload = new FormData();

      payload.append("uploadFile[0]", customFooterImg[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/custom_footer_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await saveFooterData(uploadRes.data.image_ids);

        } else {
          toast.error(uploadRes.data.message);

        }
      } catch {
        toast.error(CATCH_MESSAGE);

      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };


  const saveFooterData = async (ImgIds: any) => {
    setLoader(true);
    try {
      const resp = await wbApi.post(
        `/wb/insert-update-default-section-details`,
        {
          background_color: color,
          background_image: ImgIds ?? "undefined",
          background_image_alt: altText,
          company_id,
          id: footerID ?? "undefined",
          section_description: "",
          section_id: defaultSectionId,
          section_title: "",
          template_id,
        }
      );
      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        setUpdate(nanoid());
        setRefreshList(!refreshList)
        setLoader(false);
      } else {
        toast.error(resp.data.message);
        setLoader(false);
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  const deleteFooterApi = async (id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const resp = await wbApi.post(`wb/delete-wb-custom-footer`, {
              company_id,
              id,
              template_id,
            });
            if (resp.data.status === 1) {
              toast.success(resp.data.message);
              setUpdate(nanoid());
            } else {
              toast.error(resp.data.message)
            };
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  console.log("footerData", footerData)

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderFooter = [...footerData];
    let temp = orderFooter[currentPos].id;
    orderFooter[currentPos].id = orderFooter[newPos].id
    orderFooter[newPos].id = temp
    setUpdateOrderCustomFooter(orderFooter);
  };
  const DraggableRender = useCallback(() => {
    if (footerData?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {footerData?.map((obj: any) => {
            const { title, id, description, icon } = obj;
            const OBJ = { title, id, description, icon };
            return (
              <div className="selected_benifits_wrapper" key={id}>
                <div className="left_icon_wrapper">
                  <i className="bi bi-grip-vertical"></i>
                </div>
                <div className="benifits_name">
                  {
                    title
                  }
                </div>
                <div className="benifits_button_wrapper">

                  <div className="button" onClick={() => {
                    setSlider(true);
                    setUpdateBenifit(obj);
                  }}>
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="button" onClick={() => deleteFooterApi(id)}>
                    <i className="bi bi-trash"></i>
                  </div>

                </div>
              </div>
            );
          }
          )}
        </Draggable>
      );
    }
    return null;
  }, [footerData]);

  const updateOrderBenifitsApi = async () => {
    setUpdateLoader(true);
    try {

      let orderRes = await wbApi.post(`/ordering/order-wb-custom-footer`, {
        company_id: company_id,
        template_id: template_id,
        custom_footer_order: updateOrderCustomFooter

      })
      if (orderRes.data.status === 1) {
        toast.success(orderRes.data.message);
        getFooterData();
        setUpdateLoader(false);
      } else {
        toast.error(orderRes.data.message);
        getFooterData();
        setUpdateLoader(false);
      }
    } catch {
      setUpdateLoader(false);
      toast.error(CATCH_MESSAGE)

    }
  }

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit  Custom Footer",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            setOpen(true);
          }}
        >
          Add Footer
        </Button>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner card__padding__custom__footer">
        <ColorPicker
          value={color}
          icon="bi bi-pencil-square"
          handleChange={(e) => {
            console.log(e.target);
          }}
          id="my1"
          className="custom-width-color-picker"
        />

        <div className="cfooter-text-container">
          <InputTextField
            label="Enter Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here(1 Image Only)"}
            incomingfiles={incomingCustomFooterImg}
            dispatchFiles={(val) => accpAboutFiles(val)}
            isDelete={(val) => delteAboutFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>
        <div className="edit-contact-btn-container">
          <Button className="cancel__btn" handleClick={() => navigate(-1)}>Cancel</Button>
          <Button
            className="savebtn"
            handleClick={() => saveHandler()}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">Customize Your Footer</div>

      <div className="benifits__auto__width__card__container">
        {
          footerData?.length > 0 ? <DraggableRender /> :
            <NoDataComponent message={"No Data Found"} />
        }
      </div>
      {
        footerData.length > 0 &&
        <div className="edit-contact-btn-container">
          <Button
            handleClick={() => {
              updateOrderBenifitsApi();
            }}
            loader={updateLoader}
          >
            Update Order
          </Button>
        </div>
      }

      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddFooterSlider
          onClose={() => {
            setOpen(false);
          }}
          allIcons={allIcons}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={slider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setSlider(false)}
      >
        <EditFooterSlider
          onClose={() => {
            setSlider(false);
          }}
          allIcons={allIcons}
          updateBenifit={updateBenifit}
        />
      </SlidingPane>
    </>
  );
};

export default CustomFooter;
