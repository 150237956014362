import React, { RefObject, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export interface IImgDropProps {
  id?: any;
  className?: string;
  imgEdit?: () => void;
  incomingfiles?: any[];
  dispatchFiles?: (val: any) => void;
  isDelete?: (val: boolean) => void;
  maxFiles?: number;
  maxSize?: number;
  centerMesage: string;
  acceptFileExt?: string;
  refresh?: boolean;
}

const DropzoneArea: React.FC<IImgDropProps> = ({
  id,
  className,
  imgEdit,
  incomingfiles = [],
  dispatchFiles,
  centerMesage,
  isDelete,
  acceptFileExt,
  refresh,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const navigate = useNavigate();
  const inputDropZone: RefObject<HTMLInputElement> = React.createRef();

  useEffect(() => {
    setFiles([]);
  }, [refresh])

  const onDragOver = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
  };

  function onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let filesArray: any = [...files];
    let file = event.dataTransfer.files;

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
    dispatchFiles?.(filesArray);

    // for (const key in file) {
    //   if (file[key]) {
    //     filesArray.push(file[key]);
    //   }
    // }

    // const filteredFiles = filesArray.filter((iteam: any) => {
    //   if (typeof iteam === "object") {
    //     return Object.assign(iteam, {
    //       preview: URL.createObjectURL(iteam),
    //     });
    //   }
    // });

    // setFiles(filteredFiles);
    // dispatchFiles(filteredFiles);
  }

  //   To Open Dialogue On Button Click
  const inputOpen = () => {
    const { current } = inputDropZone;
    (current || { click: () => { } }).click();
  };

  //   OnAcceptingFiles Through Click

  const acceptFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let filesArray: any = [...files];
    let file = e.target.files ?? {};

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
    dispatchFiles?.(filesArray);
  };

  // For Deleting Image;
  const onDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {
    if (e.target !== e.currentTarget) {
      e.stopPropagation();
      const newList = files?.filter((fw: any) => fw.name !== file);
      setFiles(newList);
      dispatchFiles?.(newList);
    }
  };

  const onDeleteIamge = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {
    if (e.target !== e.currentTarget) {
      e.stopPropagation();
      const newList = incomingfiles?.filter((fw: any) => fw !== file);
      dispatchFiles?.(newList ?? []);
      isDelete?.(true);
    }
  };

  return (
    <div
      id={id}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={inputOpen}
      className={`image__drag__drop__container ${className}`}
    >
      {files.length > 0 ? (
        <>
          <div className="image-preview-container">
            {files?.map((imgFile, idx) => (

              <div key={idx} className="image-preview-wrapper">
                <img src={imgFile?.preview} className="image-preview-size" />

                <div
                  className="overlay-effect"
                  onClick={(e) => {
                    if (e.target !== e.currentTarget) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <div
                    className="edit-delet-btn-warpper"
                    onClick={(e) => {
                      if (e.target !== e.currentTarget) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <div
                      className="img-delet-btn"
                      onClick={(e) => {
                        onDelete(e, imgFile?.name);
                      }}
                    >
                      <i className="bi bi-trash img-delete-icon"></i>
                    </div>

                    {/* {imgEdit ? (
                        <div
                          className="img-delet-btn"
                          onClick={(e) => {
                            imgEdit();
                          }}
                        >
                          <i className="bi bi-pencil-square img-edit-icon"></i>
                        </div>
                      ) : null} */}
                  </div>
                </div>
              </div>

            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      {incomingfiles?.length > 0 ? (
        <div className="image-preview-container">
          {incomingfiles?.map((imgFile, idx) => (

            <div key={idx} className="image-preview-wrapper">
              <img src={imgFile} className="image-preview-size" />
              <div className="overlay-effect">
                <div
                  className="img-delet-btn"
                  onClick={(e) => {
                    onDeleteIamge(e, imgFile);
                  }}
                >
                  <i className="bi bi-trash img-delete-icon"></i>
                </div>
                {/* {imgEdit ? (
                    <div
                      className="img-delet-btn"
                      onClick={(e) => {
                        imgEdit();
                      }}
                    >
                      <i className="bi bi-pencil-square img-edit-icon"></i>
                    </div>
                  ) : null} */}
              </div>
            </div>

          ))}
        </div>
      ) : (
        <></>
      )}

      {files.length === 0 && incomingfiles.length === 0 ? (
        <div
          className="image_drag_area"
        // onClick={inputOpen}
        >
          <i className="bi bi-images"></i>
          <div className="text-area">{centerMesage}</div>
        </div>
      ) : (
        <></>
      )}
      <input
        id=""
        ref={inputDropZone}
        type="file"
        style={{ visibility: "hidden" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          acceptFileHandler(e);
        }}
        multiple
        accept={acceptFileExt}
      />
    </div>
  );
};

export default DropzoneArea;
