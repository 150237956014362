import { GET_HOTEL_DATA_AND_FACILITY, UPDATE_FACILITY_STATE, UPDATE_FACILITY_ARRAY, TAKE_FACILITY_NAME } from "../actionTypes";

export const getAllHotelsAndFacility = (data: any) => {
    return (
        {
            type: GET_HOTEL_DATA_AND_FACILITY,
            payload: data,
        }
    )
}
export const takeFacilityName = (data: any) => {
    return (
        {
            type: TAKE_FACILITY_NAME,
            payload: data
        }
    )
}
export const updateStateAfterNewFacilityAdded = (data: any) => {
    return (
        {
            type: UPDATE_FACILITY_STATE,
            payload: data
        }
    )
}
export const updateFacilityArr = (data: any) => {
    return (
        {
            type: UPDATE_FACILITY_ARRAY,
            payload: data
        }
    )
}

