import React, { useEffect } from "react";
import {
  DirectLogInAction,
  LoginSuccess,
} from "../../redux/actions/LoginAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import wbApi from "../../API/wbApi";
import { TemplateId } from "../../redux/actions/TemplateIdAction";
const Loginwithoutcredential = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let login_data: any = useParams();

  useEffect(() => {
    let send_login_data = {
      admin_id: atob(login_data.admin_id),
      auth_token: login_data.auth_token,
      company_id: atob(login_data.company_id),
      full_name: atob(login_data.full_name),
      hotel_name: atob(login_data.hotel_name),
      hotel_id: atob(login_data.hotel_id),
    };
    getUserTemplate(
      send_login_data.company_id,
      send_login_data.auth_token,
      send_login_data
    );
  }, [login_data]);

  const getUserTemplate = async (
    companyId: any,
    authToken: any,
    send_login_data: any
  ) => {
    try {
      let templateResponse = await wbApi.get(
        `/wb/check-user-template/${companyId}`,
        {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        }
      );

      if (templateResponse.data.status === 1) {
        dispatch(LoginSuccess(send_login_data));
        dispatch(TemplateId(templateResponse.data.usertemplate.template_id));
        dispatch(DirectLogInAction(true));
        navigate("/");
      }
    } catch (error) {}
  };
  return <></>;
};
export default Loginwithoutcredential;
