import React from "react";
export interface IWbSecCardProps {
  children?: any;
  update?: any;
  isInUse: boolean;
  onEditClick?: (e: any) => void;
}

const WbSectionCard: React.FC<IWbSecCardProps> = ({
  children,
  update,
  isInUse,
  onEditClick,
}) => {
  return (
    <div className="wrapper">
      <div className="card-sec-top">
        <i className="bi bi-file-earmark-richtext"></i>
        {isInUse ? <div className="active">IN USE</div> : <></>}
        <div className="overlay">
          <div className="button__container">
            <div className="button" onClick={onEditClick}>
              <i className="bi bi-pencil-square bi-icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="page-title-update-wrapper">
        <div className="page-titel">{children}</div>
        <div className="page-update">{update}</div>
      </div>
    </div>
  );
};

export default WbSectionCard;
