import { GET_SELECTED_BLOG_DATA, EMPTY_BLOG_DATA, UPDATE_BLOG_STATE } from "../actionTypes";

export const getSeletedBlogsData = (data: any) => {
    return (
        {
            type: GET_SELECTED_BLOG_DATA,
            payload: data,
        }
    )
}
export const emptyBlogData = () => {
    return (
        {
            type: EMPTY_BLOG_DATA,
            // payload: data
        }
    )
}
export const updateStateAfterNewAddedBlog = (data: any) => {
    return (
        {
            type: UPDATE_BLOG_STATE,
            payload: data
        }
    )
}

