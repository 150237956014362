import { CANCEL_CONFIRMATION_PROMPT, DISABLE_SWITCH_PROPERTY_SCREEN, ENABLE_CONFIRMATION_PROMPT, ENABLE_SWITCH_PROPERTY_SCREEN } from "../actionTypes"

export const ShowConfirmationPrompt = (message: string, action: string, onAccept: any) => {
    return ({
        type: ENABLE_CONFIRMATION_PROMPT,
        message: message,
        action: action,
        onAccept: onAccept
    })
}

export const CancelConfirmationPrompt = () => {
    return ({
        type: CANCEL_CONFIRMATION_PROMPT
    })
}

export const EnableSwitchPropertyScreen = () => {
    return ({
        type: ENABLE_SWITCH_PROPERTY_SCREEN
    })
}

export const DisableSwitchPropertyScreen = () => {
    return ({
        type: DISABLE_SWITCH_PROPERTY_SCREEN
    })
}