import { UPDATE_FOOTER } from "../actionTypes";

const initialState = ''

const FooterReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_FOOTER:
            return action.payload


        default: return state
    }
}

export default FooterReducer;