import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Map from "../../../assests/img/map.png";
import SlidingPane from "react-sliding-pane";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import InputTextField from "../../../views/inputfield/InputTextField";
import AddNearByPlaces from "./AddNearByPlaces";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../../views/datacard/CardWrapper";
import Button from "../../../views/buttons/Button";
import GoogleMapReact from "google-map-react";

const EditNearPlacesByMap = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  // const { nearby_places_hotelId } = useSelector(
  //   (state: RootState) => state.sections
  // );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector((state: RootState) => state.sections);
  const { current_property } = useSelector((state: RootState) => state.properties);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [nearbyData, setNearbyData] = useState<any>();
  const [mapCenter, setMapCenter] = useState({ lat: 26, lng: 78 });
  const [mapZoom, setMapZoom] = useState(5);
  const [dragging, setDragging] = useState(false);
  const [showMarkerLabel, setShowMarkerLabel] = useState(false);
  const [showSubmitAddress, setShowSubmitAddress] = useState(false);
  const [showUseLocation, setShowUseLocation] = useState(false);

  // states
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();
  const [nearbyPlaces, setNearbyPlaces] = useState<any>([]);
  const [thisHotelNearbyObj, setThisHotelNearbyObj] = useState<any>([]);
  const [defaultStatus, setDefaultStatus] = useState<any>({});


  const nearbyPlacesApi = async () => {
    try {
      const resp = await wbApi.get(`wb-dashboard/fetch-wb-nearby-places/${company_id}/${template_id}/${defaultSectionId}`)
      if (resp.data.status === 1) {
        setNearbyPlaces(resp.data.nearby_places);
        setDefaultStatus(resp.data.default_hotel)
      }
      else {
        setNearbyPlaces([]);
        setDefaultStatus("undefined");
      }
    }
    catch {
      toast.error(CATCH_MESSAGE)
    }
  }

  const editHandler = (obj: any) => {
    obj.mode = "Edit";
    setNearbyData(obj);
    setOpenEdit(true);
  }

  const thisHotelNearbyFind = (nearbyPlaces: any) => {
    nearbyPlaces.forEach((obj: any) => {
      if (obj?.hotel_id === current_property.hotel_id) {
        setThisHotelNearbyObj(obj);
      }
    })
  }

  useEffect(() => {
    setLatitude(current_property.latitude);
    setLongitude(current_property.longitude);
    nearbyPlacesApi();
  }, [])

  useEffect(() => {
    thisHotelNearbyFind(nearbyPlaces);
  }, [nearbyPlaces])

  const makeDefaultCheck = async (event: any) => {
    try {
      let defaultRes = await wbApi.post(`/wb/make-hotel-default-for-section`, {

        id: defaultStatus.id ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        default_hotel_id: event.target.checked ? current_property.hotel_id : "",
        section_id: defaultSectionId

      })
      if (defaultRes.data.status === 1) {
        toast.success(defaultRes.data.message);
        nearbyPlacesApi();
      } else {
        toast.error(defaultRes.data.message);
        nearbyPlacesApi();
      }

    } catch {
      toast.error(CATCH_MESSAGE);
    }
  }

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: "Edit Near By Places",
              href: "/section-default/edit-nearbyplaces"
            },
            {
              label: `${current_property.hotel_name}`,

            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      <div className="top__button__container">
        <div className="form-check default_label me-2">
          <label className="form-check-label mt-2" htmlFor="setDefault">
            Set as Default
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="setDefault"
            onChange={(e) => makeDefaultCheck(e)}
            checked={defaultStatus.default_hotel_id === current_property.hotel_id}
          ></input>
        </div>
        <Button
          icon="bi bi-info-circle"
          className="me-3"
          handleClick={() => {
            setOpen(true);
          }}
        >
          Add Nearby Places
        </Button>
      </div>

      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-promo-alt-label mt-3">
          Edit Hotel Map Location
        </div>
        <div className="near__place__map__container">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBceMBt5GputUmynEHqVn0L8rRByrIFU5M",
            }}
            options={{ mapTypeId: "roadmap", fullscreenControl: false }}
            // layerTypes={["TrafficLayer", "TransitLayer"]}
            center={mapCenter}
            zoom={mapZoom}
            onDrag={() => {
              setDragging(true);
              setShowMarkerLabel(false);
            }}
            onDragEnd={() => setDragging(false)}
            onClick={() => setShowUseLocation(false)}
            onChange={(e: any) => setMapCenter(e.center)}
          ></GoogleMapReact>
        </div>
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Latitude"
            value={latitude}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setLatitude(e.target.value)
            }}
          />
          <InputTextField
            label="Longitude"
            value={longitude}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setLongitude(e.target.value)
            }}
          />
        </div>

        <div className="edit-contact-btn-container mt-3">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button className="savebtn">Save</Button>
        </div>
      </CardWrapper>
      <div className="select__hotel__label">Nearby Places</div>
      <div className="default_section_image_card_container mt-3">
        {thisHotelNearbyObj?.places_details?.map((obj: any, id: number) => (
          <div className="default__setion__image mr-3" key={id}>
            <div className="image__container">
              <img src={Map} />
              <div className="overlay overlay-border-radius">
                <div className="button__container">
                  <div
                    className="edit-button"
                    onClick={() => {
                      editHandler(obj)
                    }}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="delete-button" onClick={() => { }}>
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="hotel__name">{obj.place_name}</div>
          </div>
        ))}
      </div>
      <div className="sixtypx-height"></div>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddNearByPlaces
          onClose={() => {
            setOpen(false);
          }}
          nearbyData={{ "mode": "Add" }}
          thisHotelNearbyObj={thisHotelNearbyObj}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openEdit}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddNearByPlaces
          onClose={() => {
            setOpenEdit(false);
          }}
          nearbyData={nearbyData}
          thisHotelNearbyObj={thisHotelNearbyObj}
        />
      </SlidingPane>
    </>
  );
};

export default EditNearPlacesByMap;
