import { UPDATE_PROMOTION } from "../actionTypes";

const initialState = ''

const PromotionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_PROMOTION:
            return action.payload


        default: return state
    }
}

export default PromotionReducer;