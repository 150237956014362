import React from "react";
import { CreateRipple } from "./NextButton";

interface IButtonProps {
  children: React.ReactNode;
  loader?: boolean;
  id?: string;
  icon?: string;
  handleClick?: () => void;
  className?: string;
  endIcon?: string;
  isAccessible?: any;
}

const Button: React.FC<IButtonProps> = ({
  children,
  id,
  icon,
  className,
  handleClick,
  loader,
  isAccessible = true,
}) => {
  return (
    isAccessible &&
    (loader ? (
      <div
        id={id}
        className={`primary-btn ${className} inactiveBtn`}
        onClickCapture={CreateRipple}
      >
        {icon ? (
          <div className="icon-wrapper">
            <i className={icon}></i>
          </div>
        ) : null}
        <div className="label-wrapper">{children}</div>
        <span
          className="spinner-border spinner-border-sm pd-4"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    ) : (
      <div
        id={id}
        className={`primary-btn ${className}`}
        onClick={handleClick}
        onClickCapture={CreateRipple}
      >
        {icon ? (
          <div className="icon-wrapper">
            <i className={icon}></i>
          </div>
        ) : null}
        <div className="label-wrapper">{children}</div>
      </div>
    ))
  );
};
export default Button;
