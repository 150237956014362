import React, { useEffect, useState } from "react";
import Button from "../../../views/buttons/Button";
import { DropZone } from "../../../views/dropzone/DropZone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import InputTextField from "../../../views/inputfield/InputTextField";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { updateStateAfterNewAddedBlog } from "../../../redux/actions/BlogsAction";
import { nanoid } from "@reduxjs/toolkit";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { useNavigate } from "react-router-dom";

interface IAddBlogSliderProps {
  onClose: () => void;
  getBlogsData: () => void;
}
const AddBlogSlider: React.FC<IAddBlogSliderProps> = ({ onClose, getBlogsData }) => {
  const [title, setTitle] = useState<string>('')
  const [altText, setAltText] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [blogImage, setBlogImage] = useState<any[]>([])
  const [loader, setLoader] = useState<boolean>(false);

  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  // const [blogsArr, setBlogsArr] = useState([])
  // const [blogsSectData, setBlogsSectData] = useState<any>(null)
  const [blogsId, setBlogsId] = useState('')
  // const [update, setUpdate] = useState('')

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);


  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }
  const accpAboutFiles = (value: any) => {
    setBlogImage(value);
  };

  function saveHandler() {
    if (blogImage.length) {
      uploadHandler();
    } else {
      AddBlogApi("undefined");
    }
  }
  const uploadHandler = async () => {
    if (blogImage.length === 1) {


      let payload = new FormData();

      payload.append("uploadFile[0]", blogImage[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/hotelblog`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await AddBlogApi(uploadRes.data.image_ids);

        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };


  const AddBlogApi = async (imgId: any) => {
    setLoader(true);
    try {
      const payload = {
        blog_description: description,
        blog_image: imgId ?? 'undefined',
        blog_image_alt: altText,
        blog_title: title,
        company_id,
        template_id,
        id: blogsId ? blogsId : 'undefined',

        banner: "", banner_alt: "", banner_title: '', page_description: "", page_title: '', seo_description: '', seo_title: "", video_banner: "", video_banner_link: ""
      }
      const resp = await wbApi.post(`wb/insert-update-wb-blog`, payload)

      if (resp.data.status === 1) {
        toast.success(resp.data.message)
        onClose()
        dispatch(updateStateAfterNewAddedBlog(nanoid()))
        setLoader(false);
        getBlogsData();
      }
      else {
        toast.error(resp.data.message);
        onClose()
        setLoader(false);
        getBlogsData();
      }
    }
    catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  }


  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Blog</div>
      </div>
      <div className="height-64px"></div>
      <InputTextField
        label="Blog Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => setTitle(e.target.value)}
      />
      <InputTextField
        label="Alternate Text"
        value={altText}
        className="description description__slider"
        handleChange={(e) => setAltText(e.target.value)}
      />
      <div className="mb-2">Choose Image</div>
      <DropzoneArea
        centerMesage={"Drag your photos or click here(1 Image Only)"}
        // incomingfiles={roomImg}
        dispatchFiles={(val) => accpAboutFiles(val)}
        // isDelete={(val) => delteAboutFiles(val)}
        acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
      // refresh={refreshList}
      />
      <div className="__default--input--field my-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn" handleClick={() => navigate(-1)}>Cancel</Button>
          <Button handleClick={() => saveHandler()} loader={loader}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default AddBlogSlider;
