import { HEADER_MENU, FOOTER_MENU } from "../actionTypes";

export const HeaderMenuData = (hmenus:any) => {
    return (
        {
            type: HEADER_MENU,
            menus: hmenus,
        }
    )
}
export const FooterMenuData = (fmenus:any) => {
    return (
        {
            type: FOOTER_MENU,
            menus: fmenus,
        }
    )
}