import React from 'react'
import WebBuilderHeader from './WebBuilderHeader';



const AppHeader = () => {
    return (
        <>
            <div className="header-wrapper header-bg-color header-position">
                <div className="header__content__wrapper">
                    <WebBuilderHeader/>
                </div>
            </div>
        </>
    )
}

export default AppHeader;
