import React from "react";
export interface INoDataComponentProps {
  message: string;
}

const NoDataComponent: React.FC<INoDataComponentProps> = ({ message }) => {
  return (
    <div className="no-data-label-container">
      <div className="icon-container">
        <i className="bi bi-x-circle mb-3"></i>
      </div>

      <div className="no-data-label">{message}</div>
    </div>
  );
};

export default NoDataComponent;
