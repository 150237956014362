import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { updateFacilityArr } from "../../../redux/actions/FacilityAction";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";


interface IEditFacilitiesSliderProps {
  onClose: () => void;
}

const EditFacilitiesSlider: React.FC<IEditFacilitiesSliderProps> = ({
  onClose,
}) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const data = useSelector(
    (state: RootState) => state.facilityData
  );

  const facilityName = useSelector(
    (state: RootState) => state.facilityName
  );

  const dispatch = useDispatch()

  const [title, setTitle] = useState('')
  const [hotelId, setHotelId] = useState('')
  const [sectId, setSectId] = useState('')
  const [allFacilities, setAllFacilities] = useState([])

  const [facility, setFacility] = useState('')

  useEffect(() => {
    if (!data || !data.hotel_id) return

    const details = data.facilityArr.filter((obj: any) => {
      if (obj.hotel_id === data.hotel_id) return obj
    })[0]
    // console.log(details, 'to set details')
    if (details) {
      setSectId(details.id)
      setHotelId(details.hotel_id)
      setAllFacilities(details.facilities)
    }
    else {
      setAllFacilities([])
      setHotelId(data.hotel_id)
      setSectId('undefined')
    }
  }, [data])

  useEffect(() => {
    if (!facilityName) return
    setTitle(facilityName)
    setFacility(facilityName)
  }, [facilityName])

  const EditFacilityApi = async () => {
    if (!title) {
      toast.error('Fill all the fields')
    }
    else {
      try {
        const editTheArr = () => {
          const editedArr: any = allFacilities.filter(name => {
            if (name !== facility) return name
          })
          editedArr.push(title)
          return editedArr
        }

        const payload = {
          company_id,
          template_id,
          hotel_id: hotelId,
          id: sectId,
          facilities: editTheArr()
        }

        // console.log(payload, 'to edit facility data')
        const resp = await wbApi.post(`wb/insert-update-wb-facility`, payload)

        if (resp.data.status === 1) {
          toast.success(resp.data.message)
          setTitle('')
          setFacility('')
          const getResp = await wbApi.get(`wb-dashboard/fetch-wb-facilities-section-details/${company_id}/${template_id}/10`)
          // console.log(getResp.data)
          dispatch(updateFacilityArr(getResp.data.hotel_facilities))
          onClose()
        }
        else {
          toast.error(resp.data.message)
        }
      }
      catch {
        toast.error(CATCH_MESSAGE)
      }
    }
  }




  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit Facility</div>
      </div>

      <div className="height-64px"></div>

      <InputTextField
        label="Enter Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => { setTitle(e.target.value) }}
      />

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn">Cancel</Button>
          <Button handleClick={() => { EditFacilityApi() }}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default EditFacilitiesSlider;
