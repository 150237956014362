import { CHOOSE_PROPERTY, GET_PROPERTIES } from "../actionTypes"

const initialState = {
    property_data: null,
    current_property: null,
}

const PropertiesReducer = (state = initialState, action: { type: string, payload: any }) => {
    // console.log(action.payload, 'prop payload reducer')
    switch (action.type) {
        case GET_PROPERTIES:
            return {
                ...state,
                property_data: action.payload
            }
        case CHOOSE_PROPERTY:
            return {
                ...state,
                current_property: action.payload
            }

        default: return state
    }
}

export default PropertiesReducer;