import { GET_SELECTED_BRAND_DATA, EMPTY_BRAND_DATA, UPDATE_BRAND_STATE } from "../actionTypes";

export const getSeletedBrandsData = (data: any) => {
    return (
        {
            type: GET_SELECTED_BRAND_DATA,
            payload: data,
        }
    )
}
export const emptyBrandData = (data: any) => {
    return (
        {
            type: EMPTY_BRAND_DATA,
            payload: data
        }
    )
}
export const updateStateAfterNewAdded = (data: any) => {
    return (
        {
            type: UPDATE_BRAND_STATE,
            payload: data
        }
    )
}

