import { IDENTITY,ALLFONTS } from "../actionTypes"

const initialState = {
    identityData:[],
    allFonts:[],
}

const IdentityReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case IDENTITY:
            return {
                ...state,
                identityData:action.payload,
            }
            case ALLFONTS:
                return {
                    ...state,
                    allFonts:action.payload,
                }
        default: return state
    }
}

export default IdentityReducer;