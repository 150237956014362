import {
  DEFAULT_SECTION,
  CUSTOM_SECTION,
  INUSE_SECTION,
  HOTEL_ROOMS_ID,
  HOTEL_ROOMS_DETAILS,
  GALLERY_DATA,
  CATEGORY_NAME,
  NEARBY_PLACE,
  NEARBY_PLACE_HOTELID,
  HOTEL_ROOOM_TYPE_DATA,
  HOTEL_SERVICES_DATA,
} from "../actionTypes";

const initialState = {
  defaultSectionId: [],
  customSection: [],
  inuseSection: [],
  hotelRoomIdOnClick: {},
  hotelRoomDetails: [],
  hotelRoomTypeDetails: {},
  galleryData: [],
  galleryCategory: "",
  nearby_places: [],
  nearby_places_hotelId: {},
  hotel_services_data: {},
};

const SectionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DEFAULT_SECTION:
      return {
        ...state,
        defaultSectionId: action.sections,
      };
    case CUSTOM_SECTION:
      return {
        ...state,
        customSection: action.sections,
      };

    case INUSE_SECTION:
      return {
        ...state,
        inuseSection: action.sections,
      };
    case HOTEL_ROOMS_ID:
      return {
        ...state,
        hotelRoomIdOnClick: action.sections,
      };
    case HOTEL_ROOMS_DETAILS:
      return {
        ...state,
        hotelRoomDetails: action.sections,
      };
    case HOTEL_ROOOM_TYPE_DATA:
      return {
        ...state,
        hotelRoomTypeDetails: action.sections,
      };
    case GALLERY_DATA:
      return {
        ...state,
        galleryData: action.sections,
      };
    case CATEGORY_NAME:
      return {
        ...state,
        galleryCategory: action.sections,
      };
    case NEARBY_PLACE:
      return {
        ...state,
        nearby_places: action.sections,
      };
    case NEARBY_PLACE_HOTELID:
      return {
        ...state,
        nearby_places_hotelId: action.sections,
      };
    case HOTEL_SERVICES_DATA:
      return {
        ...state,
        hotel_services_data: action.sections,
      };
    default:
      return state;
  }
};

export default SectionReducer;
