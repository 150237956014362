import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { packagePageAction } from "../../../redux/actions/PageAction";
import { RootState } from "../../../redux/store";
import PageAboutSectionCard from "../../../views/datacard/RectangleSectionCard";
import { Draggable } from "react-drag-reorder";
import SlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import CardWrapper from "../../../views/datacard/CardWrapper";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import Loader from "../../../views/loader/Loader";
import PackageSeoSlider from "./PackagesSeoSlider";
import PackagePageTitleSlider from "./PackagesPageTitleSlider";
import PackageSectionSlider from "./PackagesSectionSlider";

const PackagesPage = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const { packagePageData } = useSelector((state: RootState) => state.pages);

  // States
  const [openTitleSlide, setOpenTitleSlide] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openSeoSlide, setOpenSeoSlide] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [allsec, setAllSec] = useState<any[]>([]);
  const [aboutPageSec, setaboutPageSec] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [packagePageDetails, setPackagePageDetails] = useState<any>({});

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packages_page_details } = packagePageData ?? {};

  // console.log(packagePageData, 'packagePageData')

  useEffect(() => {
    packagePageDataFetching();
  }, [company_id, template_id]);

  // Fetching About Page Data

  const packagePageDataFetching = async () => {
    setLoader(true);

    try {
      let packagePageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-package-page-details/${company_id}/${template_id}/${page_Id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
      );
      if (packagePageRes.data.status === 1) {
        dispatch(packagePageAction(packagePageRes.data));
        setPackagePageDetails(packagePageRes?.data?.package_page_details);
        const packagePageAllSec = packagePageRes?.data?.all_sections?.map(
          (item: any) => {
            return { ...item, isChecked: false };
          }
        );
        setAllSec(packagePageAllSec);
        setaboutPageSec(packagePageRes?.data?.package_page_details?.page_section ?? []);
        setLoader(false);
      } else {
        setAllSec([]);
        setaboutPageSec([]);
        setLoader(false);
      }
    } catch {
      // toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Api To ReOrder
  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: packagePageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateOrder,
          page_type: "Default",
          page_id: page_Id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setDragStatus(false);
        packagePageDataFetching();
        setSaveLoader(false);
      } else {
        toast.error(orderReq.data.message);
        packagePageDataFetching();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setSaveLoader(false);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...aboutPageSec];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setDragStatus(true);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };

  const [dragStatus, setDragStatus] = useState(false);

  // useEffect used to capture the section order if drag action is not triggered.
  useEffect(() => {
    if (!dragStatus) {
      setUpdateOrder(packagePageDetails)
    }
  }, [packagePageDetails, dragStatus])

  // Draggable Handler
  // console.log(aboutPageSec, 'aboutPageSec')

  const DraggableRender = useCallback(() => {
    if (aboutPageSec && aboutPageSec.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {aboutPageSec.map((sec: any, idx: any) => {
            return (
              <div className="section-card-wrapper" key={sec.id}>
                <PageAboutSectionCard
                  onDelet={() => {
                    deleteHandler(idx);
                  }}
                  onEdit={() => {
                    navigate(`/section-default/${sec.section_routes}`);
                  }}
                >
                  {sec.section_names}
                </PageAboutSectionCard>
              </div>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [aboutPageSec]);

  // Section Delet Handler
  const deleteHandler = (index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletSecHandler(index);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };
  const deletSecHandler = (index: any) => {
    let aboutPageSections = packagePageDetails?.page_section;
    aboutPageSections.splice(index, 1);
    deletAboutSecDetails(aboutPageSections);
  };
  // Delet Section Post
  const deletAboutSecDetails = async (updateSec: any) => {
    try {
      let deletAboutSecReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: packagePageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateSec ?? [],
          page_type: "Default",
          page_id: page_Id ?? "",
        }
      );
      // console.log("deletHomeSecReq", deletAboutSecReq);
      if (deletAboutSecReq.data.status === 1) {
        toast.success(deletAboutSecReq.data.message);
        packagePageDataFetching();
      } else {
        toast.error(deletAboutSecReq.data.message);
        packagePageDataFetching();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };




  return (
    <>
      <div>
        {/* Sub-Header */}
        <div className="wb-subheader">
          {/* BreadCrumbs */}
          <CustomBreadcrumbs
            content={[
              {
                label: "Pages",
                href: "/pages-default",
              },
              {
                label: `Default Pages`,
                href: "/pages-default",
              },
              {
                label: "Package",
              },
            ]}
          />

          <div className="wb-subheader-rightsec">
            <Button
              icon="bi bi-binoculars"
              handleClick={() => {
                setOpenSeoSlide(true);
              }}
            >
              SEO
            </Button>
            <Button
              icon="bi bi-info-circle"
              handleClick={() => {
                setOpenTitleSlide(true);
              }}
            >
              Page Title
            </Button>
            <Button
              icon="bi bi-arrow-left"
              className="bck-btn"
              handleClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="wb-divider"></div>
      </div>
      {/* Main Section */}

      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className=" mt-4 banner-img-wrapper">
            {packagePageDetails !== null ? (
              packagePageDetails?.banner ? (
                <img
                  src={`${getImage(packagePageDetails?.banner)}`}
                  alt={packagePageDetails?.banner_alt}
                  className="banner-img"
                />
              ) : packagePageDetails?.video_banner ? (
                <video width="100%" height="300" controls autoPlay>
                  <source
                    src={`${getImage(packagePageDetails?.video_banner)}`}
                  />
                </video>
              ) : packagePageDetails?.video_banner_link ? (
                <iframe
                  src={packagePageDetails?.video_banner_link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  height="100%"
                  width="100%"
                ></iframe>
              ) : (
                <NoDataComponent message="No Banner Found" />
              )
            ) : (
              <NoDataComponent message="No Data Found" />
            )}

            <div
              className="edit-btn-banner"
              onClick={() => {
                navigate("edit-package");
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </div>
            {/* <div className="banner-titel">
              <div className="b-titel-text">
                {packagePageDetails?.banner_title ? (
                  <div className="">{packagePageDetails?.banner_title}</div>
                ) : (
                  <div className="no-banner-titel">
                    TITLE & DESCRIPTION OF THE PACKAGE PAGE
                  </div>
                )}
              </div>
            </div> */}
          </div>

          <CardWrapper className="mt-4 about-add-sec-card">
            <Button
              icon="bi bi-plus-lg"
              className="section-btn"
              handleClick={() => {
                setOpen(true);
              }}
            >
              Select Section
            </Button>
          </CardWrapper>

          {/* For Section */}

          <CardWrapper className=" mt-4 mb-4 home-section-save-wrapper">
            {aboutPageSec.length > 0 ? (
              <>
                <DraggableRender />
                <Button
                  className="save-btn"
                  handleClick={() => {
                    orderSaveHandler();
                  }}
                  loader={saveLoader}
                >
                  Save
                </Button>
              </>
            ) : (
              <NoDataComponent message="No Section Found" />
            )}
          </CardWrapper>
        </>
      )}

      {/* For Seo */}

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSeoSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSeoSlide(false)}
      >
        <PackageSeoSlider
          onClose={() => {
            setOpenSeoSlide(false);
          }}
          packagePageDataFetching={packagePageDataFetching}
          packagePageDetails={packagePageDetails}
        />
      </SlidingPane>

      {/* For Title */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openTitleSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenTitleSlide(false)}
      >
        <PackagePageTitleSlider
          onClose={() => {
            setOpenTitleSlide(false);
          }}
          packagePageDataFetching={packagePageDataFetching}
          packagePageDetails={packagePageDetails}
        />
      </SlidingPane>

      {/* For Section */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <PackageSectionSlider
          onClose={() => {
            setOpen(false);
          }}
          packagePageDataFetching={packagePageDataFetching}
          allsec={allsec}
          packagePageDetails={packagePageDetails}
        />
      </SlidingPane>
    </>
  );
};

export default PackagesPage;
