import React from "react";
export interface IWbPageCardProps {
  children?: any;
  update?: any;
  isActive: boolean;
  onEditClick?: (e: any) => void;
  onLinkClick?: string;
}

const WbPageCard: React.FC<IWbPageCardProps> = ({
  children,
  update,
  isActive,
  onEditClick,
  onLinkClick,
}) => {
  return (
    <div className="wrapper">
      <div className="card-sec-top">
        <i className="bi bi-file-earmark-richtext"></i>
        {isActive ? (
          <div className="active">ACTIVE</div>
        ) : (
          <div className="inactive">INACTIVE</div>
        )}
        <div className="overlay">
          <div className="button__container">
            <div className="button" onClick={onEditClick}>
              <i className="bi bi-pencil-square bi-icon"></i>
            </div>
            <a className="button" href={onLinkClick} target="_blank">
              <i className="bi bi-box-arrow-up-right bi-icon"></i>
            </a>
            {/* <div  onClick={onLinkClick}>
              
            </div> */}
          </div>
        </div>
      </div>
      <div className="page-title-update-wrapper">
        <div className="page-titel">{children}</div>
        <div className="page-update">{update}</div>
      </div>
    </div>
  );
};

export default WbPageCard;
