import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNextTwoTone } from "@mui/icons-material";
import Button from "../../../views/buttons/Button";
import SaveButton from "../../../views/buttons/Button";
import EventButton from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import AddOfferSlider from "./AddOfferSlider";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import { updateOffersArr, updateStateAfterNewOfferAdded } from "../../../redux/actions/OffersAction";
import { nanoid } from "@reduxjs/toolkit";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const OfferList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const data = useSelector(
    (state: RootState) => state.offersSect
  );
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  // console.log(data, 'offers list')

  const dispatch = useDispatch()

  const [allSelectedOffers, setAllSelectedOffers] = useState<any>([])
  const [defaultValue, setDefaultValue] = useState<any>({})

  const selectOffers = () => {
    setAllSelectedOffers([...data.offersArr.filter((obj: any) => {
      if (obj.hotel_id === data.hotel_id) return obj
    })])
  }

  useEffect(() => {
    if (!data.hotel_id) return
    selectOffers()
  }, [data])

  const getOffersDataApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-offer-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setDefaultValue(resp.data.default_hotel)
      } else {
        setDefaultValue({})
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    getOffersDataApi();
  }, []);

  const deleteSelectedOffer = async (ID: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const payload = { company_id, template_id, id: ID }
            const resp = await wbApi.post(`wb/delete-wb-offer`, payload)

            if (resp.data.status === 1) {
              toast.success(resp.data.message)
              dispatch(updateStateAfterNewOfferAdded(nanoid()))

              const getResp = await wbApi.get(`wb-dashboard/fetch-wb-offer-section-details/${company_id}/${template_id}/${defaultSectionId}`)
              dispatch(updateOffersArr(getResp.data.hotel_offers))
            }
            else {
              toast.error(resp.data.message)
            }
          }
          catch {
            toast.error(CATCH_MESSAGE)
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  }

  const makeDefaultHotel = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let response = await wbApi.post(`/wb/make-hotel-default-for-section`, {
        id: defaultValue?.id ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        default_hotel_id: event.target.checked === true ? data.hotel_id : "",
        section_id: defaultSectionId
      })
      if (response.data.status === 1) {
        toast.success(response.data.message);
        getOffersDataApi();
      } else {
        toast.error(response.data.message);
      }

    } catch (error) {
      toast.error(CATCH_MESSAGE);

    }
  }




  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Offer",
              href: "/section-default/edit-hotel-offers"
            },
            {
              label: `${data.hotel_name ? data.hotel_name : ''}`
            }
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="wb-divider"></div>

      <div className="top__button__container">
        <div className="form-check default_label me-2">
          <label className="form-check-label mt-2" htmlFor="setDefault">
            Set as Default
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="setDefault"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeDefaultHotel(e)}
          ></input>
        </div>
        <Button
          icon="bi bi-info-circle"
          className="me-3"
          handleClick={() => {
            setOpen(true);
          }}
        >
          Add Offers
        </Button>
      </div>

      <div className="default_section_image_card_container mt-3">
        {
          allSelectedOffers.map((obj: any, idx: number) => {
            const { id, hotel_id, offer_title, offer_image_id, offer_image_alt, offer_image, offer_description, display_description } = obj

            return (
              <div className="default__setion__image" style={{ marginRight: '20px', flexBasis: '23%' }} key={idx}>
                <div className="image__container" style={{ minHeight: "120px" }}>
                  <img src={getImage(offer_image)} />
                  <div className="overlay overlay-border-radius">
                    <div className="button__container">
                      <div
                        className="edit-button"
                        onClick={() => {
                          navigate(`last-minute-deals/${id}`, {
                            state: {
                              offer_title: offer_title
                            }
                          });
                        }}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </div>
                      <div className="delete-button" onClick={() => { deleteSelectedOffer(id) }}>
                        <i className="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hotel__name">{offer_title}</div>
              </div>
            )
          })
        }
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddOfferSlider
          onClose={() => {
            setOpen(false);
          }}
        />
      </SlidingPane>
    </>
  );
};


export default OfferList;
