import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import ServiceButton from "../../../views/buttons/Button";
import PrivateButton from "../../../views/buttons/Button";
import Card from "../../../views/datacard/Card";
import InputTextField from "../../../views/inputfield/InputTextField";
import { DropZone } from "../../../views/dropzone/DropZone";
import SaveButton from "../../../views/buttons/Button";
import CancelButton from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import RoomServiceSlider from "./RoomServiceSlider";
import PrivateWashroomSlider from "./PrivateWashroomSlider";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CardWrapper from "../../../views/datacard/CardWrapper";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
// Importing Ckeditor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import wbApi from "../../../API/wbApi";
import { CATCH_MESSAGE, getImage, Logout } from "../../../UtilityFunctions";
import { toast } from "react-toastify";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const EditHotelRoomType = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { hotelRoomTypeDetails, hotelRoomIdOnClick } = useSelector(
    (state: RootState) => state.sections
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const navigate = useNavigate();
  const [serviceOpen, setServiceOpen] = useState<boolean>(false);
  const [washroomOpen, setWashroomOpen] = useState<boolean>(false);
  const [bannertitle, setBannerTitle] = useState<string>("");
  const [pagetitle, setPageTitle] = useState<string>("");
  const [altText, setAltText] = useState<string>("");
  const [uTubeLink, setUtubeLink] = useState<string>("");
  const [seoTitle, setSeoTitle] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [seoDescription, setSeoDescription] = useState<string>("");
  const [pageDescription, setPageDescription] = useState<string>("");
  const [bannerImage, setBannerImage] = useState<string>("");
  const [incomingHotelRoomTypeFile, setIncomingHotelRoomTypeFile] = useState<any[]>([]);
  const [hotelRoomTypeFile, setHotelRoomTypeFile] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }
  const dispatch = useDispatch();



  // Data Fetching
  const fetchRoomTypeDetails = async () => {
    try {
      let resp = await wbApi.get(
        `/wb-dashboard/fetch-wb-room-details/${hotelRoomTypeDetails?.room_type_id}`
      );
      if (resp.data.status === 1) {
        setId(resp.data.room_details.id)
        setBannerTitle(resp.data.room_details.banner_title);
        setPageTitle(resp.data.room_details.page_title);
        setAltText(resp.data.room_details.banner_alt);
        setUtubeLink(resp.data.room_details.video_banner_link);
        setSeoTitle(resp.data.room_details.seo_title);
        setSeoDescription(resp.data.room_details.seo_description);
        setPageDescription(resp.data.room_details.page_description);
        if (resp.data.room_details.banner !== "") {
          setIncomingHotelRoomTypeFile([getImage(resp.data.room_details.banner)]);
        } else {
          setIncomingHotelRoomTypeFile([])
        }

        setBannerImage(resp.data.room_details.banner);
      } else {
        setId("undefined");
        setBannerTitle("");
        setPageTitle("");
        setAltText("");
        setUtubeLink("");
        setSeoTitle("");
        setSeoDescription("");
        setPageDescription("");
        setIncomingHotelRoomTypeFile([]);
        setBannerImage("");
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error)
    }
  };
  useEffect(() => {
    fetchRoomTypeDetails();
  }, []);

  const accpRoomTypeFiles = (value: any) => {
    setHotelRoomTypeFile(value);
  };

  const deleteHotelRoomTypeFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteRoomTypeFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteRoomTypeFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-room-details-banner`,
        {
          banner_name: bannerImage ?? "",
          company_id: company_id,
          template_id: template_id,
          room_type_id: hotelRoomTypeDetails.room_type_id,
          file_type: "images"
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchRoomTypeDetails();
      } else {
        toast.error(deleteRes.data.message);
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };


  const saveHandler = async () => {
    if (hotelRoomTypeFile.length) {
      uploadHandler();
    } else {
      updateHotelRoomTypeDetails("");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {
    setLoader(true);
    if (hotelRoomTypeFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", hotelRoomTypeFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/upload-page-banner/${company_id}/${template_id}/${hotelRoomTypeDetails?.room_type}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await updateHotelRoomTypeDetails(uploadRes.data.image_id[0] ?? "");
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch (error: any) {
        setLoader(false);
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };

  const updateHotelRoomTypeDetails = async (imgId: any) => {
    setLoader(true)
    try {
      const payload = {
        id: id ?? "undefined",
        page_title: pagetitle,
        banner: imgId ?? "",
        hotel_id: hotelRoomIdOnClick.hotel_id,
        room_type_id: hotelRoomTypeDetails?.room_type_id ?? "",
        banner_alt: altText,
        banner_title: bannertitle,
        video_banner: "",
        video_banner_link: "",
        company_id: company_id,
        template_id: template_id,
        seo_title: seoTitle,
        seo_description: seoDescription,
        page_description: pageDescription
      }

      const resp = await wbApi.post(
        `/wb/insert-update-room-details-banner`,
        payload, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
      );
      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        fetchRoomTypeDetails();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(resp.data.message);
      }
    } catch (error: any) {
      setLoader(false);
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Rooms",
              href: "/section-default/edit-room-details",
            },
            {
              label: `${hotelRoomIdOnClick?.hotel_name}`,
              href: "/section-default/edit-room-details/edit-hotel-rooms",
            },
            {
              label: `${hotelRoomTypeDetails?.room_type}`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------------- Main Page Section------------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          className="me-3"
          handleClick={() => {
            setServiceOpen(true);
          }}
        >
          Room Services
        </Button>
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            setWashroomOpen(true);
          }}
        >
          Private Washroom
        </Button>
      </div>
      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Banner Title"
            value={bannertitle}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setBannerTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text "
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>
        <InputTextField
          label="Youtube Link"
          value={uTubeLink}
          className="mt-2 youtube_link_field"
          handleChange={(e) => {
            setUtubeLink(e.target.value);
          }}
        />
        <div className="edit-promo-alt-label">Select Banner Image or Video</div>
        <DropzoneArea
          centerMesage={"Drag your photos or click here(1 Image Only)"}
          incomingfiles={incomingHotelRoomTypeFile}
          dispatchFiles={(val) => accpRoomTypeFiles(val)}
          isDelete={(val) => deleteHotelRoomTypeFiles(val)}
          refresh={refreshList}
        />
        <InputTextField
          label="Page title"
          value={pagetitle}
          className="mt-2 "
          handleChange={(e) => {
            setPageTitle(e.target.value);
          }}
        />
        <div className="edit-promo-alt-label">Page Description</div>
        <div className="">
          <CKEditor
            editor={ClassicEditor}
            data={pageDescription}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setPageDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>

        <InputTextField
          label="Enter Seo Title"
          value={seoTitle}
          className="mt-2 "
          handleChange={(e) => {
            setSeoTitle(e.target.value);
          }}
        />
        <div className="edit-promo-alt-label">Seo Description</div>
        <div className="">
          <CKEditor
            editor={ClassicEditor}
            data={seoDescription}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setSeoDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>

        <div className="mt-4 edit-promo-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            className="save-btn"
            handleClick={() => saveHandler()}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={serviceOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setServiceOpen(false)}
      >
        <RoomServiceSlider
          onClose={() => {
            setServiceOpen(false);
          }}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={washroomOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setWashroomOpen(false)}
      >
        <PrivateWashroomSlider
          onClose={() => {
            setWashroomOpen(false);
          }}
        />
      </SlidingPane>
    </>
  );
};

export default EditHotelRoomType;
