import { GET_HOTEL_DATA_AND_EVENTS, UPDATE_EVENTS_ARRAY } from "../actionTypes";

const initialState = {
    hotel_id: '', hotel_name: '', eventsArr: []
}

const EventsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_HOTEL_DATA_AND_EVENTS:
            return {
                ...action.payload,
            }
        case UPDATE_EVENTS_ARRAY:
            return {
                ...state,
                eventsArr: action.payload,
            }


        default: return state
    }
}

export default EventsReducer;