import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Button from "../../../views/buttons/Button";
import PageHomeSectionCard from "../../../views/datacard/RectangleSectionCard";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { homePageAction } from "../../../redux/actions/PageAction";
import { Draggable } from "react-drag-reorder";
import SlidingPane from "react-sliding-pane";
import HomeSectionSlider from "./HomeSectionSlider";
import SeoSlider from "./SeoSlider";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../../views/datacard/CardWrapper";
import Loader from "../../../views/loader/Loader";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";

const HomePage = () => {
  //  Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const { template_id } = useSelector((state: RootState) => state.template_id);

  // States
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openSeoSlide, setOpenSeoSlide] = useState<boolean>(false);
  const [homePageDatas, setHomePageDatas] = useState<any>();
  const [sliderimages, setsliderimages] = useState<any[]>([]);
  const [homePageSec, setHomePageSec] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    homePageDataFetching();
  }, [company_id, template_id]);

  // Get Home Page Details
  const homePageDataFetching = async () => {
    setLoader(true);
    try {
      let homePageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-home-page-details/${company_id}/${template_id}/${page_Id}`
      );

      if (homePageRes.data.status === 1) {
        setHomePageDatas(homePageRes?.data);
        setsliderimages(homePageRes?.data?.slider_images);
        setHomePageSec(homePageRes?.data?.home_page_details?.page_section);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Slider Image Move

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const updateCurrentSlide = (index: any) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  // Carousrl Previous and Next slide Handler
  const prevHandler = (
    clickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => {
    //  hasPrev:true;
    return (
      <div className="button-prev" onClick={prev}>
        <i className="bi bi-arrow-left"></i>
      </div>
    );
  };
  const nextHandler = (
    clickHandler: () => void,
    hasNext: boolean,
    label: string
  ) => {
    return (
      <div className="button-next" onClick={next}>
        <i className="bi bi-arrow-right"></i>
      </div>
    );
  };

  // Section Delet Handler
  const deleteHandler = (index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletSecHandler(index);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };
  const deletSecHandler = (index: any) => {
    let homePageSections = homePageDatas?.home_page_details.page_section;
    homePageSections.splice(index, 1);
    // console.log("updatedSection", homePageSections);
    deletHomeSecDetails(homePageSections);
  };
  // Delet Section Post
  const deletHomeSecDetails = async (updateSec: any) => {
    const updateHomeSec = {
      id: homePageDatas?.home_page_details?.id ?? "undefined",
      template_id: template_id,
      company_id: company_id,
      page_section: updateSec,
      page_type: "Default",
      page_id: page_Id,
    };

    let deletHomeSecReq = await wbApi.post(
      "/wb/insert-update-page-section",
      updateHomeSec
    );
    // console.log("deletHomeSecReq", deletHomeSecReq);
    if (deletHomeSecReq.data.status === 1) {
      toast.success("Delete Sucessfully");
      homePageDataFetching();
    }
  };

  // Ordering Menus
  const [dragStatus, setDragStatus] = useState(false);
  const positionHandler = (currentPos: any, newPos: any) => {
    setDragStatus(true);
    const orderSec = [...homePageSec];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };

  // useEffect used to capture the section order if drag action is not triggered.
  useEffect(() => {
    if (!dragStatus) {
      setUpdateOrder(homePageSec)
    }
  }, [homePageSec, dragStatus])


  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post("/wb/insert-update-page-section", {
        id: homePageDatas?.home_page_details?.id ?? "undefined",
        template_id: template_id,
        company_id: company_id,
        page_section: updateOrder,
        page_type: "Default",
        page_id: page_Id,
      });
      if (orderReq.data.status === 1) {
        toast.success(orderReq?.data?.message);
        setDragStatus(false);
        homePageDataFetching();
        setSaveLoader(false);
      } else {
        toast.error(orderReq?.data?.message);
        homePageDataFetching();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setSaveLoader(false);
    }
  };

  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (homePageSec && homePageSec?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {homePageSec?.map((sec: any, idx: any) => {
            return (
              <div className="section-card-wrapper" key={sec.id}>
                <PageHomeSectionCard
                  onDelet={() => {
                    deleteHandler(idx);
                  }}
                  onEdit={() => {
                    navigate(`/section-default/${sec.section_type}`);
                  }}
                >
                  {sec.section_names}
                </PageHomeSectionCard>
              </div>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [homePageSec]);

  return (
    <>
      {/* Sub Header */}
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Pages",
              href: "/pages-default",
            },
            {
              label: `Default Pages`,
              href: "/pages-default",
            },
            {
              label: "Home",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-binoculars"
            handleClick={() => {
              setOpenSeoSlide(true);
            }}
          >
            SEO
          </Button>
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/* Main Content */}
      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <div className="home-main-container-wrapper">
          {/* Carousel */}
          <div className="carousel-container">
            <div
              className="edit-btn"
              onClick={() => {
                navigate("home-slider");
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </div>
            {sliderimages.length > 0 ? (
              <Carousel
                showThumbs={false}
                autoPlay={true}
                interval={3000}
                infiniteLoop={true}
                renderArrowPrev={prevHandler}
                renderArrowNext={nextHandler}
                selectedItem={currentSlide}
              >
                {sliderimages?.map((im: any, idx: any) => {
                  return (
                    <div key={idx} className="home-page-slider-img">
                      <img
                        src={getImage(im.slider_image)}
                        alt="Slider-Images"
                        className="home-page-slider-img"
                      />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <NoDataComponent message={"No Slider Images/Video Found"} />
            )}
          </div>
          {/* Add Sections */}
          <CardWrapper className="mt-4 home-add-sec-card">
            <Button
              icon="bi bi-plus-lg"
              className="section-btn"
              handleClick={() => {
                setOpen(true);
              }}
            >
              Select Section
            </Button>
          </CardWrapper>

          {/* Sections */}
          <CardWrapper className=" mt-4 home-section-save-wrapper mb-3">
            {
              homePageSec?.length > 0 ? (
                <>
                  <DraggableRender />
                  <Button
                    className="save-btn"
                    handleClick={() => {
                      orderSaveHandler();
                    }}
                    loader={saveLoader}
                  >
                    Save
                  </Button>
                </>

              ) : (
                <NoDataComponent message={"You Have Not Added Any Section"} />
              )
            }

          </CardWrapper>
        </div>
      )}

      {/* For Section */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <HomeSectionSlider
          onClose={() => {
            setOpen(false);
          }}
          homePageDataFetching={homePageDataFetching}
          homePageDatas={homePageDatas}
        />
      </SlidingPane>

      {/* For Seo */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSeoSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSeoSlide(false)}
      >
        <SeoSlider
          onClose={() => {
            setOpenSeoSlide(false);
          }}
          homePageDataFetching={homePageDataFetching}
          homePageDatas={homePageDatas}
        />
      </SlidingPane>
    </>
  );
};

export default HomePage;
