import {
  DEFAULT_SECTION,
  CUSTOM_SECTION,
  INUSE_SECTION,
  HOTEL_ROOMS_ID,
  HOTEL_ROOMS_DETAILS,
  GALLERY_DATA,
  CATEGORY_NAME,
  NEARBY_PLACE,
  NEARBY_PLACE_HOTELID,
  HOTEL_ROOOM_TYPE_DATA,
  HOTEL_SERVICES_DATA,
} from "../actionTypes";

export const DefaultSectionIdAction = (dsec: any) => {
  return {
    type: DEFAULT_SECTION,
    sections: dsec,
  };
};
export const CustomSectionAction = (dsec: any) => {
  return {
    type: CUSTOM_SECTION,
    sections: dsec,
  };
};
export const InuseSectionAction = (dsec: any) => {
  return {
    type: INUSE_SECTION,
    sections: dsec,
  };
};
export const HotelRoomsAction = (hotelID: any) => {
  return {
    type: HOTEL_ROOMS_ID,
    sections: hotelID,
  };
};
export const HotelRoomsDetailsAction = (roomDetails: any) => {
  return {
    type: HOTEL_ROOMS_DETAILS,
    sections: roomDetails,
  };
};
export const HotelRoomsTypeDetailsAction = (roomTypeDetails: any) => {
  return {
    type: HOTEL_ROOOM_TYPE_DATA,
    sections: roomTypeDetails,
  };
};
export const GalleryDataAction = (galleryData: any) => {
  return {
    type: GALLERY_DATA,
    sections: galleryData,
  };
};
export const GalleryCategoryAction = (galleryCateGory: any) => {
  return {
    type: CATEGORY_NAME,
    sections: galleryCateGory,
  };
};
export const NearByPlaceAction = (nearbyPlace: any) => {
  return {
    type: NEARBY_PLACE,
    sections: nearbyPlace,
  };
};
export const NearByHotelIdAction = (nearbyPlaceHotelId: any) => {
  return {
    type: NEARBY_PLACE_HOTELID,
    sections: nearbyPlaceHotelId,
  };
};
export const HotelServicesDataAction = (hotel_services: any) => {
  return {
    type: HOTEL_SERVICES_DATA,
    sections: hotel_services,
  };
};
