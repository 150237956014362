import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextField from "../../../views/inputfield/InputTextField";
import Button from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import AddPromotionSlider from "./AddPromotionSlider";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage, Logout } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { nanoid } from "@reduxjs/toolkit";
import { getSeletedPromotionData } from "../../../redux/actions/PromotionAction";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import EditPromotionSlider from "./EditPromotionSlider";

const EditPromotion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const promotionUpdate = useSelector(
    (state: RootState) => state.promotionUpdate
  );
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [promoImageFile, setPromoImageFile] = useState<any[]>([]);
  const [promotionalData, setPromotionalData] = useState([]);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState("");
  const [promoBackgroundImg, setPromoBackgroundImg] = useState("");
  const [incomingPromoImageFile, setIncomingPromoImageFile] = useState<any[]>([]);
  const [sectId, setSectId] = useState("");
  const [update, setUpdate] = useState("");
  const [editPromo, setEditPromo] = useState<any>({});



  const PromotionalApi = async () => {
    try {
      const resp = await wbApi(
        `wb-dashboard/fetch-wb-promotions-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp?.data?.status === 1) {
        setPromotionalData(resp?.data?.hotel_promotions);
        setSectId(resp?.data?.promotions_section_details?.id);
        setTitle(resp?.data?.promotions_section_details?.section_title);
        setDescription(
          resp?.data?.promotions_section_details?.section_description ?? ""
        );
        setAltText(resp?.data?.promotions_section_details?.background_image_alt);
        setPromoBackgroundImg(resp?.data?.promotions_section_details.background_image);
        if (resp?.data?.promotions_section_details.background_image) {
          setIncomingPromoImageFile([getImage(resp?.data?.promotions_section_details.background_image)]);
        } else {
          setIncomingPromoImageFile([])
        }
      } else {
        setPromotionalData([]);
        setTitle("");
        setDescription("");
        setAltText("")
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error)
    }
  };

  useEffect(() => {
    PromotionalApi();
  }, [update, promotionUpdate]);

  const accpGalleryFiles = (value: any) => {
    setPromoImageFile(value);
  };

  const deleteGalleryFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deltePromoFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const deltePromoFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: promoBackgroundImg,
          section_id: defaultSectionId,
          template_id: template_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        PromotionalApi();
      } else {
        toast.error(deleteRes.data.message);
        PromotionalApi();
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  // Save handler
  const saveHandler = async () => {
    if (promoImageFile.length) {
      uploadHandler();
    } else {
      updatePromoSecDetails("undefined");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {
    setLoader(true);
    if (promoImageFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", promoImageFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/promotions_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await updatePromoSecDetails(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch (error: any) {
        setLoader(false);
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };


  const updatePromoSecDetails = async (ImgId: any) => {
    try {
      const res = await wbApi.post(
        `wb/insert-update-default-section-details`,
        {
          background_image: ImgId ?? "undefined",
          background_image_alt: altText,
          company_id,
          id: sectId ?? "undefined",
          section_description: description,
          section_id: defaultSectionId,
          section_title: title,
          template_id,
        }
      );

      if (res.data.status === 1) {
        setUpdate(nanoid());
        setRefreshList(!refreshList);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message)
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  const deletePromotion = async (id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const resp = await wbApi.post(`wb/delete-promotions`, {
              company_id,
              id,
              template_id,
            });
            if (resp.data.status === 1) {
              toast.success(resp.data.message);
              setUpdate(nanoid());
            } else {
              toast.success(resp.data.message)
            };
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Edit Promotion`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            setOpen(true);
          }}
        >
          Add Promotion
        </Button>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => setTitle(e.target.value)}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>
        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>
        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={incomingPromoImageFile}
            dispatchFiles={(val) => accpGalleryFiles(val)}
            isDelete={(val) => deleteGalleryFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        </div>

        <div className="edit-contact-btn-container">
          <Button className="cancel__btn" handleClick={() => navigate(-1)}>Cancel</Button>
          <Button className="savebtn" handleClick={() => saveHandler()} loader={loader}>
            Save
          </Button>
        </div>
      </CardWrapper>

      < div className="select__hotel__label">Created Promotions</div>

      {/* --------------- */}
      <div className="promotion_section_hotel_card_container mt-3">
        {
          promotionalData.length > 0 ? (
            promotionalData?.map((obj, ind) => {
              const { title, id, background_color, details } = obj;
              return (
                <div
                  className="default__setion__image"
                  key={ind}
                >
                  <div className="image__container" style={{ backgroundColor: `${background_color}` }}>
                    <i className="bi bi-file-earmark-richtext"></i>
                    <div className="overlay overlay-border-radius">
                      <div className="button__container">
                        <div
                          className="edit-button"
                          onClick={() => {
                            dispatch(
                              getSeletedPromotionData({
                                title,
                                id,
                                details,
                                background_color,
                              })
                            );
                            setEditOpen(true);
                            setEditPromo(obj);
                          }}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div
                          className="delete-button"
                          onClick={() => deletePromotion(id)}
                        >
                          <i className="bi bi-trash"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="hotel__name"
                    style={{
                      // background: "white",
                      borderRadius: "8px",
                      padding: "5px",
                    }}
                  >
                    {title}
                  </div>
                </div>
              );
            })
          ) : (
            <NoDataComponent message={"No Promotion Found"} />
          )
        }

      </div>
      {/* --------------- */}

      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddPromotionSlider
          onClose={() => {
            setOpen(false);
          }}
          PromotionalApi={PromotionalApi}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditOpen(false)}
      >
        <EditPromotionSlider
          onClose={() => {
            setEditOpen(false);
          }}
          PromotionalApi={PromotionalApi}
          editPromo={editPromo}
        />
      </SlidingPane>
    </>
  );
};

export default EditPromotion;
