import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../views/buttons/Button";
import ImageSlider from "./ImageSlider";
import VideoSlider from "./VideoSlider";
import SaveButton from "../../../views/buttons/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNextTwoTone } from "@mui/icons-material";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import wbApi from "../../../API/wbApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";

const HomeSlider = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const navigate = useNavigate();
  const [activeMenuTab, setActiveMenuTab] = useState("Image");
  const [defaultValue, setDefaultValue] = useState<any>();
  const [videoSliderDetails, setVideoSliderDetails] = useState<any>();

  const pages = ["Image", "Video"];

  useEffect(() => {
    getVideoSliderDetails();
  }, [company_id, template_id]);

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  // Api To Get Video Slider Details
  const getVideoSliderDetails = async () => {
    try {
      let videoSliderResponse = await wbApi.get(
        `/wb-dashboard/fetch-video-details/${company_id}/${template_id}`
      );

      if (videoSliderResponse.data.status === 1) {
        setDefaultValue(videoSliderResponse?.data?.slider_default_value);
        setVideoSliderDetails(videoSliderResponse?.data?.video_details);
      } else {
        setDefaultValue({});
        setVideoSliderDetails({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {/* Sub-Header Section */}
      <div className="wb-subheader">
        {/* BreadCrumbs */}
        <CustomBreadcrumbs
          content={[
            {
              label: "Pages",
              href: "/pages-default",
            },
            {
              label: `Default Pages`,
              href: "/pages-default",
            },
            {
              label: "Home",
              href: "/pages-default/home",
            },
            {
              label: "Edit Home",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <div className="header-footer-toggle">
            <ul className="nav nav-tabs">
              {pages.map((tab) => {
                return (
                  <li
                    className="nav-item"
                    role="presentation"
                    key={tab}
                    onClick={() => switchMenuTab(tab)}
                  >
                    <a
                      className={`nav-link ${tab === activeMenuTab ? "active" : ""
                        }`}
                      id={`${tab}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${tab}`}
                      aria-controls={`#${tab}`}
                      role="tab"
                      aria-selected="true"
                    >
                      {tab.toUpperCase()}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <BackButton
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </BackButton>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/* Main Section */}

      {activeMenuTab === "Image" ? (
        <ImageSlider
          defaultValue={defaultValue}
          getVideoSliderDetails={getVideoSliderDetails}
        />
      ) : (
        <VideoSlider
          defaultValue={defaultValue}
          getVideoSliderDetails={getVideoSliderDetails}
          videoSliderDetails={videoSliderDetails}
        />
      )}
    </div>
  );
};

export default HomeSlider;
