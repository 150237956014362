import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import AddSectionButton from "../../../views/buttons/Button";
import Wbconfig from "../../../API/Wbconfig.json";
import PageTitleButton from "../../../views/buttons/Button";
import PageAboutSectionCard from "../../../views/datacard/RectangleSectionCard";
import SlidingPane from "react-sliding-pane";

import SaveButton from "../../../views/buttons/Button";
import { Draggable } from "react-drag-reorder";
import { useDispatch, useSelector } from "react-redux";
import { roomPageAction } from "../../../redux/actions/PageAction";
import { RootState } from "../../../redux/store";
import PageRoomSectionCard from "../../../views/datacard/RectangleSectionCard";
import wbApi from "../../../API/wbApi";
import { useCallback, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import Loader from "../../../views/loader/Loader";
import CardWrapper from "../../../views/datacard/CardWrapper";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import { toast } from "react-toastify";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import RoomPageTitleSlider from "./RoomPageTitleSlider";
import RoomSectionSlider from "./RoomSectionSlider";
import RoomSeoSlider from "./RoomSeoSlider";

const RoomsPage = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const { roomPageData } = useSelector((state: RootState) => state.pages);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [openTitleSlide, setOpenTitleSlide] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openSeoSlide, setOpenSeoSlide] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [allsec, setAllSec] = useState<any[]>([]);
  const [roomPageSec, setRoomPageSec] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [roomPageDetails, setRoomPageDetails] = useState<any>({});

  useEffect(() => {
    roomPageDataFetching();
  }, []);

  const roomPageDataFetching = async () => {
    setLoader(true);
    try {
      let roomPageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-room-page-details/${company_id}/${template_id}/${page_Id}`
      );
      if (roomPageRes.data.status === 1) {
        dispatch(roomPageAction(roomPageRes.data));
        const roomPageAllSec = roomPageRes.data.all_sections.map(
          (item: any) => {
            return { ...item, isChecked: false };
          }
        );
        setAllSec(roomPageAllSec);
        setRoomPageSec(roomPageRes.data?.room_page_details?.page_section);
        setRoomPageDetails(roomPageRes.data?.room_page_details);
        setLoader(false);
      } else {
        setAllSec([]);
        setRoomPageSec([]);
        setRoomPageDetails({});
        setLoader(false);
      }
    } catch {
      setLoader(false);
    }
  };

  const [dragStatus, setDragStatus] = useState(false);

  // useEffect used to capture the section order if drag action is not triggered.
  useEffect(() => {
    if (!dragStatus) {
      setUpdateOrder(roomPageSec)
    }
  }, [roomPageSec, dragStatus])

  // Api To ReOrder
  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: roomPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateOrder,
          page_type: "Default",
          page_id: page_Id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setDragStatus(false);
        roomPageDataFetching();
        setSaveLoader(false);
      } else {
        toast.error(orderReq.data.message);
        roomPageDataFetching();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setSaveLoader(false);
    }
  };

  // Section Delet Handler
  const deleteHandler = (index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletSecHandler(index);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  const deletSecHandler = (index: any) => {
    let aboutPageSections = roomPageDetails?.page_section;
    if (aboutPageSections) {
      aboutPageSections.splice(index, 1);
    }
    deletAboutSecDetails(aboutPageSections);
  };
  // Delet Section Post
  const deletAboutSecDetails = async (updateSec: any) => {
    try {
      let deletAboutSecReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: roomPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateSec ?? [],
          page_type: "Default",
          page_id: page_Id ?? "",
        }
      );
      // console.log("deletHomeSecReq", deletAboutSecReq);
      if (deletAboutSecReq.data.status === 1) {
        toast.success(deletAboutSecReq.data.message);
        roomPageDataFetching();
      } else {
        toast.error(deletAboutSecReq.data.message);
        roomPageDataFetching();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...roomPageSec];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setDragStatus(true);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };

  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (roomPageSec && roomPageSec?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {roomPageSec?.map((sec: any, idx: any) => {
            return (
              <>
                <div className="section-card-wrapper" key={sec.id}>
                  <PageAboutSectionCard
                    onDelet={() => {
                      deleteHandler(idx);
                    }}
                    onEdit={() => {
                      navigate(`/section-default/${sec.section_routes}`);
                    }}
                  >
                    {sec.section_names}
                  </PageAboutSectionCard>
                </div>
              </>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [roomPageSec]);

  return (
    <>
      <div>
        {/* Sub-Header */}
        <div className="wb-subheader">
          {/* BreadCrumbs */}
          <CustomBreadcrumbs
            content={[
              {
                label: "Pages",
                href: "/pages-default",
              },
              {
                label: `Default Pages`,
                href: "/pages-default",
              },
              {
                label: "Rooms",
              },
            ]}
          />

          <div className="wb-subheader-rightsec">
            <Button
              icon="bi bi-binoculars"
              handleClick={() => {
                setOpenSeoSlide(true);
              }}
            >
              SEO
            </Button>
            <Button
              icon="bi bi-info-circle"
              handleClick={() => {
                setOpenTitleSlide(true);
              }}
            >
              Page Title
            </Button>
            <Button
              icon="bi bi-arrow-left"
              className="bck-btn"
              handleClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="wb-divider"></div>

        {/* Main Section */}

        {loader ? (
          <div className="middle-loader">
            <Loader />
          </div>
        ) : (
          <>
            <div className=" mt-4 banner-img-wrapper">
              {roomPageDetails !== null ? (
                roomPageDetails?.banner ? (
                  <img
                    src={`${getImage(roomPageDetails?.banner)}`}
                    alt={roomPageDetails?.banner_alt}
                    className="banner-img"
                  />
                ) : roomPageDetails?.video_banner ? (
                  <video width="100%" height="300" controls autoPlay>
                    <source
                      src={`${getImage(roomPageDetails?.video_banner)}`}
                    />
                  </video>
                ) : roomPageDetails?.video_banner_link ? (
                  <iframe
                    src={roomPageDetails?.video_banner_link}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    height="100%"
                    width="100%"
                  ></iframe>
                ) : (
                  <NoDataComponent message="No Banner Found" />
                )
              ) : (
                <NoDataComponent message="No Data Found" />
              )}

              <div
                className="edit-btn-banner"
                onClick={() => {
                  navigate("edit-room-banner");
                }}
              >
                <i className="bi bi-pencil-square"></i>
              </div>
              {/* <div className="banner-titel">
                <div className="b-titel-text">
                  {roomPageDetails?.banner_title ? (
                    <div className="">{roomPageDetails?.banner_title}</div>
                  ) : (
                    <div className="no-banner-titel">
                      TITLE & DESCRIPTION OF THE ROOMS PAGE
                    </div>
                  )}
                </div>
              </div> */}
            </div>

            <CardWrapper className="mt-4 about-add-sec-card">
              <Button
                icon="bi bi-plus-lg"
                className="section-btn"
                handleClick={() => {
                  setOpen(true);
                }}
              >
                Select Section
              </Button>
            </CardWrapper>

            {/* For Section */}

            <CardWrapper className=" mt-4 mb-4 home-section-save-wrapper">
              {roomPageSec?.length > 0 ? (
                <>
                  <DraggableRender />
                  <Button
                    className="save-btn"
                    handleClick={() => {
                      orderSaveHandler();
                    }}
                    loader={saveLoader}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <NoDataComponent message="No Section Found" />
              )}


            </CardWrapper>
          </>
        )}
      </div>
      {/* For Seo */}

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSeoSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSeoSlide(false)}
      >
        <RoomSeoSlider
          onClose={() => {
            setOpenSeoSlide(false);
          }}
          roomPageDataFetching={roomPageDataFetching}
          roomPageDetails={roomPageDetails}
        />
      </SlidingPane>

      {/* For Title */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openTitleSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenTitleSlide(false)}
      >
        <RoomPageTitleSlider
          onClose={() => {
            setOpenTitleSlide(false);
          }}
          roomPageDataFetching={roomPageDataFetching}
          roomPageDetails={roomPageDetails}
        />
      </SlidingPane>

      {/* For Section */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <RoomSectionSlider
          onClose={() => {
            setOpen(false);
          }}
          roomPageDataFetching={roomPageDataFetching}
          allsec={allsec}
          roomPageDetails={roomPageDetails}
        />
      </SlidingPane>
    </>
  );
};

export default RoomsPage;
