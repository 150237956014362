import React, { useEffect, useState } from 'react';
import DropzoneArea from '../../../views/customDropzone/DropzoneArea';
import InputTextField from '../../../views/inputfield/InputTextField';
// Importing Ckeditor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from '../../../views/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from '../../../redux/actions/PromptAction';
import wbApi from '../../../API/wbApi';
import { toast } from 'react-toastify';
import { RootState } from '../../../redux/store';
import { CATCH_MESSAGE, getImage, Logout } from '../../../UtilityFunctions';
export interface IUpdateHotelServiceProps {
    onClose: () => void;
    getEditServiceDetails: (val: any) => void;
    selectedHotel: any;
    editServiceDetails: any;
}

const UpdateHotelService: React.FC<IUpdateHotelServiceProps> = ({ onClose, selectedHotel, getEditServiceDetails, editServiceDetails }) => {
    const { company_id, auth_token } = useSelector(
        (state: RootState) => state.auth
    );
    const { template_id } = useSelector((state: RootState) => state.template_id);
    const { defaultSectionId } = useSelector(
        (state: RootState) => state.sections
    );
    const dispatch = useDispatch();
    const [loader, setLoader] = useState<boolean>(false);
    const [description, setDescription] = useState<any>("");
    const [altText, setAltText] = useState<any>("");
    const [updateServicefiles, setUpdateServiceFiles] = useState<any[]>([]);
    const [incomingServicefiles, setIncomingServiceFiles] = useState<any[]>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [charCount, setCharCount] = useState({
        child: 0,
        privacy: 0,
        terms_cond: 0,
        cancellation: 0,
    });
    function removeTags(str: String) {
        str = str.toString();
        const finalStr = str.replace(/&nbsp;/g, " ");
        return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
    }
    const accpServiceFiles = (value: any) => {
        setUpdateServiceFiles(value);
    };
    // Retrive Hotel Service Details
    useEffect(() => {
        setAltText(editServiceDetails?.services_image_alt);
        setDescription(editServiceDetails?.description);
        editServiceDetails?.services_image !== "" ? setIncomingServiceFiles([getImage(editServiceDetails.services_image)])
            : setIncomingServiceFiles([])
    }, [editServiceDetails])

    // Save Handler

    function saveHandler() {
        if (updateServicefiles.length) {
            uploadHandler();
        } else {
            addUpdateService("undefined");
        }
    }

    const uploadHandler = async () => {
        if (updateServicefiles.length === 1) {
            setLoader(true);

            let payload = new FormData();

            payload.append("uploadFile[0]", updateServicefiles[0]);
            try {
                let uploadRes = await wbApi.post(
                    `wb/insert-dz-images/${company_id}/${template_id}/hotelservices`,
                    payload,
                    {
                        headers: {
                            Authorization: "Bearer " + auth_token,
                        },
                    }
                );
                // console.log("uploadRes", uploadRes);
                if (uploadRes.data.status === 1) {
                    await addUpdateService(uploadRes.data.image_ids);
                    setLoader(false);
                } else {
                    toast.error(uploadRes.data.message);
                    setLoader(false);
                }
            } catch (error: any) {
                setLoader(false);
                if (error.response.status === 401) {
                    Logout();
                    toast.error("Session Expired");
                } else {
                    toast.error(CATCH_MESSAGE);
                }
            }

        } else {
            toast.error("Only One Image Allowed");
        }

    };

    // Update Promo Section

    const addUpdateService = async (Img: any) => {
        setLoader(true);
        try {
            let req = await wbApi.post("/wb/insert-update-wb-services", {
                selected_services_id: editServiceDetails?.selected_services_id,
                services_image: Img ?? "",
                services_image_alt: altText,
                company_id: company_id,
                template_id: template_id,
                hotel_id: selectedHotel?.hotel_id,
                description: description,
                services_id: editServiceDetails?.services_id ?? "undefined",
                services_title: editServiceDetails?.title,
                icon: editServiceDetails?.icon
            });
            if (req.data.status === 1) {
                toast.success(req.data.message);
                getEditServiceDetails(editServiceDetails?.services_id);
                setRefreshList(!refreshList);
                setLoader(false);
                onClose();
            } else {
                toast.error(req.data.message);
                getEditServiceDetails(editServiceDetails?.services_id);
                setLoader(false);
                onClose();
            }
        } catch (error) {
            setLoader(false);
            toast.error(CATCH_MESSAGE);
        }
    };

    const delteAboutFiles = (value: any) => {
        if (value) {
            dispatch(
                ShowConfirmationPrompt(
                    `Are you sure want to delete this ?`,
                    "DELETE",
                    async () => {
                        await delteServiceFilesApi();

                        dispatch(CancelConfirmationPrompt());
                    }
                )
            );
        }
    };
    const delteServiceFilesApi = async () => {
        try {
            let deleteRes = await wbApi.post(
                `wb/delete-dz-service-image`,
                {
                    company_id: company_id,
                    image_name: editServiceDetails?.services_image,
                    template_id: template_id,
                }
            );
            // console.log("deleteRes", deleteRes);
            if (deleteRes.data.status === 1) {
                toast.success(deleteRes.data.message);
                getEditServiceDetails(editServiceDetails?.services_id);
                onClose();
            } else {
                toast.error(deleteRes.data.message);
                getEditServiceDetails(editServiceDetails?.services_id);
                onClose();
            }
        } catch {
            toast.error(CATCH_MESSAGE);
        }
    };
    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Edit {selectedHotel.hotel_name}</div>
            </div>
            <div className="height-64px"></div>

            <div className='mt-2'>
                <InputTextField
                    label={'Alternate Text'}
                    value={altText}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setAltText(e.target.value)}
                />
            </div>

            <div className='mt-2'>
                <DropzoneArea
                    centerMesage={"Drag your photos or click here(1 Image Only)"}
                    incomingfiles={incomingServicefiles}
                    dispatchFiles={(val) => accpServiceFiles(val)}
                    isDelete={(val) => delteAboutFiles(val)}
                    acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
                    refresh={refreshList}
                />
            </div>

            <div className='mt-2'>
                <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                        removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                            "Table",
                        ],
                    }}
                    onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setDescription(data);
                        setCharCount({
                            ...charCount,
                            terms_cond: removeTags(data).length,
                        });
                    }}
                />
            </div>






            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>
            <div className="side__slider__panel__btn">
                <div className="side__slider__panel__btn_container">
                    <Button
                        className="cancel__btn"
                        handleClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        handleClick={() => {
                            saveHandler();
                        }}
                        loader={loader}
                    >
                        Save
                    </Button>
                </div>
            </div>

        </>

    )
}

export default UpdateHotelService
