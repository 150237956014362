import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNextTwoTone } from "@mui/icons-material";
import Button from "../../../views/buttons/Button";
import SaveButton from "../../../views/buttons/Button";
import CancelButton from "../../../views/buttons/Button";
import Card from "../../../views/datacard/Card";
import InputTextField from "../../../views/inputfield/InputTextField";
import { DropZone } from "../../../views/dropzone/DropZone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage, Logout } from "../../../UtilityFunctions";
import { nanoid } from "@reduxjs/toolkit";
import wbApi from "../../../API/wbApi";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../../views/datacard/CardWrapper";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const EditIndividualEvent = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { param } = useParams();

  const [bannerTitle, setBannerTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [link, setLink] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [description, setDescription] = useState<any>("");
  const [selectedOffer, setSelectedOffer] = useState<string>("");

  const [seoTitle, setSEOTitle] = useState<any>("");
  const [seoDescription, setSEODescription] = useState<any>("");

  const [offerDescription, setOfferDescription] = useState<any>("");
  const [offerTitle, setOfferTitle] = useState<any>("");
  const [offerAltText, setOfferAltText] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);

  const [hotelId, setHotelId] = useState('')


  const [update, setUpdate] = useState('')

  const [offerDetails, setOfferDetails] = useState<any>(null)
  const [bannerFile, setBannerFile] = useState<any[]>([]);
  const [incomingBannerFile, setIncomingBannerFile] = useState<any[]>([]);
  const [inBannerFile, setInBannerFile] = useState<string>("");
  const [eventImageFile, setEventImageFile] = useState<any[]>([]);
  const [incomingeventImageFile, setIncomingEventImageFile] = useState<any[]>([]);
  const [inEventImageFile, setInEventImageFile] = useState<string>("");
  const [refreshList, setRefreshList] = useState<boolean>(false);

  useEffect(() => {
    if (!location) return;
    const { state }: any = location ?? {};
    setSelectedOffer(state.offer_title);
  }, [location])

  const accpBannerFiles = (value: any) => {
    setBannerFile(value);
  };
  const accpOfferFiles = (value: any) => {
    setEventImageFile(value);
  };





  const fetchSelectedOfferData = async () => {
    try {
      const resp = await wbApi.get(`wb-dashboard/fetch-wb-offer-details/${company_id}/${template_id}/${param}`)

      if (resp.data.status === 1) {
        setOfferDetails(resp.data.offer_details)
      }
      else {
        setOfferDetails(null)
      }
    }
    catch {
      toast.error(CATCH_MESSAGE)
    }
  }

  const setAllVals = () => {
    setOfferTitle(offerDetails.offer_title)
    setOfferDescription(offerDetails.offer_description)
    setOfferAltText(offerDetails.offer_image_alt)

    setBannerTitle(offerDetails.banner_title)
    setAltText(offerDetails.banner_alt)
    setLink(offerDetails.video_banner_link)
    setPageTitle(offerDetails.page_title)
    setDescription(offerDetails.page_description)

    setSEOTitle(offerDetails.seo_title)
    setSEODescription(offerDetails.seo_description)

    setHotelId(offerDetails.hotel_id)
    setIncomingBannerFile(offerDetails.banner ? [getImage(offerDetails.banner
    )] : offerDetails.video_banner ? [getImage(offerDetails.video_banner)] : [])
    setInBannerFile(offerDetails.banner ? offerDetails.banner : offerDetails.video_banner ? offerDetails.video_banner : "")
    setIncomingEventImageFile(offerDetails.offer_image
      ? [getImage(offerDetails.offer_image
      )] : [])
    setInEventImageFile(offerDetails.offer_image
    )
  }

  useEffect(() => {
    fetchSelectedOfferData()
  }, [update])

  useEffect(() => {
    if (!offerDetails) return
    setAllVals()
  }, [offerDetails])

  const delteBannerFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteBannerFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteBannerFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-offer-detail-banner`,
        {
          company_id: company_id,
          banner_name: inBannerFile,
          template_id: template_id,
          file_type: offerDetails.banner ? "images" : "video",
          id: param,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSelectedOfferData();
      } else {
        toast.error(deleteRes.data.message);
        fetchSelectedOfferData();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  const delteOfferFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteOfferImageFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteOfferImageFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-offer-image`,
        {
          company_id: company_id,
          image_name: inEventImageFile,
          template_id: template_id,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSelectedOfferData();
      } else {
        toast.error(deleteRes.data.message);
        fetchSelectedOfferData();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  async function saveHandler() {
    try {
      let bannerResp: any = "";
      let eventImageResp: any = "undefined";
      if (bannerFile.length > 0) {
        bannerResp = await uploadBannerHandler();
      }
      if (eventImageFile.length > 0) {
        eventImageResp = await uploadOfferImageHandler();
      }
      await updateExistingOffer(bannerResp, eventImageResp);
    } catch {
      await updateExistingOffer("", "undefined");
    }
  }
  const uploadBannerHandler = async () => {
    if (bannerFile.length === 1) {
      let payload = new FormData();

      payload.append("uploadFile[0]", bannerFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/upload-page-banner/${company_id}/${template_id}/offer_detail_banner`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          // return uploadRes.data.image_ids
          if (uploadRes.data.image_id.length > 0) {
            return uploadRes.data.image_id[0];
          } else {
            return uploadRes.data.video_id[0];
          }

        } else {
          return "";

        }
      } catch (error: any) {

        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
        return "";
      }

    } else {
      toast.error("Only One Image Allowed");
    }

  };
  const uploadOfferImageHandler = async () => {
    if (eventImageFile.length === 1) {


      let payload = new FormData();

      payload.append("uploadFile[0]", eventImageFile[0]);
      try {
        let uploadRes: any = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/hotelevent`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          return uploadRes.data.image_ids;

        } else {
          return "undefined";

        }
      } catch (error: any) {
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
        return "undefined";
      }

    } else {
      toast.error("Only One Image Allowed");
    }

  };



  const updateExistingOffer = async (banner: any, offerImage: any) => {
    setLoader(true);
    try {
      const payload = {
        banner_alt: altText,
        banner_title: bannerTitle,
        company_id,
        template_id,
        offer_description: offerDescription,
        offer_image: offerImage ?? 'undefined',
        offer_image_alt: offerAltText,
        offer_title: offerTitle,
        hotel_id: hotelId,
        id: param,
        page_description: description,
        page_title: pageTitle,
        seo_description: seoDescription,
        seo_title: seoTitle,
        video_banner_link: link,
        video_banner: '',
        banner: banner ?? ''
      }
      const resp = await wbApi.post(`wb/insert-update-wb-offer-details`, payload)

      if (resp.data.status === 1) {
        toast.success(resp.data.message)
        setUpdate(nanoid())
        fetchSelectedOfferData();
        setLoader(false);
        setRefreshList(!refreshList)
      }
      else {
        toast.error(resp.data.message);
        fetchSelectedOfferData();
        setLoader(false);
      }
    }
    catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE)
    }
  }




  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Offer",
              href: "/section-default/edit-hotel-offers"
            },
            {
              label: `${selectedOffer}`
            }
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*------------------- Main Section----------------- */}
      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Banner Title"
            value={bannerTitle}
            className="edit-aboutus-subheader"
            handleChange={(e) => { setBannerTitle(e.target.value) }}
          />
          <InputTextField
            label="Banner Alt Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => { setAltText(e.target.value) }}
          />
        </div>

        <div className="mt-3">
          <InputTextField
            label="Youtube Link"
            value={link}
            className="edit-aboutus-alt-text"
            handleChange={(e) => { setLink(e.target.value) }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Banner Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here(1 Image/Video Only)"}
            incomingfiles={incomingBannerFile}
            dispatchFiles={(val) => accpBannerFiles(val)}
            isDelete={(val) => delteBannerFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div className="mt-3">
          <InputTextField
            label="Page Title"
            value={pageTitle}
            className="edit-aboutus-alt-text"
            handleChange={(e) => { setPageTitle(e.target.value) }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Enter Page Description
        </div>
        <div className="sec-description-editor" >
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);

              }}
            />
          </div>
        </div>

        <div className="mt-3">
          <InputTextField
            label="Seo Title"
            value={seoTitle}
            className="edit-aboutus-alt-text"
            handleChange={(e) => { setSEOTitle(e.target.value) }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Enter Seo Description
        </div>
        <div className="sec-description-editor" >
          <div>
            <CKEditor
              editor={ClassicEditor}
              data={seoDescription}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setSEODescription(data);

              }}
            />
          </div>
        </div>

        <div className="edit-aboutus-text-container mt-3">
          <InputTextField
            label="Offer Title"
            value={offerTitle}
            className="edit-aboutus-alt-text"
            handleChange={(e) => { setOfferTitle(e.target.value) }}
          />
          <InputTextField
            label="Enter Alt Text"
            value={offerAltText}
            className="edit-aboutus-subheader"
            handleChange={(e) => { setOfferAltText(e.target.value) }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Offer Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here(1 Image Only)"}
            incomingfiles={incomingeventImageFile}
            dispatchFiles={(val) => accpOfferFiles(val)}
            isDelete={(val) => delteOfferFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Enter Offer Description
        </div>
        <div className="sec-description-editor" >
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={offerDescription}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setOfferDescription(data);
              }}
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button className="cancel__btn" handleClick={() => navigate(-1)}>Cancel</Button>
          <Button className="savebtn" handleClick={() => saveHandler()} loader={loader}>Save</Button>
        </div>
      </CardWrapper>

    </>
  );
};

export default EditIndividualEvent;
