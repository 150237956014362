import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";

interface IAddPlaceSliderProps {
  onClose: () => void;
  nearbyData: any;
  thisHotelNearbyObj: any;
}

const AddNearByPlaces: React.FC<IAddPlaceSliderProps> = ({ onClose, nearbyData, thisHotelNearbyObj }) => {
  // Data From Redux 
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector((state: RootState) => state.sections);
  const { current_property } = useSelector((state: RootState) => state.properties);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  const [placeName, setPlaceName] = useState<any>();
  const [placeDistance, setPlaceDistance] = useState<any>();
  const [travelTime, setTravelTime] = useState<any>();
  const [placeLatitude, setPlaceLatitude] = useState<any>();
  const [placeLongitude, setPlaceLongitude] = useState<any>();
  const [loader, setLoader] = useState<any>(false);

  useEffect(() => {
    setPlaceName(nearbyData?.place_name);
    setPlaceDistance(nearbyData?.Distance);
    setTravelTime(nearbyData?.time);
    setPlaceLatitude(nearbyData?.latitude);
    setPlaceLongitude(nearbyData?.longitude);
  }, [])

  const saveHandler = async () => {
    // https://bookingjini.info/website-builder-api-work/wb/insert-update-wb-nearby-places
    setLoader(true);
    thisHotelNearbyObj?.places_details.push({
      place_name: placeName,
      distance: placeDistance,
      time: travelTime,
      latitude: placeLatitude,
      longitude: placeLongitude,
    })
    try {
      let res = await wbApi.post(
        `/wb/insert-update-wb-nearby-places`,
        thisHotelNearbyObj,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // response checking 
      if (res.data.status === 1) {
        toast.success(res.data.message);
        setLoader(false);
        onClose();
      } else {
        toast.error(res.data.message);
        setLoader(false);
        onClose();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  }

  return (
    <>
      {console.log("nearbyData : ", nearbyData)}
      {console.log("thisHotelNearbyObj : ", thisHotelNearbyObj)}
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">{nearbyData.mode} Places</div>
      </div>
      <div className="height-64px"></div>

      <InputTextField
        label="Place Name"
        value={placeName}
        className="description description__slider"
        handleChange={(e) => {
          setPlaceName(e.target.value);
        }}
      />
      <InputTextField
        label="Place Distance"
        value={placeDistance}
        className="description description__slider"
        handleChange={(e) => {
          setPlaceDistance(e.target.value);
        }}
      />
      <InputTextField
        label="Travel Time"
        value={travelTime}
        className="description description__slider"
        handleChange={(e) => {
          setTravelTime(e.target.value);
        }}
      />
      <InputTextField
        label="Place latitude"
        value={placeLatitude}
        className="description description__slider"
        handleChange={(e) => {
          setPlaceLatitude(e.target.value);
        }}
      />
      <InputTextField
        label="Place longitude"
        value={placeLongitude}
        className="description description__slider"
        handleChange={(e) => {
          setPlaceLongitude(e.target.value);
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <Button className="cancel__btn" handleClick={onClose}>Cancel</Button>
        <Button handleClick={saveHandler}>Save</Button>
      </div>
    </>
  );
};

export default AddNearByPlaces;
