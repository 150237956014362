import { UPDATE_FOOTER, GET_SELECTED_FOOTER_DATA, EMPTY_FOOTER_DATA } from "../actionTypes";

export const getSelectedFooterData = (data: any) => {
    return (
        {
            type: GET_SELECTED_FOOTER_DATA,
            payload: data,
        }
    )
}
export const emptyFooterData = () => {
    return (
        {
            type: EMPTY_FOOTER_DATA,
            // payload: data
        }
    )
}
export const updateFooter = (data: any) => {
    return (
        {
            type: UPDATE_FOOTER,
            payload: data
        }
    )
}

