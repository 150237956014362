import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import AddMenuBtn from "../../../views/buttons/Button";
import CancelButton from "../../../views/buttons/Button";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";

interface IHomeSectonSLiderProps {
  onClose: () => void;
  homePageDataFetching: () => void;
  homePageDatas: any;
}

const HomeSectionSlider: React.FC<IHomeSectonSLiderProps> = ({
  onClose,
  homePageDataFetching,
  homePageDatas
}) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const { template_id } = useSelector((state: RootState) => state.template_id);

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [checkedItem, setCheckedItem] = useState<any[]>([]);

  useEffect(() => {
    setCheckedItem(homePageDatas?.home_page_details?.page_section ?? [])
  }, [homePageDatas])


  // Hanadle Checked Sections
  const checkedSection = (
    e: React.ChangeEvent<HTMLInputElement>,
    secName: any,
    secId: any,
    secRoute: any,
    secType: any
  ) => {
    if (checkedItem) {
      let checkeds = [...checkedItem];
      if (
        checkeds.some((item: any) => {
          return item.section_names === secName;
        })
      ) {
        checkeds = checkeds.filter(
          (name: any) => name.section_names !== secName
        );
      } else {
        checkeds.push({
          id: secId,
          section_names: secName,
          section_type: secType,
          section_routes: secRoute,
        });
      }
      setCheckedItem(checkeds);
      // console.log(checkeds, "checkeds");
    }
  };

  // Selected Section Post
  const postSelectedItem = async () => {
    setLoader(true);
    try {
      let postSecReq = await wbApi.post("/wb/insert-update-page-section", {
        id: homePageDatas?.home_page_details?.id ?? "undefined",
        template_id: template_id,
        company_id: company_id,
        page_section: checkedItem,
        page_type: "Default",
        page_id: page_Id,
      });
      if (postSecReq.data.status === 1) {
        toast.success(postSecReq.data.message);
        homePageDataFetching();
        setLoader(false);
        onClose();
      } else {
        toast.error(postSecReq.data.message);
        homePageDataFetching();
        setLoader(false);
        onClose();
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = homePageDatas?.all_sections?.filter((item: any) => {
        return item.section_names
          .toLowerCase()
          .startsWith(keyword?.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(homePageDatas?.all_sections);
    }
    setSearchInput(keyword);
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Select Section</div>
      </div>
      <div className="height-64px"></div>
      <div className="search__booking__wrapper">
        <i className="bi bi-search"></i>
        <input
          placeholder="Search Bookings"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }}
        />
      </div>
      {homePageDatas?.all_sections !== null && searchInput?.length > 0
        ? filteredResults?.map((sec: any) => (

          <div key={sec.id} className="select__section__wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <div className="select__section__content">
                {sec.section_names}
              </div>
            </div>
            <div className="select__section__checkbox">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={checkedItem.some((item: any) => {
                    return item.section_names === sec.section_names;
                  })}
                  onChange={(e) => {
                    checkedSection(
                      e,
                      sec.section_names,
                      sec.id,
                      sec.section_type,
                      sec.section_routes
                    );
                  }}
                />
              </div>
            </div>
          </div>

        ))
        : homePageDatas?.all_sections?.map((sec: any) => (

          <div key={sec.id} className="select__section__wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <div className="select__section__content">
                {sec.section_names}
              </div>
            </div>
            <div className="select__section__checkbox">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={checkedItem.some((item: any) => {
                    return item.section_names === sec.section_names;
                  })}
                  onChange={(e) => {
                    checkedSection(
                      e,
                      sec.section_names,
                      sec.id,
                      sec.section_type,
                      sec.section_routes
                    );
                  }}
                />
              </div>
            </div>
          </div>

        ))}
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <CancelButton
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </CancelButton>

          <AddMenuBtn
            handleClick={() => {
              postSelectedItem();
            }}
            loader={loader}
          >
            Save
          </AddMenuBtn>
        </div>
      </div>
    </>
  );
};

export default HomeSectionSlider;
