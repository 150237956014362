import React from "react";
export interface IRectangleSectionCardProps {
  children: string;
  onEdit?: (e: any) => void;
  onDelet?: (e: any) => void;
  className?: string;
}

const RectangleSectionCard: React.FC<IRectangleSectionCardProps> = ({
  children,
  onDelet,
  onEdit,
  className,
}) => {
  return (
    <div className={`rect-sec-card-wrapper ${className}`}>
      <div className="left-sec">
        <i className="bi bi-grip-vertical"></i>
        <div className="sec-name">{children}</div>
      </div>
      <div className="right-sec">
        <div className="button" onClick={onEdit}>
          <i className="bi bi-pencil-square"></i>
        </div>
        <div className="button" onClick={onDelet}>
          <i className="bi bi-trash"></i>
        </div>
      </div>
    </div>
  );
};

export default RectangleSectionCard;
