import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { hotelListPageAction } from "../../../redux/actions/PageAction";
import { RootState } from "../../../redux/store";
import PageHotelListSectionCard from "../../../views/datacard/RectangleSectionCard";
import { Draggable } from "react-drag-reorder";
import Wbconfig from "../../../API/Wbconfig.json";
// import HotelListPageTitleSlider from "./HotelListPageTitleSlider";
import SlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
// import HotelListSectionSlider from "./HotelListSectionSlider";
// import HotelListSeoSlider from "./HotelListSeoSlider";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import CardWrapper from "../../../views/datacard/CardWrapper";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import Loader from "../../../views/loader/Loader";
import HotelListSeoSlider from "./HotelLIstSeoSlider";
import HotelListPageTitleSlider from "./HotelLIstPageTitleSlider";
import HotelListSectionSlider from "./HotelLIstSectionSlider";

const HotelListPage = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const { hotelListPageData } = useSelector((state: RootState) => state.pages);

  // States
  const [openTitleSlide, setOpenTitleSlide] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openSeoSlide, setOpenSeoSlide] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [allsec, setAllSec] = useState<any[]>([]);
  const [hotelListPageSec, setHotelListPageSec] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [hotelListPageDetails, sethotelListPageDetails] = useState<any>({});

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hotel_list_page_details } = hotelListPageData ?? {};

  useEffect(() => {
    hotelListPageDataFetching();
  }, [company_id, template_id]);

  // Fetching About Page Data

  const hotelListPageDataFetching = async () => {
    setLoader(true);
    try {
      let hotelListPageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-hotel-list-page-details/${company_id}/${template_id}/${page_Id}`
      );
      // console.log("hotelListPageData", hotelListPageData);
      if (hotelListPageRes.data.status === 1) {
        dispatch(hotelListPageAction(hotelListPageRes?.data));
        sethotelListPageDetails(hotelListPageRes?.data?.hotel_list_page_details)
        const hotelListPageAllSec = hotelListPageRes?.data?.all_sections?.map(
          (item: any) => {
            return { ...item, isChecked: false };
          }
        );
        setAllSec(hotelListPageAllSec);
        setHotelListPageSec(
          hotelListPageRes?.data?.hotel_list_page_details?.page_section ?? []
        );
        setLoader(false);
      } else {
        setAllSec([]);
        setHotelListPageSec([]);
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Api To ReOrder
  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: hotelListPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateOrder,
          page_type: "Default",
          page_id: page_Id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        hotelListPageDataFetching();
        setSaveLoader(false);
      } else {
        toast.error(orderReq.data.message);
        hotelListPageDataFetching();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setSaveLoader(false);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...hotelListPageSec];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setDragStatus(true);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };

  const [dragStatus, setDragStatus] = useState(false);

  // useEffect used to capture the section order if drag action is not triggered.
  useEffect(() => {
    if (!dragStatus) {
      setUpdateOrder(hotelListPageSec)
    }
  }, [hotelListPageSec, dragStatus])
  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (hotelListPageSec && hotelListPageSec.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {hotelListPageSec.map((sec: any, idx: any) => {
            return (
              <>
                <div className="section-card-wrapper" key={sec.id}>
                  <PageHotelListSectionCard
                    onDelet={() => {
                      deleteHandler(idx);
                    }}
                    onEdit={() => {
                      navigate(`/section-default/${sec.section_routes}`);
                    }}
                  >
                    {sec.section_names}
                  </PageHotelListSectionCard>
                </div>
              </>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [hotelListPageSec]);

  // Section Delet Handler
  const deleteHandler = (index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletSecHandler(index);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };
  const deletSecHandler = (index: any) => {
    let hotelListPageSections = hotelListPageDetails?.page_section;
    hotelListPageSections.splice(index, 1);
    deletHotelListSecDetails(hotelListPageSections);
  };
  // Delet Section Post
  const deletHotelListSecDetails = async (updateSec: any) => {
    try {
      let deletHotelListSecReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: hotelListPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateSec ?? [],
          page_type: "Default",
          page_id: page_Id ?? "",
        }
      );
      // console.log("deletHomeSecReq", deletAboutSecReq);
      if (deletHotelListSecReq.data.status === 1) {
        toast.success(deletHotelListSecReq.data.message);
        hotelListPageDataFetching();
      } else {
        toast.error(deletHotelListSecReq.data.message);
        hotelListPageDataFetching();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div>
        {/* Sub-Header */}
        <div className="wb-subheader">
          {/* BreadCrumbs */}
          <CustomBreadcrumbs
            content={[
              {
                label: "Pages",
                href: "/pages-default",
              },
              {
                label: `Default Pages`,
                href: "/pages-default",
              },
              {
                label: "Hotel List",
              },
            ]}
          />

          <div className="wb-subheader-rightsec">
            <Button
              icon="bi bi-binoculars"
              handleClick={() => {
                setOpenSeoSlide(true);
              }}
            >
              SEO
            </Button>
            <Button
              icon="bi bi-info-circle"
              handleClick={() => {
                setOpenTitleSlide(true);
              }}
            >
              Page Title
            </Button>
            <Button
              icon="bi bi-arrow-left"
              className="bck-btn"
              handleClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="wb-divider"></div>
      </div>
      {/* Main Section */}

      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className=" mt-4 banner-img-wrapper">
            {hotelListPageDetails !== null ? (
              hotelListPageDetails?.banner ? (
                <img
                  src={`${getImage(hotelListPageDetails?.banner)}`}
                  alt={hotelListPageDetails?.banner_alt}
                  className="banner-img"
                />
              ) : hotelListPageDetails?.video_banner ? (
                <video width="100%" height="300" controls autoPlay>
                  <source
                    src={`${getImage(hotelListPageDetails?.video_banner)}`}
                  />
                </video>
              ) : hotelListPageDetails?.video_banner_link ? (
                <iframe
                  src={hotelListPageDetails?.video_banner_link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  height="100%"
                  width="100%"
                ></iframe>
              ) : (
                <NoDataComponent message="No Banner Found" />
              )
            ) : (
              <NoDataComponent message="No Data Found" />
            )}

            <div
              className="edit-btn-banner"
              onClick={() => {
                navigate("edit-hotel-list-banner");
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </div>
            {/* <div className="banner-titel">
              <div className="b-titel-text">
                {hotelListPageDetails?.banner_title ? (
                  <div className="">
                    {hotelListPageDetails?.banner_title}
                  </div>
                ) : (
                  <div className="no-banner-titel">
                    TITLE & DESCRIPTION OF THE ABOUT PAGE
                  </div>
                )}
              </div>
            </div> */}
          </div>

          {/* <CardWrapper className="mt-4 about-add-sec-card">
            <Button
              icon="bi bi-plus-lg"
              className="section-btn"
              handleClick={() => {
                setOpen(true);
              }}
            >
              Select Section
            </Button>
          </CardWrapper> */}

          {/* For Section */}

          {/* <CardWrapper className=" mt-4 mb-4 home-section-save-wrapper">
            {hotelListPageSec.length > 0 ? (
              <>
                <DraggableRender />
                <Button
                  className="save-btn"
                  handleClick={() => {
                    orderSaveHandler();
                  }}
                  loader={saveLoader}
                >
                  Save
                </Button>
              </>
            ) : (
              <NoDataComponent message="No Section Found" />
            )}
          </CardWrapper> */}
        </>
      )}

      {/* For Seo */}

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSeoSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSeoSlide(false)}
      >
        <HotelListSeoSlider
          onClose={() => {
            setOpenSeoSlide(false);
          }}
          hotelListPageDataFetching={hotelListPageDataFetching}
          hotelListPageDetails={hotelListPageDetails}
        />
      </SlidingPane>

      {/* For Title */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openTitleSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenTitleSlide(false)}
      >
        <HotelListPageTitleSlider
          onClose={() => {
            setOpenTitleSlide(false);
          }}
          hotelListPageDataFetching={hotelListPageDataFetching}
          hotelListPageDetails={hotelListPageDetails}
        />
      </SlidingPane>

      {/* For Section */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <HotelListSectionSlider
          onClose={() => {
            setOpen(false);
          }}
          hotelListPageDataFetching={hotelListPageDataFetching}
          allsec={allsec}
        />
      </SlidingPane>
    </>
  );
};

export default HotelListPage;
