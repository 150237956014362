import React, { useCallback, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import CardWrapper from "../../../views/datacard/CardWrapper";
import Hotel from "../../../assests/img/hotel-image.png";
import SlidingPane from "react-sliding-pane";
import ChooseServiceSlider from "./ChooseServiceSlider";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { Capitalize, CATCH_MESSAGE } from "../../../UtilityFunctions";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { Draggable } from "react-drag-reorder";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import AddEditServices from "./AddEditServices";
import UpdateHotelService from "./UpdateHotelService";
import PageDetailsSlider from "./PageDetailsSlider";

const EditSelectedServices = () => {
  const { hotel_services_data } = useSelector(
    (state: RootState) => state.sections
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [addSlider, setAddSlider] = useState<boolean>(false);
  const [hotelUpdateSlider, setHotelUpdateSlider] = useState<boolean>(false);
  const [pageDetailsSlider, setPageDetailsSlider] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [allHotel, setAllHotels] = useState<any[]>([]);
  const [allOutlets, setAllOutlets] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [selectedHotel, setSelectedHotels] = useState<any>({});
  const [editServiceDetails, setEditServiceDetails] = useState<any>({});
  const [serviceId, setServiceId] = useState<any>("undefined");
  useEffect(() => {
    if (location.state) {
      const { services_id }: any = location.state ?? {};
      setServiceId(services_id);
      getEditServiceDetails(services_id);
      getEditServiceOutlets(services_id);
    }


  }, [location])
  // Get Edit Service Details
  const getEditServiceDetails = async (serviceId: any) => {
    try {
      let response = await wbApi.get(`/wb-dashboard/fetch-wb-outlets-details/${company_id}/${template_id}/${serviceId}`);
      if (response.data.status === 1) {
        setEditServiceDetails(response?.data?.outlet[0]);
        setAllHotels(response?.data?.all_hotels)
      } else {
        setEditServiceDetails({});
        setAllHotels([]);

      }
    } catch (error) {
      console.log(error);
    }
  }

  // Get Edit Service Details
  const getEditServiceOutlets = async (serviceId: any) => {
    try {
      let response = await wbApi.get(`/wb-dashboard/fetch-wb-outlets-details/${company_id}/${template_id}/${serviceId}`);
      if (response.data.status === 1) {
        setAllOutlets(response?.data?.outlets_list);
      } else {

        setAllOutlets([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderOutlets = [...allOutlets];
    let temp = orderOutlets[currentPos].id;
    orderOutlets[currentPos].id = orderOutlets[newPos].id
    orderOutlets[newPos].id = temp
    setUpdateOrder(orderOutlets);
  };
  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (allOutlets && allOutlets?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {allOutlets?.map((iteam: any, idx: any) => {
            return (
              <div className="selected_service_wrapper" key={idx}>
                <div className="left-service-card">
                  <i className="bi bi-grip-vertical"></i>
                  {iteam?.title}
                </div>
                <div className="right-service-card">
                  <div className="button"
                    onClick={() => {
                      // navigate(
                      //   "/section-default/edit-hotel-specifications/edit-selected-services", {
                      //   state: {
                      //     services_id: iteam.services_id,
                      //   }
                      // }
                      // )
                    }
                    }
                  >
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="button"
                  // onClick={() => deleteHandler(iteam?.selected_services_id)}
                  >
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [allOutlets]);

  const orderSaveHandler = async () => {
    setUpdateLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/ordering/order-wb-outlets",
        {
          hotel_id: hotel_services_data?.hotel_id,
          outlets: updateOrder,
          template_id: template_id,
          company_id: company_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        getEditServiceOutlets(serviceId);
        setUpdateLoader(false);
      } else {
        toast.error(orderReq.data.message);
        getEditServiceOutlets(serviceId);
        setUpdateLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setUpdateLoader(false);
    }
  }

  return (
    <div>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Services",
              href: "/section-default/edit-hotel-specifications",
            },
            {
              label: `${hotel_services_data?.hotel_name}`,
              href: "/section-default/edit-hotel-specifications/edit-hotel-services",
            },
            {
              label: `${editServiceDetails?.title}`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      <div className="input-check-add-butn-wrapper mt-2">
        <Button
          icon="bi bi-plus"
          handleClick={() => {
            setAddSlider(true);
          }}
        >
          Add {editServiceDetails?.title}
        </Button>
        <Button
          icon="bi-info-circle"
          handleClick={() => {
            setPageDetailsSlider(true);
          }}
        >
          Page Details
        </Button>
      </div>

      <div className="select__hotel__label">Select Hotels</div>
      <div className="default_section_image_card_container mt-3">
        {allHotel?.length > 0 ? (
          allHotel?.map((item: any) => (
            <div
              key={item.hotel_id}
              className="default__setion__image"
              onClick={() => {
                setSelectedHotels(item)
                setHotelUpdateSlider(true);
              }}
            >
              <div className="image__container">
                <img src={Hotel} />
                <div className="hotel__name">{Capitalize(item.hotel_name)}</div>
              </div>
            </div>
          ))
        ) : (
          <NoDataComponent message={"No Hotels Found"} />
        )}
      </div>
      <CardWrapper className="mt-4 mb-4 edit-hotel-service-card-wrapper">
        {allOutlets?.length > 0 ? (
          <DraggableRender />
        ) : (
          <NoDataComponent message={"You Have Not Selected Any Services"} />
        )}
      </CardWrapper>
      <div className="mt-2 mb-2 " style={{ width: "10%" }}>
        <Button
          handleClick={() => orderSaveHandler()}
          loader={updateLoader}
        >
          Update
        </Button>
      </div>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={addSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddSlider(false)}
      >
        <AddEditServices
          onClose={() => {
            setAddSlider(false);
          }}
          getEditServiceDetails={(val) => getEditServiceOutlets(val)}
          editServiceDetails={editServiceDetails}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={hotelUpdateSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setHotelUpdateSlider(false)}
      >
        <UpdateHotelService
          onClose={() => {
            setHotelUpdateSlider(false);
          }}
          getEditServiceDetails={(val: any) => getEditServiceDetails(val)}
          selectedHotel={selectedHotel}
          editServiceDetails={editServiceDetails}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={pageDetailsSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setPageDetailsSlider(false)}
      >
        <PageDetailsSlider
          onClose={() => {
            setPageDetailsSlider(false);
          }}
          getEditServiceDetails={(val: any) => getEditServiceDetails(val)}
          selectedHotel={selectedHotel}
          editServiceDetails={editServiceDetails} />
      </SlidingPane>
    </div>
  );
};

export default EditSelectedServices;
