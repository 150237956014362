import { UPDATE_CONTACT, GET_SELECTED_CONTACT_DATA } from "../actionTypes";

export const getSeletedContactData = (data: any) => {
    return (
        {
            type: GET_SELECTED_CONTACT_DATA,
            payload: data,
        }
    )
}
// export const emptyBlogData = () => {
//     return (
//         {
//             type: EMPTY_BLOG_DATA,
//             // payload: data
//         }
//     )
// }
export const updateStateAfterNewAddedContact = (data: any) => {
    return (
        {
            type: UPDATE_CONTACT,
            payload: data
        }
    )
}

