import React, { RefObject, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import CardWrapper from "../../../views/datacard/CardWrapper";
import { CancelConfirmationPrompt, ShowConfirmationPrompt } from "../../../redux/actions/PromptAction";

const EditGalleryData = () => {

  // Data From Redux
  const { galleryCategory } = useSelector((state: RootState) => state.sections);
  const { company_id, auth_token } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector((state: RootState) => state.sections);


  // States
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [ImgSliderData, setImageSliderData] = useState<any[]>([]);
  const [ImageIds, setImageIds] = useState<any[]>([]);
  const inputDropZone: RefObject<HTMLInputElement> = React.createRef();
  const dragItem: any = useRef(null);
  const dragOverItem: any = useRef(null);
  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchSection();
  }, []);

  // Data Fetching
  const fetchSection = async () => {
    try {
      let resp = await wbApi.get(
        `/wb-dashboard/fetch-wb-gallery-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        let galleryCategoryDetails = resp?.data?.gallery_data?.filter((iteam: any) => iteam.category.toLowerCase() === galleryCategory.toLowerCase());
        const { image_id, image_name, image_name_alt } = galleryCategoryDetails?.[0] ?? {};
        let displayImages = image_id?.map((imageId: string, index: number) => {
          return {
            ImageId: imageId,
            imageName: image_name[index]
          }

        })
        setImageSliderData(displayImages ?? []);
        setTitle(image_name_alt);
        setImageIds(image_id);

      } else {
        setImageSliderData([])
        setTitle("")
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error)
    }
  };

  const onDragOver = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
  };

  function onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let filesArray: any = [...files];
    let file = event.dataTransfer.files;

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
  }
  const inputOpen = () => {
    const { current } = inputDropZone;
    (current || { click: () => { } }).click();
  };

  //   OnAcceptingFiles Through Click =============================>>>>>>>>>>>;

  const acceptFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let filesArray: any = [...files];
    let file = e.target.files ?? {};

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
  };

  // To Make UploadImage Drag and Reorder===========================>>>>>>>>>

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
    // console.log("dragStart", e.target.innerHTML);
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
    // console.log("dragEnter", e.target.innerHTML);
  };

  const drop = (e: any) => {
    const copyListItems = [...files];
    const dragItemContent = copyListItems[dragItem?.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFiles(copyListItems);
  };

  // To Make Image Coming From Backend Drag and Reorder===========================>>>>>>>>>

  const dragStartImage = (e: any, position: any) => {
    dragItem.current = position;
    // console.log("dragStart", e.target.innerHTML);
  };
  const dragEnterImage = (e: any, position: any) => {
    dragOverItem.current = position;
    // console.log("dragEnter", e.target.innerHTML);
  };

  const dropImage = (e: any) => {
    const copyListItems = [...ImgSliderData];
    let dragItemContent = copyListItems[dragItem?.current];
    let dragOverContent = copyListItems[dragOverItem.current];
    // const temp = dragItemContent;
    // dragItemContent = dragOverContent;
    // dragOverContent = temp;
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setImageSliderData(copyListItems);
  };

  // For Deleting Image Uploaded Locally =======================>>>>>>>>;
  const onDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {
    if (e.target !== e.currentTarget) {
      e.stopPropagation();
      const newList = files?.filter((fw: any) => fw.name !== file);
      setFiles(newList);
    }
  };

  // Deleting Slider Images Coming From Backend===================>>>>>>>>>>
  const onDeleteIamge = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {

    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let deleteRes = await wbApi.post(`wb/delete-dzimages-gallery`, {
              company_id: company_id,
              image_name: file.imageName,
              category: galleryCategory,
              template_id: template_id,
            },
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (deleteRes.data.status === 1) {
              toast.success(deleteRes.data.message);
              fetchSection();
            } else {
              toast.error(deleteRes.data.message);
              fetchSection();
            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };
  const updateGallaryCategoryDetails = async (imgIds: any) => {
    setLoader(true);
    try {
      let resp = await wbApi.post(`wb/insert-update-wb-gallery`, {
        category: galleryCategory,
        company_id: company_id,
        image_name: imgIds,
        image_name_alt: title,
        template_id: template_id,
      })

      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        fetchSection();
        setRefreshList(!refreshList);
        setLoader(true);
      }
      else {
        toast.error(resp.data.message)
        setLoader(true);
      }
    }
    catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE)
    }
  }

  // Save Handler 
  const saveHandler = async () => {
    if (files?.length) {
      uploadHandler();
    } else {
      updateGallaryCategoryDetails(ImageIds ?? "undefined");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {
    if (files.length <= 20) {
      let payload = new FormData();
      files.forEach((file, index) => {
        payload.append(`uploadFile[${index}]`, file);
      })

      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-images/${company_id}/${template_id}/${galleryCategory}`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await updateGallaryCategoryDetails(uploadRes.data.image_ids);
        }
        else {
          toast.error(uploadRes.data.message);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Images number cannot be more than 20 ")
    }

  }

  // Api To Order List Of Images 
  const orderHandler = async () => {
    setUpdateLoader(true);
    try {
      let response = await wbApi.post(`/wb/insert-update-wb-gallery`,
        {
          category: galleryCategory,
          company_id: company_id,
          template_id: template_id,
          image_name: ImgSliderData.map((image: any) => image.ImageId) ?? [],
          image_name_alt: title ?? ""
        })
      if (response.data.status === 1) {
        toast.success(response.data.message);
        fetchSection();
        setUpdateLoader(false);
      } else {
        toast.error(response.data.message);
        setUpdateLoader(false);
      }

    } catch {
      setUpdateLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  }


  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: "Edit Gallery",
              href: "/section-default/edit-gallery-section-details",
            },
            {
              label: `${galleryCategory}`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*----------------- Main Section--------------- */}
      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div>
          <InputTextField
            label="Enter Alt Text"
            value={title}
            className="edit-promo-alt-text"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select BackGround Image
        </div>

        <div className="edit__dropzone__container mb-3">
          <div
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onClick={inputOpen}
            className={`mt-4 image__drag__drop__container`}
          >
            {files.length > 0 ? (
              <>
                <div className="image-preview-container">
                  {files?.map((imgFile, idx) => (
                    <>
                      <div
                        key={idx}
                        className="image-preview-wrapper"
                        draggable
                        onDragStart={(e) => dragStart(e, idx)}
                        onDragEnter={(e) => dragEnter(e, idx)}
                        onDragEnd={drop}
                      >
                        <img
                          src={imgFile?.preview}
                          className="image-preview-size"
                        />

                        <div
                          className="overlay-effect"
                          onClick={(e) => {
                            if (e.target !== e.currentTarget) {
                              e.stopPropagation();
                            }
                          }}
                        >
                          <div
                            className="edit-delet-btn-warpper"
                            onClick={(e) => {
                              if (e.target !== e.currentTarget) {
                                e.stopPropagation();
                              }
                            }}
                          >
                            <div
                              className="img-delet-btn"
                              onClick={(e) => {
                                onDelete(e, imgFile?.name);
                              }}
                            >
                              <i className="bi bi-trash img-delete-icon"></i>
                            </div>

                            {/* <div
                              className="img-delet-btn"
                              onClick={(e) => {
                                setImageSlider(true);
                                seteditFiles(imgFile);
                              }}
                            >
                              <i className="bi bi-pencil-square img-edit-icon"></i>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
            {ImgSliderData?.length > 0 ? (
              <div className="image-preview-container">
                {ImgSliderData?.map((imgFile, idx) => (

                  <div
                    key={idx}
                    className="image-preview-wrapper"
                    draggable
                    onDragStart={(e) => dragStartImage(e, idx)}
                    onDragEnter={(e) => dragEnterImage(e, idx)}
                    onDragEnd={dropImage}
                  >
                    <img
                      src={getImage(imgFile.imageName)}
                      className="image-preview-size"
                    />
                    <div className="overlay-effect">
                      <div
                        className="edit-delet-btn-warpper"
                        onClick={(e) => {
                          if (e.target !== e.currentTarget) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <div
                          className="img-delet-btn"
                          onClick={(e) => {
                            onDeleteIamge(e, imgFile);
                          }}
                        >
                          <i className="bi bi-trash img-delete-icon"></i>
                        </div>
                        {/* <div
                          className="img-delet-btn"
                          onClick={(e) => {
                            setImageSlider(true);
                            seteditFiles(imgFile);
                          }}
                        >
                          <i className="bi bi-pencil-square img-edit-icon"></i>
                        </div> */}
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            ) : (
              <></>
            )}

            {files?.length === 0 && ImgSliderData?.length === 0 ? (
              <div
                className="image_drag_area"
              // onClick={inputOpen}
              >
                <i className="bi bi-images"></i>
                <div className="text-area">Drag your photos or click here(20 Images Only)</div>
              </div>
            ) : (
              <></>
            )}
            <input
              id=""
              ref={inputDropZone}
              type="file"
              style={{ visibility: "hidden" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                acceptFileHandler(e);
              }}
              multiple
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            loader={loader}
            handleClick={() => saveHandler()}
          >
            Save
          </Button>
          <Button
            className="updatee-btn ms-2"
            loader={updateLoader}
            handleClick={() => orderHandler()}
          >
            Update Order
          </Button>

        </div>
      </CardWrapper>

      {/* {imagesLinkArr.length > 0 ? <>
        <strong>Gallary</strong>
        <div style={{ width: '100%', display: 'flex', marginBottom: '2rem', marginTop: '1rem' }}>
          {imagesLinkArr.map(image => {
            return (
              <>
                <div className="imgBox" style={{ width: "17rem", height: '10rem', background: 'white', borderRadius: '1rem', marginRight: '2rem' }}>
                  <img src={'https://bityl.co/Dnqg'} style={{ objectFit: "cover", width: '100%', height: "100%", borderRadius: '1rem' }} />
                </div>
              </>
            )
          })}
        </div>
      </>
        : <></>
      } */}
    </>
  );
};

export default EditGalleryData;
