import AddMenuBtn from "../../../views/buttons/Button";
import CancelButton from "../../../views/buttons/Button";
import Hotel from "../../../assests/img/hotel-image.png";
import InputTextField from "../../../views/inputfield/InputTextField";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import { useEffect, useState } from "react";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";

interface IEditImageSLiderProps {
  onClose: () => void;
  editfiles: any;
  getImageSliderDetails: () => void;
}

const EditImageSlider: React.FC<IEditImageSLiderProps> = ({
  onClose,
  editfiles,
  getImageSliderDetails,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [sliderText, setSliderText] = useState<string>("");
  const [altSliderText, setAltSliderText] = useState<string>("");

  useEffect(() => {
    setSliderText(editfiles.slider_caption);
    setAltSliderText(editfiles.slider_image_alt);
  }, [editfiles]);

  // console.log("editfiles ", editfiles);

  // Api To Post Updates

  const updateSliderHanlder = async () => {
    setLoader(true);
    try {
      let updateRes = await wbApi.post(`/wb/update-slider-content`, {
        slider_caption: sliderText,
        slider_id: editfiles.slider_id,
        slider_image_alt: altSliderText,
      });
      if (updateRes.data.status === 1) {
        toast.success(updateRes.data.message);
        getImageSliderDetails();
        setLoader(false);
        onClose();
      } else {
        toast.error(updateRes.data.message);
        getImageSliderDetails();
        setLoader(false);
        onClose();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };
  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit Slider</div>
      </div>
      <div className="height-64px"></div>

      <div className="slider__image__wrapper">
        <img
          src={`${
            editfiles.hasOwnProperty("slider_image")
              ? getImage(editfiles?.slider_image)
              : editfiles?.preview
          }`}
          alt=""
        />
      </div>
      <div className="">
        <InputTextField
          label="Slider Text"
          value={sliderText}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSliderText(e.target.value);
          }}
          rows={4}
          multiline
        />
      </div>
      <div className="mt-4">
        <InputTextField
          label="Alternate Slider Text"
          value={altSliderText}
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAltSliderText(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <CancelButton
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </CancelButton>

          <AddMenuBtn
            handleClick={() => {
              updateSliderHanlder();
            }}
            loader={loader}
          >
            Save
          </AddMenuBtn>
        </div>
      </div>
    </>
  );
};

export default EditImageSlider;
