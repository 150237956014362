import { GET_SELECTED_BLOG_DATA, EMPTY_BLOG_DATA, UPDATE_BLOG_STATE } from "../actionTypes";

const initialState = {
    blog_title: "",

    blog_description: "",

    blog_image_alt: "",

    display_description: "",

    blog_image_id: '',
}

const BlogsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SELECTED_BLOG_DATA:
            return {
                ...action.payload,
            }
        case EMPTY_BLOG_DATA:
            return {
            }


        default: return state
    }
}

export default BlogsReducer;