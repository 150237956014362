import { useNavigate } from "react-router-dom";
import InputTextField from "../../views/inputfield/InputTextField";
import Button from "../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage, Logout } from "../../UtilityFunctions";
import wbApi from "../../API/wbApi";
import { RefObject, useEffect, useRef, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import CardWrapper from "../../views/datacard/CardWrapper";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../redux/actions/PromptAction";
import React from "react";

const EditAwards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const [awardData, setAwardData] = useState<any>(null);
  const [altText, setAltText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [BannerImg, setBannerImg] = useState("");
  const [awardId, setAwardId] = useState("");
  const [awardBannerImg, setAwardBannerImg] = useState<any[]>([]);
  const [incomgAwardBannerImg, setIncomgAwardBannerImg] = useState<any[]>([]);
  const [firstloader, setfirstLoader] = useState<boolean>(false);
  const [secondLoader, setSecondLoader] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [ImgSliderData, setImageSliderData] = useState<any[]>([]);
  const [refreshAwardImageList, setRefreshAwardImageList] = useState<boolean>(false);
  const [awardSecondSectAlt, setAwardSecondSectAlt] = useState("");
  const [awardSecondSectId, setAwardSecondSectId] = useState("");
  const inputDropZone: RefObject<HTMLInputElement> = React.createRef();
  const dragItem: any = useRef(null);
  const dragOverItem: any = useRef(null);

  useEffect(() => {
    setFiles([]);
  }, [refreshAwardImageList])

  const onDragOver = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
  };

  function onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let filesArray: any = [...files];
    let file = event.dataTransfer.files;

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
  }
  const inputOpen = () => {
    const { current } = inputDropZone;
    (current || { click: () => { } }).click();
  };

  //   OnAcceptingFiles Through Click =============================>>>>>>>>>>>;

  const acceptFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let filesArray: any = [...files];
    let file = e.target.files ?? {};

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
  };

  // To Make UploadImage Drag and Reorder===========================>>>>>>>>>

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
    // console.log("dragStart", e.target.innerHTML);
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
    // console.log("dragEnter", e.target.innerHTML);
  };

  const drop = (e: any) => {
    const copyListItems = [...files];
    const dragItemContent = copyListItems[dragItem?.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFiles(copyListItems);
  };

  // To Make Image Coming From Backend Drag and Reorder===========================>>>>>>>>>

  const dragStartImage = (e: any, position: any) => {
    dragItem.current = position;
    // console.log("dragStart", e.target.innerHTML);
  };
  const dragEnterImage = (e: any, position: any) => {
    dragOverItem.current = position;
    // console.log("dragEnter", e.target.innerHTML);
  };

  const dropImage = (e: any) => {
    const copyListItems = [...ImgSliderData];
    const dragItemContent = copyListItems[dragItem?.current];
    const dragOverContent = copyListItems[dragOverItem.current];
    const temp = dragItemContent.slider_image;
    dragItemContent.slider_image = dragOverContent.slider_image;
    dragOverContent.slider_image = temp;
    // copyListItems.splice(dragItem.current, 1);
    // copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setImageSliderData(copyListItems);

  };


  const fetchAwardsApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-awards-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setAwardData(resp?.data?.awards);
        setAwardId(resp?.data?.awards_section_details?.id);
        setTitle(resp?.data?.awards_section_details?.section_title);
        setAltText(resp?.data?.awards_section_details?.background_image_alt);
        setDescription(resp?.data?.awards_section_details?.section_description ?? "");
        setAwardSecondSectAlt(resp?.data?.awards?.awards_image_alt);
        setAwardSecondSectId(resp?.data?.awards?.id);
        setBannerImg(resp?.data?.awards_section_details?.background_image);
        setImageSliderData(resp?.data?.awards?.awards)

        if (resp?.data?.awards_section_details?.background_image) {
          setIncomgAwardBannerImg([getImage(resp?.data?.awards_section_details?.background_image)])
        } else {
          setIncomgAwardBannerImg([]);
        }

      } else {
        setAwardId("undefined");
        setTitle("");
        setAltText("");
        setDescription("");
        setImageSliderData([]);
        setIncomgAwardBannerImg([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };



  useEffect(() => {
    fetchAwardsApi();
  }, []);

  const accpAwardBannerFiles = (value: any) => {
    setAwardBannerImg(value);
  };


  const deleteAwardBannerFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteAwardBannerFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };



  const delteAwardBannerFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: BannerImg,
          section_id: defaultSectionId,
          template_id: template_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchAwardsApi();
      } else {
        toast.error(deleteRes.data.message);
        fetchAwardsApi();
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  // For Deleting Image Uploaded Locally =======================>>>>>>>>;
  const onDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {
    if (e.target !== e.currentTarget) {
      e.stopPropagation();
      const newList = files?.filter((fw: any) => fw.name !== file);
      setFiles(newList);
    }
  };
  // Deleting Slider Images Coming From Backend===================>>>>>>>>>>
  const onDeleteIamge = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any,
    idx: number
  ) => {

    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let deleteRes = await wbApi.post(
              `/wb/delete-dz-awards-image`,
              {
                image_name: file,
                company_id: company_id,
                template_id: template_id,
              },
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (deleteRes.data.status === 1) {
              let remainAwardImages = ImgSliderData.splice(idx, 1);
              awardsImageSaveApi(ImgSliderData);

            } else {
              toast.error(deleteRes.data.message);

            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  // Save handler
  const saveSecDataHandler = async () => {
    if (awardBannerImg.length) {
      uploadHandler();
    } else {
      saveAwardsSecDataApi("undefined");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {

    if (awardBannerImg.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", awardBannerImg[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/awards_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await saveAwardsSecDataApi(uploadRes.data.image_ids);

        } else {
          toast.error(uploadRes.data.message);

        }
      } catch (error: any) {
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };

  const saveAwardsSecDataApi = async (ImgIds: any) => {
    setfirstLoader(true);
    try {
      const resp = await wbApi.post(
        `wb/insert-update-default-section-details`,
        {
          background_image: ImgIds ?? "undefined",
          background_image_alt: altText,
          company_id: company_id,
          id: awardId ?? "undefined",
          section_description: description,
          section_id: defaultSectionId,
          section_title: title,
          template_id: template_id,
        }
      );
      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        setfirstLoader(false);
        setRefreshList(!refreshList);
        fetchAwardsApi();
      } else {
        toast.error(resp.data.message);
        setfirstLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setfirstLoader(false);
    }
  };

  const saveAwardImageDataHandler = async () => {
    if (files.length) {
      awardImageUploadHandler();
    } else {
      awardsImageSaveApi([]);
    }
  };


  const awardImageUploadHandler = async () => {

    let payload = new FormData();
    files.forEach((file, index) => payload.append(`uploadFile[${index}]`, file));

    try {
      let uploadRes = await wbApi.post(
        `/wb/insert-image/${company_id}/${template_id}/Awards`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (uploadRes.data.status === 1) {
        let awardsName = files.map(file => file.name);
        await awardsImageSaveApi(awardsName);

      } else {
        toast.error(uploadRes.data.message);

      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };


  const awardsImageSaveApi = async (ImgNames: any[]) => {
    setSecondLoader(true);
    try {
      const resp = await wbApi.post(`wb/save-wb-awards`, {
        awards: ImgNames ?? [],
        awards_image_alt: awardSecondSectAlt,
        company_id: company_id,
        id: awardSecondSectId,
        template_id: template_id,
      });
      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        setSecondLoader(false);
        setRefreshAwardImageList(!refreshAwardImageList)
        fetchAwardsApi();
      } else {
        setSecondLoader(false);
        toast.error(resp.data.message);
        fetchAwardsApi();
      }
    } catch {
      setSecondLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Edit Awards`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}
      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">

        <div
          className="edit-aboutus-text-container"
        >
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => setTitle(e.target.value)}
          />
          <InputTextField
            label="Enter Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>
        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={incomgAwardBannerImg}
            dispatchFiles={(val) => accpAwardBannerFiles(val)}
            isDelete={(val) => deleteAwardBannerFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <div>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button className="cancel__btn"
            handleClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => saveSecDataHandler()}
            loader={firstloader}
          >
            Save
          </Button>
        </div>

        {/* --------------------------------------------------------- */}

        <div className="mt-3">
          <InputTextField
            label="Alternate text"
            value={awardSecondSectAlt}
            className="edit-aboutus-subheader"
            handleChange={(e) => setAwardSecondSectAlt(e.target.value)}
          />
        </div>
        <div className="edit-promo-alt-label mt-3">Select Awards Image</div>

        <div
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onClick={inputOpen}
          className={`mt-4 image__drag__drop__container`}
        >
          {files.length > 0 ? (

            <div className="image-preview-container">
              {files?.map((imgFile, idx) => (

                <div
                  key={idx}
                  className="image-preview-wrapper"
                  draggable
                  onDragStart={(e) => dragStart(e, idx)}
                  onDragEnter={(e) => dragEnter(e, idx)}
                  onDragEnd={drop}
                >
                  <img
                    src={imgFile?.preview}
                    className="image-preview-size"
                  />

                  <div
                    className="overlay-effect"
                    onClick={(e) => {
                      if (e.target !== e.currentTarget) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <div
                      className="edit-delet-btn-warpper"
                      onClick={(e) => {
                        if (e.target !== e.currentTarget) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <div
                        className="img-delet-btn"
                        onClick={(e) => {
                          onDelete(e, imgFile?.name);
                        }}
                      >
                        <i className="bi bi-trash img-delete-icon"></i>
                      </div>

                      {/* <div
                            className="img-delet-btn"
                            onClick={(e) => {
                              setImageSlider(true);
                              seteditFiles(imgFile);
                            }}
                          >
                            <i className="bi bi-pencil-square img-edit-icon"></i>
                          </div> */}
                    </div>
                  </div>
                </div>

              ))}
            </div>

          ) : (
            <></>
          )}
          {ImgSliderData?.length > 0 ? (
            <div className="image-preview-container">
              {ImgSliderData?.map((imgFile, idx) => (

                <div
                  key={idx}
                  className="image-preview-wrapper"
                  draggable
                  onDragStart={(e) => dragStartImage(e, idx)}
                  onDragEnter={(e) => dragEnterImage(e, idx)}
                  onDragEnd={dropImage}
                >
                  <img
                    src={getImage(imgFile)}
                    className="image-preview-size"
                  />
                  <div className="overlay-effect">
                    <div
                      className="edit-delet-btn-warpper"
                      onClick={(e) => {
                        if (e.target !== e.currentTarget) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <div
                        className="img-delet-btn"
                        onClick={(e) => {
                          onDeleteIamge(e, imgFile, idx);
                        }}
                      >
                        <i className="bi bi-trash img-delete-icon"></i>
                      </div>
                      {/* <div
                        className="img-delet-btn"
                        onClick={(e) => {
                          setImageSlider(true);
                          seteditFiles(imgFile);
                        }}
                      >
                        <i className="bi bi-pencil-square img-edit-icon"></i>
                      </div> */}
                    </div>
                  </div>
                </div>

              ))}
            </div>
          ) : (
            <></>
          )}

          {files?.length === 0 && ImgSliderData?.length === 0 ? (
            <div
              className="image_drag_area"
            // onClick={inputOpen}
            >
              <i className="bi bi-images"></i>
              <div className="text-area">Drag your photos or click here</div>
            </div>
          ) : (
            <></>
          )}
          <input
            id=""
            ref={inputDropZone}
            type="file"
            style={{ visibility: "hidden" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              acceptFileHandler(e);
            }}
            multiple
          />
        </div>

        {/* <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={incomingawardImgFiles}
            dispatchFiles={(val) => accpAwardImageFiles(val)}
            isDelete={(val) => deleteAwardImageFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshAwardImageList}
          />
        </div> */}

        <div className="edit-contact-btn-container">
          <Button className="cancel__btn"
            handleClick={() => navigate(-1)}
          >
            Cancel

          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              saveAwardImageDataHandler();
            }}
            loader={secondLoader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>
    </>
  );
};

export default EditAwards;
