import React from "react";
import { Outlet } from "react-router-dom";

import AppHeader from "../Components/header/AppHeader";
import AppSidebar from "../Components/sidebar/AppSidebar";
import AppContent from "../Components/contents/AppContent";
// import Breadcrumps from '../components/breadcrumbs/Breadcrumbs';

// import animate css
import "animate.css";

// custom confirmation prompt
import ConfirmationPrompt from "./ConfirmationPrompt";

// Switch property animation
import SwitchProperty from "./SwitchProperty";

const DefaultLayout: React.FC = () => {
  return (
    <>
      {/* <SwitchProperty /> */}
      <ConfirmationPrompt />
      <SwitchProperty />
      <div>
        <AppHeader />
        <div>
          <AppSidebar />
          <AppContent>
            {/* <Breadcrumps /> */}

            <Outlet />
          </AppContent>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
