import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNextTwoTone } from "@mui/icons-material";
import { DropZone } from "../../../views/dropzone/DropZone";
import InputTextField from "../../../views/inputfield/InputTextField";
import Card from "../../../views/datacard/Card";
import Button from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Hotel from "../../assests/img/hotel-image.png";
import AddBrandSlider from "./AddBrandSlider";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import {
  emptyBrandData,
  getSeletedBrandsData,
  updateStateAfterNewAdded,
} from "../../../redux/actions/BrandsAction";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";

const EditBrandSection = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [brandDetails, setBrandDetails] = useState([]);
  const [dataUpload, setDataUpload] = useState("");

  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [allHotels, setAllHotels] = useState([]);
  const [fetchedHotels, setFetchedHotels] = useState([]);
  const [sectId, setSectId] = useState([]);
  const [brandSectionDetails, setBrandSectionDetails] = useState<any>([]);
  const [brandSectionDescription, setBrandSectionDescription] =
    useState<any>("");
  const [brandLinkFile, setBrandLinkFile] = useState<any[]>([]);
  const [brandSectionBannerImg, setBrandSectionBannerImg] = useState<any>();
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const dispatch = useDispatch();

  // Data from Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { property_data, current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const update = useSelector((state: RootState) => state.stateUpdate);

  const getBrandSectionData = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-brand-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setBrandDetails(resp?.data?.brand_details);
        setBrandSectionDetails(resp?.data?.brand_section_details);
        // setBrandSectionDescription(resp?.data?.brand_section_details.section_description)
        setTitle(resp?.data?.brand_section_details?.section_title);
        setAltText(resp?.data?.brand_section_details?.background_image_alt);
        setParagraph(resp?.data?.brand_section_details?.section_description ?? "");
        setAllHotels(resp?.data?.all_hotels);
        setSectId(resp?.data?.brand_section_details?.id);
        if (resp?.data?.brand_section_details?.background_image) {
          setBrandSectionBannerImg([
            getImage(resp?.data?.brand_section_details?.background_image),
          ]);
        } else {
          setBrandSectionBannerImg([]);
        }
      } else setBrandDetails([]);
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    getBrandSectionData();
  }, [dataUpload, update]);

  const deleteBrandData = async (ID: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const resp = await wbApi.post(`wb/delete-wb-brand`, {
              company_id: company_id,
              id: ID,
              template_id: template_id,
            });

            if (resp.data.status === 1) {
              toast.success(resp.data.message);
              dispatch(updateStateAfterNewAdded(nanoid()));
            } else toast.error(resp.data.message);
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };


  // Image capture and saving sectional details
  const accpBrandFiles = (value: any) => {
    setBrandLinkFile(value);
  };

  // This is for deleting fetched images
  const deleteBrandLinkFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deleteBrandLinkFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );

    }
  };

  const deleteBrandLinkFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: brandSectionDetails.background_image,
          section_id: defaultSectionId,
          template_id: template_id,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        getBrandSectionData();
      } else {
        toast.error(deleteRes.data.message);
        getBrandSectionData();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  const saveHandler = async () => {
    if (brandLinkFile.length) {
      uploadHandler();
    } else {
      addUpdateBrandSection("undefined");
    }
  };

  // const Upload handler
  const uploadHandler = async () => {
    if (brandLinkFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", brandLinkFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/brand_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await addUpdateBrandSection(uploadRes.data.image_ids);
        } else {
          toast.error(uploadRes.data.message);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Only One Image Allowed")
    }
  };

  // handler for saving section details data
  const addUpdateBrandSection = async (imgId: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post(
        "/wb/insert-update-default-section-details",
        {
          id: sectId ?? "undefined",
          background_image: imgId ?? "undefined",
          background_image_alt: altText,
          company_id: company_id,
          section_description: paragraph,
          section_id: defaultSectionId,
          section_title: title,
          template_id: template_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (req.data.status === 1) {
        toast.success(req.data.message);
        getBrandSectionData();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(req.data.message);
        getBrandSectionData();
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Edit Brand`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            dispatch(emptyBrandData(allHotels));
            setOpen(true);
          }}
        >
          Add Brand
        </Button>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => setTitle(e.target.value)}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>
        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>
        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={brandSectionBannerImg}
            dispatchFiles={(val) => accpBrandFiles(val)}
            isDelete={(val) => deleteBrandLinkFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>
        <div className="sec-description-editor" style={{ margin: "1rem 0rem" }}>
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={paragraph}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setParagraph(data);
              }}
            />
          </div>
        </div>
        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => saveHandler()}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">Brand List</div>

      <div className="default_section_image_card_container mt-3">
        {brandDetails.length > 0 ? (
          brandDetails.map((obj: any, idx) => {
            const {
              id,
              brand_title,
              brand_image,
              brand_description,
              brand_image_alt,
              brand_hotels,
              display_description,
            } = obj;

            return (
              <div
                className="default__setion__image"
                key={idx}
                style={{ marginRight: "20px" }}
              >
                <div
                  className="image__container"
                  style={{ minHeight: "120px" }}
                >
                  <img src={getImage(brand_image)} />
                  <div className="overlay overlay-border-radius">
                    <div className="button__container">
                      <div
                        className="edit-button"
                        onClick={() => {
                          setOpen(true);
                          dispatch(
                            getSeletedBrandsData({
                              id,
                              brand_image,
                              brand_title,
                              brand_description,
                              brand_image_alt,
                              brand_hotels,
                              display_description,
                              allHotels,
                            })
                          );
                        }}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </div>
                      <div
                        className="delete-button"
                        onClick={() => {
                          deleteBrandData(id);
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hotel__name">{brand_title}</div>
              </div>
            );
          })
        ) : (
          <NoDataComponent message="No Data Found" />
        )}
      </div>

      {/* <div className="sixtypx-height"></div> */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddBrandSlider
          onClose={() => {
            setOpen(false);
          }}
          getBrandSectionData={getBrandSectionData}
        />
      </SlidingPane>
    </>
  );
};

export default EditBrandSection;
