import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import Person from "../../../assests/img/person.jpg";
import SlidingPane from "react-sliding-pane";
import EditTestimonySlider from "./EditTestimonySlider";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { Draggable } from "react-drag-reorder";
import AddTestimonySlider from "./AddTestimonySlider";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const TestimonialHotelCard = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [testiMonyOpen, setTestiMoneyOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [hotelName, setHotelName] = useState<string>("");
  const [hotelId, setHotelId] = useState<any>("");
  const [hotelTestimonial, setHotelTestimonial] = useState<any>([]);
  const [defaultHotel, setDefaultHotel] = useState<any>({});
  const [selectedTestimonial, setSelectedTestimonial] = useState<any>({});


  useEffect(() => {
    if (location.state) {
      const { hotel_id, hotel_name }: any = location.state ?? {};
      setHotelName(hotel_name);
      setHotelId(hotel_id);
    }
  }, [location])

  const testimonialApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-testimonial-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setHotelTestimonial(resp?.data?.hotel_testimonials);
        setDefaultHotel(resp?.data?.default_hotel)

      } else {
        setHotelTestimonial([]);
        setDefaultHotel({});

      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  useEffect(() => {
    testimonialApi();
  }, []);

  const makeDefault = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let defaultRes = await wbApi.post(`/wb/make-hotel-default-for-section`, {
        id: defaultHotel.id ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        default_hotel_id: event.target.checked ? hotelId : "",
        section_id: defaultSectionId
      })
      if (defaultRes.data.status === 1) {
        toast.success(defaultRes.data.message);
      } else {
        toast.error(defaultRes.data.message);
      }

    } catch {
      toast.error(CATCH_MESSAGE)
    }
  }

  const deleteTestiMonial = async (id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let deletRes = await wbApi.post(`/wb/delete-wb-testimonial`, {
              id: id ?? "",
              template_id: template_id,
              company_id: company_id
            })
            if (deletRes.data.status === 1) {
              toast.success(deletRes.data.message);
              testimonialApi();
            } else {
              toast.error(deletRes.data.message);
              testimonialApi();
            }
          } catch {
            toast.error(CATCH_MESSAGE)
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  }

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderOutlets = [...hotelTestimonial];
    let temp = orderOutlets[currentPos].id;
    orderOutlets[currentPos].id = orderOutlets[newPos].id
    orderOutlets[newPos].id = temp
    // setUpdateOrder(orderOutlets);
  };
  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (hotelTestimonial && hotelTestimonial?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {hotelTestimonial?.map((iteam: any) => (
            <div className="testimonial__card__wrapper reorder--onhover" key={iteam.id}>
              <div className="display__none">
                <div className="reorder__cursor">
                  <i className="bi bi-arrows-move"></i>
                </div>
              </div>
              <div className="testimonial__image mb-2">
                <img src={getImage(iteam.testimonial_image)} />
              </div>
              <div className="testi__label">{iteam.testimonial_title}</div>
              <div className="testi__description">
                {iteam.display_description}
              </div>
              <div className="overlay overlay-border-radius">
                <div className="button__container">
                  <div
                    className="edit_button"
                    onClick={() => {
                      setEditOpen(true);
                      setSelectedTestimonial(iteam);
                    }}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="delete-button" onClick={() => { deleteTestiMonial(iteam.id) }}>
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Draggable>
      );
    }
    return null;
  }, [hotelTestimonial]);


  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: "Edit Testimonial",
              href: "/section-default/edit-testimonial-section-details"
            },
            {
              label: `${hotelName}`,
            }
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}
      <div className="top__button__container">
        <div className="form-check default_label me-2">
          <label className="form-check-label mt-2" htmlFor="setDefault">
            Set as Default
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="setDefault"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeDefault(e)}
            checked={defaultHotel.default_hotel_id === hotelId}
          ></input>
        </div>
        <Button
          icon="bi bi-plus"
          className="me-3"
          handleClick={() => {
            setTestiMoneyOpen(true);
          }}
        >
          Add
        </Button>
      </div>

      <div className="testimonial__card__container">
        {hotelTestimonial?.length > 0 ? (
          <DraggableRender />
        ) : (
          <NoDataComponent message={"No Data Found"} />
        )
        }
      </div>
      {/* <div className="d-flex">
        <Button className="cancel__btn me-4">Cancel</Button>
        <Button className="savebtn">Save</Button>
      </div> */}
      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={testiMonyOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setTestiMoneyOpen(false)}
      >
        <AddTestimonySlider
          onClose={() => {
            setTestiMoneyOpen(false);
          }}
          testimonialApi={testimonialApi}
          hotelId={hotelId}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditOpen(false)}
      >
        <EditTestimonySlider
          onClose={() => {
            setEditOpen(false);
          }}
          selectedTestimonial={selectedTestimonial}
          testimonialApi={testimonialApi}
          hotelId={hotelId}
        />
      </SlidingPane>
    </>
  );
};

export default TestimonialHotelCard;
