import React, { useCallback, useEffect, useState } from "react";
import { Draggable } from "react-drag-reorder";
import SliderSectionCard from "../../../views/datacard/SliderSectionCard";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

interface ICategorySliderProps {
  onClose: () => void;
  fetchSection: () => void;
  categoryList: any[];
}

const AddCategorySlider: React.FC<ICategorySliderProps> = ({
  onClose,
  categoryList,
  fetchSection,
}) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);

  // States
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [updateMenuOrder, setUpdateMenuOrder] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredResults(categoryList)
  }, [categoryList])

  // Search Input Handler
  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = categoryList.filter((item: any) => {
        return item.category_name
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
      });
      setFilteredResults(filteredData);
      // console.log(filteredData, "filteredData");
    } else {
      setFilteredResults(categoryList);
    }
    setSearchInput(keyword);
  };

  const addCategoryHandler = async () => {
    setLoader(true);
    try {
      let categoryReq = await wbApi.post("/category/insert-category", {
        company_id,
        template_id,
        category_name: categoryName,
      });
      if (categoryReq.data.status === 1) {
        toast.success(categoryReq.data.message);
        fetchSection();
        setLoader(false);
        onClose();
      } else {
        toast.error(categoryReq.data.message);
        setLoader(false);
        onClose();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Category Delet Handler
  const deletHandler = (id: any, index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          let category = filteredResults;
          let delet = category.splice(index, 1);
          await deletCategory(delet[0].id);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  // Delet Category Post
  const deletCategory = async (id: any) => {
    try {
      let deletReq = await wbApi.get("/category/delete-category/" + id);
      // console.log("deletReq", deletReq);
      if (deletReq.data.status === 1) {
        toast.success("Deleted Successfully");
        fetchSection();
        onClose();
      } else {
        toast.error(deletReq.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };
  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...filteredResults];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
    const menuInOrder = orderSec.map((iteam, idx) => {
      return {
        ...filteredResults[idx],
        category_name: iteam.category_name,
      };
    });
    // console.log("menuInOrder", menuInOrder);
    setUpdateMenuOrder(menuInOrder);
  };

  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (filteredResults?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {filteredResults?.map((item: any, idx: any) => {
            return (
              <SliderSectionCard
                key={item.id}
                className="slider__draggable"
                onDelete={() => {
                  deletHandler(item.id, idx);
                }}
                editOptn={false}
                deletOptn={true}
              >
                {item.category_name}
              </SliderSectionCard>

            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [filteredResults]);

  // Api To Reorder Categories List
  const updateOrderList = async () => {
    setUpdateLoader(true);
    try {
      let orderReq = await wbApi.post(`/ordering/order-wb-gallery-image-category`, {
        company_id: company_id,
        template_id: template_id,
        category: updateMenuOrder
      });
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setUpdateLoader(false);
        fetchSection();
        onClose();
      } else {
        toast.error(orderReq.data.message);
        setUpdateLoader(false);
      }
    } catch {
      setUpdateLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  }

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Category</div>
      </div>
      <div className="height-64px"></div>

      <InputTextField
        label="Enter Category Name"
        value={categoryName}
        className="description description__slider"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCategoryName(e.target.value);
        }}
      />

      <div className="category_list_wrapper">
        <div className="list_label">List</div>
        <hr />
        <div className="search__booking__wrapper">
          <i className="bi bi-search"></i>
          <input
            placeholder="Search Category"
            value={searchInput}
            onChange={(e) => {
              searchItems(e.target.value);
            }}
          />
        </div>

        {
          filteredResults?.length > 0 ? (
            <div className="drag_list_wrapper">
              <DraggableRender />
            </div>
          ) : (
            <NoDataComponent message={"No Category Found"} />
          )
        }

      </div>



      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          {filteredResults.length > 1 ?
            <Button
              handleClick={() => {
                updateOrderList();
              }}
              loader={updateLoader}
            >
              Update Order
            </Button> : null
          }


          <Button
            handleClick={() => {
              addCategoryHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddCategorySlider;
