import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextField from "../../../views/inputfield/InputTextField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Button from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SlidingPane from "react-sliding-pane";
import AddBlogSlider from "./AddBlogSlider";
import { toast } from "react-toastify";
import { Capitalize, CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { nanoid } from "@reduxjs/toolkit";
import {
  emptyBlogData,
  getSeletedBlogsData,
} from "../../../redux/actions/BlogsAction";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import EditBlogSlider from "./EditBlogSlider";

const EditBlog = () => {
  const navigate = useNavigate();
  // Data from Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const blog_update = useSelector((state: RootState) => state.blogsUpdate);

  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  const [blogsArr, setBlogsArr] = useState([]);
  const [blogsSectData, setBlogsSectData] = useState<any>(null);
  const [blogsSectId, setBlogsSectId] = useState("undefined");
  const [update, setUpdate] = useState("");
  const [blogBannerImg, setBlogBannerImg] = useState<any>();
  const [blogFile, setBlogFile] = useState<any[]>([]);
  const [editBlogSectionDetails, setEditBlogSectionDetails] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);

  const dispatch = useDispatch();

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const accpBlogFiles = (value: any) => {
    setBlogFile(value);
  };

  const deleteBlogFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteBlogFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteBlogFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: blogsSectData.background_image,
          section_id: defaultSectionId,
          template_id: template_id,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        getBlogsData();
      } else {
        toast.error(deleteRes.data.message);
        getBlogsData();
      }
    } catch (error) {
      console.log(error);
      toast.error(CATCH_MESSAGE);
    }
  };

  // Save Handler
  // Save Handler
  const saveHandler = async () => {
    if (blogFile.length) {
      uploadHandler();
    } else {
      updateBlogSectData("undefined");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {

    if (blogFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", blogFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/blog_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await updateBlogSectData(uploadRes.data.image_ids);
        } else {
          toast.error(uploadRes.data.message);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
      }
    } else {
      toast.error("Only One Image Allowed")
    }
  };

  const updateBlogSectData = async (imgId: any) => {
    setLoader(true);
    try {
      const payload = {
        background_image: imgId ?? "undefined",
        background_image_alt: altText,
        company_id: company_id,
        id: blogsSectId ?? "undefined",
        section_description: description,
        section_id: 12,
        section_title: title,
        template_id: template_id,
      };

      const resp = await wbApi.post(
        `wb/insert-update-default-section-details`,
        payload
      );

      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        setRefreshList(!refreshList);
        setUpdate(nanoid());
        setLoader(false);
      } else {
        toast.error(resp.data.message);
        setLoader(false);
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  const getBlogsData = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-blog-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setBlogsArr(resp?.data?.hotel_blogs);
        setBlogsSectData(resp?.data?.blog_section_details);
        if (resp?.data?.blog_section_details?.background_image) {
          setBlogBannerImg([
            getImage(resp?.data?.blog_section_details?.background_image),
          ]);
        } else {
          setBlogBannerImg([]);
        }
      } else {
        setBlogsArr([]);
      }
    } catch (Err) {
      // toast.error(CATCH_MESSAGE);
      console.log(Err)
    }
  };

  useEffect(() => {
    getBlogsData();
  }, [update, blog_update]);

  useEffect(() => {
    if (!blogsSectData) return;
    setTitle(blogsSectData.section_title);
    setAltText(blogsSectData.background_image_alt);
    setDescription(blogsSectData.section_description);
    setBlogsSectId(blogsSectData.id ?? "undefined");
  }, [blogsSectData]);

  const deleteBlogApi = async (ID: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const payload = { company_id, template_id, id: ID };
            const resp = await wbApi.post(`wb/delete-wb-blog`, payload);

            if (resp.data.status === 1) {
              toast.success(resp.data.message);
              setUpdate(nanoid());
            } else toast.error(resp.data.message);
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Blog",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/* ------------------------------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            dispatch(emptyBlogData());
            setOpen(true);
          }}
        >
          Add Blog
        </Button>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => setTitle(e.target.value)}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={blogBannerImg}
            dispatchFiles={(val) => accpBlogFiles(val)}
            isDelete={(val) => deleteBlogFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);
                setCharCount({
                  ...charCount,
                  terms_cond: removeTags(data).length,
                });
              }}
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      {/* --------------------------------- */}
      {blogsArr.length > 0 ? (
        <div className="select__hotel__label">Blog List</div>
      ) : (
        <></>
      )}

      <div className="default_section_image_card_container mt-3 mb-2">
        {blogsArr.length > 0 ? (
          blogsArr.map((obj: any, ind) => {
            return (
              <div className="blog_section_card_container" key={obj.id}>
                <div className="image__container">
                  <img src={getImage(obj.blog_image)} alt={obj.blog_image_alt} />
                </div>
                <div className="blog_title">{Capitalize(obj.blog_title)}</div>
                <div className="blog_description">
                  {obj.display_description}
                </div>
                <div className="overlay">
                  <div className="button__container">
                    <div className="edit_button" onClick={() => { setEditOpen(true); setEditBlogSectionDetails(obj) }}>
                      <i className="bi bi-pencil-square"></i>
                    </div>
                    <div className="delete-button " onClick={() => deleteBlogApi(obj.id)}>
                      <i className="bi bi-trash"></i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          null
        )}
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <AddBlogSlider
          onClose={() => {
            setOpen(false);
          }}
          getBlogsData={getBlogsData}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditOpen(false)}
      >
        <EditBlogSlider
          onClose={() => {
            setEditOpen(false);
          }}
          getBlogsData={getBlogsData}
          editBlogSectionDetails={editBlogSectionDetails}
        />
      </SlidingPane>
    </>
  );
};

export default EditBlog;
