import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlternateText from "../../views/inputfield/InputTextField";
// import ImageDropzone from '../identity/ImageDropzone';
import HeaderText from "../../views/inputfield/InputTextField";
import SubHeaderText from "../../views/inputfield/InputTextField";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../views/datacard/CardWrapper";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import Button from "../../views/buttons/Button";
import wbApi from "../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage, Logout } from "../../UtilityFunctions";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../redux/actions/PromptAction";

const EditNewsletter = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [headerText, setHeaderText] = useState<string>("");
  const [subHeaderText, setSubHeaderText] = useState<string>("");
  const [altText, setAltText] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [newsfiles, setNewsFiles] = useState<any[]>([]);
  const [newsLtrId, setNewsLtrId] = useState<any>("undefined");
  const [newsLtrData, setNewsLtrData] = useState<any>({});
  const [defaultValueId, setDefaultValueId] = useState<string>("");
  const [newsLtrImg, setNewsLtrImg] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accpNewsFiles = (value: any) => {
    setNewsFiles(value);
  };

  const delteNewsFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteNewsFilesApi();;

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  useEffect(() => {
    fetchSection();
  }, []);

  // Promotion Banner Fetching

  const fetchSection = async () => {
    try {
      let SectionRes = await wbApi.get(
        `/wb-dashboard/fetch-newsletter-details/${company_id}/${template_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // console.log("SectionRes", SectionRes);
      if (SectionRes.data.status === 1) {
        setNewsLtrData(SectionRes?.data?.newsletter ?? {});
        if (SectionRes?.data?.promo_news_default_value) {
          setDefaultValueId(
            SectionRes?.data?.promo_news_default_value?.default_value
          );
        } else {
          setDefaultValueId("");
        }
        if (SectionRes?.data?.newsletter) {
          setNewsLtrId(SectionRes?.data?.newsletter?.id);
          setAltText(SectionRes?.data?.newsletter?.image_alt);
          setHeaderText(SectionRes?.data?.newsletter?.header);
          setSubHeaderText(SectionRes?.data?.newsletter?.sub_header);
          if (SectionRes.data.newsletter.image) {
            setNewsLtrImg([getImage(SectionRes.data.newsletter.image)]);
          } else {
            setNewsLtrImg([]);
          }
        } else {
          setNewsLtrId("undefined");
          setAltText("");
          setHeaderText("");
          setSubHeaderText("");
          setNewsLtrImg([]);
        }
      }
    } catch (error: any) {
      // toast.error(CATCH_MESSAGE);
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  // Setting Default

  const makeNewsDefault = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let req = await wbApi.post("/wb/make-promo-newsletter-default", {
        company_id: company_id,
        template_id: template_id,
        default_value: e.target.checked ? "newsletter" : "",
      });
      // console.log("req", req);
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
      } else {
        toast.error(req.data.message);
        fetchSection();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Save Handler

  function saveHandler() {
    if (newsfiles.length) {
      uploadHandler();
    } else {
      addUpdateNews("undefined");
    }
  }

  // To Upload Image
  const uploadHandler = async () => {
    if (newsfiles.length === 1) {
      setLoader(true);

      let payload = new FormData();

      payload.append("uploadFile[0]", newsfiles[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/newsletter`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateNews(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };

  // Update Promo Section

  const addUpdateNews = async (newsLetterImg: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post(
        "/wb/insert-update-newsletter",
        {
          id: newsLtrId ?? "undefined",
          image: newsLetterImg,
          image_alt: altText,
          company_id: company_id,
          template_id: template_id,
          header: headerText,
          sub_header: subHeaderText,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(req.data.message);
        fetchSection();
        setLoader(false);
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  const delteNewsFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-newsletter`, {
        company_id: company_id,
        image_name: newsLtrData.image,
        template_id: template_id,
      });
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSection();
      } else {
        toast.error(deleteRes.data.message);
        fetchSection();
      }
    } catch (error) {
      // console.log(error);
      toast.error(CATCH_MESSAGE);
    }
  };
  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit News Letter",
            },
          ]}
        />

        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/* Main Section */}

      <div className="form-check mt-4">
        <label className="form-check-label" htmlFor="setDefault">
          Set as Default
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          value="promotional"
          id="setDefault"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeNewsDefault(e)}
          checked={defaultValueId.toLowerCase() === "newsletter"}
        ></input>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <HeaderText
          label="Header"
          value={headerText}
          className="edit-promo-alt-text"
          handleChange={(e) => setHeaderText(e.target.value)}
        />
        <div className="mt-4 edit-newsletter-text-container">
          <SubHeaderText
            label="Sub Header"
            value={subHeaderText}
            className="edit-newsletter-subheader"
            handleChange={(e) => setSubHeaderText(e.target.value)}
          />
          <AlternateText
            label="Alternate Text"
            value={altText}
            className="edit-newsletter-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>
        <div className="edit-promo-alt-label">Select News Letter Image</div>
        <DropzoneArea
          centerMesage={"Drag your photos or click here (1 Image Only)"}
          incomingfiles={newsLtrImg}
          dispatchFiles={(val) => accpNewsFiles(val)}
          isDelete={(val) => delteNewsFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
          refresh={refreshList}
        />
        <div className="mt-4 edit-promo-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="save-btn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>
    </>
  );
};

export default EditNewsletter;
