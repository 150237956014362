import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import LogIn from "../pages/login/LogIn";
import Loginwithoutcredential from "../pages/login/Loginwithoutcredential";
// import ResetPassword from "../pages/ResetPassword";
// import EnterNewPassword from "../pages/EnterNewPassword";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LogIn />} />
      <Route
        path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
        element={<Loginwithoutcredential />}
      />
      {/* <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/new-password" element={<EnterNewPassword />} /> */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthRoutes;
