import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import wbApi from '../../../API/wbApi';
import { updateStateAfterNewAddedContact } from '../../../redux/actions/ContactAction';
import { RootState } from '../../../redux/store';
import Button from '../../../views/buttons/Button';
import InputTextField from '../../../views/inputfield/InputTextField';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CATCH_MESSAGE, validateEmail, validateLandline, validateMobile } from '../../../UtilityFunctions';
interface IContactSliderProps {
    onClose: () => void;
    allIcon: any[];
    contactsApi: () => void;
    // fetchSection: () => void;
}

const EditContactUsSlider: React.FC<IContactSliderProps> = ({
    onClose,
    allIcon,
    contactsApi,
    // fetchSection
}) => {
    const { company_id } = useSelector((state: RootState) => state.auth);
    const { template_id } = useSelector((state: RootState) => state.template_id);
    const data = useSelector((state: RootState) => state.contactData);

    const dispatch = useDispatch()



    // States
    const [selectIcon, setSelectIcon] = useState<any>("");
    const [searchInput, setSearchInput] = useState<any>("");
    const [filteredResults, setFilteredResults] = useState<any[]>([]);
    const [addressName, setAddressName] = useState("");
    const [description, setDescription] = useState<any>("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
    const [landLineNumber, setLandLineNumber] = useState("");
    const [landLineNumberHelperText, setLandLineNumberHelperText] = useState("");
    const [whatsAppNumber, setWhatsAppNumber] = useState("");
    const [whatsAppNumberHelperText, setWhatsAppNumberHelperText] = useState("");
    const [email, setEmail] = useState<any[]>([""]);
    const [emailHelperText, setEmailHelperText] = useState<any>("");
    const [sectId, setSectId] = useState("");
    const [loader, setLoader] = useState<boolean>(false);
    const [charCount, setCharCount] = useState({
        child: 0,
        privacy: 0,
        terms_cond: 0,
        cancellation: 0,
    });
    function removeTags(str: String) {
        str = str.toString();
        const finalStr = str.replace(/&nbsp;/g, " ");
        return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
    }
    useEffect(() => {
        if (!data.id) return
        setLandLineNumber(data.landline_no)
        setMobileNumber(data.mobile_no)
        setWhatsAppNumber(data.whatsapp_no)
        setEmail(data.email)
        setDescription(data.display_description)
        setSectId(data.id)
        setAddressName(data.address_name)
    }, [data]);



    const searchItems = (searchValue: any) => {
        const keyword = searchValue;
        if (keyword !== "") {
            const filteredData = allIcon.filter((item: any) => {
                return item.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(allIcon);
        }
        setSearchInput(keyword);
    };

    const selectIconHandler = (icon: any) => {
        let icons = [];
        let selectedIcons = icons.push(icon);
        setSelectIcon(selectedIcons);
    };
    const addEmailHandler = () => {
        let addEmail = [...email, ""]
        setEmail(addEmail)
    };

    const handleChangeHandler = (e: any, idx: any) => {
        const { value } = e.target;
        let emailsArr = [...email];
        emailsArr[idx] = value;
        setEmail(emailsArr);

        if (!validateEmail(value)) {
            setEmailHelperText("Invalid email");
        } else {
            setEmailHelperText("");

        }
    };

    const addContactHandler = async () => {
        if (emailHelperText.length === 0
            &&
            whatsAppNumberHelperText.length === 0
            &&
            landLineNumberHelperText.length === 0
            &&
            mobileNumberHelperText.length === 0) {
            setLoader(true);
            try {
                let addContactReq = await wbApi.post(
                    "wb/insert-update-wb-contact-details",
                    {
                        id: sectId ? sectId : "undefined",
                        company_id,
                        template_id,
                        icon: selectIcon,
                        address_name: addressName,
                        description: description,
                        mobile_no: mobileNumber,
                        landline_no: landLineNumber,
                        whatsapp_no: whatsAppNumber,
                        email: email,
                    }
                );
                // console.log("addContactReq", addContactReq);
                if (addContactReq.data.status === 1) {
                    toast.success(addContactReq.data.message);
                    dispatch(updateStateAfterNewAddedContact(nanoid()))
                    onClose()
                    setLoader(false);
                    contactsApi();
                } else {
                    toast.error(addContactReq.data.message);
                    onClose()
                    setLoader(false);
                }
            } catch (error) {
                toast.error(CATCH_MESSAGE);
                setLoader(false);
                // console.log(error);
            }
        } else {

        }

    };
    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Edit {data.address_name}</div>
            </div>
            <div className="height-64px"></div>
            <div className="search__booking__wrapper">
                <i className="bi bi-search"></i>
                <input
                    placeholder="Search Icon"
                    value={searchInput}
                    onChange={(e) => {
                        searchItems(e.target.value);
                    }}
                />
            </div>
            <div className="row">
                {filteredResults.map((item) => {
                    return (

                        <div
                            key={item.id}
                            className={`${item.icon} col-12`}
                            style={{ marginBottom: "0.5rem" }}
                            onClick={() => {
                                selectIconHandler(item);
                            }}
                        >
                            {item.name}
                        </div>

                    );
                })}
            </div>
            <InputTextField
                label="Address Name"
                value={addressName}
                className="description description__slider"
                handleChange={(e) => {
                    setAddressName(e.target.value);
                }}
            />
            <div style={{ marginBottom: "1rem" }}>
                <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
                    }}
                    onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setDescription(data);
                        setCharCount({
                            ...charCount,
                            terms_cond: removeTags(data).length,
                        });
                    }}
                />
            </div>
            <InputTextField
                label="Mobile Number"
                value={mobileNumber}
                className="description description__slider"
                handleChange={(e) => {
                    setMobileNumber(e.target.value);
                    if (validateMobile(e.target.value)) {
                        setMobileNumberHelperText("")
                    } else {
                        setMobileNumberHelperText("Invalid Mobile Number")
                    }
                }}
            />
            <InputTextField
                label="Landline Number"
                value={landLineNumber}
                className="description description__slider"
                handleChange={(e) => {
                    setLandLineNumber(e.target.value);
                    if (validateLandline(e.target.value)) {
                        setMobileNumberHelperText("")
                    } else {
                        setMobileNumberHelperText("Invalid LandLine Number")
                    }
                }}
            />
            <InputTextField
                label="WhatsApp Number"
                value={whatsAppNumber}
                className="description description__slider"
                handleChange={(e) => {
                    setWhatsAppNumber(e.target.value);
                    if (validateMobile(e.target.value)) {
                        setMobileNumberHelperText("")
                    } else {
                        setMobileNumberHelperText("Invalid Mobile Number")
                    }
                }}
            />

            <div className="email__section__wrapper">
                <div className="email_button_wrapper">
                    {email.map((email: any, index: any) => {
                        return (

                            <InputTextField
                                label="Email"
                                key={index}
                                value={email}
                                className="description description__slider"
                                handleChange={
                                    (e: any) => {
                                        handleChangeHandler(e, index)
                                    }
                                }
                                helperText={emailHelperText}
                            />


                        );
                    })}
                </div>
                <div
                    className="email__addbtn"
                    onClick={(e: any) => {
                        addEmailHandler();
                    }}
                >
                    <i className="bi bi-plus-lg"></i>
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>

            <div className="side__slider__panel__btn">
                <div className="side__slider__panel__btn_container">
                    <Button
                        className="cancel__btn"
                        handleClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        handleClick={() => {
                            addContactHandler();
                        }}
                        loader={loader}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </>
    )
}

export default EditContactUsSlider
