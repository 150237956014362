import { UPDATE_EVENTS_STATE } from "../actionTypes"

const initialState = ''

const EventsUpdate = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_EVENTS_STATE:
            return action.payload

        default: return state
    }
}

export default EventsUpdate;