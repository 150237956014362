import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import Hotel from "../../../assests/img/hotel-image.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import {
  HotelRoomsAction,
  HotelRoomsDetailsAction,
} from "../../../redux/actions/SectionAction";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../../views/datacard/CardWrapper";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { Capitalize, CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";


const EditRooms = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const { hotelRoomIdOnClick } = useSelector(
    (state: RootState) => state.sections
  );

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [hotelName, setHotelName] = useState<any>("");
  const [hotelId, setHotelId] = useState<number>(0);

  const [aboutSliderOpen, setAboutSliderOpen] = useState(false);

  const [roomImg, setRoomImg] = useState<any[]>([]);

  const [hotelDetails, setHotelDetails] = useState<any[]>([]);
  const [hotelAboutDetails, setHotelAboutDetails] = useState<any[]>([]);

  const [editRoomfiles, setEditRoomFiles] = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [room, setRoom] = useState("");
  const [roomId, setRoomId] = useState<any>("");
  const [roomData, setRoomData] = useState<any>({});
  const [allhotelDetails, setAllHotelDetails] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });


  const accpAboutFiles = (value: any) => {
    setEditRoomFiles(value);
  };

  const delteAboutFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteRoomSecFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };
  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }
  // Data Fetching

  useEffect(() => {
    fetchSection();
  }, []);

  const fetchSection = async () => {
    try {
      let SectionRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-room-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (SectionRes.data.status === 1) {
        setRoomData(SectionRes.data.room_section_details);
        setAllHotelDetails(SectionRes.data.all_hotels_data);
        dispatch(HotelRoomsDetailsAction(SectionRes.data.room_details.data));
        if (SectionRes.data.room_section_details) {
          setRoomId(SectionRes.data.room_section_details.id);
          setAltText(SectionRes.data.room_section_details.background_image_alt);
          setTitle(SectionRes.data.room_section_details.section_title);
          setDescription(
            SectionRes.data.room_section_details.section_description
          );
          SectionRes.data.room_section_details?.background_image
            ? setRoomImg([
              getImage(SectionRes.data.room_section_details.background_image),
            ])
            : setRoomImg([]);
        } else {
          setRoomId("undefined");
          setAltText("");
          setTitle("");
          setDescription("");
        }
      } else {
        setRoomData({});
        setAllHotelDetails([]);
      }
    } catch (error: any) {
      // toast.error(CATCH_MESSAGE);
      console.log(error);
    }
  };

  function saveHandler() {
    if (editRoomfiles.length) {
      uploadHandler();
    } else {
      addUpdateRooms("undefined");
    }
  }

  const uploadHandler = async () => {
    if (editRoomfiles.length === 1) {
      setLoader(true);

      let payload = new FormData();

      payload.append("uploadFile[0]", editRoomfiles[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/room_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateRooms(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };

  // Update Promo Section

  const addUpdateRooms = async (image_ids: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-default-section-details", {
        id: roomId ?? "undefined",
        background_image: image_ids,
        section_id: defaultSectionId,
        background_image_alt: altText ?? "",
        company_id: company_id,
        template_id: template_id,
        section_title: title ?? "",
        section_description: description ?? "",
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(req.data.message);
        fetchSection();
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  const delteRoomSecFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: roomData.background_image,
          template_id: template_id,
          section_id: defaultSectionId,
        }
      );
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSection();
      } else {
        toast.error(deleteRes.data.message);
        fetchSection();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Rooms",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e: any) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Enter Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e: any) => {
              setAltText(e.target.value);
            }}
          />
        </div>

        <div className="edit-promo-alt-label">
          Select Your Back Ground Image
        </div>
        <DropzoneArea
          centerMesage={"Drag your photos or click here(1 Image Only)"}
          incomingfiles={roomImg}
          dispatchFiles={(val) => accpAboutFiles(val)}
          isDelete={(val) => delteAboutFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
          refresh={refreshList}
        />
        <div className="mt-4">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>
        <div className="mt-4 edit-promo-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="save-btn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">Select Hotels</div>
      <div className="default_section_image_card_container mt-3">
        {allhotelDetails?.length > 0 ? (
          allhotelDetails?.map((item) => (
            <div
              key={item.hotel_id}
              className="default__setion__image"
              onClick={() => {
                navigate("/section-default/edit-room-details/edit-hotel-rooms");
                dispatch(HotelRoomsAction(item));
              }}
            >
              <div className="image__container">
                {
                  item?.hotel_image ? (
                    <img
                      src={item.hotel_image}
                      alt={item.hotel_name}
                      className="default__setion__image__image"
                    />
                  ) : (
                    <div className="image_container_icon_wrapper">
                      <FontAwesomeIcon icon={Icons.faHotel} size="2x" color="blue" />
                    </div>

                  )
                }
                <div className="hotel__name">{Capitalize(item.hotel_name)}</div>
              </div>
            </div>
          ))
        ) : (
          <NoDataComponent message="No Hotels Found" />
        )}
      </div>
      <div className="sixtypx-height"></div>
    </>
  );
};

export default EditRooms;
