import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import SeoButton from "../../../views/buttons/Button";
import AddSectionButton from "../../../views/buttons/Button";
import img1 from "../../../assests/img/hotel-image.png";
import PageTitleButton from "../../../views/buttons/Button";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNextTwoTone } from "@mui/icons-material";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { testimonialPageAction } from "../../../redux/actions/PageAction";
import { RootState } from "../../../redux/store";
import PageTestimonialSectionCard from "../../../views/datacard/RectangleSectionCard";
import SaveButton from "../../../views/buttons/Button";
import { Draggable } from "react-drag-reorder";
import Wbconfig from "../../../API/Wbconfig.json";
import SlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import CardWrapper from "../../../views/datacard/CardWrapper";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import Loader from "../../../views/loader/Loader";
import TestimonialSeoSlider from "./TestimonialSeoSlider";
import TestimonialPageTitleSlider from "./TestimonialPageTitleSlider";
import TestimonialSectionSlider from "./TestimonialSectionSlider";

const TestimonialPage = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);


  // States
  const [openTitleSlide, setOpenTitleSlide] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openSeoSlide, setOpenSeoSlide] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [allsec, setAllSec] = useState<any[]>([]);
  const [testimonialPageSec, setTestimonialPageSec] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [testimonialPageDetails, setTestimonialPageDetails] = useState<any>({});

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    testimonialPageDataFetching();
  }, [company_id, template_id]);

  // Fetching About Page Data

  const testimonialPageDataFetching = async () => {
    setLoader(true);

    try {
      let testimonialPageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-testimonial-page-details/${company_id}/${template_id}/${page_Id}`
      );
      // console.log("testimonialPageData", testimonialPageData);
      if (testimonialPageRes.data.status === 1) {
        dispatch(testimonialPageAction(testimonialPageRes?.data));
        setTestimonialPageDetails(testimonialPageRes?.data?.testimonial_page_details)
        const testimonialPageAllSec = testimonialPageRes?.data?.all_sections?.map(
          (item: any) => {
            return { ...item, isChecked: false };
          }
        );
        setAllSec(testimonialPageAllSec);
        setTestimonialPageSec(
          testimonialPageRes?.data?.testimonial_page_details?.page_section ?? []
        );
        setLoader(false);
      } else {
        setAllSec([]);
        setTestimonialPageSec([]);
        setLoader(false);
      }
    } catch {
      // toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Api To ReOrder
  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: testimonialPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateOrder,
          page_type: "Default",
          page_id: page_Id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setDragStatus(false);
        testimonialPageDataFetching();
        setSaveLoader(false);
      } else {
        toast.error(orderReq.data.message);
        testimonialPageDataFetching();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setSaveLoader(false);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...testimonialPageSec];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setDragStatus(true);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };

  const [dragStatus, setDragStatus] = useState(false);

  // useEffect used to capture the section order if drag action is not triggered.
  useEffect(() => {
    if (!dragStatus) {
      setUpdateOrder(testimonialPageSec)
    }
  }, [testimonialPageSec, dragStatus])

  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (testimonialPageSec && testimonialPageSec.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {testimonialPageSec.map((sec: any, idx: any) => {
            return (
              <>
                <div className="section-card-wrapper" key={sec.id}>
                  <PageTestimonialSectionCard
                    onDelet={() => {
                      deleteHandler(idx);
                    }}
                    onEdit={() => {
                      navigate(`/section-default/${sec.section_routes}`);
                    }}
                  >
                    {sec.section_names}
                  </PageTestimonialSectionCard>
                </div>
              </>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [testimonialPageSec]);

  // Section Delet Handler
  const deleteHandler = (index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletSecHandler(index);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };
  const deletSecHandler = (index: any) => {
    let testimonialPageSections = testimonialPageDetails?.page_section;
    testimonialPageSections.splice(index, 1);
    deletTestimonialSecDetails(testimonialPageSections);
  };
  // Delet Section Post
  const deletTestimonialSecDetails = async (updateSec: any) => {
    try {
      let deletTestimonialSecReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: testimonialPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateSec ?? [],
          page_type: "Default",
          page_id: page_Id ?? "",
        }
      );
      // console.log("deletHomeSecReq", deletAboutSecReq);
      if (deletTestimonialSecReq.data.status === 1) {
        toast.success(deletTestimonialSecReq.data.message);
        testimonialPageDataFetching();
      } else {
        toast.error(deletTestimonialSecReq.data.message);
        testimonialPageDataFetching();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div>
        {/* Sub-Header */}
        <div className="wb-subheader">
          {/* BreadCrumbs */}
          <CustomBreadcrumbs
            content={[
              {
                label: "Pages",
                href: "/pages-default",
              },
              {
                label: `Default Pages`,
                href: "/pages-default",
              },
              {
                label: "Testimonial",
              },
            ]}
          />

          <div className="wb-subheader-rightsec">
            <Button
              icon="bi bi-binoculars"
              handleClick={() => {
                setOpenSeoSlide(true);
              }}
            >
              SEO
            </Button>
            <Button
              icon="bi bi-info-circle"
              handleClick={() => {
                setOpenTitleSlide(true);
              }}
            >
              Page Title
            </Button>
            <Button
              icon="bi bi-arrow-left"
              className="bck-btn"
              handleClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="wb-divider"></div>
      </div>
      {/* Main Section */}

      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className=" mt-4 banner-img-wrapper">
            {testimonialPageDetails !== null ? (
              testimonialPageDetails?.banner ? (
                <img
                  src={`${getImage(testimonialPageDetails?.banner)}`}
                  alt={testimonialPageDetails?.banner_alt}
                  className="banner-img"
                />
              ) : testimonialPageDetails?.video_banner ? (
                <video width="100%" height="300" controls autoPlay>
                  <source
                    src={`${getImage(testimonialPageDetails?.video_banner)}`}
                  />
                </video>
              ) : testimonialPageDetails?.video_banner_link ? (
                <iframe
                  src={testimonialPageDetails?.video_banner_link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  height="100%"
                  width="100%"
                ></iframe>
              ) : (
                <NoDataComponent message="No Banner Found" />
              )
            ) : (
              <NoDataComponent message="No Data Found" />
            )}

            <div
              className="edit-btn-banner"
              onClick={() => {
                navigate("edit-testimonial-banner");
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </div>
            {/* <div className="banner-titel">
              <div className="b-titel-text">
                {testimonialPageDetails?.banner_title ? (
                  <div className="">
                    {testimonialPageDetails?.banner_title}
                  </div>
                ) : (
                  <div className="no-banner-titel">
                    TITLE & DESCRIPTION OF THE ABOUT PAGE
                  </div>
                )}
              </div>
            </div> */}
          </div>

          <CardWrapper className="mt-4 about-add-sec-card">
            <Button
              icon="bi bi-plus-lg"
              className="section-btn"
              handleClick={() => {
                setOpen(true);
              }}
            >
              Select Section
            </Button>
          </CardWrapper>

          {/* For Section */}

          <CardWrapper className=" mt-4 mb-4 home-section-save-wrapper">
            {testimonialPageSec.length > 0 ? (
              <>
                <DraggableRender />
                <Button
                  className="save-btn"
                  handleClick={() => {
                    orderSaveHandler();
                  }}
                  loader={saveLoader}
                >
                  Save
                </Button>
              </>

            ) : (
              <NoDataComponent message="No Section Found" />
            )}
          </CardWrapper>
        </>
      )}

      {/* For Seo */}

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSeoSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSeoSlide(false)}
      >
        <TestimonialSeoSlider
          onClose={() => {
            setOpenSeoSlide(false);
          }}
          testimonialPageDataFetching={testimonialPageDataFetching}
          testimonialPageDetails={testimonialPageDetails}
        />
      </SlidingPane>

      {/* For Title */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openTitleSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenTitleSlide(false)}
      >
        <TestimonialPageTitleSlider
          onClose={() => {
            setOpenTitleSlide(false);
          }}
          testimonialPageDataFetching={testimonialPageDataFetching}
          testimonialPageDetails={testimonialPageDetails}
        />
      </SlidingPane>

      {/* For Section */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <TestimonialSectionSlider
          onClose={() => {
            setOpen(false);
          }}
          testimonialPageDataFetching={testimonialPageDataFetching}
          allsec={allsec}
          testimonialPageDetails={testimonialPageDetails}
        />
      </SlidingPane>
    </>
  );
};

export default TestimonialPage;
