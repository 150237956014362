import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import AlternateText from "../../../views/inputfield/InputTextField";
import TitleText from "../../../views/inputfield/InputTextField";
import Hotel from "../../../assests/img/hotel-image.png";
// Importing Ckeditor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import SlidingPane from "react-sliding-pane";
import AboutHotelSlider from "./AboutHotelSlider";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../../views/datacard/CardWrapper";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { Capitalize, CATCH_MESSAGE, getImage, Logout } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";


const EditAboutus = () => {
  // Data From Redux

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  //States
  const [loader, setLoader] = useState<boolean>(false);
  const [hotelName, setHotelName] = useState<any>("");
  const [hotelId, setHotelId] = useState<number>(0);
  const [altText, setAltText] = useState<any>("");
  const [aboutSliderOpen, setAboutSliderOpen] = useState(false);
  const [description, setDescription] = useState<any>("");
  const [titleText, setTitleText] = useState<any>("");
  const [AboutUsData, setAboutUsData] = useState<any>("");
  const [AboutUsImg, setAboutUsImg] = useState<any[]>([]);
  const [AboutUsId, setAboutUsId] = useState<any>("undefined");
  const [hotelDetails, setHotelDetails] = useState<any[]>([]);
  const [hotelAboutDetails, setHotelAboutDetails] = useState<any[]>([]);
  const [sectionId, setSectionId] = useState<any>("");
  const [aboutUsfiles, setAboutUsFiles] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  //  Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();


  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const accpAboutFiles = (value: any) => {
    setAboutUsFiles(value);
  };

  const delteAboutFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteAboutUsFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  useEffect(() => {
    fetchSection();
  }, []);

  // Data Fetching

  const fetchSection = async () => {
    try {
      let SectionRes = await wbApi.get(
        `/wb-dashboard/fetch-about-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      // console.log("SectionRes", SectionRes);
      if (SectionRes.data.status === 1) {
        setAboutUsData(SectionRes?.data?.about_section_details);
        setHotelDetails(SectionRes?.data?.hotel_details);
        setHotelAboutDetails(SectionRes?.data?.hotel_about_details);
        if (SectionRes?.data?.about_section_details) {
          setAboutUsId(SectionRes?.data?.about_section_details?.id);
          setSectionId(SectionRes?.data?.about_section_details?.section_id);
          setDescription(
            SectionRes?.data?.about_section_details?.section_description
          );
          setTitleText(SectionRes?.data?.about_section_details?.section_title);
          setAltText(
            SectionRes?.data?.about_section_details?.background_image_alt
          );
          SectionRes?.data?.about_section_details?.background_image
            ? setAboutUsImg([
              getImage(
                SectionRes?.data?.about_section_details?.background_image
              ),
            ])
            : setAboutUsImg([]);
        } else {
          setAboutUsId("undefined");
          setDescription("");
          setTitleText("");
          setAltText("");
          setAboutUsImg([]);
          setSectionId("");
        }
      } else {
        setHotelDetails([]);
        setHotelAboutDetails([]);
      }
    } catch (error: any) {
      // toast.error(CATCH_MESSAGE);
      console.log(error);
    }
  };

  // Save Handler

  function saveHandler() {
    if (aboutUsfiles.length) {
      uploadHandler();
    } else {
      addUpdateAbout("undefined");
    }
  }

  const uploadHandler = async () => {
    if (aboutUsfiles.length === 1) {
      setLoader(true);

      let payload = new FormData();

      payload.append("uploadFile[0]", aboutUsfiles[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/about_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateAbout(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch (error: any) {
        setLoader(false);
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }

    } else {
      toast.error("Only One Image Allowed");
    }

  };

  // Update Promo Section

  const addUpdateAbout = async (promoBannerImg: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-default-section-details", {
        id: AboutUsId ?? "undefined",
        background_image: promoBannerImg,
        section_id: defaultSectionId,
        background_image_alt: altText,
        company_id: company_id,
        template_id: template_id,
        section_title: titleText,
        section_description: description,
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(req.data.message);
        fetchSection();
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };
  const delteAboutUsFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: AboutUsData.background_image,
          template_id: template_id,
          section_id: defaultSectionId,
        }
      );
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSection();
      } else {
        toast.error(deleteRes.data.message);
        fetchSection();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      {/* Sub Header */}
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit About Us",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/* Main Section */}

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container" style={{ padding: 0 }}>
          <TitleText
            label="Enter Title"
            value={titleText}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitleText(e.target.value);
            }}
          />
          <AlternateText
            label="Enter Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>

        <div className="edit-promo-alt-label" style={{ margin: '1rem 0' }}>
          Select Your Back Ground Image
        </div>

        <DropzoneArea
          centerMesage={"Drag your photos or click here(1 Image Only)"}
          incomingfiles={AboutUsImg}
          dispatchFiles={(val) => accpAboutFiles(val)}
          isDelete={(val) => delteAboutFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
          refresh={refreshList}
        />
        <div className="mt-4">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>
        <div className="mt-4 edit-promo-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="save-btn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">Select Hotels</div>
      <div className="default_section_image_card_container mt-3">
        {hotelDetails?.length > 0 ? (
          hotelDetails?.map((item: any) => (
            <div
              key={item.hotel_id}
              className="default__setion__image"
              onClick={() => {
                setAboutSliderOpen(true);
                setHotelId(item.hotel_id);
                setHotelName(item.hotel_name);
              }}
            >
              <div className="image__container">
                {
                  item?.hotel_image ? (
                    <img
                      src={item.hotel_image}
                      alt={item.hotel_name}
                      className="default__setion__image__image"
                    />
                  ) : (
                    <div className="image_container_icon_wrapper">
                      <FontAwesomeIcon icon={Icons.faHotel} size="2x" color="blue" />
                    </div>

                  )
                }
                <div className="hotel__name">{Capitalize(item.hotel_name)}</div>
              </div>
            </div>
          ))
        ) : (
          <NoDataComponent message={"No Hotels Found"} />
        )}
      </div>
      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={aboutSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAboutSliderOpen(false)}
      >
        <AboutHotelSlider
          onClose={() => {
            setAboutSliderOpen(false);
          }}
          hotelId={hotelId}
          hotelName={hotelName}
          hotelAboutDetails={hotelAboutDetails}
          fetchSection={fetchSection}
        />
      </SlidingPane>
    </>
  );
};

export default EditAboutus;
