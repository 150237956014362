import { TEMPLATE_ID } from "../actionTypes"

const initialState = {
   template_id:null,
}

const TemplateIdReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TEMPLATE_ID:
            return {
                ...state,
                template_id:action.id
            }
        default: return state
    }
}

export default TemplateIdReducer;