import { Breadcrumbs, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WbPageCard from "../../views/datacard/WbPageCard";
import { NavigateNextTwoTone } from "@mui/icons-material";
import wbApi from "../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  CustomPageAction,
  DefaultPageIdAction,
  inUsePagesAction,
} from "../../redux/actions/PageAction";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import Loader from "../../views/loader/Loader";
import NoDataComponent from "../../Components/noDataComponent/NoDataComponent";

const DefaultPage = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const [inUsePageId, setInUsePageId] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [defaultPages, setDefaultPages] = useState<any[]>([]);
  const [companyHomeUrl, setCompanyHomeUrl] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllPages();
    fetchInUsePages();
    fetchCompanyDetails();
  }, [company_id, template_id]);

  // Fetch all Pages
  const fetchAllPages = async () => {
    setLoader(true);
    try {
      let allPageRes = await wbApi.get(
        `/wb-dashboard/fetch-all-default-custom-pages/${company_id}/${template_id}`
      );

      // console.log("allPageRes", allPageRes);
      if (allPageRes.data.status === 1) {
        // console.log(allPageRes.data);
        setDefaultPages(allPageRes.data.default_pages);

        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // console.log("defaultPages", defaultPages);

  // Fetch Inuse Pages

  const fetchInUsePages = async () => {
    try {
      let inUsePageRes = await wbApi.get(
        `/wb-dashboard/fetch-in-use-pages/${company_id}/${template_id}`
      );

      // console.log("inUsePageRes", inUsePageRes);
      if (inUsePageRes.data.status === 1) {
        const active = inUsePageRes.data.in_use_pages?.map(
          (obj: any) => obj.page_id
        );
        setInUsePageId(active);
      } else {
        setInUsePageId([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Fetch Company Details

  const fetchCompanyDetails = async () => {
    try {
      let companyRes = await wbApi.get(
        `/wb-dashboard/fetch-company-details/${company_id}/${template_id}`
      );
      if (companyRes.data.status === 1) {
        setCompanyHomeUrl(companyRes.data.company_details.home_url);
      } else {
        setCompanyHomeUrl("");
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      {/* Sub Header */}
      <div className="wb-subheader">
        {/* Bread-Crumbs */}
        <CustomBreadcrumbs
          content={[
            {
              label: "Pages",
              href: "/pages-default",
            },
            {
              label: `Default Pages`,
            },
          ]}
        />

        {/* <div className="wb-subheader-rightsec">
          <div className="header-footer-toggle">
            <ul className="nav nav-tabs">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="Default"
                  data-bs-toggle="tab"
                  data-bs-target="crs"
                  aria-controls="crs"
                  role="tab"
                  aria-selected="true"
                >
                  DEFAULT
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  onClick={() => navigate("/pages-custom")}
                  className="nav-link"
                  id="custom"
                  data-bs-toggle="tab"
                  data-bs-target="list"
                  aria-controls="list"
                  role="tab"
                  aria-selected="true"
                >
                  CUSTOM
                </a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <div className="wb-divider"></div>

      {/* Main Section */}

      <div className="mt-4">
        {loader ? (
          <div className="middle-loader">
            <Loader />
          </div>
        ) : (
          <div className="page-main-section">
            {defaultPages.length > 0 ? (
              defaultPages?.map((pages: any, idx: any) => {
                // console.log(pages, 'pages')
                return (
                  <div key={idx} className="page-default-section ">
                    <WbPageCard
                      key={pages.id}
                      update=""
                      isActive={inUsePageId.includes(pages.id)}
                      onEditClick={() => {
                        navigate(`/pages-default/${pages.page_route}`);
                        dispatch(DefaultPageIdAction(pages.id));
                      }}
                      onLinkClick={`${companyHomeUrl}/${pages?.front_view_page_route}`}
                    >
                      {pages.pages_name}
                    </WbPageCard>
                  </div>
                );
              })
            ) : (
              <NoDataComponent message="No Pages Found" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DefaultPage;
