import React from "react";

interface IsliderSectionCardProps {
  className: string;
  children: string;
  onDelete?: () => void;
  onEdit?: () => void;
  editOptn: boolean;
  deletOptn: boolean;
}

const SliderSectionCard: React.FC<IsliderSectionCardProps> = ({
  className,
  children,
  onDelete,
  onEdit,
  editOptn,
  deletOptn,
}) => {
  return (
    <div className={`slider__draggable ${className}`}>
      <div className="left-section">
        <i className="bi bi-grip-vertical"></i>
        <div className="sec-name">{children}</div>
      </div>
      <div className="right-section">
        {editOptn ? (
          <div className="button" onClick={onEdit}>
            <i className="bi bi-pencil-square"></i>
          </div>
        ) : null}
        {deletOptn ? (
          <div className="button" onClick={onDelete}>
            <i className="bi bi-trash"></i>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SliderSectionCard;
