import React from "react";

// importing mui components
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";

export interface IInputTextFieldProps {
  label: string;
  value: string | undefined;
  className?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: string | number;
}

const InputTextField: React.FC<IInputTextFieldProps> = ({
  label,
  value,
  className,
  handleChange,
  helperText,
  handleFocus,
  onKeyPress,
  disabled,
  multiline,
  rows,
}) => {
  return (
    <FormControl variant="outlined" fullWidth className={className}>
      <InputLabel htmlFor={label} className={className}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={label}
        className={`${className} ${disabled ? "disable__cursor" : ""}`}
        type="text"
        style={{ backgroundColor: "white" }}
        label={label}
        error={helperText?.trim() ? true : false}
        value={value || ""}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        // endAdornment={
        //   !helperText?.trim() && value ? (
        //     <InputAdornment position="end">
        //       <IconButton edge="end">
        //         <i className="bi bi-check-lg text-success"></i>
        //       </IconButton>
        //     </InputAdornment>
        //   ) : (
        //     false
        //   )
        // }
        onKeyPress={onKeyPress}
        multiline={multiline}
        rows={rows}
      />
      {helperText && (
        <FormHelperText className="text-danger">
          {helperText ? helperText : " "}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputTextField;
