import { UPDATE_GALLARY } from "../actionTypes"

const initialState = ''

const GallaryUpdate = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_GALLARY:
            return action.payload

        default: return state
    }
}

export default GallaryUpdate;