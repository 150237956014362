import {
  PAGE_ID,
  HOME_PAGE,
  ABOUT_PAGE,
  ROOM_PAGE,
  CONTACTUS_PAGE,
  GALLERY_PAGE,
  SERVICE_PAGE,
  PACKAGE_PAGE,
  BLOG_PAGE,
  FACILITY_PAGE,
  EVENTS_PAGE,
  OFFERS_PAGE,
  TESTIMONIAL_PAGE,
  PROMOTIONS_PAGE,
  HOTEL_PAGE,
  HOTEL_LIST_PAGE,
} from "../actionTypes";

const initialState = {
  page_Id: null,
  homePageData: {},
  aboutPageData: {},
  roomPageData: {},
  contactPageData: {},
  galleryPageData: {},
  servicePageData: {},
  packagePageData: {},
  blogPageData: {},
  facilityPageData: {},
  eventsPageData: {},
  offersPageData: {},
  testimonialPageData: {},
  promotionsPageData: {},
  hotelPageData: {},
  hotelListPageData: {},
};

const PageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PAGE_ID:
      return {
        ...state,
        page_Id: action.pages,
      };
    case HOME_PAGE:
      return {
        ...state,
        homePageData: action.pages,
      };
    case ABOUT_PAGE:
      return {
        ...state,
        aboutPageData: action.pages,
      };
    case ROOM_PAGE:
      return {
        ...state,
        roomPageData: action.pages,
      };
    case CONTACTUS_PAGE:
      return {
        ...state,
        contactPageData: action.pages,
      };
    case GALLERY_PAGE:
      return {
        ...state,
        galleryPageData: action.pages,
      };
    case SERVICE_PAGE:
      return {
        ...state,
        servicePageData: action.pages,
      };

    case PACKAGE_PAGE:
      return {
        ...state,
        packagePageData: action.pages,
      };
    case FACILITY_PAGE:
      return {
        ...state,
        facilityPageData: action.pages,
      };
    case BLOG_PAGE:
      return {
        ...state,
        blogPageData: action.pages,
      };
    case EVENTS_PAGE:
      return {
        ...state,
        eventsPageData: action.pages,
      };
    case OFFERS_PAGE:
      return {
        ...state,
        offersPageData: action.pages,
      };
    case TESTIMONIAL_PAGE:
      return {
        ...state,
        testimonialPageData: action.pages,
      };
    case PROMOTIONS_PAGE:
      return {
        ...state,
        promotionsPageData: action.pages,
      };
    case HOTEL_PAGE:
      return {
        ...state,
        hotelPageData: action.pages,
      };
    case HOTEL_LIST_PAGE:
      return {
        ...state,
        hotelListPageData: action.pages,
      };
    default:
      return state;
  }
};

export default PageReducer;
