import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { CurrentProperty } from "../../../redux/actions/PropertiesAction";
import { CATCH_MESSAGE, getImage, Capitalize } from "../../../UtilityFunctions";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../../views/datacard/CardWrapper";
import InputTextField from "../../../views/inputfield/InputTextField";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import Button from "../../../views/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const EditPackage = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [allHotelsData, setAllHotelsData] = useState<any>();
  const [packageSectionDetails, setPackageSectionDetails] = useState<any>();
  const [packageBannerId, setPackageBannerId] = useState<any>();
  const [packageBannerImg, setPackageBannerImg] = useState<any>();
  const [packageFile, setPackageFile] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [update, setUpdate] = useState("");

  const accpPackageFiles = (value: any) => {
    setPackageFile(value);
  };

  const deletePackageFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deltePackageFilesApi();;

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const deltePackageFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: packageSectionDetails.background_image,
          section_id: defaultSectionId,
          template_id: template_id,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        packagesApi();
      } else {
        toast.error(deleteRes.data.message);
        packagesApi();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const packagesApi = async () => {
    try {
      let res = await wbApi.get(
        `/wb-dashboard/fetch-wb-package-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (res.data.status === 1) {
        setAllHotelsData(res?.data?.all_hotels_data);
        setPackageSectionDetails(res?.data?.package_section_details);
        setPackageBannerId(res?.data?.package_section_details?.id);
        setTitle(res?.data?.package_section_details?.section_title);
        setAltText(res?.data?.package_section_details?.background_image_alt);
        setDescription(res?.data?.package_section_details?.section_description ?? "");
        if (res?.data?.package_section_details?.background_image) {
          setPackageBannerImg([
            getImage(res?.data?.package_section_details?.background_image),
          ]);
        } else {
          setPackageBannerImg([]);
        }
      } else {
        setAllHotelsData([]);
        setPackageSectionDetails([]);
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error)
    }
  };
  useEffect(() => {
    packagesApi();
  }, []);

  const saveHandler = () => {
    if (packageFile.length) {
      uploadHandler();
    } else {
      addUpdatePackages("undefined");
    }
  };

  const uploadHandler = async () => {
    if (packageFile.length === 1) {
      setLoader(true);
      let payload = new FormData();
      payload.append("uploadFile[0]", packageFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/package_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdatePackages(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };

  // add Update Package Default Section
  const addUpdatePackages = async (imgId: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-default-section-details", {
        id: packageBannerId ?? "undefined",
        background_image: imgId ?? "undefined",
        background_image_alt: altText,
        company_id: company_id,
        section_id: defaultSectionId,
        section_title: title,
        template_id: template_id,
        section_description: description,
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        packagesApi();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(req.data.message);
        packagesApi();
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Package",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*------------------- Main Section----------------- */}
      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={packageBannerImg}
            dispatchFiles={(val) => accpPackageFiles(val)}
            isDelete={(val) => deletePackageFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);
                setCharCount({
                  ...charCount,
                  terms_cond: removeTags(data).length,
                });
              }}
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      {/* List all the Hotels & select Hotel section */}
      <div className="select__hotel__label">Select Hotels</div>
      <div className="default_section_image_card_container mt-3">
        {allHotelsData?.length > 0 ? (
          allHotelsData?.map((item: any) => (
            <div
              key={item.hotel_id}
              className="default__setion__image mr-2"
              onClick={() => {
                navigate(
                  `/section-default/edit-package-section-details/package-list`
                );
                dispatch(CurrentProperty(item));
              }}
            >
              <div className="image__container">
                {
                  item?.hotel_image ? (
                    <img
                      src={item.hotel_image}
                      alt={Capitalize(item.hotel_name)}
                      className="default__setion__image__image"
                    />
                  ) : (
                    <div className="image_container_icon_wrapper">
                      <FontAwesomeIcon icon={Icons.faHotel} size="2x" color="blue" />
                    </div>

                  )
                }
                <div className="hotel__name">{Capitalize(item.hotel_name)}</div>
              </div>
            </div>

          ))
        ) : (
          <NoDataComponent message={"No Hotels Found"} />
        )}
      </div>
      <div className="sixtypx-height"></div>
    </>
  );
};

export default EditPackage;
