import React, { useState } from "react";
import Button from "../../../views/buttons/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import InputTextField from "../../../views/inputfield/InputTextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";

interface IAddPackageSliderProps {
  onClose: () => void;
  packagesApi: () => void;
}
const AddPackageSlider: React.FC<IAddPackageSliderProps> = ({ onClose, packagesApi }) => {
  // Data From Redux 
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector((state: RootState) => state.sections);
  const { current_property } = useSelector((state: RootState) => state.properties);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states 
  const [addPackageFile, setAddPackageFile] = useState<any[]>([]);
  const [packageTitle, setPackageTitle] = useState<any>("");
  const [altText, setAltText] = useState<any>("")
  const [addPackageBannerImg, setAddPackageBannerImg] = useState<any>([]);
  const [cancellationDetails, setCancellationDetails] = useState<any>("");
  const [cancellationTitle, setCancellationTitle] = useState<any>("");
  const [exclusionDetails, setExclusionDetails] = useState<any>("");
  const [exclusionTitle, setExclusionTitle] = useState<any>("");
  const [inclusionDetails, setInclusionDetails] = useState<any>("");
  const [inclusionTitle, setInclusionTitle] = useState<any>("");
  const [itineraryDetails, setItineraryDetails] = useState<any>("");
  const [itineraryTitle, setItineraryTitle] = useState<any>("");
  const [overviewDetails, setOverviewDetails] = useState<any>("");
  const [overviewTitle, setOverviewTitle] = useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const accpAddPackageFiles = (value: any) => {
    setAddPackageFile(value);
  };

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const saveHandler = () => {
    if (addPackageFile.length) {
      uploadHandler();
    } else {
      addUpdateAddPackage("undefined");
    }
  }

  const uploadHandler = async () => {

    if (addPackageFile.length === 1) {
      setLoader(true);
      let payload = new FormData();
      payload.append("uploadFile[0]", addPackageFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/hotelpackage`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateAddPackage(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed");
    }
  }

  // Update ContactUs Section
  const addUpdateAddPackage = async (ImgId: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-wb-package", {
        id: "undefined",
        package_image: ImgId,
        package_image_alt: altText,
        company_id: company_id,
        template_id: template_id,
        hotel_id: current_property.hotel_id,
        cancellation_details: cancellationDetails,
        cancellation_title: cancellationTitle,
        exclusion_details: exclusionDetails,
        exclusion_title: exclusionTitle,
        inclusion_details: inclusionDetails,
        inclusion_title: inclusionTitle,
        itinerary_details: itineraryDetails,
        itinerary_title: itineraryTitle,
        overview_details: overviewDetails,
        overview_title: overviewTitle,
        package_title: packageTitle,
      },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        onClose();
        packagesApi();
        setLoader(false);
      } else {
        toast.error(req.data.message);
        onClose();
        packagesApi();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Package</div>
      </div>

      <div className="height-64px"></div>

      {/* --------------------------------------------------- */}
      <InputTextField
        label="Package Title"
        value={packageTitle}
        className="description description__slider"
        handleChange={(e) => {
          setPackageTitle(e.target.value);
        }}
      />

      <InputTextField
        label="Alternate Text"
        value={altText}
        className="description description__slider"
        handleChange={(e) => {
          setAltText(e.target.value);
        }}
      />

      <div className="mb-2">Choose Image</div>

      <div className="slider__dropzone__height mb-3">
        <DropzoneArea
          centerMesage={"Drag your photos or click here (1 Image Only)"}
          // incomingfiles={addPackageBannerImg}
          dispatchFiles={(val) => accpAddPackageFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
        // isDelete={(val) => deleteAddPackageFiles(val)}
        />
      </div>

      <InputTextField
        label="Enter OverView Title"
        value={overviewTitle}
        className="description description__slider"
        handleChange={(e) => {
          setOverviewTitle(e.target.value);
        }}
      />

      <div>Enter Overview Details</div>

      <div className="__default--input--field my-4">
        <CKEditor
          editor={ClassicEditor}
          data={overviewDetails}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setOverviewDetails(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>

      <InputTextField
        label="Inclusion Title"
        value={inclusionTitle}
        className="description description__slider"
        handleChange={(e) => {
          setInclusionTitle(e.target.value);
        }}
      />

      <div>Enter Inclusion Details</div>

      <div className="__default--input--field my-3">
        <CKEditor
          editor={ClassicEditor}
          data={inclusionDetails}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setInclusionDetails(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            })
          }}
        />
      </div>

      <InputTextField
        label="Exclusion Title"
        value={exclusionTitle}
        className="description description__slider"
        handleChange={(e) => {
          setExclusionTitle(e.target.value);
        }}
      />

      <div>Enter Exclusion Details</div>

      <div className="__default--input--field my-3">
        <CKEditor
          editor={ClassicEditor}
          data={exclusionDetails}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setExclusionDetails(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>

      <InputTextField
        label="Itinerary Title"
        value={itineraryTitle}
        className="description description__slider"
        handleChange={(e) => {
          setItineraryTitle(e.target.value);
        }}
      />

      <div>Enter Itinerary Details</div>

      <div className="__default--input--field my-3">
        <CKEditor
          editor={ClassicEditor}
          data={itineraryDetails}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setItineraryDetails(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>

      <InputTextField
        label="T/C Cancellation Title"
        value={cancellationTitle}
        className="description description__slider"
        handleChange={(e) => {
          setCancellationTitle(e.target.value)
        }}
      />

      <div>Enter T/C Cancellation Details</div>

      <div className="__default--input--field my-3">
        <CKEditor
          editor={ClassicEditor}
          data={cancellationDetails}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setCancellationDetails(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            })
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={onClose}
          >Cancel</Button>
          <Button
            handleClick={() => saveHandler()}
            loader={loader}
          >Save</Button>
        </div>
      </div>
    </>
  );
};

export default AddPackageSlider;
