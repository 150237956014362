import React from "react";
interface ICardWrapperProps {
  children: React.ReactNode;
  className?: string;
}

const CardWrapper: React.FC<ICardWrapperProps> = ({ children, className }) => {
  return <div className={`card-wrapper ${className}`}>{children}</div>;
};

export default CardWrapper;
