import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// import bootstrap icon
import "bootstrap-icons/font/bootstrap-icons.css";

// Custom Style Sheet
import "./assests/css/styles.min.css";

//import redux elements
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";

import "./App.css";

// import routes
import AuthRoutes from "./routes/AuthRoutes";
import AppRoutes from "./routes/AppRoutes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PersistGate } from "redux-persist/es/integration/react";
import { persistor } from "./redux/store";

import { library } from '@fortawesome/fontawesome-svg-core';

function App() {
  const isAuthorized = useSelector((state: RootState) => state.auth.auth_token);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        closeOnClick
        hideProgressBar={true}
      />
      <Router>{isAuthorized ? <AppRoutes /> : <AuthRoutes />}</Router>
    </PersistGate>
  );
}

export default App;
