import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import LoginReducer from "./reducers/LoginReducer";
import SidebarReducer from "./reducers/SidebarReducer";
import PropertiesReducer from "./reducers/PropertiesReducer";
import PromptReducer from "./reducers/PromptReducer";
import TemplateIdReducer from "./reducers/TemplateIdReducer";
import MenuReducer from "./reducers/MenuReducer";
import PageReducer from "./reducers/PageReducer";
import IdentityReducer from "./reducers/IdentityReducer";
import SectionReducer from "./reducers/SectionReducer";
import BrandsReducer from "./reducers/BrandsReducer";
import BrandsUpdate from "./reducers/BrandUpdateReducer";
import BlogsReducer from "./reducers/BlogsReducer";
import BlogUpdate from "./reducers/BlogsUpdateReducer";
import EventsReducer from "./reducers/EventsReducer";
import EventsUpdate from "./reducers/EventUpdateReducer";
import OffersReducer from "./reducers/OffersReducer";
import OffersUpdate from "./reducers/OffersUpdateReducer";
import FooterReducer from "./reducers/FooterReducer";
import FooterDataReducer from "./reducers/FooterDataReducer";
import PromotionReducer from "./reducers/PromotionUpdateReducer";
import PromotionDataReducer from "./reducers/PromotionData";
import ContactReducer from "./reducers/ContactReducer";
import ContactDataReducer from "./reducers/ContactDataReducer";
import GallaryUpdate from "./reducers/GallaryReducer";
import GallaryDataReducer from "./reducers/GallaryDataReducer";
import FacilityUpdateReducer from "./reducers/FacilityUpdateReducer";
import FacilityDataReducer from "./reducers/FacilityReducer";
import FacilityNameReducer from "./reducers/FacilityNameReducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth", "properties", "sidebar", "add_property", "add_room_type", "add_floors", "template_id", "identity", "menu", "pages", "sections"],
};

const rootReducer = combineReducers({
    auth: LoginReducer,
    properties: PropertiesReducer,
    sidebar: SidebarReducer,
    prompt: PromptReducer,
    template_id: TemplateIdReducer,
    identity: IdentityReducer,
    menu: MenuReducer,
    pages: PageReducer,
    sections: SectionReducer,
    allSects: BrandsReducer,
    stateUpdate: BrandsUpdate,
    blogsSect: BlogsReducer,
    blogsUpdate: BlogUpdate,

    eventsSect: EventsReducer,
    eventsSectUpdate: EventsUpdate,
    offersSect: OffersReducer,
    offersSectUpdate: OffersUpdate,

    footer: FooterReducer,
    footerData: FooterDataReducer,

    promotionUpdate: PromotionReducer,
    promotionData: PromotionDataReducer,

    contact: ContactReducer,
    contactData: ContactDataReducer,

    gallary: GallaryUpdate,
    gallaryData: GallaryDataReducer,

    facilityUpdate: FacilityUpdateReducer,
    facilityData: FacilityDataReducer,
    facilityName: FacilityNameReducer,
})

export default persistReducer(persistConfig, rootReducer)