import React, { useEffect, useState } from "react";
import CancelButton from "../../../views/buttons/Button";
import SaveButton from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InputTextField from "../../../views/inputfield/InputTextField";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { updateStateAfterNewAddedPromotion } from "../../../redux/actions/PromotionAction";
import { nanoid } from "@reduxjs/toolkit";
import ColorPicker from "../../../views/colorpicker/ColorPicker";

interface IAddPromotionSliderProps {
  onClose: () => void;
  PromotionalApi: () => void;
}
const AddPromotionSlider: React.FC<IAddPromotionSliderProps> = ({
  onClose,
  PromotionalApi,
}) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [loader, setLoader] = useState<boolean>(false);
  const [title, setTitle] = useState('')
  const [color, setColor] = useState('#8a9dc1')
  const [description, setDescription] = useState('')
  const [sectId, setSectId] = useState("");


  const addPromotionApi = async () => {
    setLoader(true);
    try {
      const resp = await wbApi.post(`wb/save-hotel-promotions`, {
        title, background_color: color, company_id, id: 'undefined', template_id, description
      })

      if (resp.data.status === 1) {
        toast.success(resp.data.message)
        PromotionalApi();
        setLoader(false);
        onClose();
      }
      else {
        toast.error(resp.data.message)
        setLoader(false);
        onClose();
      }
    }
    catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  }


  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Promotion</div>
      </div>
      <div className="height-64px"></div>


      <ColorPicker
        className="promo_sec_color_picker"
        value={color}
        icon="bi bi-pencil-square"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setColor(e.target.value);
        }}
        id="promo"
      />
      <InputTextField
        label="Promotion Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => setTitle(e.target.value)}
      />

      <div className="__default--input--field mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <CancelButton className="cancel__btn" handleClick={() => onClose()}>Cancel</CancelButton>
          <SaveButton handleClick={() => addPromotionApi()} loader={loader}>Save</SaveButton>
        </div>
      </div>
    </>
  );
};

export default AddPromotionSlider;
