import { TAKE_FACILITY_NAME } from "../actionTypes";

const initialState = ''

const FacilityNameReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TAKE_FACILITY_NAME:
            return action.payload


        default: return state
    }
}

export default FacilityNameReducer;