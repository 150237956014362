import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import AutoWidthCard from "../../../views/datacard/AutoWidthCard";
import SlidingPane from "react-sliding-pane";
import AddBenifitsSlider from "./AddBenifitsSlider";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { CancelConfirmationPrompt, ShowConfirmationPrompt } from "../../../redux/actions/PromptAction";
import { Draggable } from "react-drag-reorder";
import EditBenifitsSlider from "./EditBenifitsSlider";

const BookingBenifits = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addOpen, setAddOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [addLoader, setAddLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [updateBenifit, setUpdateBenifit] = useState<any>({});
  const [benefitsArr, setBenefitsArr] = useState([]);
  const [selectedBenifits, setSelectedBenifits] = useState<any[]>([]);
  const [updateOrderBenifits, setUpdateOrderBenifits] = useState<any[]>([]);
  const [allIcons, setAllIcons] = useState<any[]>([]);

  const [selectBenifitType, setSelectBenifitType] = useState<any>([]);
  const [selectedBenifitNames, setSlectedBenifitNames] = useState([]);

  const getBookingsData = async () => {
    try {
      const resp = await wbApi(
        `wb-dashboard/fetch-wb-booking-benefits/${company_id}/${template_id}`
      );
      if (resp.data.status === 1) {
        setBenefitsArr(resp.data.all_benefits);
        setSelectedBenifits(resp.data.selected_benefits);
        setSelectBenifitType(resp.data.selected_benefits);
        setAllIcons(resp.data.all_icons)
      } else {
        setBenefitsArr([]);
        setAllIcons([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    getBookingsData();
  }, []);

  const deleteBookingdata = async (id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const resp = await wbApi.post(`new/delete-benefits`, {
              benefit_id: id,
              company_id,
              template_id,
            });

            if (resp.data.status === 1) {
              toast.success(resp.data.message);
              getBookingsData();
            } else toast.error(resp.data.message);
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  const addSelectedBenifitsApi = async () => {
    if (selectBenifitType.length <= 0) {
      return;
    } else {
      setAddLoader(true);
      try {
        const payload = {
          company_id,
          template_id,
          user_selected_benefits: selectBenifitType,
        };
        const resp = await wbApi.post(`new/insert-update-benefits`, payload);

        if (resp.data.status === 1) {
          toast.success(resp.data.message);
          getBookingsData();
          setAddLoader(false);
        } else {
          toast.error(resp.data.message);
          setAddLoader(false);
        }
      } catch {
        setAddLoader(false);
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  const selectTheBenifitType = (obj: any) => {
    setSelectBenifitType([...selectBenifitType, obj]);
  };

  useEffect(() => {
    if (selectBenifitType.length <= 0) return;
    setSlectedBenifitNames(
      selectBenifitType.map((obj: any) => obj.benefit_title)
    );
  }, [selectBenifitType.length]);

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderBenifit = [...selectedBenifits];
    let temp = orderBenifit[currentPos].id;
    orderBenifit[currentPos].id = orderBenifit[newPos].id
    orderBenifit[newPos].id = temp
    setUpdateOrderBenifits(orderBenifit);
  };
  const DraggableRender = useCallback(() => {
    if (selectedBenifits?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {selectedBenifits?.map((obj: any) => {
            const { benefit_title, benefit_id, icon, id } = obj;
            return (
              <div className="selected_benifits_wrapper" key={id}>
                <div className="left_icon_wrapper">
                  <i className="bi bi-grip-vertical"></i>
                </div>
                <div className="benifits_name">
                  {
                    benefit_title
                  }
                </div>
                <div className="benifits_button_wrapper">

                  <div className="button" onClick={() => {
                    setEditOpen(true);
                    setUpdateBenifit(obj);
                  }}>
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="button" onClick={() => deleteBookingdata(benefit_id)}>
                    <i className="bi bi-trash"></i>
                  </div>

                </div>
              </div>
            );
          }
          )}
        </Draggable>
      );
    }
    return null;
  }, [selectedBenifits]);

  const updateOrderBenifitsApi = async () => {
    setUpdateLoader(true);
    try {

      let orderRes = await wbApi.post(`/order/order-benefits`, {
        company_id: company_id,
        template_id: template_id,
        benefits: updateOrderBenifits

      })
      if (orderRes.data.status === 1) {
        toast.success(orderRes.data.message);
        getBookingsData();
        setUpdateLoader(false);
      } else {
        toast.error(orderRes.data.message);
        getBookingsData();
        setUpdateLoader(false);
      }
    } catch {
      setUpdateLoader(false);
      toast.error(CATCH_MESSAGE)

    }
  }

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Edit Booking Benifits`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*------------------- Main Section----------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            setAddOpen(true);
          }}
        >
          Add benifits
        </Button>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="small__card__container">
          {benefitsArr?.length > 0 ? (
            benefitsArr?.map((obj, id) => {
              const { benefit_title, benefit_id, icon } = obj;
              return (
                <div
                  className="booking__benifts__card"
                  key={id}
                  onClick={() => selectTheBenifitType(obj)}
                  style={
                    selectedBenifitNames.includes(benefit_title)
                      ? selectedStyle
                      : notSelectedStyle
                  }
                >
                  {benefit_title}
                </div>
              );
            })
          ) : (
            <NoDataComponent message={"No Data Found"} />
          )}
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              addSelectedBenifitsApi();
            }}
            loader={addLoader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">List of Selected Benefits</div>

      <div className="benifits__auto__width__card__container">
        {selectedBenifits?.length > 0 ? (
          <DraggableRender />
        ) : (
          <NoDataComponent message={"No Data Found"} />
        )}
      </div>

      {
        selectedBenifits.length > 0 &&
        <div className="edit-contact-btn-container">
          <Button
            handleClick={() => {
              updateOrderBenifitsApi();
            }}
            loader={updateLoader}
          >
            Update Order
          </Button>
        </div>
      }





      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={addOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddOpen(false)}
      >
        <AddBenifitsSlider
          onClose={() => {
            setAddOpen(false);
          }}
          updateFunc={() => {
            getBookingsData();
          }}
          allIcons={allIcons}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditOpen(false)}
      >
        <EditBenifitsSlider
          onClose={() => {
            setEditOpen(false);
          }}
          updateFunc={() => {
            getBookingsData();
          }}
          updateBenifit={updateBenifit}
          allIcons={allIcons}
        />
      </SlidingPane>
    </>
  );
};

const selectedStyle = {
  cursor: "pointer",
  border: "1px solid #3d4ac0",
};
const notSelectedStyle = {
  cursor: "pointer",
};

export default BookingBenifits;
