import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../API/wbApi";
import { RootState } from "../../redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import AddMenuBtn from "../../views/buttons/Button";
import CancelButton from "../../views/buttons/Button";
import InputSelectField from "../../views/inputfield/InputSelectField";
import InputTextField from "../../views/inputfield/InputTextField";

interface IMenuSliderProps {
  onClose: () => void;
  fetchMenuData: () => void;
}

const MenuSlider: React.FC<IMenuSliderProps> = ({ onClose, fetchMenuData }) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [loader, setLoader] = useState<boolean>(false);
  const [menuName, setMenuName] = useState<string>("");
  const [menuStatus, setMenuStatus] = useState<string>("");

  // Api Call To Add New Menu
  const addNewMenu = async () => {
    setLoader(true);
    try {
      let addNewRes = await wbApi.post(
        `/wb/add-menu`,
        {
          company_id: company_id,
          template_id: template_id,
          menu_name: menuName,
          menu_status: menuStatus,
          page_id: "1",
          page_url: "",
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (addNewRes.data.status === 1) {
        toast.success(addNewRes.data.message);
        setLoader(false);
        onClose();
        fetchMenuData();
      } else {
        toast.error(addNewRes.data.message);
        setLoader(false);
        onClose();
        fetchMenuData();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add menu</div>
      </div>

      <div className="height-64px"></div>
      <div className="slider__parent__wrapper">
        <div className="standard-page--label mb-4 ">Menu Area</div>
        <div
          className="d-flex flex-column justify-content-between"
          style={{ gap: "1rem" }}
        >
          <div className="__default--input--field">
            <InputSelectField
              label="Menu Area"
              value={menuStatus}
              options={["Header", "Footer"]}
              handleChange={(e) => {
                setMenuStatus(e.target.value);
              }}
            />
          </div>
          <div className="__default--input--field">
            <InputTextField
              label="Menu name"
              value={menuName}
              handleChange={(e) => {
                setMenuName(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <CancelButton
          className="cancel__btn"
          handleClick={() => {
            onClose();
          }}
        >
          Cancel
        </CancelButton>
        <AddMenuBtn
          handleClick={() => {
            addNewMenu();
          }}
          loader={loader}
        >
          Save
        </AddMenuBtn>
      </div>
    </>
  );
};

export default MenuSlider;
