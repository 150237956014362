import React, { useEffect, useState } from "react";
import Button from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InputTextField from "../../../views/inputfield/InputTextField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import { RootState } from "../../../redux/store";
import { updateFooter } from "../../../redux/actions/FooterActions";
import { nanoid } from "@reduxjs/toolkit";

interface IEditFooterSliderProps {
  onClose: () => void;
  allIcons: any[];
  updateBenifit: any;
}
const EditFooterSlider: React.FC<IEditFooterSliderProps> = ({ onClose, allIcons, updateBenifit }) => {
  const [title, setTitle] = useState('')
  const [footerId, setFooterId] = useState('')
  const [description, setDescription] = useState('')
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [clickedIcon, setClickedIcon] = useState<any>("");
  const [searchInput, setSearchInput] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(updateBenifit.title);
    setDescription(updateBenifit.description);
    setClickedIcon({
      icon: updateBenifit.icon[0]["icon"],
      name: updateBenifit.icon[0]["icon_name"]
    }
    );
    setFooterId(updateBenifit.id)
  }, [updateBenifit]);

  useEffect(() => {
    setFilteredResults(allIcons);
  }, [allIcons]);

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = allIcons?.filter(
        (item: any) => {
          return item.name.toLowerCase().startsWith(keyword.toLowerCase());
        }
      );
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allIcons);
    }
    setSearchInput(keyword);
  };


  const addNewFooterData = async () => {
    setLoader(true);
    try {
      const resp = await wbApi.post(`wb/insert-update-wb-custom-footer`, {
        company_id,
        description: description,
        icon: [{
          icon: clickedIcon.icon,
          icon_name: clickedIcon.name
        }],
        id: footerId ?? 'undefined',
        template_id,
        title: title
      })
      if (resp.data.status) {
        toast.success(resp.data.message)
        dispatch(updateFooter(nanoid()))
        onClose();
        setLoader(false);
      }
      else {
        toast.error(resp.data.message);
        onClose();
        setLoader(false);
      }
    }
    catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE)
    }
  }
  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit {title}</div>
      </div>

      <div className="height-64px"></div>

      <InputTextField
        label="Enter Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => setTitle(e.target.value)}
      />

      <div className="__default--input--field mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);

          }}
        />
      </div>

      <div className="search__booking__wrapper mt-3">
        <i className="bi bi-search"></i>
        <input placeholder="Search Icons"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }} />
      </div>
      <div className="service_icon_wrapper">
        {filteredResults?.map((icon: any) => {
          return (
            <div
              key={icon.id}
              className={`service_icon ${clickedIcon.name === icon.name ? "selected" : ""}`}
              onClick={() => setClickedIcon(icon)}
            >
              <i className={`${icon.icon} me-4`} aria-hidden="true"></i>
              {icon.name}
            </div>
          )
        })}
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn" handleClick={() => onClose()}>Cancel</Button>
          <Button handleClick={() => addNewFooterData()} loader={loader}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default EditFooterSlider;
