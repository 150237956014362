import React, { useCallback, useEffect, useState } from "react";
import { Draggable } from "react-drag-reorder";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import SliderSectionCard from "../../../views/datacard/SliderSectionCard";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

interface IWashroomSliderProps {
  onClose: () => void;
}
const PrivateWashroomSlider: React.FC<IWashroomSliderProps> = ({ onClose }) => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { hotelRoomTypeDetails, hotelRoomIdOnClick } = useSelector(
    (state: RootState) => state.sections
  );
  const [availabilitesName, setAvailabilities] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [washRoomAvail, setWashRoomAvail] = useState<any[]>([]);
  const [washroomId, setWashroomId] = useState<any>("undefined");
  const [washRoomDataOrder, setWashRoomDataOrder] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRoomWashroomDetails()
  }, [])

  const fetchRoomWashroomDetails = async () => {
    try {
      let response = await wbApi.get(`/wb-dashboard/fetch-wb-private-washroom/${company_id}/${template_id}`);
      if (response.data.status === 1) {
        let privateWashRoomDetails = response?.data?.all_private_washroom_data?.filter((iteam: any) =>
          iteam.room_type_id === hotelRoomTypeDetails?.room_type_id
        )?.[0]
        setWashRoomAvail(privateWashRoomDetails?.private_washroom ?? []);
        setWashroomId(privateWashRoomDetails?.id);
      } else {
        setWashRoomAvail([]);
        // setServiceId("")
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...washRoomAvail];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setWashRoomDataOrder(orderSec);
  };

  const DraggableRender = useCallback(() => {
    if (washRoomAvail?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {washRoomAvail?.map((item: any, idx: any) => {
            return (
              <SliderSectionCard
                key={idx}
                className="slider__draggable"
                onDelete={() => {
                  deletHandler(item.id, idx);
                }}
                editOptn={false}
                deletOptn={true}
              >
                {item.service_title}
              </SliderSectionCard>

            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [washRoomAvail]);

  const addWashroomAvailabilites = async () => {
    setLoader(true);
    let washRoomPayload = [...washRoomAvail, { service_title: availabilitesName }]
    try {
      let res = await wbApi.post(`/wb/insert-update-wb-private-washroom`, {
        company_id: company_id,
        hotel_id: hotelRoomIdOnClick?.hotel_id,
        id: washroomId ?? "undefined",
        private_washroom: washRoomPayload ?? [],
        room_type_id: hotelRoomTypeDetails?.room_type_id,
        template_id: template_id
      })
      if (res.data.status === 1) {
        toast.success(res.data.message)
        setLoader(false);
        onClose();
      } else {
        toast.error(res.data.message)
        setLoader(false);
        onClose();
      }

    } catch {
      toast.error(CATCH_MESSAGE)
      setLoader(false);

    }
  }

  // Category Delet Handler
  const deletHandler = (id: any, index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          let services = washRoomAvail;
          let deletPayload = services.splice(index, 1) ?? [];
          await deletRoomWashroom(services ?? []);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  // Delet Category Post
  const deletRoomWashroom = async (deletPayload: any) => {
    try {
      let deletReq = await wbApi.post("/wb/insert-update-wb-private-washroom", {
        id: washroomId ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelRoomIdOnClick.hotel_id,
        room_type_id: hotelRoomTypeDetails.room_type_id,
        room_services: deletPayload ?? []
      });
      // console.log("deletReq", deletReq);
      if (deletReq.data.status === 1) {
        toast.success(deletReq.data.message);
        fetchRoomWashroomDetails();
        onClose();
      } else {
        toast.error(deletReq.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };
  // Api To Reorder Categories List
  const updateOrderList = async () => {
    setUpdateLoader(true);
    try {
      let orderReq = await wbApi.post(`/wb/insert-update-wb-private-washroom`, {
        id: washroomId ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelRoomIdOnClick.hotel_id,
        room_type_id: hotelRoomTypeDetails.room_type_id,
        room_services: washRoomDataOrder
      });
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setUpdateLoader(false);
        fetchRoomWashroomDetails();
        onClose();
      } else {
        toast.error(orderReq.data.message);
        setUpdateLoader(false);
      }
    } catch {
      setUpdateLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  }
  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Private Washroom</div>
      </div>
      <div className="height-64px"></div>

      <InputTextField
        label="Washroom Availabilities"
        value={availabilitesName}
        className="description description__slider"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setAvailabilities(e.target.value)}
      />
      <div className="category_list_wrapper">
        <div className="drag_list_wrapper">
          <DraggableRender />
        </div>
      </div>


      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <Button className="cancel__btn" handleClick={() => onClose()}>Cancel</Button>
        {
          washRoomAvail.length > 1 && <Button
            handleClick={() => updateOrderList()}
            loader={updateLoader}
          >
            Update Order
          </Button>
        }

        <Button
          handleClick={() => addWashroomAvailabilites()}
          loader={loader}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default PrivateWashroomSlider;
