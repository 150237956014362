import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import EditImageSlider from "./EditImageSlider";
import SlidingPane from "react-sliding-pane";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import Loader from "../../../views/loader/Loader";
import Button from "../../../views/buttons/Button";
import { Draggable } from "react-drag-reorder";
import { log } from "console";
import { CancelConfirmationPrompt, ShowConfirmationPrompt } from "../../../redux/actions/PromptAction";

const ImageSlider = ({ defaultValue, getVideoSliderDetails }: any) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [loader, setLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [uploadLoader, setUploadLoader] = useState<boolean>(false);
  const [imageSlider, setImageSlider] = useState<boolean>(false);
  const [ImgSliderData, setImageSliderData] = useState<any[]>([]);
  const [ImgtoCompareOrder, setImageCompareOrder] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [editfiles, seteditFiles] = useState<any[]>([]);
  const [defaultSlider, setDefaultSlider] = useState<boolean>(false);
  const inputDropZone: RefObject<HTMLInputElement> = React.createRef();
  const dragItem: any = useRef(null);
  const dragOverItem: any = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultSlider(defaultValue?.default_value?.toLowerCase() === "image");
  }, [defaultValue]);
  useEffect(() => {
    getImageSliderDetails();
  }, []);

  const onDragOver = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
  };

  function onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let filesArray: any = [...files];
    let file = event.dataTransfer.files;

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
  }
  const inputOpen = () => {
    const { current } = inputDropZone;
    (current || { click: () => { } }).click();
  };

  // Api to Get Slider Details =========================>>>>>>>>>

  const getImageSliderDetails = async () => {
    setLoader(true);
    try {
      let sliderRes = await wbApi.get(
        `/wb-dashboard/fetch-slider-details/${company_id}/${template_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // console.log("sliderRes", sliderRes);
      if (sliderRes.data.status === 1) {
        setImageSliderData(sliderRes?.data?.templateslider);
        setImageCompareOrder(sliderRes?.data?.templateslider);
        setLoader(false);
      } else {
        setImageSliderData([]);
        setLoader(false);
      }
    } catch {
      // toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  //   OnAcceptingFiles Through Click =============================>>>>>>>>>>>;

  const acceptFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let filesArray: any = [...files];
    let file = e.target.files ?? {};

    Object.entries(file).forEach(([key, value]: any) => {
      Object.assign(value, {
        preview: URL.createObjectURL(value),
      });
      return filesArray.push(value);
    });

    setFiles(filesArray);
  };

  // For Deleting Image Uploaded Locally =======================>>>>>>>>;
  const onDelete = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {
    if (e.target !== e.currentTarget) {
      e.stopPropagation();
      const newList = files?.filter((fw: any) => fw.name !== file);
      setFiles(newList);
    }
  };
  // Deleting Slider Images Coming From Backend===================>>>>>>>>>>
  const onDeleteIamge = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    file: any
  ) => {

    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let deleteRes = await wbApi.post(
              `/wb/delete-dzimages-slider`,
              {
                image_name: file?.slider_image,
                company_id: company_id,
                template_id: template_id,
              },
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              }
            );

            if (deleteRes.data.status === 1) {
              toast.success(deleteRes.data.message);
              getImageSliderDetails();
            } else {
              toast.error(deleteRes.data.message);
              getImageSliderDetails();
            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );






  };

  // Api To Set Default
  const setDefault = async () => {
    try {
      let defaultRes = await wbApi.post(`/wb/make-slider-default`, {
        company_id: company_id,
        default_value: "image",
        template_id: template_id,
      });
      // console.log("defaultRes", defaultRes);
      if (defaultRes.data.status === 1) {
        toast.success(defaultRes.data.message);
        getVideoSliderDetails();
      } else {
        toast.error(defaultRes.data.message);
        getVideoSliderDetails();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // To Make UploadImage Drag and Reorder===========================>>>>>>>>>

  const dragStart = (e: any, position: any) => {
    dragItem.current = position;
    // console.log("dragStart", e.target.innerHTML);
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
    // console.log("dragEnter", e.target.innerHTML);
  };

  const drop = (e: any) => {
    const copyListItems = [...files];
    const dragItemContent = copyListItems[dragItem?.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setFiles(copyListItems);
  };

  // To Make Image Coming From Backend Drag and Reorder===========================>>>>>>>>>

  const dragStartImage = (e: any, position: any) => {
    dragItem.current = position;
    // console.log("dragStart", e.target.innerHTML);
  };
  const dragEnterImage = (e: any, position: any) => {
    dragOverItem.current = position;
    // console.log("dragEnter", e.target.innerHTML);
  };

  const dropImage = (e: any) => {
    const copyListItems = [...ImgSliderData];
    const dragItemContent = copyListItems[dragItem?.current];
    const dragOverContent = copyListItems[dragOverItem.current];
    const temp = dragItemContent.slider_image;
    dragItemContent.slider_image = dragOverContent.slider_image;
    dragOverContent.slider_image = temp;
    // copyListItems.splice(dragItem.current, 1);
    // copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setImageSliderData(copyListItems);

  };

  // Api To Update Order Of Images
  const updateOrdreHandler = async () => {
    setUpdateLoader(true);
    try {
      let updateRes = await wbApi.post(`/ordering/order-wb-slider`, {
        company_id: company_id,
        template_id: template_id,
        slider: ImgSliderData,
      });
      if (updateRes.data.status === 1) {
        toast.success(updateRes.data.message);
        setUpdateLoader(false);
        getImageSliderDetails();
      } else {
        toast.error(updateRes.data.message);
        setUpdateLoader(false);
        getImageSliderDetails();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setUpdateLoader(false);
    }
  };

  // Api To Update Order Of Images
  const uploadHandler = async () => {

    if (files.length > 0) {
      setUploadLoader(true);

      let payload = new FormData();

      files.forEach((image: any) => {
        payload.append("uploadFile[]", image);
      });
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-image/${company_id}/${template_id}/Slider`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          // toast.success(uploadRes.data.message);
          await insertImageData(uploadRes.data.image_name);
          setUploadLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setUploadLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setUploadLoader(false);
      }
    } else {
      toast.error("Image Required !")
    }

  };

  // Api To Insert Data
  const insertImageData = async (imageData: any) => {
    let prevImages = ImgSliderData.map((iteam) => iteam.slider_image);
    try {
      let insertDataRes = await wbApi.post(
        `/template/insert-update-data`,
        {
          company_id: company_id,
          template_id: template_id,
          slider_image: [...prevImages, ...imageData],
        },

        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      // console.log("insertDataRes", insertDataRes);
      if (insertDataRes.data.status === 1) {
        toast.success(insertDataRes.data.message);
        setFiles([]);
        getImageSliderDetails();
      } else {
        toast.error(insertDataRes.data.message);
        getImageSliderDetails();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="form-check mt-4">
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Set as Default
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          checked={defaultSlider}
          id="setDefault"
          onChange={() => setDefaultSlider(!defaultSlider)}
          onClick={() => setDefault()}
        ></input>
      </div>

      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <div
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onClick={inputOpen}
          className={`mt-4 image__drag__drop__container`}
        >
          {files.length > 0 ? (
            <>
              <div className="image-preview-container">
                {files?.map((imgFile, idx) => (
                  <>
                    <div
                      key={idx}
                      className="image-preview-wrapper"
                      draggable
                      onDragStart={(e) => dragStart(e, idx)}
                      onDragEnter={(e) => dragEnter(e, idx)}
                      onDragEnd={drop}
                    >
                      <img
                        src={imgFile?.preview}
                        className="image-preview-size"
                      />

                      <div
                        className="overlay-effect"
                        onClick={(e) => {
                          if (e.target !== e.currentTarget) {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <div
                          className="edit-delet-btn-warpper"
                          onClick={(e) => {
                            if (e.target !== e.currentTarget) {
                              e.stopPropagation();
                            }
                          }}
                        >
                          <div
                            className="img-delet-btn"
                            onClick={(e) => {
                              onDelete(e, imgFile?.name);
                            }}
                          >
                            <i className="bi bi-trash img-delete-icon"></i>
                          </div>

                          <div
                            className="img-delet-btn"
                            onClick={(e) => {
                              setImageSlider(true);
                              seteditFiles(imgFile);
                            }}
                          >
                            <i className="bi bi-pencil-square img-edit-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
          {ImgSliderData?.length > 0 ? (
            <div className="image-preview-container">
              {ImgSliderData?.map((imgFile, idx) => (

                <div
                  key={idx}
                  className="image-preview-wrapper"
                  draggable
                  onDragStart={(e) => dragStartImage(e, idx)}
                  onDragEnter={(e) => dragEnterImage(e, idx)}
                  onDragEnd={dropImage}
                >
                  <img
                    src={getImage(imgFile?.slider_image)}
                    className="image-preview-size"
                  />
                  <div className="overlay-effect">
                    <div
                      className="edit-delet-btn-warpper"
                      onClick={(e) => {
                        if (e.target !== e.currentTarget) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <div
                        className="img-delet-btn"
                        onClick={(e) => {
                          onDeleteIamge(e, imgFile);
                        }}
                      >
                        <i className="bi bi-trash img-delete-icon"></i>
                      </div>
                      <div
                        className="img-delet-btn"
                        onClick={(e) => {
                          setImageSlider(true);
                          seteditFiles(imgFile);
                        }}
                      >
                        <i className="bi bi-pencil-square img-edit-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>

              ))}
            </div>
          ) : (
            <></>
          )}

          {files?.length === 0 && ImgSliderData?.length === 0 ? (
            <div
              className="image_drag_area"
            // onClick={inputOpen}
            >
              <i className="bi bi-images"></i>
              <div className="text-area">Drag your photos or click here</div>
            </div>
          ) : (
            <></>
          )}
          <input
            id=""
            ref={inputDropZone}
            type="file"
            style={{ visibility: "hidden" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              acceptFileHandler(e);
            }}
            multiple
          />
        </div>
      )}

      <div className="d-flex justify-content-between">
        <div className="mt-2 mb-2" style={{ width: "15%" }}>
          <Button
            handleClick={() => {
              uploadHandler();
            }}
            loader={uploadLoader}
          >
            Upload
          </Button>
        </div>
        <div className="mt-2 mb-2" style={{ width: "15%" }}>
          <Button
            handleClick={() => {
              updateOrdreHandler();
            }}
            loader={updateLoader}
          >
            Update Order
          </Button>
        </div>
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={imageSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setImageSlider(false)}
      >
        <EditImageSlider
          onClose={() => setImageSlider(false)}
          editfiles={editfiles}
          getImageSliderDetails={getImageSliderDetails}
        />
      </SlidingPane>
    </>
  );
};

export default ImageSlider;
