import { GET_HOTEL_DATA_AND_OFFERS, UPDATE_OFFERS_STATE, UPDATE_OFFERS_ARRAY } from "../actionTypes";

export const getAllHotelsAndOffers = (data: any) => {
    return (
        {
            type: GET_HOTEL_DATA_AND_OFFERS,
            payload: data,
        }
    )
}
// export const emptyBlogData = () => {
//     return (
//         {
//             type: EMPTY_BLOG_DATA,
//             // payload: data
//         }
//     )
// }
export const updateStateAfterNewOfferAdded = (data: any) => {
    return (
        {
            type: UPDATE_OFFERS_STATE,
            payload: data
        }
    )
}
export const updateOffersArr = (data: any) => {
    return (
        {
            type: UPDATE_OFFERS_ARRAY,
            payload: data
        }
    )
}

