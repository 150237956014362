import { GET_HOTEL_DATA_AND_FACILITY, UPDATE_FACILITY_STATE, UPDATE_FACILITY_ARRAY } from "../actionTypes";

const initialState = {
    hotel_id: '', hotel_name: '', facilityArr: []
}

const FacilityDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_HOTEL_DATA_AND_FACILITY:
            return {
                ...action.payload,
            }
        case UPDATE_FACILITY_ARRAY:
            return {
                ...state,
                facilityArr: action.payload,
            }


        default: return state
    }
}

export default FacilityDataReducer;