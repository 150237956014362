import {
  PAGE_ID,
  CUSTOM_PAGE,
  INUSE_PAGES,
  HOME_PAGE,
  ABOUT_PAGE,
  ROOM_PAGE,
  CONTACTUS_PAGE,
  GALLERY_PAGE,
  SERVICE_PAGE,
  PACKAGE_PAGE,
  BLOG_PAGE,
  FACILITY_PAGE,
  EVENTS_PAGE,
  OFFERS_PAGE,
  TESTIMONIAL_PAGE,
  PROMOTIONS_PAGE,
  HOTEL_PAGE,
  HOTEL_LIST_PAGE,
} from "../actionTypes";

export const DefaultPageIdAction = (page_Id: any) => {
  return {
    type: PAGE_ID,
    pages: page_Id,
  };
};
export const CustomPageAction = (cpages: any) => {
  return {
    type: CUSTOM_PAGE,
    pages: cpages,
  };
};
export const inUsePagesAction = (inUsePages: any) => {
  return {
    type: INUSE_PAGES,
    pages: inUsePages,
  };
};
export const homePageAction = (homePageData: any) => {
  return {
    type: HOME_PAGE,
    pages: homePageData,
  };
};
export const aboutPageAction = (aboutPageData: any) => {
  return {
    type: ABOUT_PAGE,
    pages: aboutPageData,
  };
};
export const roomPageAction = (roomPageData: any) => {
  return {
    type: ROOM_PAGE,
    pages: roomPageData,
  };
};
export const contactPageAction = (contactPageData: any) => {
  return {
    type: CONTACTUS_PAGE,
    pages: contactPageData,
  };
};
export const galleryPageAction = (galleryPageData: any) => {
  return {
    type: GALLERY_PAGE,
    pages: galleryPageData,
  };
};
export const servicePageAction = (servicePageData: any) => {
  return {
    type: SERVICE_PAGE,
    pages: servicePageData,
  };
};
export const packagePageAction = (packagePageData: any) => {
  return {
    type: PACKAGE_PAGE,
    pages: packagePageData,
  };
};
export const blogPageAction = (blogPageData: any) => {
  return {
    type: BLOG_PAGE,
    pages: blogPageData,
  };
};
export const facilityPageAction = (facilityPageData: any) => {
  return {
    type: FACILITY_PAGE,
    pages: facilityPageData,
  };
};
export const eventsPageAction = (eventsPageData: any) => {
  return {
    type: EVENTS_PAGE,
    pages: eventsPageData,
  };
};
export const offersPageAction = (offersPageData: any) => {
  return {
    type: OFFERS_PAGE,
    pages: offersPageData,
  };
};
export const testimonialPageAction = (testimonialPageData: any) => {
  return {
    type: TESTIMONIAL_PAGE,
    pages: testimonialPageData,
  };
};
export const promotionsPageAction = (promotionsPageData: any) => {
  return {
    type: PROMOTIONS_PAGE,
    pages: promotionsPageData,
  };
};
export const hotelPageAction = (hotelPageData: any) => {
  return {
    type: HOTEL_PAGE,
    pages: hotelPageData,
  };
};
export const hotelListPageAction = (hotelListPageData: any) => {
  return {
    type: HOTEL_LIST_PAGE,
    pages: hotelListPageData,
  };
};
