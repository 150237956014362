import { GET_SELECTED_GALLARY_DATA, UPDATE_GALLARY } from "../actionTypes";

export const getSeletedGallaryData = (data: any) => {
    return (
        {
            type: GET_SELECTED_GALLARY_DATA,
            payload: data,
        }
    )
}
// export const emptyBrandData = (data: any) => {
//     return (
//         {
//             type: EMPTY_BRAND_DATA,
//             payload: data
//         }
//     )
// }
export const updateGallarySectAfterNewAdded = (data: any) => {
    return (
        {
            type: UPDATE_GALLARY,
            payload: data
        }
    )
}

