import { GET_HOTEL_DATA_AND_EVENTS, UPDATE_EVENTS_STATE, UPDATE_EVENTS_ARRAY } from "../actionTypes";

export const getAllHotelsAndEvents = (data: any) => {
    return (
        {
            type: GET_HOTEL_DATA_AND_EVENTS,
            payload: data,
        }
    )
}
// export const emptyBlogData = () => {
//     return (
//         {
//             type: EMPTY_BLOG_DATA,
//             // payload: data
//         }
//     )
// }
export const updateStateAfterNewEventAdded = (data: any) => {
    return (
        {
            type: UPDATE_EVENTS_STATE,
            payload: data
        }
    )
}
export const updateEventsArr = (data: any) => {
    return (
        {
            type: UPDATE_EVENTS_ARRAY,
            payload: data
        }
    )
}

