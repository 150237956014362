import { HEADER_MENU,FOOTER_MENU} from "../actionTypes";


const initialState = {
   headermenus:[],
   footermenus:[],
}

const MenuReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case HEADER_MENU:
            return(
                {
                    ...state,
                    headermenus:action.menus,
                }
            ) 
        case FOOTER_MENU:
            return(
                {
                    ...state,
                    footermenus:action.menus,
                }
            )           
        default: return state
    }
}

export default MenuReducer;