import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
export interface IAddNewServicesSliderProps {
  onClose: () => void;
  fetchSection: () => void;
}

const AddNewServices: React.FC<IAddNewServicesSliderProps> = ({
  onClose,
  fetchSection,
}) => {
  const { hotel_services_data } = useSelector(
    (state: RootState) => state.sections
  );

  const [loader, setLoader] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [clickedIcon, setClickedIcon] = useState<any>("");
  const [serviceTitle, setServiceTitle] = useState<any>("");

  useEffect(() => {
    setFilteredResults(hotel_services_data?.all_icons);
  }, [hotel_services_data]);
  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = hotel_services_data?.all_icons?.filter(
        (item: any) => {
          return item.name.toLowerCase().startsWith(keyword.toLowerCase());
        }
      );
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(hotel_services_data?.all_icons);
    }
    setSearchInput(keyword);
  };

  const addNewServices = async () => {
    setLoader(true);
    try {
      let res = await wbApi.post(`/wb/add-wb-services`, {
        icon: clickedIcon ?? "",
        title: serviceTitle ?? "",
      });
      if (res.data.status === 1) {
        toast.success(res.data.message);
        fetchSection();
        setLoader(false);
      } else {
        toast.error(res.data.message);
        fetchSection();
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <div>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add New Services</div>
      </div>
      <div className="height-64px"></div>
      <div>
        <InputTextField
          label={"Service Title"}
          value={serviceTitle}
          handleChange={(e) => setServiceTitle(e.target.value)}
        />
        <div className="mt-3 search__booking__wrapper">
          <i className="bi bi-search"></i>
          <input
            placeholder="Search Icons"
            value={searchInput}
            onChange={(e) => {
              searchItems(e.target.value);
            }}
          />
        </div>
        <div className="service_icon_wrapper">
          {filteredResults?.map((icon: any) => (
            <>
              <div
                key={icon.id}
                className={`service_icon ${icon.icon === clickedIcon ? "selected" : ""}`}
                onClick={() => setClickedIcon(icon.icon)}
              >
                <i className={`${icon.icon} me-4`} aria-hidden="true"></i>
                {icon.name}
              </div>
            </>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "white",
          }}
        ></div>
        <div className="side__slider__panel__btn">
          <div className="side__slider__panel__btn_container">
            <Button
              className="cancel__btn"
              handleClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>

            <Button
              handleClick={() => {
                addNewServices();
              }}
              loader={loader}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewServices;
