import { UPDATE_OFFERS_STATE } from "../actionTypes"

const initialState = ''

const OffersUpdate = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_OFFERS_STATE:
            return action.payload

        default: return state
    }
}

export default OffersUpdate;