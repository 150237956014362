import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WbPageCard from "../../views/datacard/WbPageCard";
import Button from "../../views/buttons/Button";
import SaveButton from "../../views/buttons/Button";
import PageHomeSectionCard from "../../views/datacard/RectangleSectionCard";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loader from "../../views/loader/Loader";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import NoDataComponent from "../../Components/noDataComponent/NoDataComponent";
import wbApi from "../../API/wbApi";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../UtilityFunctions";

const CustomPage = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const navigate = useNavigate();
  const [inUsePageId, setInUsePageId] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [customPages, setCustomPages] = useState<any[]>([]);

  useEffect(() => {
    fetchAllPages();
    fetchInUsePages();
  }, [company_id, template_id]);

  // Fetch all Pages
  const fetchAllPages = async () => {
    setLoader(true);
    try {
      let allPageRes = await wbApi.get(
        `/wb-dashboard/fetch-all-default-custom-pages/${company_id}/${template_id}`
      );

      // console.log("allPageRes", allPageRes);
      if (allPageRes.data.status === 1) {
        // console.log(allPageRes.data);
        setCustomPages(allPageRes.data.custom_pages);

        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  // Api To Get Inuse Pages List
  const fetchInUsePages = async () => {
    try {
      let inUsePageRes = await wbApi.get(
        `/wb-dashboard/fetch-in-use-pages/${company_id}/${template_id}`
      );

      // console.log("inUsePageRes", inUsePageRes);
      if (inUsePageRes.data.status === 1) {
        const active = inUsePageRes.data.in_use_pages?.map(
          (obj: any) => obj.page_id
        );
        setInUsePageId(active);
      } else {
        setInUsePageId([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Pages",
              href: "/pages-custom",
            },
            {
              label: `Custom Pages`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button icon="bi bi-plus-lg">Add</Button>

          <div className="header-footer-toggle">
            <ul className="nav nav-tabs">
              <li className="nav-item" role="presentation">
                <a
                  onClick={() => navigate("/pages-default")}
                  className="nav-link"
                  id="default"
                  data-bs-toggle="tab"
                  data-bs-target="list"
                  aria-controls="list"
                  role="tab"
                  aria-selected="true"
                >
                  DEFAULT
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="custom"
                  data-bs-toggle="tab"
                  data-bs-target="crs"
                  aria-controls="crs"
                  role="tab"
                  aria-selected="true"
                >
                  CUSTOM
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="wb-divider"></div>

      <div className="mt-4">
        {loader ? (
          <div className="middle-loader">
            <Loader />
          </div>
        ) : (
          <div className="page-main-section">
            {customPages.length > 0 ? (
              customPages?.map((pages: any, idx: any) => {
                return (
                  <div key={idx} className="page-default-section ">
                    <WbPageCard
                      key={pages.id}
                      update="Last Updated- 2days ago"
                      isActive={inUsePageId.includes(pages.id)}
                      onEditClick={() => {
                        navigate(`/page-default/${pages.page_route}`);
                      }}
                    >
                      {pages.pages_name}
                    </WbPageCard>
                  </div>
                );
              })
            ) : (
              <NoDataComponent message="No Pages Found" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomPage;
