import React, { useEffect, useState } from "react";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

interface IAboutHotelSliderProps {
  onClose: () => void;
  fetchSection: () => void;
  hotelId: any;
  hotelName: any;
  hotelAboutDetails: any[];
}

const AboutHotelSlider: React.FC<IAboutHotelSliderProps> = ({
  onClose,
  fetchSection,
  hotelId,
  hotelName,
  hotelAboutDetails,
}) => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [hotelAboutData, setHotelAboutData] = useState<any>({});
  const [altText, setAltText] = useState("");
  const [AboutId, setAboutId] = useState<any>("");
  const [aboutImage, setAboutImage] = useState<any[]>([]);
  const [description, setDescription] = useState<any>("");
  const [aboutUsfiles, setAboutUsFiles] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (hotelAboutDetails.length > 0) {
      const hotelAbtData = hotelAboutDetails?.filter((iteam: any) => {
        return iteam.hotel_id === hotelId;
      });
      setHotelAboutData(hotelAbtData[0]);
      setTitle(hotelAbtData[0]?.title);
      setSubTitle(hotelAbtData[0]?.subtitle);
      setAltText(hotelAbtData[0]?.about_image_alt);
      hotelAbtData[0]?.image_name
        ? setAboutImage([getImage(hotelAbtData[0]?.image_name)])
        : setAboutImage([]);
      setAboutId(hotelAbtData[0]?.about_id);
    }
  }, [hotelAboutDetails]);

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const accpAboutFiles = (value: any) => {
    setAboutUsFiles(value);
  };

  const delteAboutFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteHotelAboutFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  function saveHandler() {
    if (aboutUsfiles.length) {
      uploadHandler();
    } else {
      addUpdateAbout([]);
    }
  }

  const uploadHandler = async () => {
    if (aboutUsfiles.length === 1) {
      setLoader(true);

      let payload = new FormData();

      payload.append("uploadFile[0]", aboutUsfiles[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/HotelAbout`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateAbout([uploadRes.data.image_ids]);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };

  const addUpdateAbout = async (about_image: any[]) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/new/insert-update-about", {
        about_id: AboutId ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelId,
        hotel_name: hotelName ?? "",
        title: title ?? "",
        subtitle: subtitle ?? "",
        about_image_alt: altText ?? "",
        description: description,
        about_image: about_image ?? [],
      });

      // console.log(req, "Postreq");
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
        setLoader(false);
        onClose();
      } else {
        toast.error(req.data.message);
        fetchSection();
        setLoader(false);
        onClose();
      }
    } catch (error) {
      // console.log(error);
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };
  const delteHotelAboutFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-about-section-image`, {
        company_id: company_id,
        image_name: hotelAboutData.image_name,
        template_id: template_id,
      });
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSection();
        onClose();
      } else {
        toast.error(deleteRes.data.message);
        fetchSection();
        onClose();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit {hotelName}</div>
      </div>
      <div className="height-64px"></div>

      <div className="about-hotel-slider-main-contant-warpper">
        <InputTextField
          label="Enter Title"
          value={title}
          className="description description__slider"
          handleChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <InputTextField
          label="Enter Subtitle"
          value={subtitle}
          className="description description__slider"
          handleChange={(e) => {
            setSubTitle(e.target.value);
          }}
        />
        <InputTextField
          label="Enter Alternate Text"
          value={altText}
          className="description description__slider"
          handleChange={(e) => {
            setAltText(e.target.value);
          }}
        />
        <div className="mb-2 select-image-label">
          Select Your Images for Hotel About Section
        </div>

        <DropzoneArea
          centerMesage={"Drag your photos or click here(1 image)"}
          incomingfiles={aboutImage}
          dispatchFiles={(val) => accpAboutFiles(val)}
          isDelete={(val) => delteAboutFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
        />

        <div className="mt-4">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AboutHotelSlider;
