import React, { useEffect, useState } from "react";
import Button from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InputTextField from "../../../views/inputfield/InputTextField";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { updateStateAfterNewAddedPromotion } from "../../../redux/actions/PromotionAction";
import { nanoid } from "@reduxjs/toolkit";
import ColorPicker from "../../../views/colorpicker/ColorPicker";

interface IEditPromotionSliderProps {
  onClose: () => void;
  PromotionalApi: () => void;
  editPromo: any;
}
const EditPromotionSlider: React.FC<IEditPromotionSliderProps> = ({
  onClose,
  PromotionalApi,
  editPromo
}) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const data = useSelector((state: RootState) => state.promotionData);
  const dispatch = useDispatch()

  const [title, setTitle] = useState('')
  const [color, setColor] = useState('#ffffff')
  const [description, setDescription] = useState('')
  const [promoId, setPromoId] = useState('')
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    setPromoId(editPromo.id);
    setTitle(editPromo.title);
    setColor(editPromo.background_color);
    setDescription(editPromo.description);
  }, [editPromo])

  const updatePromotionApi = async () => {
    setLoader(true);
    try {
      const resp = await wbApi.post(`wb/save-hotel-promotions`, {
        title, background_color: color, company_id, id: promoId, template_id, description
      })

      if (resp.data.status === 1) {
        setLoader(false);
        toast.success(resp.data.message)
        PromotionalApi();
        onClose()
      }
      else {
        setLoader(false);
        toast.error(resp.data.message)
        PromotionalApi();
        onClose()
      }

    }
    catch {
      toast.error(CATCH_MESSAGE)
      setLoader(false);
    }
  }

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Edit {`${(title)}`}</div>
      </div>
      <div className="height-64px"></div>


      <ColorPicker
        className="promo_sec_color_picker"
        value={color}
        icon="bi bi-pencil-square"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setColor(e.target.value);
        }}
        id="promo"
      />

      <InputTextField
        label="Promotion Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => setTitle(e.target.value)}
      />

      <div className="__default--input--field mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn" handleClick={() => onClose()} loader={loader}>Cancel</Button>
          <Button handleClick={() => updatePromotionApi()}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default EditPromotionSlider;
