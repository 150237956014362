import React, { useCallback, useEffect, useState } from "react";
import InputSelectField from "../../views/inputfield/InputSelectField";
import InputType from "../../views/inputfield/InputType";
// import SaveButton from "../../views/buttons/Button";
import Button from "../../views/buttons/Button";
import InputTextField from "../../views/inputfield/InputTextField";
import { Draggable } from "react-drag-reorder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import wbApi from "../../API/wbApi";
import CardWrapper from "../../views/datacard/CardWrapper";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../redux/actions/PromptAction";

export interface IHeaderMenuProps {
  menus: any[];
  fetchMenuData: () => void;
  updateOrder: (e: any) => void;
  setHeaderMenu: (e: any) => void;
}

const HeaderMenu: React.FC<IHeaderMenuProps> = ({
  menus,
  fetchMenuData,
  updateOrder,
  setHeaderMenu,
}) => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [selectMenu, setSelectMenu] = useState<any>();
  const [selected, setSelected] = useState<string>("Home");
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [allPages, setAllPages] = useState<any[]>([]);
  const [selectPage, setSelectPage] = useState<any>("");
  const [extLink, setExtLink] = useState<any>("");
  const [pageId, setPageId] = useState<number>(0);
  const [menuId, setMenuId] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [updateMenuOrder, setUpdateMenuOrder] = useState<any[]>([]);

  const dispatch = useDispatch();

  // Menu Tab Handler
  const clickHandler = (menuName: any) => {
    const select = menus.filter((obj) => {
      return obj.menu_name === menuName;
    });
    setSelectMenu(select[0]);
    setSelectPage(select[0]?.page_id);
    setExtLink(select[0]?.page_url);
  };

  useEffect(() => {
    clickHandler(selected);
  }, [selected]);

  // Api To Post Menu Page and Url
  const saveHeaderUrlHandler = async () => {
    setLoader(true);
    try {
      let headerUrlReq = await wbApi.post("/wb/change-menu-page", {
        company_id: company_id,
        template_id: template_id,
        menu_id: selectMenu.menu_id,
        page_id: selectPage,
        page_url: extLink,
      });
      console.log(headerUrlReq);
      if (headerUrlReq.data.status === 1) {
        toast.success(headerUrlReq.data.message);
        fetchMenuData();
        setLoader(false);
      } else {
        toast.error(headerUrlReq.data.message);
        fetchMenuData();
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderMenu = [...menus];
    const result = orderMenu.splice(currentPos, 1);
    const upDateMenu = orderMenu.splice(newPos, 0, ...result);
    updateOrder(orderMenu);
    // console.log(orderMenu, "orderMenu");
    const menuInOrder = orderMenu.map((iteam, idx) => {
      return {
        ...menus[idx],
        menu_name: iteam.menu_name,
      };
    });
    // console.log("menuInOrder", menuInOrder);
    setUpdateMenuOrder(menuInOrder);
  };

  // Draggable Menu Mapping

  const DraggableMenuRender = useCallback(() => {
    if (menus && menus.length) {
      return (
        <Draggable onPosChange={positionHandler}>
          {menus.map((m: any, idx) => {
            // console.log("m", m.menu_name);
            return (
              <div
                onClick={() => {
                  setSelected(m.menu_name);
                  // toggleActiveStyle(m.menu_id);
                  setActiveIndex(idx);
                }}
              >
                <div
                  key={m.menu_id}
                  className={`menu-left-nav ${
                    idx === activeIndex ? "active" : ""
                  }`}
                >
                  {m.menu_name}
                </div>
              </div>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [menus, activeIndex]);

  // Api Call To Post Update Order Menu List
  const updateMenuOrderHandler = async () => {
    setUpdateLoader(true);
    try {
      let updateRes = await wbApi.post(`/order/menu-order-update`, {
        company_id: company_id,
        template_id: template_id,
        menu_name: updateMenuOrder,
      });
      // console.log("updateRes", updateRes);
      if (updateRes.data.status === 1) {
        toast.success(updateRes.data.message);
        fetchMenuData();
        setUpdateLoader(false);
      } else {
        toast.error(updateRes.data.message);
        fetchMenuData();
        setUpdateLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setUpdateLoader(false);
    }
  };
  // To Delete Menu
  const deleteMenuHandler = () => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          setDeleteLoader(true);
          try {
            let deleteRes = await wbApi.post(`/wb/delete-menu`, {
              menu_id: selectMenu.menu_id,
              template_id: template_id,
              company_id: company_id,
            });
            // console.log("deleteRes", deleteRes);
            if (deleteRes.data.status === 1) {
              toast.success(deleteRes.data.message);
              setDeleteLoader(false);
              fetchMenuData();
            } else {
              toast.error(deleteRes.data.message);
              setDeleteLoader(false);
              fetchMenuData();
            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  return (
    <CardWrapper className="menu-card-wrapper">
      <div className="menu-sec-wrapper">
        <div className="menu-leftsec">
          <DraggableMenuRender />
        </div>
        <div className="menu-rightsec">
          <div className="menu-select-page-label">Select Pages</div>
          <div className="menu-dropdown-wrapper">
            <InputSelectField
              label="Select Page"
              value={selectPage}
              values={selectMenu?.all_pages?.map((iteam: any) => iteam.page_id)}
              options={selectMenu?.all_pages?.map(
                (iteam: any) => iteam.page_name
              )}
              handleChange={(e) => {
                setSelectPage(e.target.value);
              }}
            />
          </div>
          <div className="menu-select-page-label">Or External Link</div>
          <div className="menu-link-wrapper">
            <InputTextField
              label="External Link"
              value={`${extLink}`}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setExtLink(e.target.value);
              }}
            />
          </div>
          <div className="menu-btn-warpper">
            <Button
              className="save-btn"
              handleClick={saveHeaderUrlHandler}
              loader={loader}
            >
              Save
            </Button>
            <Button
              className="updatee-btn"
              handleClick={updateMenuOrderHandler}
              loader={updateLoader}
            >
              Update Order
            </Button>
            <Button
              className="cancel__btn"
              icon="bi bi-trash"
              handleClick={deleteMenuHandler}
              loader={deleteLoader}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};
export default HeaderMenu;
