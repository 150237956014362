import React, { useCallback, useEffect, useRef, useState } from "react";
import ColorPicker from "../../views/colorpicker/ColorPicker";
import InputSelectField from "../../views/inputfield/InputSelectField";
import InputTextField from "../../views/inputfield/InputTextField";
import Button from "../../views/buttons/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import wbApi from "../../API/wbApi";
import { UploadHandle } from "../../views/dropzone/DropZone";
import Loader from "../../views/loader/Loader";
import {
  CATCH_MESSAGE,
  getImage,
  Logout,
  UpdateSidebar,
} from "../../UtilityFunctions";
import FetchButton from "../../views/buttons/FetchButton";
import CardWrapper from "../../views/datacard/CardWrapper";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import { ActiveSidebarItem } from "../../redux/actions/SidebarAction";



const Identity = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const [loader, setLoader] = useState<boolean>(false);
  const [btnloader, setBtnLoader] = useState<boolean>(false);
  const [syncloader, setSyncLoader] = useState<boolean>(false);
  const [liveStatus, setLiveStatus] = useState<any>("Publish");
  const [textColor, setTextColor] = useState("#000000");
  const [themeColor, setThemeColor] = useState("#C95151");
  const [themeFont, setThemeFont] = useState("");
  const [analyticScript, setanalyticScript] = useState("");
  const [schemaCode, setSchemaCode] = useState("");
  const [companyApiKey, setCompnayApiKey] = useState("");
  const [altText, setAltText] = useState("");
  const [logo, setLogo] = useState<any[]>([]);
  const [faviconLogo, setFaviconLogo] = useState<any[]>([]);

  const [allfonts, setAllfonts] = useState<any[]>([]);
  const dispatch = useDispatch();
  const logoimageUploading = useRef<UploadHandle>(null);
  const faviconimageUploading = useRef<UploadHandle>(null);
  const [logofiles, setlogoFiles] = useState<any[]>([]);
  const [favicon, setFavicon] = useState<any[]>([]);
  const [allHotels, setAllHotels] = useState<any[]>([]);
  const [refreshLogoList, setRefreshLogoList] = useState<boolean>(false);
  const [refreshFaviconList, setRefreshFaviconList] = useState<boolean>(false);

  useEffect(() => {
    dispatch(ActiveSidebarItem("Identity"));
  }, [])


  const deletResLogo = (val: boolean) => {
    if (val) {
      setlogoFiles([]);
      setLogo([]);
    }
  };
  const deletResFavicon = (val: boolean) => {
    if (val) {
      setFavicon([]);
      setFaviconLogo([]);
    }
  };

  useEffect(() => {
    if (template_id) {
      fetchIdentityData();
      getWebSiteLiveStatus();
      getAllHotels();
      getCompanyApiKey();
    }
  }, [company_id, template_id]);

  const getCompanyApiKey = async () => {
    setLoader(true);
    try {
      let fetchCompanyKey = await wbApi.get(
        "/wb-dashboard/fetch-company-api-key/" + company_id + "/" + template_id
      );
      if (fetchCompanyKey.data.status === 1) {
        setCompnayApiKey(fetchCompanyKey.data.company_api_key)
        setLoader(false);
      } else {
        setCompnayApiKey("")
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  //  Identity Data Fetch
  const fetchIdentityData = async () => {
    setLoader(true);
    try {
      let fetchIdentityData = await wbApi.get(
        "/wb-dashboard/fetch-identity-data/" + company_id + "/" + template_id
      );
      if (fetchIdentityData.data.status === 1) {
        setTextColor(fetchIdentityData.data.identitydata[0].text_color);
        setThemeColor(fetchIdentityData.data.identitydata[0].theme_color);
        setThemeFont(fetchIdentityData.data.identitydata[0].theme_font);
        setAltText(fetchIdentityData.data.identitydata[0].logo_alt);
        setLogo([getImage(fetchIdentityData.data.identitydata[0].logo)]);
        setFaviconLogo([
          getImage(fetchIdentityData.data.identitydata[0].favicon),
        ]);
        setanalyticScript(
          fetchIdentityData.data.identitydata[0].google_analytics
        );
        setSchemaCode(fetchIdentityData.data.identitydata[0].schema_code);
        const fontOptions = fetchIdentityData.data.allfonts.map(
          (iteam: any) => {
            return iteam.font_name;
          }
        );
        setAllfonts(fontOptions);
        setLoader(false);
      } else {
        setTextColor("");
        setThemeColor("");
        setThemeFont("");
        setAltText("");
        setLogo([]);
        setFaviconLogo([]);
        setanalyticScript("");
        setSchemaCode("");
        setAllfonts([]);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  // To Fetch Website Live Status
  const getWebSiteLiveStatus = async () => {
    try {
      let liveStatusResponse = await wbApi.get(
        `/wb-dashboard/fetch-website-live-status/${company_id}/${template_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (liveStatusResponse.data.status === 1) {
        setLiveStatus(
          `${liveStatusResponse.data.website_live_status
            ? `Published on ${liveStatusResponse.data.website_live_status.live_date}`
            : `Publish`
          }`
        );
      } else {
        setLiveStatus("Publish");
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  // To Make Website Live
  const makeWebSiteLive = async () => {
    try {
      let liveStatusResponse = await wbApi.post(
        `/wb/publish-website`, {
        api_key: companyApiKey
      },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (liveStatusResponse.data.status === 1) {
        toast.success(liveStatusResponse.data.message)
        getWebSiteLiveStatus()
      } else {
        toast.error(liveStatusResponse.data.message);
        // setLiveStatus("Publish");
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Get All Hotels By Company Id
  const getAllHotels = async () => {
    try {
      let allHotelRes = await wbApi.get(
        `/update-hotel-data/get-hotel-id/${company_id}`
      );

      if (allHotelRes.status === 200) {
        setAllHotels(allHotelRes.data);
      } else {
        setAllHotels([]);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Helper function to handle logo and favicon
  const accpLogoFiles = (value: any) => {
    setlogoFiles(value);
  };
  const accpfavLogoFiles = (value: any) => {
    setFavicon(value);
  };

  // Api Call To Upload Logo
  const postLogoApiCall = async () => {
    const sizeCheck = logofiles.filter((i) => i.size > 1024 * 1024);
    if (sizeCheck.length === 0) {
      try {
        let payloadLogo = new FormData();
        logofiles.forEach((image: any) => {
          payloadLogo.append("uploadFile[]", image);
        });
        let logoresponse = await wbApi.post(
          `/wb/insert-logo/${company_id}/${template_id}/logo`,
          payloadLogo
        );

        if (logoresponse.data.status == 1) {
          return logoresponse.data.image_ids;
        } else {
          return [];
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        return [];
      }
    } else {
      sizeCheck?.map((iteam: any) =>
        toast.error(`${iteam.name} is Greater than 1mb`)
      );
    }
  };

  // Api Call To Upload Favicon
  const postFaviconApiCall = async () => {
    const faviconsizeCheck = favicon.filter((i) => i.size > 1024 * 1024);
    if (faviconsizeCheck.length === 0) {
      try {
        let payloadFavicon = new FormData();
        favicon.forEach((image: any) => {
          payloadFavicon.append("uploadFile[]", image);
        });
        let faviconResponse = await wbApi.post(
          `/wb/insert-favicon/${company_id}/${template_id}/favicon`,
          payloadFavicon
        );

        if (faviconResponse.data.status == 1) {
          return faviconResponse.data.image_ids;
        } else {
          return [];
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        return [];
      }
    } else {
      faviconsizeCheck?.map((iteam: any) =>
        toast.error(`${iteam.name} is Greater than 1mb`)
      );
    }
  };

  // Api Call To Post Identity Data
  const postIdentityData = async (logo: any[], faviconLogo: any[]) => {
    setBtnLoader(true);
    try {
      let res = await wbApi.post(
        "/wb/insert-update-identity/" + company_id + "/" + template_id,
        {
          theme_color: themeColor,
          text_color: textColor,
          theme_font: themeFont,
          logo_alt: altText,
          schema_code: schemaCode,
          google_analytics: analyticScript,
          logo: logo ?? [],
          favicon: faviconLogo ?? [],
        }
      );

      if (res.data.status === 1) {
        toast.success(res.data.message);
        setBtnLoader(false);
        setRefreshLogoList(true);
        setRefreshFaviconList(true);
        fetchIdentityData();
        setlogoFiles([])
        setlogoFiles([])
      } else {
        toast.error(res.data.message);
        setBtnLoader(false);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setBtnLoader(false);

      if (error.response.status === 401) {
        Logout();
      }
    }
  };

  // Indentity Data Post
  const identitySaveHandler = async () => {
    try {
      let logoResp = [];
      let faviconResp = [];
      if (logofiles.length > 0) {
        logoResp = await postLogoApiCall();
      }
      if (favicon.length > 0) {
        faviconResp = await postFaviconApiCall();
      }
      await postIdentityData(logoResp, faviconResp);
    } catch {
      await postIdentityData([], []);
    }
  };

  // if (logoimageUploading.current && faviconimageUploading.current) {
  //   logoimageUploading.current.uploadImage?.();
  //   faviconimageUploading.current.uploadImage?.();
  // }

  const syncHandler = async () => {
    setSyncLoader(true);
    try {
      let syncRes = await wbApi.post(
        `/update-hotel-data/insert-update-tables`,
        {
          company_id: company_id,
          hotel_id: allHotels.map((iteam) => iteam.hotel_id),
        }
      );

      if (syncRes.data.status === 1) {
        toast.success(syncRes.data.message);
        setSyncLoader(false);
      } else {
        toast.error(syncRes.data.message);
        setSyncLoader(false);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setSyncLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <div>
          {/* Sub-Header */}
          <div className="wb-subheader">
            <div className="wb-subheader-title">Identity</div>
            <div className="publish-sync-btn-wrapper">
              {
                liveStatus.toLowerCase() === "publish" ? (
                  <div
                    className="wb-subheader-publish"
                    onClick={() => {
                      makeWebSiteLive();
                    }}
                  >
                    {liveStatus && (
                      <div className="wb-subheader-publish-text">
                        {liveStatus !== "Publish" && (
                          <i
                            className={` wb-subheader-publish-icon bi bi-check-all`}
                          ></i>
                        )}

                        <div className="publish">{liveStatus}</div>
                      </div>
                    )}
                  </div>

                ) : (
                  <div
                    className="wb-subheader-publish"
                    onClick={() => {
                      getWebSiteLiveStatus();
                    }}
                  >
                    {liveStatus && (
                      <div className="wb-subheader-publish-text">
                        <i
                          className={` wb-subheader-publish-icon bi bi-check-all`}
                        ></i>

                        <div className="publish">{liveStatus}</div>
                      </div>
                    )}
                  </div>
                )
              }

              <div>
                <FetchButton
                  className="fetch__btn"
                  icon="bi bi-arrow-repeat"
                  loader={syncloader}
                  handleClick={() => {
                    syncHandler();
                  }}
                >
                  Sync
                </FetchButton>
              </div>
            </div>
          </div>
          <div className="wb-divider"></div>

          {/* Main Content */}

          <CardWrapper className="identity-content">
            {/* Color */}
            <div className="color-selector-wrapper">
              <div className="text-color">
                <label className="label">Text Color</label>
                <ColorPicker
                  value={textColor}
                  icon="bi bi-pencil-square"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTextColor(e.target.value);
                  }}
                  id="my1"
                />
              </div>
              <div className="theme-color">
                <label className="label">Theme Color</label>
                <ColorPicker
                  value={themeColor}
                  icon="bi bi-pencil-square"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setThemeColor(e.target.value);
                  }}
                  id="my2"
                />
              </div>
            </div>
            {/* Font  */}
            <div className="font-alt-text-wrapper">
              <div className="theme-font">
                <label className="label">Theme Font</label>

                <InputSelectField
                  label="Select Font"
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setThemeFont(e.target.value);
                  }}
                  options={allfonts}
                  value={themeFont}
                />
              </div>
              <div className="alt-text">
                <label className="label">Alternate Text</label>
                <InputTextField
                  label="Alternate Text"
                  value={altText}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAltText(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* Logo */}
            <div className="logo-favicon-wrapper">
              <div className="logo-section">
                <label className="label">Upload Your Logo</label>
                {/* DropZone For Logo */}
                <DropzoneArea
                  id={"logo-drop"}
                  incomingfiles={logo}
                  dispatchFiles={(val) => accpLogoFiles(val)}
                  isDelete={(val) => deletResLogo(val)}
                  centerMesage={"Drag your photos or click here"}
                  acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
                  refresh={refreshLogoList}
                />
              </div>
              <div className="favicon-section">
                <label className="label">Upload Your Favicon</label>
                {/* Dropzone For Favicon */}
                <DropzoneArea
                  id={"favicon-drop"}
                  incomingfiles={faviconLogo}
                  dispatchFiles={(val) => accpfavLogoFiles(val)}
                  isDelete={(val) => deletResFavicon(val)}
                  centerMesage={"Drag your photos or click here"}
                  acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
                  refresh={refreshFaviconList}
                />
              </div>
            </div>
            {/* Scripts */}
            <div className="analytics-schema-wrapper">
              <div className="analytics">
                <label className="label">Enter Your Analytics Script</label>
                <textarea
                  className="form-control script-textarea"
                  id="exampleFormControlTextarea1"
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setanalyticScript(e.target.value);
                  }}
                  value={analyticScript}
                ></textarea>
              </div>
              <div className="schema">
                <label className="label">Enter Your Schema Code</label>
                <textarea
                  className="form-control script-textarea"
                  id="exampleFormControlTextarea1"
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setSchemaCode(e.target.value);
                  }}
                  value={schemaCode}
                ></textarea>
              </div>
            </div>
            {/* Button */}
            <div className="save-btn">
              <Button handleClick={identitySaveHandler} loader={btnloader}>
                Save
              </Button>
            </div>
          </CardWrapper>
        </div>
      )
      }
    </>
  );
};

export default Identity;
