import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";

interface IPromotionsSeoSliderProps {
  onClose: () => void;
  promotionsPageDataFetching: () => void;
  promotionsPageDetails: any;
}

const PromotionsSeoSlider: React.FC<IPromotionsSeoSliderProps> = ({
  onClose,
  promotionsPageDataFetching,
  promotionsPageDetails
}) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);


  // States
  const [seoTitel, setSeoTitel] = useState<any>("");
  const [prevSeoTitel, setPrevSeoTitel] = useState<any>("");
  const [seoDescription, setSeoDescription] = useState<any>("");
  const [prevseoDescription, setPrevSeoDescription] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    setSeoTitel(promotionsPageDetails?.seo_title);
    setPrevSeoTitel(promotionsPageDetails?.seo_title);
    setSeoDescription(promotionsPageDetails?.seo_description);
    setPrevSeoDescription(promotionsPageDetails?.seo_description);
  }, [promotionsPageDetails])

  // Post Seo Details
  const saveSeoDetails = async () => {
    if (prevSeoTitel !== seoTitel && prevseoDescription !== seoDescription) {
      let resTitle = await saveSeoTitle();
      let resDescription = await saveSeoDesc();
      if (resTitle.status === 1 && resDescription.status === 1) {
        toast.success("content updated sucessfully");
      } else {
        toast.error("content updation failed");
      }
    } else if (prevSeoTitel !== seoTitel) {
      saveSeoTitle().then((value) => {
        if (value.status === 1) {
          toast.success(value?.message);
        } else {
          toast.error(value?.message);
        }
      });
    } else if (prevseoDescription !== seoDescription) {
      saveSeoDesc().then((result) => {
        if (result.status === 1) {
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      });

    } else {
      toast.error("You have not done any changes");
    }


  };

  const saveSeoTitle = async () => {
    setLoader(true);
    try {
      let seoTitelReq = await wbApi.post("/wb/insert-update-seo-title", {
        id: promotionsPageDetails?.id ?? "undefined",
        template_id: template_id,
        company_id: company_id,
        seo_title: seoTitel,
        page_type: "Default",
        page_id: page_Id ?? "",
      });
      if (seoTitelReq.data.status === 1) {
        promotionsPageDataFetching();
        setLoader(false);
        onClose();
        return seoTitelReq.data;
      } else {
        promotionsPageDataFetching();
        setLoader(false);
        onClose();
        return seoTitelReq.data;
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  }
  const saveSeoDesc = async () => {
    setLoader(true);
    try {
      let seoDescriptionReq = await wbApi.post(
        "/wb/insert-update-seo-description",
        {
          id: promotionsPageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          seo_description: seoDescription,
          page_type: "Default",
          page_id: page_Id ?? "",
        }
      );
      if (seoDescriptionReq.data.status === 1) {
        promotionsPageDataFetching();
        setLoader(false);
        onClose();
        return seoDescriptionReq.data;
      } else {
        promotionsPageDataFetching();
        setLoader(false);
        onClose();
        return seoDescriptionReq.data;
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  }

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">SEO Title and Description</div>
      </div>
      <div className="height-64px"></div>

      <div className="seo-slider-inputfield-wrapper">
        <InputTextField
          label="Title"
          value={seoTitel}
          handleChange={(e) => {
            setSeoTitel(e.target.value);
          }}
        />
        <InputTextField
          label="Description"
          value={seoDescription}
          handleChange={(e) => {
            setSeoDescription(e.target.value);
          }}
          rows={3}
          multiline
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button handleClick={saveSeoDetails} loader={loader}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default PromotionsSeoSlider;
