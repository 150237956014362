import { GET_SELECTED_PROMOTION_DATA } from "../actionTypes";

const initialState = {
    title: '', id: "", details: '', background_color: ""
}

const PromotionDataReducer = (state = initialState, action: any) => {
    // console.log(action, 'reducer promo')
    switch (action.type) {
        case GET_SELECTED_PROMOTION_DATA:
            return { ...state, ...action.payload }


        default: return state
    }
}

export default PromotionDataReducer;