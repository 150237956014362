import Button from "../../../views/buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { Draggable } from "react-drag-reorder";
import SlidingPane from "react-sliding-pane";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";
import { useState, useEffect, useCallback } from "react";
import wbApi from "../../../API/wbApi";
import { updateStateAfterNewFacilityAdded, updateFacilityArr, takeFacilityName } from "../../../redux/actions/FacilityAction";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import AddFacilitiesSlider from "./AddFacilitiesSlider";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import AutoWidthCardForFacility from "../../../views/datacard/AutoWidthCardForFacility";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const FacilitiesHotelwise = () => {

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const data = useSelector(
    (state: RootState) => state.facilityData
  );
  const [defaultHotel, setDefaultHotel] = useState<any>();

  const [editOpen, setEditOpen] = useState(false);
  const [facilitesOpen, setFacilitiesOpen] = useState(false);
  const [allSelectedFacilities, setAllSelectedFacilitiesData] = useState<any>([])


  const dispatch = useDispatch();
  const navigate = useNavigate();



  const selectFacility = () => {
    setAllSelectedFacilitiesData({
      ...data.facilityArr.filter((obj: any) => {
        if (obj.hotel_id === data.hotel_id) return obj
      })[0]
    })
  }

  useEffect(() => {
    if (!data.hotel_id) return
    selectFacility()
  }, [data])

  const getFacilityDataApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-facilities-section-details/${company_id}/${template_id}/10`
      );
      if (resp.data.status === 1) {
        setDefaultHotel(resp.data.default_hotel);
      } else {
        setDefaultHotel({})
      }
    }
    catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    getFacilityDataApi();
  }, []);

  const deleteSelectedFacility = async (obj: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const payload = {
              company_id,
              template_id,
              id: obj.ID,
              hotel_id: obj.HOTEL_ID,
              facilities: obj.FACILITIES.filter((Name: any) => {
                if (Name !== obj.SELECTEDFACILITY) return Name
              })
            }
            const resp = await wbApi.post(`wb/insert-update-wb-facility`, payload)

            if (resp.data.status === 1) {
              toast.success(resp.data.message)
              dispatch(updateStateAfterNewFacilityAdded(nanoid()))

              const getResp = await wbApi.get(`wb-dashboard/fetch-wb-facilities-section-details/${company_id}/${template_id}/10`)
              dispatch(updateFacilityArr(getResp.data.hotel_facilities))
            }
            else {
              toast.error(resp.data.message)
            }
          }
          catch {
            toast.error(CATCH_MESSAGE)
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  }

  const makeDefault = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let response = await wbApi.post("/wb/make-hotel-default-for-section", {
        company_id: company_id,
        default_hotel_id: data?.hotel_id,
        id: e.target.checked === true ? defaultHotel?.id : "undefined",
        section_id: defaultSectionId,
        template_id: template_id,
      });
      if (response.data.status === 1) {
        toast.success(response.data.message);
        getFacilityDataApi();
      } else {
        toast.error(response.data.message);
      }
    } catch {
      toast.error(CATCH_MESSAGE)
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderServices = [...allSelectedFacilities?.facilities];
    let temp = orderServices[currentPos].selected_services_id;
    orderServices[currentPos].selected_services_id = orderServices[newPos].selected_services_id
    orderServices[newPos].selected_services_id = temp
    // setUpdateOrder(orderServices);
  };

  const DraggableRender = useCallback(() => {
    if (allSelectedFacilities?.facilities && allSelectedFacilities?.facilities?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {allSelectedFacilities?.facilities?.map((name: any, idx: any) => {
            return (
              <AutoWidthCardForFacility
                serviceName={name}
                handleClick={() => {
                  deleteSelectedFacility({
                    HOTEL_ID: allSelectedFacilities.hotel_id,
                    ID: allSelectedFacilities.id,
                    FACILITIES: allSelectedFacilities.facilities,
                    SELECTEDFACILITY: name
                  });
                }}
                editData={() => { dispatch(takeFacilityName(name)) }}
              />
            )
          })

          }
        </Draggable>
      );
    }
    return null;
  }, [allSelectedFacilities]);




  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: "Edit Facilites",
              href: `/section-default/edit-facility-section-details`
            },
            {
              label: `${data.hotel_name ? data.hotel_name : ''}`,
            }
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="wb-divider"></div>

      <div className="top__button__container">
        <div className="form-check default_label me-2">
          <label className="form-check-label mt-2" htmlFor="setDefault">
            Set as Default
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="setDefault"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeDefault(e)}
            checked={
              defaultHotel?.default_hotel_id === data?.hotel_id
            }
          ></input>
        </div>
        <Button
          icon="bi bi-plus"
          className="me-3"
          handleClick={() => {
            setFacilitiesOpen(true);
          }}
        >
          Add
        </Button>
      </div>

      <div className="auto__width__card__container">
        {allSelectedFacilities?.facilities?.length > 0 ? (
          <DraggableRender />
        ) : (
          <NoDataComponent message={"No Facilites Found"} />
        )}
      </div>

      {/* {allSelectedFacilities.facilities && allSelectedFacilities.facilities.length > 0 ?
        <div className="edit-contact-btn-container">
          <Button className="cancel__btn me-4">Cancel</Button>
          <Button className="savebtn">Save</Button>
        </div>
        : <></>} */}

      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={facilitesOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setFacilitiesOpen(false)}
      >
        <AddFacilitiesSlider
          onClose={() => {
            setFacilitiesOpen(false);
          }}
        />
      </SlidingPane>

      {/* <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditOpen(false)}
      >
        <EditFacilitiesSlider
          onClose={() => {
            setEditOpen(false);
          }}
        />
      </SlidingPane> */}
    </>
  );
};

export default FacilitiesHotelwise;
