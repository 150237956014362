import React, { useState } from "react";
import Button from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InputTextField from "../../../views/inputfield/InputTextField";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import { updateOffersArr } from "../../../redux/actions/OffersAction";
import wbApi from "../../../API/wbApi";
import { CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";

interface IAddOfferSliderProps {
  onClose: () => void;
}

const AddEventSlider: React.FC<IAddOfferSliderProps> = ({ onClose }) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const { property_data, current_property } = useSelector(
    (state: RootState) => state.properties
  );

  const data = useSelector(
    (state: RootState) => state.offersSect
  );

  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [eventImageFile, setEventImageFile] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);


  const [update, setUpdate] = useState('')

  const dispatch = useDispatch();

  const accpAboutFiles = (value: any) => {
    setEventImageFile(value);
  };

  function saveHandler() {
    if (eventImageFile.length) {
      uploadHandler();
    } else {
      AddOfferApi("undefined");
    }
  }

  const uploadHandler = async () => {
    if (eventImageFile.length === 1) {


      let payload = new FormData();

      payload.append("uploadFile[0]", eventImageFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/offer_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await AddOfferApi(uploadRes.data.image_ids);

        } else {
          toast.error(uploadRes.data.message);

        }
      } catch (error: any) {

        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }

    } else {
      toast.error("Only One Image Allowed");
    }

  };

  const AddOfferApi = async (ImgId: any) => {
    setLoader(true);
    try {
      const payload = {
        company_id,
        offer_description: description,
        offer_image: ImgId ?? 'undefined',
        offer_image_alt: altText,
        offer_title: title,
        hotel_id: data.hotel_id,
        id: 'undefined',
        template_id,
      }
      const resp = await wbApi.post(`wb/insert-update-wb-offer`, payload)

      if (resp.data.status === 1) {
        toast.success(resp.data.message)
        const getResp = await wbApi.get(`wb-dashboard/fetch-wb-offer-section-details/${company_id}/${template_id}/${defaultSectionId}`)
        dispatch(updateOffersArr(getResp.data.hotel_offers))
        onClose()
        setLoader(false);
      }
      else {
        toast.error(resp.data.message)
        setLoader(false);
        onClose()
      }
    }
    catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE)
    }
  }




  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Offer</div>
      </div>

      <div className="height-64px"></div>
      <InputTextField
        label="Offer Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => { setTitle(e.target.value) }}
      />
      <InputTextField
        label="Alternate Text"
        value={altText}
        className="description description__slider"
        handleChange={(e) => { setAltText(e.target.value) }}
      />

      <div className="mb-2">Select Offer Image</div>

      <DropzoneArea
        centerMesage={"Drag your photos or click here(1 Image Only)"}
        // incomingfiles={eventIncomingImageFile}
        dispatchFiles={(val) => accpAboutFiles(val)}
        // isDelete={(val) => delteAboutFiles(val)}
        acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
      // refresh={refreshList}
      />

      <div className="__default--input--field mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn" handleClick={() => onClose()}>Cancel</Button>
          <Button handleClick={() => saveHandler()}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default AddEventSlider;
