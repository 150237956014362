import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextField from "../../../views/inputfield/InputTextField";
import Hotel from "../../../assests/img/hotel-image.png";
import SlidingPane from "react-sliding-pane";
import AddCategorySlider from "./AddCategorySlider";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import {
  GalleryCategoryAction,
  GalleryDataAction,
} from "../../../redux/actions/SectionAction";
import { RootState } from "../../../redux/store";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WbPageCard from "../../../views/datacard/WbPageCard";
import { CATCH_MESSAGE, getImage, Logout } from "../../../UtilityFunctions";
import { getSeletedGallaryData } from "../../../redux/actions/GallaryActions";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import Button from "../../../views/buttons/Button";

const EditGallery = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  //  States
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [sectId, setSectId] = useState<any>("undefined");
  const [galleryDataArray, setGalleryDataArray] = useState([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  // const [galleryId, setGalleryId] = useState<any>("");
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const [galleryBannerImg, setGalleryBannerImg] = useState<any>();
  const [galleryFile, setGalleryFile] = useState<any[]>([]);
  const [gallerySecDetails, setGallerySecDetails] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  // Data Fetching
  const fetchSection = async () => {
    let defaultId;
    if (defaultSectionId.length) {
      defaultId = defaultSectionId;
    } else {
      defaultId = 1;
    }
    try {
      let resp = await wbApi.get(
        `/wb-dashboard/fetch-wb-gallery-section-details/${company_id}/${template_id}/${defaultId}`
      );
      if (resp.data.status === 1) {
        setCategoryList(resp?.data?.category_list);
        setGalleryDataArray(resp?.data?.gallery_data);
        if (resp?.data?.gallery_section_details) {
          setAltText(resp?.data?.gallery_section_details?.background_image_alt);
          setTitle(resp?.data?.gallery_section_details?.section_title);
          setDescription(resp?.data?.gallery_section_details?.section_description);
          setSectId(resp?.data?.gallery_section_details?.id);
          setGallerySecDetails(resp?.data?.gallery_section_details);
          if (resp?.data?.gallery_section_details?.background_image) {
            setGalleryBannerImg([
              getImage(resp?.data?.gallery_section_details?.background_image),
            ]);
          } else {
            setGalleryBannerImg([]);
          }
        }
      } else {
        setSectId("undefined");
        setCategoryList([]);
        setGalleryDataArray([]);
      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error)
    }
  };
  useEffect(() => {
    fetchSection();
  }, []);

  const updateGallaryDetails = async (imgId: any) => {
    setLoader(true)
    try {
      const payload = {
        background_image: imgId ?? "undefined",
        background_image_alt: altText,
        company_id: company_id,
        id: sectId ?? "undefined",
        section_description: description,
        section_id: defaultSectionId,
        section_title: title,
        template_id: template_id,
      };
      const resp = await wbApi.post(
        `wb/insert-update-default-section-details`,
        payload
      );
      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        fetchSection();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(resp.data.message);
      }
    } catch (error: any) {
      setLoader(false);
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  const accpGalleryFiles = (value: any) => {
    setGalleryFile(value);
  };

  const deleteGalleryFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteGalleryFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteGalleryFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: gallerySecDetails?.background_image,
          section_id: defaultSectionId,
          template_id: template_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSection();
      } else {
        toast.error(deleteRes.data.message);
        fetchSection();
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  // Save handler
  const saveHandler = async () => {
    if (galleryFile.length) {
      uploadHandler();
    } else {
      updateGallaryDetails("undefined");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {
    setLoader(true);
    if (galleryFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", galleryFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/gallery_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await updateGallaryDetails(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch (error: any) {
        setLoader(false);
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Edit Gallery`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*------------------ Main Section---------------- */}
      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            setCategoryOpen(true);
          }}
        >
          Add Category
        </Button>
      </div>

      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>
        <div className="edit-promo-alt-label">Select Back Ground Image</div>
        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={galleryBannerImg}
            dispatchFiles={(val) => accpGalleryFiles(val)}
            isDelete={(val) => deleteGalleryFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div className="mt-4 sec-description-editor">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>
        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>

          <Button
            className="savebtn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">Select Category</div>

      <div className="option_selection_card_wrapper mt-3">
        {categoryList.length > 0 ?
          categoryList?.map((item: any) => {
            const { category_name, id } = item;
            const galleryCategoryImages = galleryDataArray?.filter((obj: any) => obj.category.toLowerCase() === category_name.toLowerCase());
            return (
              <div
                key={item.id}
                className="selection_image_wrapper"
                onClick={() => {
                  navigate(`/section-default/edit-gallery-data`);
                  dispatch(
                    getSeletedGallaryData({
                      category_name,
                      id,
                      galleryCategoryImages,
                    })
                  );
                  dispatch(GalleryCategoryAction(item.category_name));
                }}
              >
                <div className="image__container">
                  <i className="bi bi-image"></i>
                </div>
                <div className="hotel__name">{item.category_name}</div>
              </div>
            );
          }) : (
            <NoDataComponent message={"No Category Found"} />
          )}
      </div>

      <div className="sixtypx-height"></div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={categoryOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setCategoryOpen(false)}
      >
        <AddCategorySlider
          onClose={() => {
            setCategoryOpen(false);
          }}
          categoryList={categoryList}
          fetchSection={fetchSection}
        />
      </SlidingPane>
    </>
  );
};

export default EditGallery;
