import { UPDATE_PROMOTION, GET_SELECTED_PROMOTION_DATA } from "../actionTypes";

export const getSeletedPromotionData = (data: any) => {
    return (
        {
            type: GET_SELECTED_PROMOTION_DATA,
            payload: data,
        }
    )
}
// export const emptyBlogData = () => {
//     return (
//         {
//             type: EMPTY_BLOG_DATA,
//             // payload: data
//         }
//     )
// }
export const updateStateAfterNewAddedPromotion = (data: any) => {
    return (
        {
            type: UPDATE_PROMOTION,
            payload: data
        }
    )
}

