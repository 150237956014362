import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import {
  CustomSectionAction,
  InuseSectionAction,
} from "../../redux/actions/SectionAction";
import wbApi from "../../API/wbApi";
import WbSectionCard from "../../views/datacard/WbSectionCard";

const CustomSection = () => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { customSection, inuseSection } = useSelector(
    (state: RootState) => state.sections
  );

  // States
  const [noOfPages, setNoOfPages] = useState(1);
  const [customSectionState, setCustomSectionsState] = useState<any[]>([]);
  const [inUseSecId, setInUseSecId] = useState<any[]>([]);

  // Variables

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(template_id, 'template_id')
  // All Section Fetching
  const fetchAllSection = async () => {
    let allSectionRes = await wbApi.get(
      "/wb-dashboard/fetch-all-default-custom-sections/" +
      company_id +
      "/" +
      "2",
      // template_id
    );
    // console.log("allSectionRes", allSectionRes);
    if (allSectionRes.data.status === 1) {
      // console.log(allSectionRes.data);
      setCustomSectionsState(allSectionRes.data.custom_sections);
      dispatch(CustomSectionAction(allSectionRes.data.custom_sections));
    }
  };

  // Fetch Inuse Pages

  const fetchInUseSection = async () => {
    let inUseSecRes = await wbApi.get(
      "/wb-dashboard/fetch-in-use-sections/" + company_id + "/" + "2"
      // template_id
    );

    // console.log("inUseSecRes", inUseSecRes);
    if (inUseSecRes.data.status === 1) {
      dispatch(InuseSectionAction(inUseSecRes.data.in_use_sections));
    }
  };
  useEffect(() => {
    fetchAllSection();
    fetchInUseSection();
  }, []);

  // Inuse Section Id Extraction
  const inUse: React.SetStateAction<any[]> = [];
  useEffect(() => {
    inuseSection.map((obj: any) => {
      inUse.push(obj.section_id);
    });
    setInUseSecId(inUse);
  }, []);

  return (
    <div>
      {/* Sub Header */}
      <div className="wb-subheader">
        <div className="wb-subheader-title">Sections</div>
        <div className="wb-subheader-rightsec">
          <div className="header-footer-toggle">
            <ul className="nav nav-tabs">
              <li className="nav-item" role="presentation">
                <a
                  onClick={() => navigate("/section-default")}
                  className="nav-link"
                  id="default"
                  data-bs-toggle="tab"
                  data-bs-target="list"
                  aria-controls="list"
                  role="tab"
                  aria-selected="true"
                >
                  DEFAULT
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="custom"
                  data-bs-toggle="tab"
                  data-bs-target="crs"
                  aria-controls="crs"
                  role="tab"
                  aria-selected="true"
                >
                  CUSTOM
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/* Main Section */}

      <div className="mt-4">
        <div className="row page-main-section">
          {customSectionState?.map((sec: any, idx: any) => {
            return (
              <div key={idx} className=" col-3 page-default-section ">
                <WbSectionCard
                  key={sec.id}
                  update="Last Updated- 2days ago"
                  isInUse={inUseSecId.includes(sec.id)}
                  onEditClick={() => {
                    navigate(`/page-default/${sec.section_routes}`);
                  }}
                >
                  {sec.section_names}
                </WbSectionCard>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomSection;
