import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import kernelApi from "../../API/kernelApi";
import { RootState } from "../../redux/store";
import { CATCH_MESSAGE, Logout } from "../../UtilityFunctions";

const WebBuilderHeader = () => {
  const { hotel_name, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const [hotelName, setHotelName] = useState<string>("");

  useEffect(() => {
    getHotelDetails();
  }, []);
  // Api To Get Hotel Name
  const getHotelDetails = async () => {
    try {
      let hotelRes = await kernelApi.get(
        `/extranetv4/superadmin-dashboard/retrive-details/${company_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (hotelRes.data.status === 1) {
        setHotelName(hotelRes.data.details[0].hotel_name);
      } else {
        setHotelName("");
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      }
    }
  };
  return (
    <div className="wb-header d-flex">
      {/* <PreviewButton className="wb-header-preview" icon="bi bi-play">
        Preview Website
      </PreviewButton> */}
      {/* <div className="notification__wrapper">
        <i className="bi bi-bell notification--icon"></i>
      </div> */}
      {/* <ManageDropdown/> */}
      <div className="ms-2 header_hotel_name">
        <i className="bi bi-building me-2"></i>
        {hotel_name
          ? `${hotel_name}(${hotel_id})`
          : `${hotelName}(${hotel_id})`}
      </div>
    </div>
  );
};

export default WebBuilderHeader;
