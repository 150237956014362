import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import Card from "../../../views/datacard/Card";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import InputTextField from "../../../views/inputfield/InputTextField";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { Capitalize, CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import CardWrapper from "../../../views/datacard/CardWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";

const EditTestimonial = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const navigate = useNavigate();

  // States
  const [testimonialBannerId, setTestimonialBannerId] = useState<any>();
  const [testimonialSectionDetails, setTestimonialSectionDetails] =
    useState<any>([]);
  const [hotelTestimonial, setHotelTestimonial] = useState<any>([]);
  const [allHotelsData, setAllHotelsData] = useState<any>([]);
  const [testimonialBannerImg, setTestimonialBannerImg] = useState<any>([]);
  const [testimonialFile, setTestimonialFile] = useState<any[]>([]);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const accpTestimonialFiles = (value: any) => {
    setTestimonialFile(value);
  };
  const dispatch = useDispatch();


  const deleteTestimonialFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteTestimonialFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteTestimonialFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: testimonialSectionDetails.background_image,
          section_id: defaultSectionId,
          template_id: template_id,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        testimonialApi();
      } else {
        toast.error(deleteRes.data.message);
        testimonialApi();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const saveHandler = () => {
    if (testimonialFile.length) {
      uploadHandler();
    } else {
      addUpdateTestimonial("undefined");
    }
  };

  const uploadHandler = async () => {
    if (testimonialFile.length === 1) {
      setLoader(true);
      let payload = new FormData();
      payload.append("uploadFile[0]", testimonialFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/testimonial_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateTestimonial(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only one Image Allowed");
    }

  };

  // Update ContactUs Section
  const addUpdateTestimonial = async (testimonialBannerImgId: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-default-section-details", {
        id: testimonialBannerId ?? "undefined",
        background_image: testimonialBannerImgId,
        background_image_alt: altText ?? "",
        company_id: company_id,
        section_id: defaultSectionId,
        section_title: title ?? "",
        template_id: template_id,
        section_description: description ?? "",
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        testimonialApi();
        setLoader(false);
      } else {
        toast.error(req.data.message);
        testimonialApi();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  const testimonialApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-testimonial-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setHotelTestimonial(resp?.data?.hotel_testimonials);
        setAllHotelsData(resp?.data?.all_hotels_data);
        setTestimonialSectionDetails(resp?.data?.testimonial_section_details);
        setTestimonialBannerId(resp?.data?.testimonial_section_details?.id);
        setTitle(resp?.data?.testimonial_section_details?.section_title);
        setAltText(resp?.data?.testimonial_section_details?.background_image_alt);
        if (resp?.data?.testimonial_section_details?.background_image) {
          setTestimonialBannerImg([
            getImage(resp?.data?.testimonial_section_details?.background_image),
          ]);
        } else {
          setTestimonialBannerImg([]);
        }
        setDescription(
          resp?.data?.testimonial_section_details?.section_description ?? ""
        );
      } else {
        setHotelTestimonial([]);
        setAllHotelsData([]);
        setTestimonialSectionDetails([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  useEffect(() => {
    testimonialApi();
  }, []);

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: "Edit Testimonial",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>
        <div className="edit-promo-alt-label">Select Back Ground Image</div>
        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={testimonialBannerImg}
            dispatchFiles={(val) => accpTestimonialFiles(val)}
            isDelete={(val) => deleteTestimonialFiles(val)}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      <div className="select__hotel__label">Select Hotels</div>
      <div className="default_section_image_card_container mt-3">
        {allHotelsData?.length > 0 ? (
          allHotelsData?.map((item: any) => (
            <div
              key={item.hotel_id}
              className="default__setion__image"
              onClick={() => {
                navigate(
                  `/section-default/edit-testimonial-section-details/edit-testimonial-hotels`, {
                  state: {
                    hotel_id: item.hotel_id,
                    hotel_name: item.hotel_name
                  }
                }
                );
                // navigate(`/section-default/${sec.section_routes}`);
                // dispatch(DefaultSectionIdAction(sec.id));
                // console.log("sec.id : ", sec.id);
              }}
            >
              <div className="image__container">
                {
                  item?.hotel_image ? (
                    <img
                      src={item.hotel_image}
                      alt={item.hotel_name}
                      className="default__setion__image__image"
                    />
                  ) : (
                    <div className="image_container_icon_wrapper">
                      <FontAwesomeIcon icon={Icons.faHotel} size="2x" color="blue" />
                    </div>

                  )
                }
                <div className="hotel__name">{Capitalize(item.hotel_name)}</div>
              </div>
            </div>
          ))
        ) : (
          <NoDataComponent message={"No Hotels Found"} />
        )}
      </div>
      <div className="sixtypx-height"></div>
    </>
  );
};

export default EditTestimonial;
