import React from 'react'
import { Link } from 'react-router-dom';

interface IProps {
    content: { label: string, href?: string }[];
}

const CustomBreadcrumbs: React.FC<IProps> = ({ content }) => {
    return (
        <div className="section__heading">
            {content?.map((breadcrumb, index: number) => {
                return (
                    <React.Fragment key={index}>
                        {breadcrumb.href ?
                            <Link to={breadcrumb.href}>
                                <div
                                    className={`${index !== content.length - 1
                                        ? "breadcrumbs__labels"
                                        : "breadcrumbs__labels_second"
                                        }`}>
                                    {breadcrumb?.label}
                                </div>
                            </Link> :
                            <div
                                className={`${index !== content.length - 1
                                    ? "breadcrumbs__labels"
                                    : "breadcrumbs__labels_second"
                                    }`}>
                                {breadcrumb?.label}
                            </div>
                        }
                        {index !== content.length - 1 && (
                            <div className="breadcrumbs__arrows">
                                <i className="bi bi-chevron-right"></i>
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    )
}

export default CustomBreadcrumbs