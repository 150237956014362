import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import Button from "../../../views/buttons/Button";
import { toast } from "react-toastify";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";

interface IOffersSectionSliderProps {
  onClose: () => void;
  offersPageDataFetching: () => void;
  allsec: any[];
  offerPageDetails: any;
}

const OffersSectionSlider: React.FC<IOffersSectionSliderProps> = ({
  onClose,
  offersPageDataFetching,
  allsec,
  offerPageDetails
}) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [checkedItem, setCheckedItem] = useState<any[]>([]);

  useEffect(() => {
    setCheckedItem(offerPageDetails?.page_section ?? []);
  }, []);

  useEffect(() => {
    setFilteredResults(allsec);
  }, []);

  // Hanadle Checked Sections
  const checkedSection = (e: React.ChangeEvent<HTMLInputElement>, sec: any) => {
    if (checkedItem) {
      let checkeds = [...checkedItem];

      if (
        checkedItem.length > 1 &&
        checkedItem.some((item: any) => {
          return item?.section_names === sec.section_names;
        })
      ) {
        checkeds = checkedItem?.filter(
          (name: any) => name?.section_names !== sec.section_names
        );
      } else {
        checkeds.push(sec);
      }
      setCheckedItem(checkeds);
      // console.log(checkeds, "checkeds");
    }
    // let filterResult = filteredResults.map((item=>{
    //   if(item.id === sec.id){
    //     item.isChecked = !item.isChecked
    //   }
    //   return item;
    // }))
    // setFilteredResults(filterResult)
  };

  // Selected Section Post
  const postSelectedItem = async () => {
    // const pageSec=filteredResults.filter((i)=>{
    //   return i.isChecked===true;
    // })
    setLoader(true);
    try {
      let postSecReq = await wbApi.post("/wb/insert-update-page-section", {
        id: offerPageDetails?.id ?? "undefined",
        template_id: template_id,
        company_id: company_id,
        page_section: checkedItem,
        page_type: "Default",
        page_id: page_Id ?? "",
      });
      if (postSecReq.data.status === 1) {
        toast.success(postSecReq.data.message);
        offersPageDataFetching();
        setLoader(false);
        onClose();
      } else {
        toast.error(postSecReq.data.message);
        offersPageDataFetching();
        setLoader(false);
        onClose();
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = allsec?.filter((item: any) => {
        return item.section_names
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allsec);
    }
    setSearchInput(keyword);
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Select Section</div>
      </div>
      <div className="height-64px"></div>
      <div className="search__booking__wrapper">
        <i className="bi bi-search"></i>
        <input
          placeholder="Search Section"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }}
        />
      </div>

      {filteredResults?.map((sec: any) => (
        <>
          <div key={sec.id} className="select__section__wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <div className="select__section__content">
                {sec.section_names}
              </div>
            </div>
            <div className="select__section__checkbox">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={
                    checkedItem &&
                    checkedItem.some((item: any) => {
                      return item.section_names === sec.section_names;
                    })
                  }
                  onChange={(e) => {
                    checkedSection(e, sec);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ))}

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            handleClick={() => {
              postSelectedItem();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default OffersSectionSlider;
