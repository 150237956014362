import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import wbApi from "../../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { servicePageAction } from "../../../redux/actions/PageAction";
import { RootState } from "../../../redux/store";
import PageAboutSectionCard from "../../../views/datacard/RectangleSectionCard";
import { Draggable } from "react-drag-reorder";
import SlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import CardWrapper from "../../../views/datacard/CardWrapper";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";
import Loader from "../../../views/loader/Loader";
import ServiceSeoSlider from "./ServicesSeoSlider";
import ServiceSectionSlider from "./ServicesSectionSlider";
import ServicePageTitleSlider from "./ServicesPageTitleSlider";

const ServicesPage = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);

  // console.log(servicePageData, 'servicePageData')

  // States
  const [openTitleSlide, setOpenTitleSlide] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openSeoSlide, setOpenSeoSlide] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [allsec, setAllSec] = useState<any[]>([]);
  const [servicePageSec, setServicePageSec] = useState<any[]>([]);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [servicePageDetails, setServicePageDetails] = useState<any>({});

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log(servicePageData, 'servicePageData')

  useEffect(() => {
    servicePageDataFetching();
  }, [company_id, template_id]);

  // Fetching About Page Data

  const servicePageDataFetching = async () => {
    setLoader(true);

    try {
      let servicePageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-services-page-details/${company_id}/${template_id}/${page_Id}`
      );
      if (servicePageRes?.data.status === 1) {
        dispatch(servicePageAction(servicePageRes?.data));
        setServicePageDetails(servicePageRes?.data?.services_page_details)
        // console.log(servicePageRes?.data, 'servicePageRes?.data')
        const aboutPageAllSec = servicePageRes?.data?.all_sections?.map(
          (item: any) => {
            return { ...item, isChecked: false };
          }
        );
        setAllSec(aboutPageAllSec);
        setServicePageSec(servicePageRes?.data?.services_page_details?.page_section ?? []);
        setLoader(false);
      } else {
        setAllSec([]);
        setServicePageSec([]);
        setLoader(false);
      }
    }
    catch (error) {
      console.log("error", error)
      // toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Api To ReOrder
  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: servicePageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateOrder,
          page_type: "Default",
          page_id: page_Id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setDragStatus(false);
        servicePageDataFetching();
        setSaveLoader(false);
      } else {
        toast.error(orderReq.data.message);
        servicePageDataFetching();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setSaveLoader(false);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...servicePageSec];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setDragStatus(true);
    setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };

  const [dragStatus, setDragStatus] = useState(false);

  // useEffect used to capture the section order if drag action is not triggered.
  useEffect(() => {
    if (!dragStatus) {
      setUpdateOrder(servicePageSec)
    }
  }, [servicePageSec, dragStatus])

  // Draggable Handler
  // console.log(servicePageSec, 'servicePageSec')

  const DraggableRender = useCallback(() => {
    if (servicePageSec && servicePageSec.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {servicePageSec.map((sec: any, idx: any) => {
            return (
              <div className="section-card-wrapper" key={sec.id}>
                <PageAboutSectionCard
                  onDelet={() => {
                    deleteHandler(idx);
                  }}
                  onEdit={() => {
                    navigate(`/section-default/${sec.section_routes}`);
                  }}
                >
                  {sec.section_names}
                </PageAboutSectionCard>
              </div>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [servicePageSec]);

  // Section Delet Handler
  const deleteHandler = (index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletSecHandler(index);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  const deletSecHandler = (index: any) => {
    let servcPageSections = servicePageDetails?.page_section;
    servcPageSections.splice(index, 1);
    deletAboutSecDetails(servcPageSections);
  };
  // Delet Section Post
  const deletAboutSecDetails = async (updateSec: any) => {
    try {
      let deletAboutSecReq = await wbApi.post(
        "/wb/insert-update-page-section",
        {
          id: servicePageDetails?.id ?? "undefined",
          template_id: template_id,
          company_id: company_id,
          page_section: updateSec ?? [],
          page_type: "Default",
          page_id: page_Id ?? "",
        }
      );
      // console.log("deletHomeSecReq", deletAboutSecReq);
      if (deletAboutSecReq.data.status === 1) {
        toast.success(deletAboutSecReq.data.message);
        servicePageDataFetching();
      } else {
        toast.error(deletAboutSecReq.data.message);
        servicePageDataFetching();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };




  return (
    <>
      <div>
        {/* Sub-Header */}
        <div className="wb-subheader">
          {/* BreadCrumbs */}
          <CustomBreadcrumbs
            content={[
              {
                label: "Pages",
                href: "/pages-default",
              },
              {
                label: `Default Pages`,
                href: "/pages-default",
              },
              {
                label: "Services",
              },
            ]}
          />

          <div className="wb-subheader-rightsec">
            <Button
              icon="bi bi-binoculars"
              handleClick={() => {
                setOpenSeoSlide(true);
              }}
            >
              SEO
            </Button>
            <Button
              icon="bi bi-info-circle"
              handleClick={() => {
                setOpenTitleSlide(true);
              }}
            >
              Page Title
            </Button>
            <Button
              icon="bi bi-arrow-left"
              className="bck-btn"
              handleClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="wb-divider"></div>
      </div>
      {/* Main Section */}

      {loader ? (
        <div className="middle-loader">
          <Loader />
        </div>
      ) : (
        <>
          <div className=" mt-4 banner-img-wrapper">
            {servicePageDetails !== null ? (
              servicePageDetails?.banner ? (
                <img
                  src={`${getImage(servicePageDetails?.banner)}`}
                  alt={servicePageDetails?.banner_alt}
                  className="banner-img"
                />
              ) : servicePageDetails?.video_banner ? (
                <video width="100%" height="300" controls autoPlay>
                  <source
                    src={`${getImage(servicePageDetails?.video_banner)}`}
                  />
                </video>
              ) : servicePageDetails?.video_banner_link ? (
                <iframe
                  src={servicePageDetails?.video_banner_link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  height="100%"
                  width="100%"
                ></iframe>
              ) : (
                <NoDataComponent message="No Banner Found" />
              )
            ) : (
              <NoDataComponent message="No Data Found" />
            )}

            <div
              className="edit-btn-banner"
              onClick={() => {
                navigate("edit-services");
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </div>
            {/* <div className="banner-titel">
              <div className="b-titel-text">
                {servicePageDetails?.banner_title ? (
                  <div className="">{servicePageDetails?.banner_title}</div>
                ) : (
                  <div className="no-banner-titel">
                    TITLE & DESCRIPTION OF THE SERVICES PAGE
                  </div>
                )}
              </div>
            </div> */}
          </div>

          <CardWrapper className="mt-4 about-add-sec-card">
            <Button
              icon="bi bi-plus-lg"
              className="section-btn"
              handleClick={() => {
                setOpen(true);
              }}
            >
              Select Section
            </Button>
          </CardWrapper>

          {/* For Section */}

          <CardWrapper className=" mt-4 mb-4 home-section-save-wrapper">
            {servicePageSec.length > 0 ? (
              <>
                <DraggableRender />
                <Button
                  className="save-btn"
                  handleClick={() => {
                    orderSaveHandler();
                  }}
                  loader={saveLoader}
                >
                  Save
                </Button>
              </>

            ) : (
              <NoDataComponent message="No Section Found" />
            )}


          </CardWrapper>
        </>
      )}

      {/* For Seo */}

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openSeoSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSeoSlide(false)}
      >
        <ServiceSeoSlider
          onClose={() => {
            setOpenSeoSlide(false);
          }}
          servicePageDataFetching={servicePageDataFetching}
          servicePageDetails={servicePageDetails}
        />
      </SlidingPane>

      {/* For Title */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={openTitleSlide}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenTitleSlide(false)}
      >
        <ServicePageTitleSlider
          onClose={() => {
            setOpenTitleSlide(false);
          }}
          servicePageDataFetching={servicePageDataFetching}
          servicePageDetails={servicePageDetails}
        />
      </SlidingPane>

      {/* For Section */}
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <ServiceSectionSlider
          onClose={() => {
            setOpen(false);
          }}
          servicePageDataFetching={servicePageDataFetching}
          allsec={allsec}
          servicePageDetails={servicePageDetails}
        />
      </SlidingPane>
    </>
  );
};

export default ServicesPage;
