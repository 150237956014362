import { GET_SELECTED_CONTACT_DATA } from "../actionTypes";

const initialState = {
    landline_no: '',
    mobile_no: '',
    whatsapp_no: '',
    display_email: '',
    display_description: '',
    id: '',
    address_name: ''
}

const ContactDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SELECTED_CONTACT_DATA:
            return {
                ...action.payload,
            }



        default: return state
    }
}

export default ContactDataReducer;