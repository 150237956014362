import { current } from "@reduxjs/toolkit";
import React, { useRef } from "react";
export interface IColorPickerProps {
  value?: string;
  icon?: string;
  className?: string;
  id?: any;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ColorPicker: React.FC<IColorPickerProps> = ({
  value,
  icon,
  className,
  id,
  handleChange,
}) => {
  // const inputRef = useRef<HTMLInputElement>(null);
  const onClick = () => {
    // console.log(inputRef);
    // inputRef.current?.focus();
    document.getElementById(id)?.click();
  };
  return (
    <div className={`colr-pickr-wrapper ${className}`}>
      <div className="color-picker-box">
        <div className="colr-picker-value">{value}</div>
        <input
          className={`colr-picker`}
          id={id}
          // ref={inputRef}
          type="color"
          value={value}
          onChange={handleChange}
          placeholder={value}
        />
      </div>

      <div className="colr-picker-icon-wrapper">
        <i className={`picker-icon ${icon}`} onClick={onClick}></i>
      </div>
    </div>
  );
};

export default ColorPicker;
