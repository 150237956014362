import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlternateText from "../../views/inputfield/InputTextField";
// import ImageDropzone from '../identity/ImageDropzone';
import DescriptionText from "../../views/inputfield/InputTextField";
// Importing Buttons
import Button from "../../views/buttons/Button";
// Importing Ckeditor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import wbApi from "../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../views/datacard/CardWrapper";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import { CATCH_MESSAGE, getImage, Logout } from "../../UtilityFunctions";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../redux/actions/PromptAction";

const EditPromotionalBanner = () => {
  // Data From Redux 

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  //States
  const [loader, setLoader] = useState<boolean>(false);
  const [altText, setAltText] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [promoBannerData, setPromoBannerData] = useState<any>("");
  const [promoBannerImg, setPromoBannerImg] = useState<any[]>([]);
  const [promoBannerId, setPromoBannerId] = useState<any>("");
  const [defaultValueId, setDefaultValueId] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<boolean>(false);
  const [valueCheck, setValueCheck] = useState<any>("");
  const [promofiles, setPromoFiles] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [promotionalNotification, setPromotionalNotification] =
    useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  // Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const accpPromoFiles = (value: any) => {
    setPromoFiles(value);
  };

  const deltePromoFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deltePromoFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );

    }
  };

  useEffect(() => {
    fetchSection();
  }, []);

  // Promotion Banner Fetching

  const fetchSection = async () => {
    try {
      let SectionRes = await wbApi.get(
        `/wb-dashboard/fetch-promo-banner-details/${company_id}/${template_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (SectionRes.data.status === 1) {
        setPromoBannerData(SectionRes.data);
        if (SectionRes.data.promo_news_default_value) {
          setDefaultValueId(
            SectionRes.data.promo_news_default_value.default_value
          );
        } else {
          setDefaultValueId("");
        }
        if (SectionRes.data.promotional_notification) {
          setPromoBannerId(SectionRes.data.promotional_notification.id);
          setAltText(SectionRes.data.promotional_notification.image_alt);
          setDescription(SectionRes.data.promotional_notification.description);
          if (SectionRes.data.promotional_notification.image) {
            setPromoBannerImg([
              getImage(SectionRes.data.promotional_notification.image),
            ]);
          } else {
            setPromoBannerImg([]);
          }
        } else {
          setPromoBannerId("");
          setAltText("");
          setDescription("");
          setPromoBannerImg([]);
        }
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  // Setting Default
  const makePromoDefault = async (e: any) => {
    try {
      let req = await wbApi.post("/wb/make-promo-newsletter-default", {
        company_id: company_id,
        template_id: template_id,
        default_value: e.target.checked ? "promotional" : "",
      });
      // console.log("req", req);
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
      } else {
        toast.error(req.data.message);
        fetchSection();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Save Handler
  function saveHandler() {
    if (promofiles.length) {
      uploadHandler();
    } else {
      addUpdatePromo("undefined");
    }
  }

  const uploadHandler = async () => {
    if (promofiles.length === 1) {
      setLoader(true);
      let payload = new FormData();
      payload.append("uploadFile[0]", promofiles[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/promotional_banner`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdatePromo(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch (error: any) {
        setLoader(false);
        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }
    } else {
      toast.error("Only One Image Allowed");
    }

  };

  // Update Promo Section

  const addUpdatePromo = async (promoBannerImg: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-promotional-banner", {
        id: promoBannerId ?? "undefined",
        image: promoBannerImg ?? "undefined",
        image_alt: altText ?? "",
        company_id: company_id,
        template_id: template_id,
        description: description ?? "",
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        fetchSection();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(req.data.message);
        fetchSection();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  const deltePromoFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-promo-banner`, {
        company_id: company_id,
        image_name: promoBannerData.promotional_notification.image,
        template_id: template_id,
      });
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchSection();
      } else {
        toast.error(deleteRes.data.message);
        fetchSection();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      {/* Sub Header */}
      <div className="wb-subheader">
        {/* Bread-Crumbs */}
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Promotional Banner",
            },
          ]}
        />

        <div className="wb-subheader-rightsec">
          {/* Back Button */}
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/* Main Section */}

      <div className="form-check mt-4">
        <label className="form-check-label" htmlFor="setDefault">
          Set as Default
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          value="promotional"
          id="setDefault"
          checked={defaultValueId.toLowerCase() === "promotional"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => makePromoDefault(e)}

        ></input>
      </div>

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <AlternateText
          label="Alternate Text"
          value={altText}
          className="edit-promo-alt-text"
          handleChange={(e) => {
            setAltText(e.target.value);
          }}
        />
        <div className="edit-promo-alt-label">
          Select Promotional Banner Image
        </div>
        <DropzoneArea
          centerMesage={"Drag your photos or click here (1 Image Only)"}
          incomingfiles={promoBannerImg}
          dispatchFiles={(val) => accpPromoFiles(val)}
          isDelete={(val) => deltePromoFiles(val)}
          acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
          refresh={refreshList}
        />
        <div className="mt-4">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>
        <div className="mt-4 edit-promo-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="save-btn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>
    </>
  );
};

export default EditPromotionalBanner;
