import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import wbApi from "../../API/wbApi";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import NoDataComponent from "../../Components/noDataComponent/NoDataComponent";
import { DefaultSectionIdAction } from "../../redux/actions/SectionAction";
import { RootState } from "../../redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import WbSectionCard from "../../views/datacard/WbSectionCard";
import Loader from "../../views/loader/Loader";

const DefaultSections = () => {
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const [loader, setLoader] = useState<boolean>(false);
  const [defaultSections, setDefaultSections] = useState<any[]>([]);
  const [inUsePageId, setInUsePageId] = useState<any[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllSections();
    fetchInUseSec();
    // fetchCompanyDetails();
  }, [company_id, template_id]);

  // Fetch All Sections
  const fetchAllSections = async () => {
    setLoader(true);
    try {
      let allSecRes = await wbApi.get(
        `/wb-dashboard/fetch-all-default-custom-sections/${company_id}/${template_id}`
      );

      // console.log("allSecRes", allSecRes);
      if (allSecRes.data.status === 1) {
        setDefaultSections(allSecRes.data.default_sections);

        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Fetch Inuse Section
  const fetchInUseSec = async () => {
    try {
      let inUseSecRes = await wbApi.get(
        `/wb-dashboard/fetch-in-use-sections/${company_id}/${template_id}`
      );
      if (inUseSecRes.data.status === 1) {
        const active = inUseSecRes?.data?.in_use_sections?.map(
          (obj: any) => obj.section_id
        );
        setInUsePageId(active);
      } else {
        setInUsePageId([]);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <>
        {/* Sub Header */}
        <div className="wb-subheader">
          {/* Bread-Crumbs */}
          <CustomBreadcrumbs
            content={[
              {
                label: "Sections",
                href: "/section-default",
              },
              {
                label: `Default Sections`,
              },
            ]}
          />

          {/* <div className="wb-subheader-rightsec">
            <div className="header-footer-toggle">
              <ul className="nav nav-tabs">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="Default"
                    data-bs-toggle="tab"
                    data-bs-target="crs"
                    aria-controls="crs"
                    role="tab"
                    aria-selected="true"
                  >
                    DEFAULT
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() => navigate("/pages-custom")}
                    className="nav-link"
                    id="custom"
                    data-bs-toggle="tab"
                    data-bs-target="list"
                    aria-controls="list"
                    role="tab"
                    aria-selected="true"
                  >
                    CUSTOM
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="wb-divider"></div>

        {/* Main Section */}

        <div className="mt-4">
          {loader ? (
            <div className="middle-loader">
              <Loader />
            </div>
          ) : (
            <div className="page-main-section">
              {defaultSections?.length > 0 ? (
                defaultSections?.map((sec: any, idx: any) => {
                  return (
                    <div key={idx} className="page-default-section ">
                      <WbSectionCard
                        key={sec.id}
                        update=""
                        isInUse={inUsePageId.includes(sec.id)}
                        onEditClick={() => {
                          navigate(`/section-default/${sec.section_routes}`);
                          dispatch(DefaultSectionIdAction(sec.id));
                        }}
                      >
                        {sec.section_names}
                      </WbSectionCard>
                    </div>
                  );
                })
              ) : (
                <NoDataComponent message="No Sections Found" />
              )}
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default DefaultSections;
