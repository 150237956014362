
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import DropzoneArea from '../../../views/customDropzone/DropzoneArea';
import InputTextField from '../../../views/inputfield/InputTextField';
// Importing Ckeditor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from '../../../views/buttons/Button';
export interface IPageDetailsSliderProps {
    onClose: () => void;
    getEditServiceDetails: (val: any) => void;
    selectedHotel: any;
    editServiceDetails: any;
}

const PageDetailsSlider: React.FC<IPageDetailsSliderProps> = ({
    onClose,
    getEditServiceDetails,
    selectedHotel,
    editServiceDetails
}) => {
    const { company_id, auth_token } = useSelector(
        (state: RootState) => state.auth
    );
    const { hotel_services_data } = useSelector(
        (state: RootState) => state.sections
    );
    const { template_id } = useSelector((state: RootState) => state.template_id);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState<boolean>(false);
    const [description, setDescription] = useState<any>("");
    const [seoDescription, setSeoDescription] = useState<any>("");
    const [title, setTitle] = useState<any>("");
    const [bannerTitle, setBannerTitle] = useState<any>("");
    const [altText, setAltText] = useState<any>("");
    const [uTubeLink, setUtubeLink] = useState<any>("");
    const [updateServicefiles, setUpdateServiceFiles] = useState<any[]>([]);
    const [incomingServicefiles, setIncomingServiceFiles] = useState<any[]>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);
    const [charCount, setCharCount] = useState({
        child: 0,
        privacy: 0,
        terms_cond: 0,
        cancellation: 0,
    });
    function removeTags(str: String) {
        str = str.toString();
        const finalStr = str.replace(/&nbsp;/g, " ");
        return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
    }
    const accpServiceFiles = (value: any) => {
        setUpdateServiceFiles(value);
    };
    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Edit Page Details</div>
            </div>
            <div className="height-64px"></div>

            <div className='mt-2'>
                <InputTextField
                    label={'Page Title'}
                    value={title}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                />
            </div>
            <div className='mt-2'>
                <InputTextField
                    label={'Banner Title'}
                    value={bannerTitle}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setBannerTitle(e.target.value)}
                />
            </div>

            <div className='mt-2'>
                <InputTextField
                    label={'Alternate Text'}
                    value={altText}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setAltText(e.target.value)}
                />
            </div>
            <div className='mt-2'>
                <InputTextField
                    label={'Youtube Link'}
                    value={uTubeLink}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setUtubeLink(e.target.value)}
                />
            </div>

            <div className='mt-2'>
                <DropzoneArea
                    centerMesage={"Drag your photos or click here(1 Image Only)"}
                    incomingfiles={incomingServicefiles}
                    dispatchFiles={(val) => accpServiceFiles(val)}
                    // isDelete={(val) => delteAboutFiles(val)}
                    acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
                    refresh={refreshList}
                />
            </div>

            <div className='mt-2'>
                <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                        removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                            "Table",
                        ],
                    }}
                    onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setDescription(data);
                        setCharCount({
                            ...charCount,
                            terms_cond: removeTags(data).length,
                        });
                    }}
                />
            </div>
            <div className='mt-2'>
                <InputTextField
                    label={'Seo Title'}
                    value={title}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                />
            </div>
            <div className='mt-2'>
                Seo Description
            </div>

            <div className='mt-2'>
                <CKEditor
                    editor={ClassicEditor}
                    data={seoDescription}
                    config={{
                        removePlugins: [
                            "EasyImage",
                            "ImageUpload",
                            "MediaEmbed",
                            "Table",
                        ],
                    }}
                    onChange={(event: string, editor: any) => {
                        const data = editor.getData();
                        setSeoDescription(data);
                        setCharCount({
                            ...charCount,
                            terms_cond: removeTags(data).length,
                        });
                    }}
                />
            </div>






            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>
            <div className="side__slider__panel__btn">
                <div className="side__slider__panel__btn_container">
                    <Button
                        className="cancel__btn"
                        handleClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        handleClick={() => {
                            // saveHandler();
                        }}
                        loader={loader}
                    >
                        Save
                    </Button>
                </div>
            </div>


        </>
    )
}

export default PageDetailsSlider
