import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../views/buttons/Button";
import InputTextField from "../../views/inputfield/InputTextField";
import SaveButton from "../../views/buttons/Button";
import CancelButton from "../../views/buttons/Button";
import Card from "../../views/datacard/Card";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CATCH_MESSAGE, getImage, validateURL } from "../../UtilityFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import wbApi from "../../API/wbApi";
import { toast } from "react-toastify";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import CardWrapper from "../../views/datacard/CardWrapper";

const EditFacebook = () => {
  // Data From Redux 
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { property_data, current_property } = useSelector(
    (state: RootState) => state.properties
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector((state: RootState) => state.sections);

  const navigate = useNavigate();

  // State 
  const [dataId, setDataId] = useState<any>();
  const [title, setTitle] = useState<any>("");
  const [facebookPageUrl, setFacebookPageUrl] = useState<string>("");
  const [facebookPageUrlHelperText, setFacebookPageUrlHelperText] = useState<string>("");
  const [altText, setAltText] = useState<any>("");
  const [facebookPageUrlBannerImg, setFacebookPageUrlBannerImg] = useState<any>([]);
  const [facebookLinkFile, setFacebookLinkFile] = useState<any>();
  const [facebookLinkDetails, setFacebookLinkDetails] = useState<any>();
  const [description, setDescription] = useState<any>("");
  const [hotelId, setHotelId] = useState<any>("");
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const accpFbLinkFiles = (value: any) => {
    setFacebookLinkFile(value[0]);
  };

  const deleteFbLinkFiles = (value: any) => {
    if (value) {
      delteFbLinkFilesApi();
    }
  };

  const delteFbLinkFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-default-section-background-image`, {
        company_id: company_id,
        image_name: facebookLinkDetails.background_image,
        section_id: defaultSectionId,
        template_id: template_id,
      });
      console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        fetchFacebookData();
      } else {
        toast.error(deleteRes.data.message);
        fetchFacebookData();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const fetchFacebookData = async () => {
    try {
      let fbRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-facebook-details/${company_id}/${template_id}/${defaultSectionId}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (fbRes.data.status === 1) {
        setFacebookPageUrl(fbRes?.data?.facebook_data?.facebook_link);
        setAltText(fbRes?.data?.facebook_section_details?.background_image_alt);
        setFacebookLinkDetails(fbRes?.data?.facebook_section_details);
        setTitle(fbRes?.data?.facebook_section_details?.section_title);
        setDataId(fbRes?.data?.facebook_section_details?.id);
        if (fbRes?.data?.facebook_section_details?.background_image) {
          setFacebookPageUrlBannerImg([
            getImage(fbRes?.data?.facebook_section_details?.background_image),
          ]);
        } else {
          setFacebookPageUrlBannerImg([]);
        }
        setDescription(fbRes?.data?.facebook_section_details?.section_description ?? "");
        setHotelId(fbRes?.data?.facebook_data.hotel_id)
      }
    } catch (error: any) {
      // toast.error(CATCH_MESSAGE)
      console.log(error);
    }
  }
  useEffect(() => {
    fetchFacebookData();
  }, [])

  // Save Handler
  const saveHandler = async () => {
    if (facebookLinkFile) {
      uploadHandler();
    } else {
      addUpdateFbLink("undefined");
    }
  };

  // Image uploader handler
  const uploadHandler = async () => {
    let payload = new FormData();
    payload.append("uploadFile[]", facebookLinkFile);
    try {
      let uploadRes = await wbApi.post(
        `/wb/insert-dz-images/${company_id}/${template_id}/facebook_background_image`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (uploadRes.data.status === 1) {
        await addUpdateFbLink(uploadRes.data.image_ids);

      }
      else {
        toast.error(uploadRes.data.message);

      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  }

  // Add Update Facebook Page Link Section
  const addUpdateFbLink = async (facebookPageUrlBannerImg: any) => {

    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-default-section-details", {
        id: dataId ?? "undefined",
        background_image: facebookPageUrlBannerImg,
        background_image_alt: altText,
        company_id: company_id,
        section_id: defaultSectionId,
        section_title: title,
        template_id: template_id,
        section_description: description,
      },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        setRefreshList(!refreshList)
        await insertFbLink(facebookPageUrl);
        setLoader(false);
      }
      else {
        toast.error(req.data.message);
        fetchFacebookData();
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      console.log(error)
    }
  }

  // Handler function to insert fb Link 
  const insertFbLink = async (url: any) => {
    try {
      let insertReq = await wbApi.post("/wb/insert-update-facebook-link", {
        company_id: company_id,
        facebook_link: facebookPageUrl,
        hotel_id: hotelId,
      },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });
      if (insertReq.data.status === 1) {
        toast.success(insertReq.data.message);
        setLoader(false);
        fetchFacebookData();
      } else {
        toast.error(insertReq.data.message);
        setLoader(false);
        fetchFacebookData();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      console.log(error)
    }
  }

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Facebook",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      <CardWrapper className="mt-4 mb-4 edit-facebook-conatiner">
        <InputTextField
          label="Facebook Page Url"
          value={facebookPageUrl}
          className="edit-promo-alt-text"
          handleChange={(e) => {
            const { value } = e.target ?? {};
            setFacebookPageUrl(e.target.value);
            if (validateURL(value)) {
              setFacebookPageUrlHelperText("")
            } else {
              setFacebookPageUrlHelperText("Please enter a valid link")
            }

          }}
          helperText={facebookPageUrlHelperText}
        />
        <div className="edit-title-text-container mt-3">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value)
            }}
          />
        </div>
        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>
        <div className="edit__dropzone__container__facebook">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={facebookPageUrlBannerImg}
            dispatchFiles={(val) => accpFbLinkFiles(val)}
            isDelete={(val) => deleteFbLinkFiles(val)}
            // acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>
        {/* <div className="col-sm-12"> */}
        <div className="px-0 my-3">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>

        <div className="edit-promo-btn-container mt-3">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >Cancel</Button>
          <Button
            className="save-btn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >Save</Button>
        </div>
      </CardWrapper>
      <div className="sixtypx-height"></div>
    </>
  );
};

export default EditFacebook;
