import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import AddPackageSlider from "./AddPackageSlider";
import EditPackageSlider from "./EditPackageSlider";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";

const PackageList = () => {
  // Data from Redux 
  const { company_id, auth_token } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector((state: RootState) => state.sections);
  const { current_property } = useSelector((state: RootState) => state.properties);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // states
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [hotelPackages, setHotelPackages] = useState<any>([]);
  const [currentPackage, setCurrentPackage] = useState<any>([]);
  const [defaultHotelData, setDefaultHotelData] = useState<any>({});
  const [defaultStatus, setDefaultStatus] = useState<boolean>(false);

  const packagesApi = async () => {
    try {
      let res = await wbApi.get(`/wb-dashboard/fetch-wb-package-section-details/${company_id}/${template_id}/${defaultSectionId}`)
      if (res.data.status === 1) {
        let packageArr: any = [];
        res?.data?.hotel_packages?.forEach((obj: any) => {
          if (obj.hotel_id === current_property.hotel_id) {
            packageArr.push(obj);
          }
        })
        setHotelPackages(packageArr);
        setDefaultHotelData(res?.data?.default_hotel);

      } else {
        setHotelPackages([]);
        setDefaultHotelData([]);
      }

    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  }
  useEffect(() => {
    packagesApi();
  }, []);

  useEffect(() => {
    if (!editOpen) {
      packagesApi();
    }
  }, [editOpen])

  const editHandler = (obj: any) => {
    setCurrentPackage(obj);
    setEditOpen(true);
  }

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const makeDefaultHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let res = await wbApi.post(`/wb/make-hotel-default-for-section`,
        {
          company_id: company_id,
          default_hotel_id: event.target.checked ? current_property.hotel_id : "",
          id: defaultHotelData.id ?? "undefined",
          section_id: defaultSectionId,
          template_id: template_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        });
      if (res.data.status === 1) {
        toast.success(res.data.message)
        packagesApi()
      } else {
        toast.error(res.data.message)
      }
    } catch {
      toast.error(CATCH_MESSAGE)

    }
  }

  const deleteHandler = async (obj: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            let res = await wbApi.post(`/wb/delete-wb-package`,
              {
                company_id: obj.company_id,
                id: obj.id,
                template_id: obj.template_id,
              },
              {
                headers: {
                  Authorization: "Bearer " + auth_token,
                },
              });
            if (res.data.status === 1) {
              toast.success(res.data.message);
              packagesApi()
            } else {
              toast.error(res.data.message);
              packagesApi()
            }
          } catch {
            toast.error(CATCH_MESSAGE)
          }

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  }

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Package",
              href: "/section-default/edit-package-section-details"
            },
            {
              label: `${current_property.hotel_name}`
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="wb-divider"></div>

      <div className="top__button__container">
        <div className="form-check default_label me-2">
          <label className="form-check-label mt-2" htmlFor="setDefault">
            Set as Default
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="setDefault"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeDefaultHandler(e)}
            checked={defaultHotelData.default_hotel_id === current_property.hotel_id}
          ></input>
        </div>
        <Button
          icon="bi bi-plus"
          className="me-3"
          handleClick={() => {
            setAddOpen(true);
          }}
        >
          Add
        </Button>
      </div>

      <div className="default_section_image_card_container mt-3">
        {hotelPackages?.length > 0 ? (
          hotelPackages.map((obj: any, keyId: number) => (
            <div key={keyId} className="default__setion__image reorder--onhover" style={{ marginRight: '20px', flexBasis: '23%' }}>
              <div className="display__none">
                <div className="reorder__cursor">
                  <i className="bi bi-arrows-move"></i>
                </div>
              </div>
              <div className="image__container">
                <img src={getImage(obj?.package_image)} />
              </div>
              <div className="card__footer">
                <div className="package__hotel__name">{obj?.package_title}</div>
                <div className="package__description">
                  {removeTags(obj?.overview_details?.slice(0, 50))}...
                </div>
              </div>
              <div className="overlay overlay-border-radius">
                <div className="button__container">
                  <div
                    className="edit_button m-0"
                    onClick={(e) => {
                      editHandler(obj);
                    }}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="delete-button" onClick={(e) => {
                    deleteHandler(obj);
                  }}>
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          ))) : (
          <NoDataComponent message={"No Packages Found"} />
        )}
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={addOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddOpen(false)}
      >
        <AddPackageSlider
          onClose={() => {
            setAddOpen(false);
          }}
          packagesApi={packagesApi}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditOpen(false)}
      >
        <EditPackageSlider
          onClose={() => {
            setEditOpen(false);
          }}
          currentPackage={currentPackage}
          packagesApi={packagesApi}
        />
      </SlidingPane>
    </>
  );
};

export default PackageList;
