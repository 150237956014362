import React, { useCallback, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import BackButton from "../../../views/buttons/Button";
import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import CardWrapper from "../../../views/datacard/CardWrapper";
import SliderSectionCard from "../../../views/datacard/SliderSectionCard";
import SlidingPane from "react-sliding-pane";
import ChooseServiceSlider from "./ChooseServiceSlider";
import AddNewServices from "./AddNewServices";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { Draggable } from "react-drag-reorder";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../../redux/actions/PromptAction";

const EditHotelServices = () => {
  const { hotel_services_data } = useSelector(
    (state: RootState) => state.sections
  );
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [addSrvcSlider, setAddSrvcSlider] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [chooseSrvcSlider, setChooseSrvcSlider] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [hotelServicesData, setHotelServicesData] = useState<any>({
    hotel_services: [],
    all_icons: [],
    selected_services: [],
  });
  const [defaultHotel, setDefaultHotel] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const datatoMap = hotel_services_data?.selected_services?.filter(
      (iteam: any) => iteam.hotel_id === hotel_services_data.hotel_id
    );
    setSelectedServices(datatoMap);
  }, [hotel_services_data]);

  useEffect(() => {
    fetchSection();
  }, [company_id, template_id, defaultSectionId]);
  // Data Fetching
  const fetchSection = async () => {
    try {
      let SectionRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-services-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (SectionRes.data.status === 1) {
        const serviceData = {
          hotel_services: SectionRes.data.hotel_services,
          all_icons: SectionRes.data.all_icons,
          selected_services: SectionRes?.data?.selected_services?.filter(
            (iteam: any) => iteam.hotel_id === hotel_services_data.hotel_id
          ),
        };
        setHotelServicesData(serviceData);
        const datatoMapped = SectionRes?.data?.selected_services?.filter(
          (iteam: any) => iteam.hotel_id === hotel_services_data.hotel_id
        );
        setSelectedServices(datatoMapped);
        if (SectionRes.data.default_hotel) {
          setDefaultHotel(SectionRes.data.default_hotel);
        } else {
          setDefaultHotel({});
        }
      } else {
        setHotelServicesData({});
      }
    } catch (error: any) {
      // toast.error(CATCH_MESSAGE);
      console.log(error);
    }
  };

  // Section Delet Handler
  const deleteHandler = (id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          await deletServiceDetails(id);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );
  };

  // Delet Section Post
  const deletServiceDetails = async (service_id: any) => {
    try {
      let deletReq = await wbApi.post("/wb/delete-wb-services", {
        selected_services_id: service_id ?? "",
        template_id: template_id,
        company_id: company_id,
      });
      // console.log("deletReq", deletReq);
      if (deletReq.data.status === 1) {
        toast.success(deletReq.data.message);
        fetchSection();
      } else {
        toast.error(deletReq.data.message);
        fetchSection();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Api To ReOrder
  const orderSaveHandler = async () => {
    setSaveLoader(true);
    try {
      let orderReq = await wbApi.post(
        "/ordering/order-wb-services",
        {
          hotel_id: hotel_services_data.hotel_id,
          services: updateOrder,
          template_id: template_id,
          company_id: company_id,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        fetchSection();
        setSaveLoader(false);
      } else {
        toast.error(orderReq.data.message);
        fetchSection();
        setSaveLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);

      setSaveLoader(false);
    }
  };

  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderServices = [...selectedServices];
    let temp = orderServices[currentPos].selected_services_id;
    orderServices[currentPos].selected_services_id = orderServices[newPos].selected_services_id
    orderServices[newPos].selected_services_id = temp
    setUpdateOrder(orderServices);
  };

  // Draggable Handler

  const DraggableRender = useCallback(() => {
    if (selectedServices && selectedServices?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {selectedServices?.map((iteam: any, idx: any) => {
            return (
              <div className="selected_service_wrapper" key={idx}>
                <div className="left-service-card">
                  <i className="bi bi-grip-vertical"></i>
                  {iteam?.title}
                </div>
                <div className="right-service-card">
                  <div className="button"
                    onClick={() => {
                      navigate(
                        "/section-default/edit-hotel-specifications/edit-selected-services", {
                        state: {
                          services_id: iteam.services_id,
                        }
                      }
                      )
                    }
                    }
                  >
                    <i className="bi bi-pencil-square"></i>
                  </div>
                  <div className="button"
                    onClick={() => deleteHandler(iteam?.selected_services_id)}
                  >
                    <i className="bi bi-trash"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [selectedServices]);

  const makeDefault = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let response = await wbApi.post("/wb/make-hotel-default-for-section", {
        company_id: company_id,
        default_hotel_id: hotel_services_data?.hotel_id,
        id: e.target.checked === true ? defaultHotel?.default_hotel_id : "undefined",
        section_id: defaultSectionId,
        template_id: template_id,
      });
      if (response.data.status === 1) {
        toast.success(response.data.message);
        fetchSection();
      } else {
        toast.error(response.data.message);
      }
    } catch {
      toast.error(CATCH_MESSAGE)
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Services",
              href: "/section-default/edit-hotel-specifications",
            },
            {
              label: `${hotel_services_data?.hotel_name}`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      <div className="input-check-add-butn-wrapper">
        <div className="form-check mt-4">
          <label className="form-check-label" htmlFor="setDefault">
            Set as Default
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value="promotional"
            id="setDefault"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => makeDefault(e)}
            checked={
              defaultHotel?.default_hotel_id === hotel_services_data?.hotel_id
            }
          ></input>
        </div>
        <Button
          icon="bi-info-circle"
          handleClick={() => {
            setChooseSrvcSlider(true);
          }}
        >
          Choose Services
        </Button>
        <Button
          icon="bi-info-circle"
          handleClick={() => {
            setAddSrvcSlider(true);
          }}
        >
          Add New Services
        </Button>
      </div>
      <CardWrapper className="mt-4 mb-4 edit-hotel-service-card-wrapper">
        {selectedServices?.length > 0 ? (
          <DraggableRender />
        ) : (
          <NoDataComponent message={"You Have Not Selected Any Services"} />
        )}
      </CardWrapper>
      <div className="mt-2 mb-2 " style={{ width: "10%" }}>
        <Button handleClick={() => orderSaveHandler()} loader={saveLoader}>
          Update
        </Button>
      </div>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={addSrvcSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddSrvcSlider(false)}
      >
        <AddNewServices
          onClose={() => {
            setAddSrvcSlider(false);
          }}
          fetchSection={fetchSection}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={chooseSrvcSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setChooseSrvcSlider(false)}
      >
        <ChooseServiceSlider
          onClose={() => {
            setChooseSrvcSlider(false);
          }}
          hotelServicesData={hotelServicesData}
          fetchSection={fetchSection}
        />
      </SlidingPane>
    </>
  );
};

export default EditHotelServices;
