import { Input } from "@mui/material";
import React from "react";
import InputTextField from "../../../views/inputfield/InputTextField";
// import ImageDropzone from "../../identity/ImageDropzone";
import TrailVideo from "../../../assests/videos/trailVideo.mp4";
import Card from "../../../views/datacard/Card";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import Button from "../../../views/buttons/Button";
import CircularProgressWithLabel from "../../../views/dropzone/CircularProgressWithLabel";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import CardWrapper from "../../../views/datacard/CardWrapper";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";

const VideoSlider = ({
  defaultValue,
  getVideoSliderDetails,
  videoSliderDetails,
}: any) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { homePageData } = useSelector((state: RootState) => state.pages);
  const [defaultSlider, setDefaultSlider] = useState<boolean>(false);
  const [uploadLoader, setUploadLoader] = useState<boolean>(false);
  const [videofiles, setVideoFiles] = useState<any>();
  const [youTubeLink, setYoutubeLink] = useState<any>("");
  const accpVideoFiles = (value: any) => {
    setVideoFiles(value[0]);
  };

  useEffect(() => {
    setYoutubeLink(videoSliderDetails?.video_url);
  }, [videoSliderDetails]);
  useEffect(() => {
    setDefaultSlider(defaultValue?.default_value?.toLowerCase() === "video");
  }, [defaultValue]);

  // Api To Set Default
  const setDefault = async () => {
    try {
      let defaultRes = await wbApi.post(`/wb/make-slider-default`, {
        company_id: company_id,
        default_value: "video",
        template_id: template_id,
      });
      if (defaultRes.data.status === 1) {
        toast.success(defaultRes.data.message);
        getVideoSliderDetails();
      } else {
        toast.error(defaultRes.data.message);
        getVideoSliderDetails();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Api To Update Order Of Images
  const uploadHandler = async () => {
    setUploadLoader(true);

    let payload = new FormData();
    payload.append("uploadFile[]", videofiles);

    try {
      let uploadRes = await wbApi.post(
        `/new/upload-video/${company_id}/${template_id}/Slider`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      // console.log("uploadRes", uploadRes);
      if (uploadRes.data.status === 1) {
        toast.success(uploadRes.data.message);
        await insertImageData(uploadRes.data.video_id);
        setUploadLoader(false);
      } else {
        toast.error(uploadRes.data.message);
        setUploadLoader(false);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
      setUploadLoader(false);
    }
  };

  // Api To Insert Data
  const insertImageData = async (video_id: any) => {
    try {
      let insertDataRes = await wbApi.post(
        `/template/insert-update-data`,
        {
          id: homePageData?.home_page_details?.id ?? "undefined",
          company_id: company_id,
          template_id: template_id,
          category: "Slider",
          video_url: youTubeLink,
          video_id: video_id,
        },

        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      // console.log("insertDataRes", insertDataRes);
      if (insertDataRes.data.status === 1) {
        toast.success(insertDataRes.data.message);

        getVideoSliderDetails();
      } else {
        toast.error(insertDataRes.data.message);
        getVideoSliderDetails();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="form-check mt-4">
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Set as Default
        </label>
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          checked={defaultSlider}
          id="setDefault"
          onChange={() => setDefaultSlider(!defaultSlider)}
          onClick={() => setDefault()}
        ></input>
      </div>
      <CardWrapper className="mt-4 mb-4 vdo-slider-wrapper">
        <div className="vdo-sldr-left">
          <InputTextField
            label="YouTube Link"
            value={youTubeLink}
            className="vdo-link-input"
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setYoutubeLink(e.target.value)
            }
          />
          <div className="or">Or</div>
          <DropzoneArea
            centerMesage={"Drag your Video or click here"}
            dispatchFiles={(val) => accpVideoFiles(val)}
          />
        </div>
        <div className="vdo-sldr-right">
          {videofiles ? (
            <video
              className="vdo-slider"
              width="100%"
              height="400"
              controls
              autoPlay
            >
              <source src={videofiles.preview} type="video/mp4" />
              {/* <source src="movie.ogg" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
          ) : (
            <NoDataComponent message="No Video Available" />
          )}
        </div>
      </CardWrapper>
      <div className="mt-2 mb-2" style={{ width: "15%" }}>
        <Button
          className="save-btn"
          handleClick={() => {
            uploadHandler();
          }}
          loader={uploadLoader}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default VideoSlider;
