import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextField from "../../views/inputfield/InputTextField";
import Card from "../../views/datacard/Card";
import Button from "../../views/buttons/Button";
import CancelButton from "../../views/buttons/Button";
import SaveButton from "../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import wbApi from "../../API/wbApi";
import { CATCH_MESSAGE, getImage } from "../../UtilityFunctions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { nanoid } from "@reduxjs/toolkit";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../redux/actions/PromptAction";

const EditMarketing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const [newDataPost, setNewDataPost] = useState("");
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [marketingBannerImg, setMarketingBannerImg] = useState<any>([]);
  const [marketingFile, setMarketingFile] = useState<any[]>([]);
  const [marketingSectionDetails, setMarketingSectionDetails] = useState<any>(
    []
  );
  const [marketingBannerId, setMarketingBannerId] = useState<any>();
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const accpMarketingFiles = (value: any) => {
    setMarketingFile(value);
  };

  const deleteMarketingFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteMarketingFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteMarketingFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-marketing-image`, {
        company_id: company_id,
        image_name: marketingSectionDetails.marketing_image,
        template_id: template_id,
      });
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        marketingApi();
      } else {
        toast.error(deleteRes.data.message);
        marketingApi();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };


  // Handler function to save img and data
  const saveHandler = () => {
    if (marketingFile.length) {
      uploadHandler();
    } else {
      addUpdateMarketing("undefined");
    }
  };

  const uploadHandler = async () => {

    if (marketingFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", marketingFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/marketing`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );

        if (uploadRes.data.status === 1) {
          await addUpdateMarketing(uploadRes.data.image_ids);
        } else {
          toast.error(uploadRes.data.message);
        }
      } catch {
        toast.error(CATCH_MESSAGE)
      }
    } else {
      toast.error("Only One Image Allowed")
    }
  };

  // Update Marketing Section
  const addUpdateMarketing = async (marketingBannerImg: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-wb-marketing", {
        id: marketingBannerId ?? "undefined",
        marketing_image: marketingBannerImg,
        marketing_image_alt: altText,
        company_id: company_id,
        marketing_title: title,
        template_id: template_id,
        marketing_description: description,
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        marketingApi();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(req.data.message);
        marketingApi();
        setLoader(false);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };


  const marketingApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-marketing-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp?.data?.status === 1) {
        setMarketingSectionDetails(resp?.data?.marketing_details);
        setMarketingBannerId(resp?.data?.marketing_details?.id);
        setTitle(resp?.data?.marketing_details?.marketing_title);
        setAltText(resp?.data?.marketing_details?.marketing_image_alt);
        setDescription(resp?.data?.marketing_details?.display_description ?? "");
        if (resp?.data?.marketing_details?.marketing_image) {
          setMarketingBannerImg([
            getImage(resp?.data?.marketing_details?.marketing_image),
          ]);
        } else {
          setMarketingBannerImg([]);
        }
      } else {
        setMarketingSectionDetails([]);
        setDescription("");
        setAltText("");
        setTitle("");
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    marketingApi();
  }, []);

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Edit Marketing`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => setTitle(e.target.value)}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={marketingBannerImg}
            dispatchFiles={(val) => accpMarketingFiles(val)}
            isDelete={(val) => deleteMarketingFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div className="sec-description-editor" style={{ margin: "1rem 0rem" }}>
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>
    </>
  );
};

export default EditMarketing;
