import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
// Importing Ckeditor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface IAboutPageTitleSLiderProps {
  onClose: () => void;
  packagePageDataFetching: () => void;
  packagePageDetails: any;
}

const PackagePageTitleSlider: React.FC<IAboutPageTitleSLiderProps> = ({
  onClose,
  packagePageDataFetching,
  packagePageDetails
}) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);

  // States
  const [titel, setTitel] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  useEffect(() => {
    setTitel(packagePageDetails?.page_title);
    setDescription(packagePageDetails?.page_description ?? "");
  }, [packagePageDetails])

  // Post Title and Description Details
  const saveTitleDetails = async () => {
    setLoader(true);
    try {
      let TitelReq = await wbApi.post("/wb/insert-update-page-title", {
        id: packagePageDetails?.id ?? "undefined",
        template_id: template_id,
        company_id: company_id,
        page_title: titel,
        page_type: "Default",
        page_id: page_Id ?? "",
        page_description: description,
      });
      if (TitelReq.data.status === 1) {
        toast.success(TitelReq.data.message);
        packagePageDataFetching();
        setLoader(false);
        onClose();
      } else {
        toast.error(TitelReq.data.message);
        packagePageDataFetching();
        setLoader(false);
        onClose();
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };




  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Page Title and Description</div>
      </div>
      <div className="height-64px"></div>
      <div className="seo-slider-inputfield-wrapper">
        <InputTextField
          label="Title"
          value={titel}
          handleChange={(e) => {
            setTitel(e.target.value);
          }}
        />
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: [
              "EasyImage",
              "ImageUpload",
              "MediaEmbed",
              "Table",
            ],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            handleClick={() => {
              saveTitleDetails();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default PackagePageTitleSlider;
