import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";

interface IAddBenifitsSLiderProps {
  onClose: () => void;
  updateFunc: () => void;
  allIcons: any[]
}
const AddBenifits: React.FC<IAddBenifitsSLiderProps> = ({ onClose, updateFunc, allIcons }) => {
  const [title, setTitle] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [clickedIcon, setClickedIcon] = useState<any>("");
  const [searchInput, setSearchInput] = useState<any>("");

  useEffect(() => {
    setFilteredResults(allIcons);
  }, [allIcons]);

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = allIcons?.filter(
        (item: any) => {
          return item.name.toLowerCase().startsWith(keyword.toLowerCase());
        }
      );
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(allIcons);
    }
    setSearchInput(keyword);
  };

  const addNewBenifit = async () => {
    setLoader(true);
    if (!title) {
      return
    }
    else {
      try {
        const payload = { benefit_title: title, icon: clickedIcon }
        const resp = await wbApi.post(`wb/add-wb-booking-benefits`, payload)

        if (resp.data.status === 1) {
          toast.success(resp.data.message)
          updateFunc()
          setLoader(false);
          onClose()
        }
        else {
          setLoader(false);
          toast.error(resp.data.message);
        }
      }
      catch {
        toast.error(CATCH_MESSAGE)
      }
    }
  }




  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Benifits</div>
      </div>
      <div className="height-64px"></div>
      <InputTextField
        label="Benifit Title"
        value={title}
        className="description description__slider"
        handleChange={(e) => setTitle(e.target.value)}
      />

      <div className="search__booking__wrapper">
        <i className="bi bi-search"></i>
        <input
          placeholder="Search Icons"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }}
        />
      </div>
      <div className="service_icon_wrapper">
        {filteredResults.map((icon, i) => (

          <div
            key={icon.id}
            className={`service_icon ${icon.icon === clickedIcon ? "selected" : ""}`}
            onClick={() => setClickedIcon(icon.icon)}
          >
            <i className={`${icon.icon} me-4`} aria-hidden="true"></i>
            {icon.name}
          </div>

        ))}
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <Button className="cancel__btn" handleClick={() => onClose()}>Cancel</Button>

        <Button handleClick={() => { addNewBenifit() }} loader={loader}>Save</Button>
      </div>
    </>
  );
};

export default AddBenifits;
