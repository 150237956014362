import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
export interface IChooseServiceSliderProps {
  onClose: () => void;
  fetchSection: () => void;
  hotelServicesData: any;
}

const ChooseServiceSlider: React.FC<IChooseServiceSliderProps> = ({
  onClose,
  fetchSection,
  hotelServicesData,
}) => {
  const { hotel_services_data } = useSelector(
    (state: RootState) => state.sections
  );
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);

  const [loader, setLoader] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [checkedItem, setCheckedItem] = useState<any[]>([]);

  console.log("hotelServicesData", hotelServicesData);

  useEffect(() => {
    const selected_services_name = hotelServicesData?.selected_services?.map(
      (service: any) => service?.title
    );

    const alreadySelected = hotelServicesData?.hotel_services?.filter(
      (item: any) => {
        if (selected_services_name.includes(item.title)) {
          return item;
        }
      }
    );

    setCheckedItem(alreadySelected);
    setFilteredResults(hotelServicesData?.hotel_services);
  }, [hotelServicesData]);

  // Hanadle Checked Sections
  const checkedSection = (e: React.ChangeEvent<HTMLInputElement>, sec: any) => {
    if (checkedItem) {
      let checkeds = [...checkedItem];

      if (
        checkedItem.length > 1 &&
        checkedItem.some((item: any) => {
          return item?.title === sec.title;
        })
      ) {
        checkeds = checkedItem?.filter(
          (name: any) => name?.section_names !== sec.section_names
        );
      } else {
        checkeds.push(sec);
      }
      setCheckedItem(checkeds);
    }
  };
  //   Selected Services Post
  const postSelectedItem = async () => {
    setLoader(true);
    try {
      let postSecReq = await wbApi.post(
        "/wb/select-hotel-services",
        {
          company_id: company_id,
          hotel_id: hotel_services_data.hotel_id,
          template_id: template_id,
          user_selected_service: checkedItem,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (postSecReq.data.status === 1) {
        toast.success(postSecReq.data.message);
        fetchSection();
        setLoader(false);
        onClose();
      } else {
        toast.error(postSecReq.data.message);
        fetchSection();
        setLoader(false);
        onClose();
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = hotelServicesData?.hotel_services?.filter(
        (item: any) => {
          return item.title.toLowerCase().startsWith(keyword.toLowerCase());
        }
      );
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(hotelServicesData?.hotel_services);
    }
    setSearchInput(keyword);
  };

  return (
    <div>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Choose Services</div>
      </div>
      <div className="height-64px"></div>
      <div className="search__booking__wrapper">
        <i className="bi bi-search"></i>
        <input
          placeholder="Search Services"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }}
        />
      </div>
      {filteredResults?.map((service: any) => (
        <>
          <div key={service?.services_id} className="select__section__wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <div className="select__section__content">{service?.title}</div>
            </div>
            <div className="select__section__checkbox">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  checked={
                    checkedItem &&
                    checkedItem.some((item: any) => {
                      return item?.title === service?.title;
                    })
                  }
                  onChange={(e) => {
                    checkedSection(e, service);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ))}
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>

          <Button
            handleClick={() => {
              postSelectedItem();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseServiceSlider;
