import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../views/buttons/Button";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import wbApi from "../../../API/wbApi";
import { testimonialPageAction } from "../../../redux/actions/PageAction";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import { toast } from "react-toastify";
import ImageTestimonialBanner from "./ImageTestimonialBanner";
import VideoTestimonialBanner from "./VideoTestimonialBanner";

const EditTestimonialBanner = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeMenuTab, setActiveMenuTab] = useState("Image");
  const pages = ["Image", "Video"];

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
    // console.log(tab);
  };
  // useEffect(() => {
  //   aboutPageDataFetching();
  // }, [company_id, template_id]);

  // Fetching About Page Data
  const testimonialPageDataFetching = async () => {
    try {
      let testimonialPageRes = await wbApi.get(
        `/wb-dashboard/fetch-wb-testimonial-page-details/${company_id}/${template_id}/${page_Id}`
      );
      // console.log("aboutPageData", aboutPageData);
      if (testimonialPageRes.data.status === 1) {
        dispatch(testimonialPageAction(testimonialPageRes.data));
        const testimonialPageAllSec = testimonialPageRes.data.all_sections.map(
          (item: any) => {
            return { ...item, isChecked: false };
          }
        );
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  return (
    <>
      {/* Sub Header */}
      <div className="wb-subheader">
        {/* BreadCrumbs */}
        <CustomBreadcrumbs
          content={[
            {
              label: "Pages",
              href: "/pages-default",
            },
            {
              label: `Default Pages`,
              href: "/pages-default",
            },
            {
              label: "Testimonial",
              href: "/pages-default/edit-testimonial-page",
            },
            {
              label: "Edit Banner",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          {/* Toggle Button */}
          <div className="header-footer-toggle">
            <ul className="nav nav-tabs">
              {pages.map((tab) => {
                return (
                  <li
                    className="nav-item"
                    role="presentation"
                    key={tab}
                    onClick={() => switchMenuTab(tab)}
                  >
                    <a
                      className={`nav-link ${
                        tab === activeMenuTab ? "active" : ""
                      }`}
                      id={`${tab}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${tab}`}
                      aria-controls={`#${tab}`}
                      role="tab"
                      aria-selected="true"
                    >
                      {tab.toUpperCase()}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Back Button */}
          <BackButton
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </BackButton>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/* MainContent */}
      {activeMenuTab === "Image" ? (
        <ImageTestimonialBanner
          testimonialPageDataFetching={testimonialPageDataFetching}
        />
      ) : (
        <VideoTestimonialBanner
          testimonialPageDataFetching={testimonialPageDataFetching}
        />
      )}
    </>
  );
};

export default EditTestimonialBanner;
