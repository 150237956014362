import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import AddButton from "../../views/buttons/Button";
import FooterMenu from "./FooterMenu";
import HeaderMenu from "./HeaderMenu";
import wbApi from "../../API/wbApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FooterMenuData, HeaderMenuData } from "../../redux/actions/MenuAction";
import MenuSlider from "./MenuSlider";
import { Card } from "@mui/material";
import CardWrapper from "../../views/datacard/CardWrapper";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, Logout, UpdateSidebar } from "../../UtilityFunctions";
import Loader from "../../views/loader/Loader";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";

const Menu = () => {
  const dispatch = useDispatch();
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const [activeMenuTab, setActiveMenuTab] = useState("footer");
  const [menuData, setMenuData] = useState<any[]>([]);
  const [headerMenu, setHeaderMenu] = useState<any[]>([]);
  const [footerMenu, setFooterMenu] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const pages = ["header", "footer"];

  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  const panelHandler = () => {
    setOpen(!open);
  };

  // Fetching Menu Data
  const fetchMenuData = async () => {
    setLoader(true);
    try {
      let menuRes = await wbApi.get(
        `/wb-dashboard/fetch-menu-data/${company_id}/${template_id}`
      );
      // console.log("menuRes===>>", menuRes);
      if (menuRes.data.status === 1) {
        const menudata = menuRes.data.menudata.map((item: any) => {
          return { ...item, isActive: false };
        });
        setMenuData(menudata);
        const header = menuRes?.data?.menudata?.filter(
          (obj: { menu_status: string }) => {
            return obj.menu_status === "header";
          }
        );

        setHeaderMenu(header);
        dispatch(HeaderMenuData(header));

        const footer = menuRes?.data?.menudata?.filter(
          (obj: { menu_status: string }) => {
            return obj.menu_status === "footer";
          }
        );
        dispatch(FooterMenuData(footer));
        setFooterMenu(footer);
        setActiveMenuTab("header");
        setLoader(false);
      } else {
        setHeaderMenu([]);
        setFooterMenu([]);
        setLoader(false);
      }
    } catch (error: any) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      if (error.response.status === 401) {
        Logout();
      }
    }
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  // Menu Data Extraction

  // useEffect(() => {
  //   const header=menuData.filter(obj=>{
  //     return obj.menu_status ==="header";
  //   });
  //   setHeaderMenu(header);

  //   const footer=menuData.filter(obj=>{
  //     return obj.menu_status ==="footer";
  //   });
  //   setFooterMenu(footer);

  // },[menuData]);

  const updateHeaderMenuOrder = (menu: any) => {
    setHeaderMenu(menu);
  };
  const updateFooterMenuOrder = (menu: any) => {
    setFooterMenu(menu);
  };

  return (
    <>
      <div>
        {/* Sub Header */}

        <div className="wb-subheader">
          <CustomBreadcrumbs
            content={[
              {
                label: "Menu",
                href: "/menu",
              },
              {
                label: `${activeMenuTab === "header" ? "Header" : "Footer"}`,
              },
            ]}
          />
          <div className="wb-subheader-rightsec">
            <AddButton icon="bi bi-plus-lg" handleClick={panelHandler}>
              Add
            </AddButton>
            <div className="header-footer-toggle">
              <ul className="nav nav-tabs">
                {pages.map((tab) => {
                  return (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={tab}
                      onClick={() => switchMenuTab(tab)}
                    >
                      <a
                        className={`nav-link ${
                          tab === activeMenuTab ? "active" : ""
                        }`}
                        id={`${tab}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${tab}`}
                        aria-controls={`#${tab}`}
                        role="tab"
                        aria-selected="true"
                      >
                        {tab.toUpperCase()}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="wb-divider"></div>

        {/* Main Content */}
        {loader ? (
          <div className="middle-loader">
            <Loader />
          </div>
        ) : activeMenuTab === "header" ? (
          <HeaderMenu
            menus={headerMenu}
            fetchMenuData={fetchMenuData}
            updateOrder={updateHeaderMenuOrder}
            setHeaderMenu={setHeaderMenu}
          />
        ) : (
          <FooterMenu
            menus={footerMenu}
            fetchMenuData={fetchMenuData}
            updateOrder={updateFooterMenuOrder}
            setFooterMenu={setFooterMenu}
          />
        )}
        {/* <div className="main-content-card menu-card"></div> */}
      </div>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={open}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpen(false)}
      >
        <MenuSlider
          onClose={() => {
            setOpen(false);
          }}
          fetchMenuData={fetchMenuData}
        />
      </SlidingPane>
    </>
  );
};

export default Menu;
