import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { browserName } from "react-device-detect";
import { LoginSuccess } from "../../redux/actions/LoginAction";
import LogInButton from "../../views/buttons/Button";
import LoginImg from "../../assests/img/login-magnifing.svg";
import InputTextField from "../../views/inputfield/InputTextField";
import InputPasswordField from "../../views/inputfield/InputPasswordField";
import { toast } from "react-toastify";
import { validateEmail, validateMobile } from "../../UtilityFunctions";
import kernelApi from "../../API/kernelApi";
import Loader from "../../views/loader/Loader";
import wbApi from "../../API/wbApi";
import { RootState } from "../../redux/store";
import { TemplateId } from "../../redux/actions/TemplateIdAction";
import WindMillLoader from "../../views/loader/WindMillLoader";
import FlashingLoader from "../../views/loader/FlashingLoader";
const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailHelperText, setEmailHelperText] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [isloading, setIsLoading] = useState(false);

  const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (
      validateEmail(e.target.value) ||
      validateMobile(e.target.value) ||
      !e.target.value
    ) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("Invalid email or mobile");
    }
  };
  const PasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length < 4 && e.target.value) {
      setPasswordHelperText("Invalid password");
    } else {
      setPasswordHelperText(" ");
    }
  };

  const LoginFormSubmit = async () => {
    if (!email) {
      toast.error("Enter your email or mobile");
    } else if (!password) {
      toast.error("Please enter your password");
    } else {
      setIsLoading(true);
      let response = await kernelApi.post("/admin/auth", {
        email: email,
        password: password,
        browser: browserName,
      });
      setTimeout(async () => {
        if (response.data.status === 1) {
          getUserTemplate(
            response?.data?.company_id,
            response?.data?.auth_token
          );
          dispatch(LoginSuccess(response.data));
          navigate("/");
        } else if (response.data.status === 2) {
          toast.error(response.data.message);
        } else {
          toast.error("Invalid Credentials");
        }
        setEmail("");
        setPassword("");
        setIsLoading(false);
      }, 600);
    }
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      LoginFormSubmit();
    }
  };

  // Get User Template Data

  const getUserTemplate = async (companyId: any, authToken: any) => {
    try {
      let templateResponse = await wbApi.get(
        `/wb/check-user-template/${companyId}`,
        {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        }
      );

      if (templateResponse.data.status === 1) {
        dispatch(TemplateId(templateResponse.data.usertemplate.template_id));
      }
    } catch (error) { }
  };

  return (
    <div className="container-fluid">
      <div className="row login-wrapper">
        <div className="col-5 login-col-left">
          <div className="img-wrapper">
            <img src={LoginImg} />
          </div>
        </div>
        <div className="col-7 login-col-right">
          {!isloading ? (
            <div className="row  log-in-credential">
              <div className="div-login-label">Log In</div>
              <div className="div__inputs__wrapp">
                <div className="div--email--input">
                  <InputTextField
                    value={email}
                    helperText={emailHelperText}
                    label="Email or Mobile"
                    handleChange={EmailOnChange}
                    onKeyPress={onKeyEnter}
                    handleFocus={(e) => {
                      if (!e.target.value) setEmailHelperText("");
                    }}
                  />
                </div>
                <div className="div--password--input">
                  <InputPasswordField
                    value={password}
                    helperText={passwordHelperText}
                    onEnterKey={onKeyEnter}
                    handleFocus={(e) => {
                      if (!e.target.value) setPasswordHelperText("");
                    }}
                    handleChange={PasswordOnChange}
                  />
                </div>
                <LogInButton
                  className="login--btn"
                  handleClick={() => LoginFormSubmit()}
                >
                  Login
                </LogInButton>
                <div>Version : 2.1.0</div>
              </div>
            </div>
          ) : (
            <div className="login-wait-message">
              <div className="div--head--label">Logging you in....</div>

              <div className="d-flex flex-row align-items-center">
                <div className="div--helper--text me-4">
                  Please wait while we are logging you in
                </div>
                <FlashingLoader />
              </div>

              {/* <Loader /> */}
              {/* <WindMillLoader /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogIn;
