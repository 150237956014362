export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const DIRECT_LOGIN = "DIRECT_LOGIN";
export const GET_PROPERTIES = "GET_PROPERTIES";
export const CHOOSE_PROPERTY = "CHOOSE_PROPERTY";
export const ACTIVE_SIDEBAR_ITEM = "ACTIVE_SIDEBAR_ITEM";
export const ACTIVE_SIDEBAR_SUBITEM = "ACTIVE_SIDEBAR_SUBITEM";
export const UPDATE_BREADCRUMBS = "UPDATE_BREADCRUMBS";
export const ADD_PROPERTY_TYPE = "ADD_PROPERTY_TYPE";
export const ADD_PROPERTY_SUBTYPE = "ADD_PROPERTY_SUBTYPE";
export const ADD_PROPERTY_DETAILS = "ADD_PROPERTY_DETAILS";
export const ADD_PROPERTY_ADDRESS = "ADD_PROPERTY_ADDRESS";
export const ADD_PROPERTY_LOCATION = "ADD_PROPERTY_LOCATION";
export const ADD_PROPERTY_AMENITIES = "ADD_PROPERTY_AMENITIES";
export const ADD_PROPERTY_IMAGES = "ADD_PROPERTY_IMAGES";
export const ADD_PROPERTY_RESET = "ADD_PROPERTY_RESET";
export const ADD_ROOM_TYPE_BASIC_DETAILS = "ADD_ROOM_TYPE_BASIC_DETAILS";
export const ADD_ROOM_TYPE_OCCUPANCY = "ADD_ROOM_TYPE_OCCUPANCY";
export const ADD_ROOM_TYPE_PRICE = "ADD_ROOM_TYPE_PRICE";
export const ADD_ROOM_TYPE_MEAL_PLAN = "ADD_ROOM_TYPE_MEAL_PLAN";
export const ADD_ROOM_TYPE_EP_PRICE_UPDATE = "ADD_ROOM_TYPE_EP_PRICE_UPDATE";
export const ADD_ROOM_TYPE_IMAGES = "ADD_ROOM_TYPE_IMAGES";
export const ADD_ROOM_TYPE_RESET = "ADD_ROOM_TYPE_RESET";
export const ADD_NUMBER_OF_FLOORS = "ADD_NUMBER_OF_FLOORS";
export const ADD_SERVICEABLE_FLOORS = "ADD_SERVICEABLE_FLOORS";
export const RESET_FLOOR_SELECTION = "RESET_FLOOR_SELECTION";
export const ENABLE_CONFIRMATION_PROMPT = "ENABLE_CONFIRMATION_PROMPT";
export const CANCEL_CONFIRMATION_PROMPT = "CANCEL_CONFIRMATION_PROMPT";
export const ENABLE_SWITCH_PROPERTY_SCREEN = "ENABLE_SWITCH_PROPERTY_SCREEN";
export const DISABLE_SWITCH_PROPERTY_SCREEN = "DISABLE_SWITCH_PROPERTY_SCREEN";

// For Identity Page
export const TEMPLATE_ID = "TEMPLATE_ID";
export const IDENTITY = "IDENTITY";
export const ALLFONTS = "ALLFONTS";

// For Menu
export const HEADER_MENU = "HEADER_MENU";
export const FOOTER_MENU = "FOOTER_MENU";

// For Page
export const PAGE_ID = "PAGE_ID";
export const DEFAULT_PAGE = "DEFAULT_PAGE";
export const CUSTOM_PAGE = "CUSTOM_PAGE";
export const INUSE_PAGES = "INUSE_PAGES";
export const HOME_PAGE = "HOME_PAGE";
export const ABOUT_PAGE = "ABOUT_PAGE";
export const ROOM_PAGE = "ROOM_PAGE";
export const CONTACTUS_PAGE = "CONTACTUS_PAGE";
export const GALLERY_PAGE = "GALLERY_PAGE";
export const SERVICE_PAGE = "SERVICE_PAGE";
export const PACKAGE_PAGE = "PACKAGE_PAGE";
export const BLOG_PAGE = "BLOG_PAGE";
export const FACILITY_PAGE = "FACILITY_PAGE";
export const EVENTS_PAGE = "EVENTS_PAGE";
export const OFFERS_PAGE = "OFFERS_PAGE";
export const TESTIMONIAL_PAGE = "TESTIMONIAL_PAGE";
export const PROMOTIONS_PAGE = "PROMOTIONS_PAGE";
export const HOTEL_PAGE = "HOTEL_PAGE";
export const HOTEL_LIST_PAGE = "HOTEL_LIST_PAGE";

// For Section
export const DEFAULT_SECTION = "DEFAULT_SECTION";
export const INUSE_SECTION = "INUSE_SECTION";
export const CUSTOM_SECTION = "CUSTOM_SECTION";
export const HOTEL_ROOMS_ID = "HOTEL_ROOMS_ID";
export const HOTEL_ROOMS_DETAILS = "HOTEL_ROOMS_DETAILS";
export const GALLERY_DATA = "GALLERY_DATA";
export const CATEGORY_NAME = "CATEGORY_NAME";
export const NEARBY_PLACE = "NEARBY_PLACE";
export const NEARBY_PLACE_HOTELID = "NEARBY_PLACE_HOTELID";
export const HOTEL_ROOOM_TYPE_DATA = "HOTEL_ROOOM_TYPE_DATA";

export const GET_SELECTED_BRAND_DATA = "GET_SELECTED_BRAND_DATA";
export const EMPTY_BRAND_DATA = "EMPTY_BRAND_DATA";
export const UPDATE_BRAND_STATE = "UPDATE_BRAND_STATE";

export const GET_SELECTED_BLOG_DATA = "GET_SELECTED_BLOG_DATA";
export const EMPTY_BLOG_DATA = "EMPTY_BLOG_DATA";
export const UPDATE_BLOG_STATE = "UPDATE_BLOG_STATE";

export const GET_HOTEL_DATA_AND_EVENTS = "GET_HOTEL_DATA_AND_EVENTS";
export const UPDATE_EVENTS_STATE = "UPDATE_EVENTS_STATE";
export const UPDATE_EVENTS_ARRAY = "UPDATE_EVENTS_ARRAY";

export const GET_HOTEL_DATA_AND_OFFERS = "GET_HOTEL_DATA_AND_OFFERS";
export const UPDATE_OFFERS_STATE = "UPDATE_OFFERS_STATE";
export const UPDATE_OFFERS_ARRAY = "UPDATE_OFFERS_ARRAY";

export const UPDATE_FOOTER = "UPDATE_FOOTER";
export const GET_SELECTED_FOOTER_DATA = "GET_SELECTED_FOOTER_DATA";
export const EMPTY_FOOTER_DATA = "EMPTY_FOOTER_DATA";

export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const GET_SELECTED_PROMOTION_DATA = "GET_SELECTED_PROMOTION_DATA";

export const GET_HOTEL_DATA_AND_FACILITY = "GET_HOTEL_DATA_AND_FACILITY";
export const UPDATE_FACILITY_STATE = "UPDATE_FACILITY_STATE";
export const UPDATE_FACILITY_ARRAY = "UPDATE_FACILITY_ARRAY";
export const TAKE_FACILITY_NAME = "TAKE_FACILITY_NAME";

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const GET_SELECTED_CONTACT_DATA = "GET_SELECTED_CONTACT_DATA";

export const UPDATE_GALLARY = "UPDATE_GALLARY";
export const GET_SELECTED_GALLARY_DATA = "GET_SELECTED_GALLARY_DATA";

export const HOTEL_SERVICES_DATA = "HOTEL_SERVICES_DATA";
