import React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
// Import LayOut
import DefaultLayout from "../layout/DefaultLayout";
import Identity from "../pages/identity/Identity";
// import LogIn from "../pages/login/LogIn";
import FooterMenu from "../pages/menu/FooterMenu";
import CustomPage from "../pages/pages/CustomPage";
import HomePage from "../pages/pages/home-page/HomePage";
import Sections from "../pages/sections/DefaultSections";
import Menu from "../pages/menu/Menu";
import DefaultPage from "../pages/pages/DefaultPage";
import ContactusPage from "../pages/pages/contact-page/ContactusPage";
import RoomsPage from "../pages/pages/room-page/RoomsPage";
import AboutPage from "../pages/pages/about-page/AboutPage";
import TestPage from "../pages/pages/TestPage";
import HomeSlider from "../pages/pages/home-page/HomeSlider";
import VideoSlider from "../pages/pages/home-page/VideoSlider";
import DefaultSections from "../pages/sections/DefaultSections";
import CustomSection from "../pages/sections/CustomSection";
import EditPromotionalBanner from "../pages/sections/EditPromotionalBanner";
import EditNewsletter from "../pages/sections/EditNewsletter";
import EditAboutus from "../pages/sections/Edit_About_Sec/EditAboutus";
import EditRooms from "../pages/sections/Edit_Rooms_Sec/EditRooms";
import EditGallery from "../pages/sections/Edit_Gallery_Sec/EditGallery";
import EditContactus from "../pages/sections/Edit_Contact_Sec/EditContactus";
import EditServices from "../pages/sections/Edit_Services/EditServices";
import EditNearbyplace from "../pages/sections/EditNearByPlaces_Sec/EditNearbyplace";
import EditTestimonial from "../pages/sections/Edit_Testimonial_Sec/EditTestimonial";
import EditFacility from "../pages/sections/Edit_Facility_Sec/EditFacility";
import EditPackage from "../pages/sections/Edit_Package_Sec/EditPackage";
import EditBlog from "../pages/sections/Edit_Blog_Sec/EditBlog";
import GalleryPage from "../pages/pages/gallary-page/GallaryPage";
// import ServicesPage from "../pages/pages/ServicesPage";
// import PackagePage from "../pages/pages/PackagePage";
// import FacilityPage from "../pages/pages/FacilityPage";
// import BlogPage from "../pages/pages/BlogPage";
import BookingBenifits from "../pages/sections/Edit_BookingBenifit_Sec/BookingBenifits";
import EditMarketing from "../pages/sections/EditMarketing";
import EditOffers from "../pages/sections/Edit_Offer_Sec/EditOffers";
import EditJoinnow from "../pages/sections/EditJoinnow";
import EditBrandSection from "../pages/sections/Edit_Brand_Sec/EditBrandSection";
import EditHotelRooms from "../pages/sections/Edit_Rooms_Sec/EditHotelRooms";
import EditHotelRoomType from "../pages/sections/Edit_Rooms_Sec/EditHotelRoomType";
import EditAboutBanner from "../pages/pages/about-page/EditAboutBanner";
import EditRoomBanner from "../pages/pages/room-page/EditRoomBanner";
import EditConatctBanner from "../pages/pages/contact-page/EditConatctBanner";
import EditGallaryBanner from "../pages/pages/gallary-page/EditGallaryBanner";
import EditServicesBanner from "../pages/pages/services-page/EditServicesBanner";
import ServicesPage from "../pages/pages/services-page/ServicesPage";
import EditPackagesBanner from "../pages/pages/packages-page/EditPackagesBanner";
import PackagesPage from "../pages/pages/packages-page/PackagesPage";
import FacilityPage from "../pages/pages/facility-page/FacilityPage";
import EditFacilityBanner from "../pages/pages/facility-page/EditFacilityBanner";
import BlogsPage from "../pages/pages/blogs-page/BlogsPage";
import EditBlogsBanner from "../pages/pages/blogs-page/EditBlogsBanner";
import EditFacebook from "../pages/sections/EditFacebook";
import EditPromotion from "../pages/sections/Edit_Promotion_Sec/EditPromotion";
import EditAwards from "../pages/sections/EditAwards";
import EventsPage from "../pages/pages/events-page/EventsPage";
import EditEventsBanner from "../pages/pages/events-page/EditEventsBanner";
import OffersPage from "../pages/pages/offers-page/OffersPage";
import EditOffersBanner from "../pages/pages/offers-page/EditOffersBanner";
import TestimonialPage from "../pages/pages/testimonial-page/TestimonialPage";
import EditTestimonialBanner from "../pages/pages/testimonial-page/EditTestimonialBanner";
import PromotionsPage from "../pages/pages/promotions-page/PromotionsPage";
import EditPromotionsBanner from "../pages/pages/promotions-page/EditPromotionsBanner";
import HotelPage from "../pages/pages/hotel-page/HotelPage";
import EditHotelBanner from "../pages/pages/hotel-page/EditHotelBanner";
import HotelListPage from "../pages/pages/hotel-list-page/HotelLIstPage";
import EditHotelListBanner from "../pages/pages/hotel-list-page/EditHotelListBanner";
import CustomFooter from "../pages/sections/Edit_CustomFooter_Sec/CustomFooter";
import TestimonialHotelCard from "../pages/sections/Edit_Testimonial_Sec/TestimonialHotelCard";
import EditOffer from "../pages/sections/Edit_Offer_Sec/EditOffers";
import OfferList from "../pages/sections/Edit_Offer_Sec/OfferList";
import EditEvent from "../pages/sections/Edit_Event_Sec/EditEvent";
import CreatedEvent from "../pages/sections/Edit_Event_Sec/CreatedEvent";
import EditIndividualEvent from "../pages/sections/Edit_Event_Sec/EditIndividualEvent";
import LastMinuteDeals from "../pages/sections/Edit_Offer_Sec/LastMinuteDeals";
import PackageList from "../pages/sections/Edit_Package_Sec/PackageList";
import FacilitiesHotelwise from "../pages/sections/Edit_Facility_Sec/FacilitiesHotelwise";
import EditHotelServices from "../pages/sections/Edit_Services/EditHotelServices";
import EditSelectedServices from "../pages/sections/Edit_Services/EditSelectedServices";
import Loginwithoutcredential from "../pages/login/Loginwithoutcredential";
import EditGalleryData from "../pages/sections/Edit_Gallery_Sec/EditGalleryData";
import EditNearPlacesByMap from "../pages/sections/EditNearByPlaces_Sec/EditNearPlacesByMap";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
          element={<Loginwithoutcredential />}
        />
        <Route path="/login" element={<Navigate to={"/"} />} />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Identity />} />

          {/* Menu Routing */}
          <Route path="menu" element={<Outlet />}>
            <Route index element={<Menu />} />
          </Route>

          {/*  Pages Routing */}
          <Route path="pages-default" element={<Outlet />}>
            <Route index element={<DefaultPage />} />

            <Route path="home" element={<Outlet />}>
              <Route index element={<HomePage />} />
              <Route path="home-slider" element={<HomeSlider />} />
            </Route>

            <Route path="about-us" element={<Outlet />}>
              <Route index element={<AboutPage />} />
              <Route path="edit-about" element={<EditAboutBanner />} />
            </Route>

            <Route path="edit-room-page" element={<Outlet />}>
              <Route index element={<RoomsPage />} />
              <Route path="edit-room-banner" element={<EditRoomBanner />} />
            </Route>

            <Route path="edit-contactus-page" element={<Outlet />}>
              <Route index element={<ContactusPage />} />
              <Route
                path="edit-contact-banner"
                element={<EditConatctBanner />}
              />
            </Route>

            {/* ------------------------------ */}
            <Route path="edit-gallery-page" element={<Outlet />}>
              <Route index element={<GalleryPage />} />
              <Route path="edit-gallary" element={<EditGallaryBanner />} />
            </Route>

            <Route path="edit-services-page" element={<Outlet />}>
              <Route index element={<ServicesPage />} />
              <Route path="edit-services" element={<EditServicesBanner />} />
            </Route>

            <Route path="edit-package-page" element={<Outlet />}>
              <Route index element={<PackagesPage />} />
              <Route path="edit-package" element={<EditPackagesBanner />} />
            </Route>

            <Route path="edit-facility-page" element={<Outlet />}>
              <Route index element={<FacilityPage />} />
              <Route path="edit-facility" element={<EditFacilityBanner />} />
            </Route>

            <Route path="edit-blog-page" element={<Outlet />}>
              <Route index element={<BlogsPage />} />
              <Route path="edit-blog" element={<EditBlogsBanner />} />
            </Route>
            {/* ------------------------------ */}

            <Route path="edit-events-page" element={<Outlet />}>
              <Route index element={<EventsPage />} />
              <Route path="edit-event-banner" element={<EditEventsBanner />} />
            </Route>

            <Route path="edit-offers-page" element={<Outlet />}>
              <Route index element={<OffersPage />} />
              <Route path="edit-offer-banner" element={<EditOffersBanner />} />
            </Route>

            <Route path="edit-testimonial-page" element={<Outlet />}>
              <Route index element={<TestimonialPage />} />
              <Route
                path="edit-testimonial-banner"
                element={<EditTestimonialBanner />}
              />
            </Route>

            <Route path="edit-promotions-page" element={<Outlet />}>
              <Route index element={<PromotionsPage />} />
              <Route
                path="edit-promotions-banner"
                element={<EditPromotionsBanner />}
              />
            </Route>
            <Route path="edit-hotel-page" element={<Outlet />}>
              <Route index element={<HotelPage />} />
              <Route path="edit-hotel-banner" element={<EditHotelBanner />} />
            </Route>
            <Route path="edit-hotel-list-page" element={<Outlet />}>
              <Route index element={<HotelListPage />} />
              <Route
                path="edit-hotel-list-banner"
                element={<EditHotelListBanner />}
              />
            </Route>
          </Route>
          <Route path="pages-custom" element={<Outlet />}>
            <Route index element={<CustomPage />} />
          </Route>

          {/*====================== Section  Routing=================== */}
          <Route path="section-default" element={<Outlet />}>
            <Route index element={<DefaultSections />} />
            {/* <Route path="section-default" element={<DefaultSections />} /> */}
            <Route path="section-custom" element={<CustomSection />} />
            <Route path="edit-promotion" element={<EditPromotionalBanner />} />
            <Route path="edit-newsletter" element={<EditNewsletter />} />
            <Route path="edit-hotel-about" element={<EditAboutus />} />
            <Route path="edit-room-details" element={<Outlet />}>
              <Route index element={<EditRooms />} />
              <Route path="edit-hotel-rooms" element={<EditHotelRooms />} />
              <Route
                path="edit-hotel-room-type"
                element={<EditHotelRoomType />}
              />
            </Route>

            <Route
              path="edit-gallery-section-details"
              element={<EditGallery />}
            />
            <Route path="edit-gallery-data" element={<EditGalleryData />} />

            <Route path="edit-contact-us-section" element={<EditContactus />} />
            <Route path="edit-hotel-specifications" element={<Outlet />}>
              <Route index element={<EditServices />} />
              <Route
                path="edit-hotel-services"
                element={<EditHotelServices />}
              />
              <Route
                path="edit-selected-services"
                element={<EditSelectedServices />}
              />
            </Route>
            <Route
              path="edit-facebook-section-details"
              element={<EditFacebook />}
            />

            <Route
              path="edit-promotions-section-details"
              element={<EditPromotion />}
            />

            <Route
              path="edit-awards-section-details"
              element={<EditAwards />}
            />

            <Route
              path="edit-marketing-section-details"
              element={<EditMarketing />}
            />

            <Route
              path="edit-join-now-section-details"
              element={<EditJoinnow />}
            />

            <Route
              path="edit-brand-section-details"
              element={<EditBrandSection />}
            />

            <Route path="edit-custom-footer" element={<CustomFooter />} />

            <Route
              path="edit-bookings-benefits"
              element={<BookingBenifits />}
            />

            <Route path="edit-blog-section-details" element={<EditBlog />} />

            <Route path="edit-testimonial-section-details" element={<Outlet />}>
              <Route index element={<EditTestimonial />} />
              <Route
                path="edit-testimonial-hotels"
                element={<TestimonialHotelCard />}
              />
            </Route>

            {/* -------------------------------- */}
            <Route path="edit-hotel-offers" element={<Outlet />}>
              <Route index element={<EditOffer />} />
              <Route path="offer-list" element={<Outlet />}>
                <Route index element={<OfferList />} />
                <Route
                  path={`last-minute-deals/:param`}
                  element={<LastMinuteDeals />}
                />
              </Route>
            </Route>

            <Route path="edit-event-section-details" element={<Outlet />}>
              <Route index element={<EditEvent />} />
              <Route path="created-event" element={<Outlet />}>
                <Route index element={<CreatedEvent />} />
                <Route
                  path={`edit-individual-event/:param`}
                  element={<EditIndividualEvent />}
                />
              </Route>
            </Route>

            <Route path="edit-nearby-places" element={<Outlet />}>
              <Route index element={<EditNearbyplace />} />
              <Route
                path="edit-nearplaces-by-map"
                element={<EditNearPlacesByMap />}
              />
            </Route>

            <Route path="edit-package-section-details" element={<Outlet />}>
              <Route index element={<EditPackage />} />
              <Route path="package-list" element={<PackageList />} />
            </Route>

            <Route path="edit-facility-section-details" element={<Outlet />}>
              <Route index element={<EditFacility />} />
              <Route
                path="edit-hotel-facilites"
                element={<FacilitiesHotelwise />}
              />
            </Route>

            {/* <Route path="edit-nearby-places" element={<Outlet />}>
              <Route index element={<EditNearbyplace />} />
              <Route
                path="edit-nearplaces-by-map"
                element={<EditNearPlacesByMap />}
              />
            </Route>

            {/* 
            <Route path="edit-facility-section-details" element={<Outlet />}>
              <Route index element={<EditFacility />} />
              <Route
                path="edit-hotel-facilites"
                element={<FacilitiesHotelwise />}
              />
            </Route>

            <Route path="edit-package-section-details" element={<Outlet />}>
              <Route index element={<EditPackage />} />
              <Route path="package-list" element={<PackageList />} />
            </Route>
            
            <Route path="edit-blog-section-details" element={<EditBlog />} />
            <Route
              path="edit-bookings-benefits"
              element={<BookingBenifits />}
            />
            <Route
              path="edit-marketing-section-details"
              element={<EditMarketing />}
            />
            <Route path="edit-hotel-offers" element={<EditOffers />} />
            <Route
              path="edit-join-now-section-details"
              element={<EditJoinnow />}
            />
            <Route
              path="edit-brand-section-details"
              element={<EditBrandSection />}
            />
            <Route path="edit-gallery-data" element={<EditGalleryData />} />
            <Route path="select-services" element={<Outlet />}>
              <Route index element={<SelectServices />} />
              <Route path="add-restrurant" element={<AddRestrurant />} />
            </Route>

            <Route path="edit-event-section-details" element={<Outlet />}>
              <Route index element={<EditEvent />} />
              <Route path="created-event" element={<Outlet />}>
                <Route index element={<CreatedEvent />} />
                <Route
                  path="edit-individual-event"
                  element={<EditIndividualEvent />}
                />
              </Route>
            </Route>
            
            <Route path="edit-hotel-offers" element={<Outlet />}>
              <Route index element={<EditOffer />} />
              <Route path="offer-list" element={<Outlet />}>
                <Route index element={<OfferList />} />
                <Route path="last-minute-deals" element={<LastMinuteDeals />} />
              </Route>
            </Route>

            <Route
              path="edit-facebook-section-details"
              element={<EditFacebook />}
            />
            <Route
              path="edit-promotions-section-details"
              element={<EditPromotion />}
            />
            <Route
              path="edit-awards-section-details"
              element={<EditAwards />}
            />
            <Route path="edit-custom-footer" element={<CustomFooter />} /> */}
          </Route>

          <Route path="section-default/:param" element={<Outlet />}>
            <Route index element={<DefaultSections />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;
