import { GET_HOTEL_DATA_AND_OFFERS, UPDATE_OFFERS_ARRAY } from "../actionTypes";

const initialState = {
    hotel_id: '', hotel_name: '', offersArr: []
}

const OffersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_HOTEL_DATA_AND_OFFERS:
            return {
                ...action.payload,
            }
        case UPDATE_OFFERS_ARRAY:
            return {
                ...state,
                offersArr: action.payload,
            }


        default: return state
    }
}

export default OffersReducer;