import { GET_SELECTED_GALLARY_DATA } from "../actionTypes";

const initialState = {
    category_name: '',
    id: '',
    galleryDataArray: []
}

const GallaryDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SELECTED_GALLARY_DATA:
            return {
                ...action.payload,
            }


        default: return state
    }
}

export default GallaryDataReducer;