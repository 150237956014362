import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DropZone } from "../../views/dropzone/DropZone";
import InputTextField from "../../views/inputfield/InputTextField";
import Card from "../../views/datacard/Card";
import Button from "../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../UtilityFunctions";
import wbApi from "../../API/wbApi";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import DropzoneArea from "../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../Components/breadCrumb/CustomBreadcrumbs";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../redux/actions/PromptAction";

const EditJoinnow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [refreshList, setRefreshList] = useState<boolean>(false);

  const [joinNowBannerImg, setJoinNowBannerImg] = useState<any>();
  // const [newDataUpload, setNewDataUpload] = useState();
  const [joinNowFile, setJoinNowFile] = useState<any[]>([]);
  const [dataId, setDataId] = useState<any>("undefined");
  const [joinNowSectionDetails, setJoinNowSectionDetails] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);


  const accpJoinNowFiles = (value: any) => {
    setJoinNowFile(value);
  };

  const deleteJoinNowFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deleteJoinNowFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const deleteJoinNowFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: joinNowSectionDetails.background_image,
          section_id: defaultSectionId,
          template_id: template_id,
        }
      );
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        joinNowApi();
      } else {
        toast.error(deleteRes.data.message);
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Save Handler function
  const saveHandler = async () => {
    if (joinNowFile.length) {
      uploadHandler();
    } else {
      addUpdateJoinNow("undefined");
    }
  };

  const uploadHandler = async () => {
    if (joinNowFile.length === 1) {
      let payload = new FormData();
      payload.append("uploadFile[0]", joinNowFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/join_now_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        if (uploadRes.data.status === 1) {
          await addUpdateJoinNow(uploadRes.data.image_ids);

        } else {
          toast.error(uploadRes.data.message);

        }
      } catch {
        toast.error(CATCH_MESSAGE);

      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };

  const addUpdateJoinNow = async (ImgId: any) => {
    setLoader(true);
    try {
      const resp = await wbApi.post(
        `wb/insert-update-default-section-details`,
        {
          background_image: ImgId ?? "undefined",
          background_image_alt: altText,
          company_id: company_id,
          id: dataId ?? "undefined",
          section_description: description,
          section_id: defaultSectionId,
          section_title: title,
          template_id: template_id,
        }
      );
      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        joinNowApi();
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(resp.data.message);
        setLoader(false);
      };
    } catch {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Handler function to retreieve the data for the section
  const joinNowApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-join-now-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setDataId(resp?.data?.join_now_section_details?.id);
        setTitle(resp?.data?.join_now_section_details?.section_title);
        setAltText(resp?.data?.join_now_section_details?.background_image_alt);
        setDescription(resp?.data?.join_now_section_details?.section_description ?? "");
        if (resp?.data?.join_now_section_details?.background_image) {
          setJoinNowBannerImg([
            getImage(resp?.data?.join_now_section_details?.background_image),
          ]);
        } else {
          setJoinNowBannerImg([]);
        }
        setJoinNowSectionDetails(resp?.data?.join_now_section_details);
      } else {
        setJoinNowBannerImg([]);
        setDescription("");
        setAltText("");
        setTitle("");
        setDataId("undefined")
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    joinNowApi();
  }, []);

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Section",
              href: "/section-default",
            },
            {
              label: `Join Now`,
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>
      {/*------------------- Main Section----------------- */}

      <CardWrapper className="mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => setTitle(e.target.value)}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => setAltText(e.target.value)}
          />
        </div>
        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>
        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={joinNowBannerImg}
            dispatchFiles={(val) => accpJoinNowFiles(val)}
            isDelete={(val) => deleteJoinNowFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>
        <div className="sec-description-editor" style={{ margin: "1rem 0rem" }}>
          <div>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </div>
        </div>
        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button className="savebtn" handleClick={() => saveHandler()} loader={loader}>
            Save
          </Button>
        </div>
      </CardWrapper>
    </>
  );
};

export default EditJoinnow;
