import { useNavigate } from "react-router-dom";
import Button from "../../../views/buttons/Button";
import { DropZone } from "../../../views/dropzone/DropZone";
import InputTextField from "../../../views/inputfield/InputTextField";
import SaveButton from "../../../views/buttons/Button";
import CancelButton from "../../../views/buttons/Button";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import wbApi from "../../../API/wbApi";
import { toast } from "react-toastify";
import { Capitalize, CATCH_MESSAGE, Logout } from "../../../UtilityFunctions";
import { getAllHotelsAndOffers } from "../../../redux/actions/OffersAction";
import { nanoid } from "@reduxjs/toolkit";
import CardWrapper from "../../../views/datacard/CardWrapper";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const EditOffer = () => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const updateOffer = useSelector((state: RootState) => state.offersSectUpdate);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );

  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [offerSectId, setOfferSectId] = useState("");
  const [description, setDescription] = useState<any>("");
  const [offersArr, setOffersArr] = useState([]);
  const [hotelsArr, setHotelsArr] = useState([]);
  const [offerImageFile, setOfferImageFile] = useState<any[]>([]);
  const [incomingImageFile, setIncomingImageFile] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });

  const [update, setUpdate] = useState("");

  const dispatch = useDispatch();

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const getOffersDataApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-offer-section-details/${company_id}/${template_id}/${defaultSectionId}`
      );
      if (resp.data.status === 1) {
        setOffersArr(resp?.data?.hotel_offers);
        setTitle(resp?.data?.offers_section_details?.section_title);
        setDescription(resp?.data?.offers_section_details?.section_description ?? "");
        setAltText(resp?.data?.offers_section_details?.background_image_alt);
        setOfferSectId(resp?.data?.offers_section_details?.id);
        setHotelsArr(resp?.data?.hotel_details);
        // if(resp?.data.)
        // setIncomingImageFile()
      } else {
        setOffersArr([]);
        setTitle("");
        setDescription("");
        setAltText("");
        setOfferSectId("undefined");

      }
    } catch (error) {
      // toast.error(CATCH_MESSAGE);
      console.log(error)
    }
  };

  useEffect(() => {
    getOffersDataApi();
  }, [update, updateOffer]);

  const accpAboutFiles = (value: any) => {
    setOfferImageFile(value);
  };

  const delteAboutFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteOfferSecFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  const delteOfferSecFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          // image_name: backgroundImg,
          template_id: template_id,
          section_id: defaultSectionId,
        }
      );
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        getOffersDataApi();
      } else {
        toast.error(deleteRes.data.message);
        getOffersDataApi();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };

  function saveHandler() {
    if (offerImageFile.length) {
      uploadHandler();
    } else {
      postOfferSectDataApi("undefined");
    }
  }

  const uploadHandler = async () => {
    if (offerImageFile.length === 1) {


      let payload = new FormData();

      payload.append("uploadFile[0]", offerImageFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/offer_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await postOfferSectDataApi(uploadRes.data.image_ids);

        } else {
          toast.error(uploadRes.data.message);

        }
      } catch (error: any) {

        if (error.response.status === 401) {
          Logout();
          toast.error("Session Expired");
        } else {
          toast.error(CATCH_MESSAGE);
        }
      }

    } else {
      toast.error("Only One Image Allowed");
    }

  };

  const postOfferSectDataApi = async (ImgIds: any) => {
    setLoader(true);
    try {
      const payload = {
        background_image: ImgIds ?? "undefined",
        background_image_alt: altText,
        company_id,
        id: offerSectId ?? "undefined",
        section_description: description,
        section_id: defaultSectionId,
        section_title: title,
        template_id,
      };
      const resp = await wbApi.post(
        `wb/insert-update-default-section-details`,
        payload
      );

      if (resp.data.status === 1) {
        toast.success(resp.data.message);
        setUpdate(nanoid());
        setRefreshList(!refreshList);
        setLoader(false);
      } else {
        toast.error(resp.data.message);
        setLoader(false);
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Offer",
            },
          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      {/*------------------- Main Section----------------- */}
      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>

        <div className="edit-promo-alt-label mt-3">
          Select Back Ground Image
        </div>

        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here(1 Image Only)"}
            incomingfiles={incomingImageFile}
            dispatchFiles={(val) => accpAboutFiles(val)}
            isDelete={(val) => delteAboutFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <div className="">
            <CKEditor
              editor={ClassicEditor}
              data={description}
              config={{
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                ],
              }}
              onChange={(event: string, editor: any) => {
                const data = editor.getData();
                setDescription(data);
                setCharCount({
                  ...charCount,
                  terms_cond: removeTags(data).length,
                });
              }}
            />
          </div>
        </div>

        <div className="edit-contact-btn-container">
          <Button className="cancel__btn" handleClick={() => navigate(-1)}>Cancel</Button>
          <Button
            className="savebtn"
            handleClick={() => saveHandler()}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      {hotelsArr.length > 0 ? (
        <div className="select__hotel__label">Select Hotels</div>
      ) : (
        <></>
      )}

      <div className="default_section_image_card_container mt-3">
        {hotelsArr.length > 0 ? (
          hotelsArr.map((item: any) => {
            return (
              <div
                key={item.hotel_id}
                className="default__setion__image"
                onClick={() => {
                  dispatch(
                    getAllHotelsAndOffers({ hotel_id: item.hotel_id, hotel_name: item.hotel_name, offersArr })
                  );
                  navigate("offer-list");
                }}
              >
                <div className="image__container">
                  {
                    item?.hotel_image ? (
                      <img
                        src={item.hotel_image}
                        alt={item.hotel_name}
                        className="default__setion__image__image"
                      />
                    ) : (
                      <div className="image_container_icon_wrapper">
                        <FontAwesomeIcon icon={Icons.faHotel} size="2x" color="blue" />
                      </div>

                    )
                  }
                  <div className="hotel__name">{Capitalize(item.hotel_name)}</div>
                </div>
              </div>

            );
          })
        ) : (
          <NoDataComponent message="No Hotels Found" />
        )}
      </div>
    </>
  );
};

export default EditOffer;
