import React, { useCallback, useEffect, useState } from "react";
import SliderSectionCard from "../../../views/datacard/SliderSectionCard";
import Button from "../../../views/buttons/Button";
import InputTextField from "../../../views/inputfield/InputTextField";
import { Draggable } from "react-drag-reorder";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";

interface IServiceSliderProps {
  onClose: () => void;
}
const RoomServiceSlider: React.FC<IServiceSliderProps> = ({ onClose }) => {
  // Data From Redux
  const { company_id } = useSelector((state: RootState) => state.auth);
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { hotelRoomTypeDetails, hotelRoomIdOnClick } = useSelector(
    (state: RootState) => state.sections
  );

  // States
  const [searchInput, setSearchInput] = useState<any>("");
  const [filteredResults, setFilteredResults] = useState<any[]>([]);
  const [roomServiceName, setRoomServiceName] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [updateOrder, setUpdateOrder] = useState<any[]>([]);
  const [allIcons, setAllIcons] = useState<any[]>([]);
  const [selectedIcons, setSelectedIcons] = useState<any>("");
  const [roomServices, setRoomServices] = useState<any[]>([]);
  const [serviceId, setServiceId] = useState<any>("undefined");
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRoomServiceDetails()
  }, [])

  const fetchRoomServiceDetails = async () => {
    try {
      let response = await wbApi.get(`/wb-dashboard/fetch-wb-room-services/${company_id}/${template_id}`);
      if (response.data.status === 1) {
        setFilteredResults(response?.data?.all_icons);
        setAllIcons(response?.data?.all_icons);
        let roomServiceDetails = response?.data?.all_room_services?.filter((iteam: any) =>
          iteam.room_type_id === hotelRoomTypeDetails?.room_type_id
        )?.[0]
        setRoomServices(roomServiceDetails?.room_services ?? []);
        setServiceId(roomServiceDetails?.id);
      } else {
        setFilteredResults([]);
        setAllIcons([])
        setRoomServices([]);
        // setServiceId("")
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Search Input Handler
  const searchItems = (searchValue: any) => {
    const keyword = searchValue;
    if (keyword !== "") {
      const filteredData = allIcons.filter((item: any) => {
        return item.name
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
      });
      setFilteredResults(filteredData);
      // console.log(filteredData, "filteredData");
    } else {
      setFilteredResults(allIcons);
    }
    setSearchInput(keyword);
  };



  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...roomServices];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    setUpdateOrder(orderSec);
  };

  const DraggableRender = useCallback(() => {
    if (roomServices?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {roomServices?.map((item: any, idx: any) => {
            return (
              <SliderSectionCard
                key={idx}
                className="slider__draggable"
                onDelete={() => {
                  deletHandler(item.id, idx);
                }}
                editOptn={false}
                deletOptn={true}
              >
                {item.service_title}
              </SliderSectionCard>

            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [roomServices]);

  const addRoomServiceHandler = async () => {
    let roomServiceArray = [...roomServices, {
      service_title: roomServiceName,
      icon: selectedIcons.icon,
      icon_name: selectedIcons.name
    }]
    setLoader(true);
    try {
      let categoryReq = await wbApi.post("/wb/insert-update-wb-room-services", {
        id: serviceId ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelRoomIdOnClick.hotel_id,
        room_type_id: hotelRoomTypeDetails.room_type_id,
        room_services: roomServiceArray
      });
      if (categoryReq.data.status === 1) {
        toast.success(categoryReq.data.message);
        fetchRoomServiceDetails();
        setLoader(false);
        onClose();
      } else {
        toast.error(categoryReq.data.message);
        setLoader(false);
        onClose();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
  };

  // Category Delet Handler
  const deletHandler = (id: any, index: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          let services = roomServices;
          let deletPayload = services.splice(index, 1) ?? [];
          await deletRoomService(services ?? []);

          dispatch(CancelConfirmationPrompt());
        }
      )
    );

  };

  // Delet Category Post
  const deletRoomService = async (deletPayload: any) => {
    try {
      let deletReq = await wbApi.post("/wb/insert-update-wb-room-services", {
        id: serviceId ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelRoomIdOnClick.hotel_id,
        room_type_id: hotelRoomTypeDetails.room_type_id,
        room_services: deletPayload ?? []
      });
      // console.log("deletReq", deletReq);
      if (deletReq.data.status === 1) {
        toast.success(deletReq.data.message);
        fetchRoomServiceDetails();
        onClose();
      } else {
        toast.error(deletReq.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  // Api To Reorder Categories List
  const updateOrderList = async () => {
    setUpdateLoader(true);
    try {
      let orderReq = await wbApi.post(`/wb/insert-update-wb-room-services`, {
        id: serviceId ?? "undefined",
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelRoomIdOnClick.hotel_id,
        room_type_id: hotelRoomTypeDetails.room_type_id,
        room_services: updateOrder
      });
      if (orderReq.data.status === 1) {
        toast.success(orderReq.data.message);
        setUpdateLoader(false);
        fetchRoomServiceDetails();
        onClose();
      } else {
        toast.error(orderReq.data.message);
        setUpdateLoader(false);
      }
    } catch {
      setUpdateLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  }

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Room Services</div>
      </div>
      <div className="height-64px"></div>

      <div className="search__booking__wrapper mb-0">
        <i className="bi bi-search"></i>
        <input
          placeholder="Search Icons"
          value={searchInput}
          onChange={(e) => {
            searchItems(e.target.value);
          }}
        />
      </div>

      <div className="icon_container mt-2 mb-2">
        {
          filteredResults.length > 0 ? filteredResults?.map((item) => {
            return (
              <div
                key={item.id}
                className={`icon_wrapper ${item.id === selectedIcons.id ? "selectedIcon" : ""}`}
                onClick={() => setSelectedIcons(item)}>
                <i className={`${item.icon} me-2 `}></i>
                {item.name}
              </div>
            )
          }) : (<h5>No Icons Found</h5>)
        }
      </div>



      <InputTextField
        label="Room Services Name"
        value={roomServiceName}
        className="description description__slider"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setRoomServiceName(e.target.value);
        }}
      />
      <div className="category_list_wrapper">
        <div className="list_label">List</div>
        <hr />


        {
          roomServices?.length > 0 ? (
            <div className="drag_list_wrapper">
              <DraggableRender />
            </div>
          ) : (
            <NoDataComponent message={"No Services Found"} />
          )
        }

      </div>
      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <Button className="cancel__btn">Cancel</Button>
        {
          roomServices.length > 1 && <Button
            handleClick={() => updateOrderList()}
            loader={updateLoader}
          >
            Update Order
          </Button>
        }

        <Button
          handleClick={() => addRoomServiceHandler()}
          loader={loader}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default RoomServiceSlider;
