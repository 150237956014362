import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import { RootState } from "../../../redux/store";
import { CATCH_MESSAGE, getImage, Logout } from "../../../UtilityFunctions";
import Button from "../../../views/buttons/Button";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import CardWrapper from "../../../views/datacard/CardWrapper";
import InputTextField from "../../../views/inputfield/InputTextField";

const VideoOffersBanner = ({ offersPageDataFetching }: any) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { page_Id } = useSelector((state: RootState) => state.pages);
  const { offersPageData } = useSelector((state: RootState) => state.pages);
  const { offer_page_details } = offersPageData ?? {};
  const [loader, setLoader] = useState<boolean>(false);
  const [titel, setTitle] = useState<any>("");
  const [altTesxt, setAltText] = useState<any>("");
  const [link, setLink] = useState<any>("");
  const [bannerFiles, setBannerFiles] = useState<any[]>([]);
  const [inComingbannerFiles, setinComingBannerFiles] = useState<any[]>([]);
  const [refreshBannerFiles, setRefreshBannerFiles] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(offer_page_details?.banner_title);
    setAltText(offer_page_details?.banner_alt);
    setLink(offer_page_details?.video_banner_link);
    if (offer_page_details?.video_banner) {
      setinComingBannerFiles([getImage(offer_page_details?.video_banner)]);
    } else {
      setinComingBannerFiles([]);
    }
  }, [offer_page_details]);

  const accpBannerFiles = (val: any) => {
    setBannerFiles(val);
  };

  // Api To Update Order Of Images
  const uploadHandler = async () => {
    if (bannerFiles.length) {
      if (bannerFiles.length === 1) {
        setLoader(true);

        let payload = new FormData();

        payload.append("uploadFile[0]", bannerFiles[0]);
        try {
          let uploadRes = await wbApi.post(
            `/wb/upload-page-banner/${company_id}/${template_id}/offerbanner`,
            payload,
            {
              headers: {
                Authorization: "Bearer " + auth_token,
              },
            }
          );
          // console.log("uploadRes", uploadRes);
          if (uploadRes.data.status === 1) {
            await insertImageData(uploadRes.data.video_id[0]);
            setLoader(false);
          } else {
            toast.error(uploadRes.data.message);
            setLoader(false);
          }
        } catch {
          toast.error(CATCH_MESSAGE);
          setLoader(false);
        }
      } else {
        toast.error("Only One Image Required");
      }
    } else {
      insertImageData(offer_page_details?.video_banner_id ?? "");
    }

  };

  // Api To Insert Data
  const insertImageData = async (videoData: any) => {
    setLoader(true);
    try {
      let insertDataRes = await wbApi.post(
        `/wb/insert-update-page-banner`,
        {
          id: offer_page_details?.id ?? "undefined",
          banner: "",
          page_id: page_Id ?? "",
          banner_alt: altTesxt,
          banner_title: titel,
          video_banner: videoData,
          video_banner_link: link,
          company_id: company_id,
          template_id: template_id,
          page_type: "default",
        },

        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      // console.log("insertDataRes", insertDataRes);
      if (insertDataRes.data.status === 1) {
        toast.success(insertDataRes.data.message);
        setinComingBannerFiles([]);
        setRefreshBannerFiles(!refreshBannerFiles);
        setLoader(false);
        offersPageDataFetching();
      } else {
        toast.error(insertDataRes.data.message);
        offersPageDataFetching();
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
      if (error.response.status === 401) {
        Logout();
        toast.error("Session Expired");
      } else {
        toast.error(CATCH_MESSAGE);
      }
    }
  };

  //   Api To Delete Image
  const deletRes = (val: boolean) => {
    if (val) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await deleteBannerImage();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };
  const deleteBannerImage = async () => {
    try {
      let deleteRes = await wbApi.post(`/wb/delete-dz-page-banner`, {
        banner_name: offer_page_details?.video_banner,
        company_id: company_id,
        template_id: template_id,
        page_id: page_Id,
        file_type: "videos",
      });
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        setinComingBannerFiles([]);
        offersPageDataFetching();
      } else {
        toast.error(deleteRes.data.message);
        offersPageDataFetching();
      }
    } catch { }
  };
  return (
    <>
      <CardWrapper className="edit-banner-card-wrapper">
        <div className="edit-banner-left-side">
          <InputTextField
            className="edit-abt-inpt"
            label="Enter Titel On Banner"
            value={titel}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            className="edit-abt-inpt"
            label="Enter Alternate Text"
            value={altTesxt}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAltText(e.target.value);
            }}
          />
          <InputTextField
            className="edit-abt-inpt"
            label="Enter YouTube Link"
            value={link}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLink(e.target.value);
            }}
          />
        </div>
        <div className="edit-banner-right-side">
          <DropzoneArea
            centerMesage={"Drop Your Video Here Or Click Here (1 Video)"}
            className="banner-dropzone"
            incomingfiles={inComingbannerFiles}
            dispatchFiles={(val) => accpBannerFiles(val)}
            isDelete={(val) => deletRes(val)}
            refresh={refreshBannerFiles}
            acceptFileExt="video/mp4,video/x-m4v,video/*"
          />
        </div>
      </CardWrapper>
      <div className="mt-2 mb-2">
        <Button
          className="save-btn"
          loader={loader}
          handleClick={() => uploadHandler()}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default VideoOffersBanner;
