import { LOGIN_SUCCESS, DIRECT_LOGIN } from "../actionTypes";

export const LoginSuccess = (auth: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload: auth,
  };
};
export const DirectLogInAction = (auth: any) => {
  return {
    type: DIRECT_LOGIN,
    payload: auth,
  };
};
