import React, { useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DropZone } from "../../../views/dropzone/DropZone";
import InputTextField from "../../../views/inputfield/InputTextField";
import Button from "../../../views/buttons/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CancelConfirmationPrompt, ShowConfirmationPrompt } from "../../../redux/actions/PromptAction";
import { toast } from "react-toastify";
import wbApi from "../../../API/wbApi";
import { CATCH_MESSAGE } from "../../../UtilityFunctions";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";


interface IAddTestimonySliderProps {
  onClose: () => void;
  testimonialApi: () => void;
  hotelId: number;
}

const AddTestimonySlider: React.FC<IAddTestimonySliderProps> = ({
  onClose,
  testimonialApi,
  hotelId
}) => {
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [altText, setAltText] = useState<string>("");
  const [incomeImage, setIncomingImage] = useState<any[]>([]);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [description, setDescription] = useState<any>("");
  const [testimonialFile, setTestimonialFile] = useState<any[]>([]);
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const accpAboutFiles = (value: any) => {
    setTestimonialFile(value);
  };

  const delteAboutFiles = (value: any) => {
    if (value) {
      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteEditTestimonialFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };
  function saveHandler() {
    if (testimonialFile.length) {
      uploadHandler();
    } else {
      addUpdateTestimonail("undefined");
    }
  }
  const uploadHandler = async () => {
    if (testimonialFile.length === 1) {
      setLoader(true);

      let payload = new FormData();

      payload.append("uploadFile[0]", testimonialFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/hoteltestimonial`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateTestimonail(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };

  const addUpdateTestimonail = async (image_ids: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-wb-testimonial", {
        id: "undefined",
        testimonial_image: image_ids ?? "undefined",
        testimonial_image_alt: altText,
        company_id: company_id,
        template_id: template_id,
        hotel_id: hotelId,
        testimonial_title: name,
        testimonial_description: description
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        testimonialApi();
        setRefreshList(!refreshList);
        setLoader(false);
        onClose();
      } else {
        toast.error(req.data.message);
        testimonialApi();
        setLoader(false);
        onClose();
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };
  const delteEditTestimonialFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-testimonial-image`,
        {
          company_id: company_id,
          // image_name: roomData.background_image,
          template_id: template_id,
          section_id: defaultSectionId,
        }
      );
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        testimonialApi();
      } else {
        toast.error(deleteRes.data.message);
        testimonialApi();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">Add Testimonial</div>
      </div>
      <div className="height-64px"></div>
      <InputTextField
        label="Enter Name"
        value={name}
        className="description description__slider"
        handleChange={(e) => setName(e.target.value)}
      />
      <InputTextField
        label="Alternate Text"
        value={altText}
        className="description description__slider"
        handleChange={(e) => setAltText(e.target.value)}
      />
      <div className="mb-2">Choose Image</div>
      <DropzoneArea
        centerMesage={"Drag your photos or click here(1 Image Only)"}
        incomingfiles={incomeImage}
        dispatchFiles={(val) => accpAboutFiles(val)}
        isDelete={(val) => delteAboutFiles(val)}
        acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
        refresh={refreshList}
      />

      <div className="__default--input--field mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={description}
          config={{
            removePlugins: [
              "EasyImage",
              "ImageUpload",
              "MediaEmbed",
              "Table",
            ],
          }}
          onChange={(event: string, editor: any) => {
            const data = editor.getData();
            setDescription(data);
            setCharCount({
              ...charCount,
              terms_cond: removeTags(data).length,
            });
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>
      <div className="side__slider__panel__btn">
        <div className="side__slider__panel__btn_container">
          <Button className="cancel__btn" handleClick={() => {
            onClose();
          }}>Cancel</Button>
          <Button loader={loader} handleClick={() => saveHandler()}>Save</Button>
        </div>
      </div>
    </>
  );
};

export default AddTestimonySlider;
