import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import Card from "../../../views/datacard/Card";
import DropzoneArea from "../../../views/customDropzone/DropzoneArea";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import SlidingPane from "react-sliding-pane";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import wbApi from "../../../API/wbApi";
import { CATCH_MESSAGE, getImage } from "../../../UtilityFunctions";
import { getSeletedContactData } from "../../../redux/actions/ContactAction";
import CustomBreadcrumbs from "../../../Components/breadCrumb/CustomBreadcrumbs";
import Button from "../../../views/buttons/Button";
import CardWrapper from "../../../views/datacard/CardWrapper";
import InputTextField from "../../../views/inputfield/InputTextField";
import ContactSlider from "./AddContactSlider";
import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../../redux/actions/PromptAction";
import AddContactSlider from "./AddContactSlider";
import NoDataComponent from "../../../Components/noDataComponent/NoDataComponent";
import EditContactUsSlider from "./EditContactUsSlider";
import { Draggable } from "react-drag-reorder";
import RectangleSectionCard from "../../../views/datacard/RectangleSectionCard";

const EditContactus = () => {
  // Data From Redux
  const { company_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { template_id } = useSelector((state: RootState) => state.template_id);
  const { defaultSectionId } = useSelector(
    (state: RootState) => state.sections
  );
  const update = useSelector((state: RootState) => state.contact);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [contactUsBannerId, setContactUsBannerId] = useState<any>("");
  const [getData, setGetData] = useState([]);
  const [allIcons, setAllIcons] = useState<any[]>([]);
  const [contactSectionDetails, setContactSectionDetails] = useState<any>([]);
  const [contactUsFile, setContactUsFile] = useState<any[]>([]);
  const [contactUsBannerImg, setContactUsBannerImg] = useState<any[]>([]);
  const [addSlider, setAddSlider] = useState<boolean>(false);
  const [editSlider, setEditSlider] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [description, setDescription] = useState<any>("");
  const [charCount, setCharCount] = useState({
    child: 0,
    privacy: 0,
    terms_cond: 0,
    cancellation: 0,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const accpContactUsFiles = (value: any) => {
    setContactUsFile(value);
  };

  const deleteContactApi = async (id: any) => {

    dispatch(
      ShowConfirmationPrompt(
        `Are you sure want to delete this ?`,
        "DELETE",
        async () => {
          try {
            const resp = await wbApi.post(`wb/delete-wb-contact-details`, {
              company_id,
              template_id,
              id,
            });

            if (resp.data.status === 1) {
              toast.success(resp.data.message);
              contactsApi();
            } else {
              toast.error(resp.data.message);
            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }
          dispatch(CancelConfirmationPrompt());
        }
      )
    );


  };

  function removeTags(str: String) {
    str = str.toString();
    const finalStr = str.replace(/&nbsp;/g, " ");
    return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
  }

  const saveHandler = async () => {
    if (contactUsFile.length) {
      uploadHandler();
    } else {
      addUpdateContactUs("undefined");
    }
  };

  const uploadHandler = async () => {

    if (contactUsFile.length === 1) {
      setLoader(true);
      let payload = new FormData();
      payload.append("uploadFile[0]", contactUsFile[0]);
      try {
        let uploadRes = await wbApi.post(
          `/wb/insert-dz-images/${company_id}/${template_id}/contact_background_image`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );
        // console.log("uploadRes", uploadRes);
        if (uploadRes.data.status === 1) {
          await addUpdateContactUs(uploadRes.data.image_ids);
          setLoader(false);
        } else {
          toast.error(uploadRes.data.message);
          setLoader(false);
        }
      } catch {
        toast.error(CATCH_MESSAGE);
        setLoader(false);
      }
    } else {
      toast.error("Only One Image Allowed")
    }

  };

  // Update ContactUs Section
  const addUpdateContactUs = async (contactUsBannerImg: any) => {
    setLoader(true);
    try {
      let req = await wbApi.post("/wb/insert-update-default-section-details", {
        id: contactUsBannerId ?? "undefined",
        background_image: contactUsBannerImg ?? "undefined",
        background_image_alt: altText,
        company_id: company_id,
        section_id: defaultSectionId,
        section_title: title,
        template_id: template_id,
        section_description: description,
      });
      if (req.data.status === 1) {
        toast.success(req.data.message);
        contactsApi();
        setLoader(false);
        setRefreshList(!refreshList);

      } else {
        toast.error(req.data.message);
        contactsApi();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  const contactsApi = async () => {
    try {
      const resp = await wbApi.get(
        `wb-dashboard/fetch-wb-contact-section-details/${company_id}/${template_id}/${defaultSectionId}`
      )
      if (resp.data.status === 1) {
        setGetData(resp?.data?.wb_contact_details ?? []);
        setAllIcons(resp?.data?.all_icons ?? []);
        setContactSectionDetails(resp?.data?.contact_section_details ?? {});
        setContactUsBannerId(resp?.data?.contact_section_details?.id ?? "");
        setTitle(resp?.data?.contact_section_details?.section_title ?? "");
        setAltText(resp?.data?.contact_section_details?.background_image_alt ?? "");
        if (resp?.data?.contact_section_details?.background_image) {
          setContactUsBannerImg([
            getImage(resp?.data?.contact_section_details?.background_image),
          ]);
        } else {
          setContactUsBannerImg([]);
        }
        setDescription(resp?.data?.contact_section_details?.section_description ?? "");
      } else {
        setGetData([]);
        setAllIcons([]);
        setContactSectionDetails([]);
      }
    } catch (error) {
      console.log(error)
      // toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    contactsApi();
  }, [update]);

  const delteGalleryFiles = (value: any) => {
    if (value) {

      dispatch(
        ShowConfirmationPrompt(
          `Are you sure want to delete this ?`,
          "DELETE",
          async () => {
            await delteGalleryFilesApi();

            dispatch(CancelConfirmationPrompt());
          }
        )
      );
    }
  };

  // Delete Service Background Image
  const delteGalleryFilesApi = async () => {
    try {
      let deleteRes = await wbApi.post(
        `/wb/delete-dz-default-section-background-image`,
        {
          company_id: company_id,
          image_name: contactSectionDetails?.background_image,
          template_id: template_id,
          section_id: defaultSectionId,
        }
      );
      // console.log("deleteRes", deleteRes);
      if (deleteRes.data.status === 1) {
        toast.success(deleteRes.data.message);
        contactsApi();
      } else {
        toast.error(deleteRes.data.message);
        contactsApi();
      }
    } catch {
      toast.error(CATCH_MESSAGE);
    }
  };
  // Ordering Menus
  const positionHandler = (currentPos: any, newPos: any) => {
    const orderSec = [...getData];
    const result = orderSec.splice(currentPos, 1);
    const upDateSec = orderSec.splice(newPos, 0, ...result);
    // setUpdateOrder(orderSec);
    // console.log(orderSec, "orderSec");
  };


  const DraggableRender = useCallback(() => {
    if (getData && getData?.length > 0) {
      return (
        <Draggable onPosChange={positionHandler}>
          {getData.map((sec: any, idx: any) => {
            return (
              <>
                <div className="section-card-wrapper" key={sec.id}>
                  <RectangleSectionCard
                    onDelet={() => {
                      deleteContactApi(sec.id);
                    }}
                    onEdit={() => {
                      dispatch(getSeletedContactData(sec));
                      setEditSlider(true);
                    }}
                  >
                    {sec.address_name}
                  </RectangleSectionCard>
                </div>
              </>
            );
          })}
        </Draggable>
      );
    }
    return null;
  }, [getData]);



  return (
    <>
      <div className="wb-subheader">
        <CustomBreadcrumbs
          content={[
            {
              label: "Sections",
              href: "/section-default",
            },
            {
              label: `Default Section`,
              href: "/section-default",
            },
            {
              label: " Edit Contact Us",
            },

          ]}
        />
        <div className="wb-subheader-rightsec">
          <Button
            icon="bi bi-arrow-left"
            className="bck-btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className="wb-divider"></div>

      <div className="top__button__container">
        <Button
          icon="bi bi-info-circle"
          handleClick={() => {
            setAddSlider(true);
          }}
        >
          Add Address
        </Button>
      </div>
      {/* -------------------------------- */}

      <CardWrapper className=" mt-4 mb-4 edit-promo-conatiner">
        <div className="edit-aboutus-text-container">
          <InputTextField
            label="Enter Title"
            value={title}
            className="edit-aboutus-subheader"
            handleChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputTextField
            label="Alternate Text"
            value={altText}
            className="edit-aboutus-alt-text"
            handleChange={(e) => {
              setAltText(e.target.value);
            }}
          />
        </div>
        <div className="edit-promo-alt-label">Select Back Ground Image</div>
        <div className="edit__dropzone__container">
          <DropzoneArea
            centerMesage={"Drag your photos or click here (1 Image Only)"}
            incomingfiles={contactUsBannerImg}
            dispatchFiles={(val) => accpContactUsFiles(val)}
            isDelete={(val) => delteGalleryFiles(val)}
            acceptFileExt="image/jpg,image/jpeg,image/png,image/gif"
            refresh={refreshList}
          />
        </div>

        <div
          className="sec-description-editor"
          style={{ margin: "1.5rem 0rem" }}
        >
          <CKEditor
            editor={ClassicEditor}
            data={description}
            config={{
              removePlugins: [
                "EasyImage",
                "ImageUpload",
                "MediaEmbed",
                "Table",
              ],
            }}
            onChange={(event: string, editor: any) => {
              const data = editor.getData();
              setDescription(data);
              setCharCount({
                ...charCount,
                terms_cond: removeTags(data).length,
              });
            }}
          />
        </div>

        <div className="edit-contact-btn-container">
          <Button
            className="cancel__btn"
            handleClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            className="savebtn"
            handleClick={() => {
              saveHandler();
            }}
            loader={loader}
          >
            Save
          </Button>
        </div>
      </CardWrapper>

      {getData?.length > 0 ? (
        <>
          <div className="someTitle">Edit Contact</div>
          {/* {DATA_ARR} */}
          <DraggableRender />
        </>
      ) : (
        <NoDataComponent message={"No Contacts Found"} />
      )}

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={addSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddSlider(false)}
      >
        <AddContactSlider
          onClose={() => {
            setAddSlider(false);
          }}
          allIcon={allIcons}
          contactsApi={contactsApi}
        // categoryList={categoryList}
        // fetchSection={fetchSection}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="35%"
        isOpen={editSlider}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditSlider(false)}
      >
        <EditContactUsSlider
          onClose={() => {
            setEditSlider(false);
          }}
          allIcon={allIcons}
          contactsApi={contactsApi}
        // categoryList={categoryList}
        // fetchSection={fetchSection}
        />
      </SlidingPane>
    </>
  );
};

export default EditContactus;
